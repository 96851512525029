import { modalTypes } from '../modalTypes'

import {
	ICancel,
	IConfirm,
	IDecline,
	IHideModal,
	IHideProgress,
	IShowModal,
	IShowProgress,
} from './IModelManagerActions'
import { modalManagerTypes } from './modalManagerTypes'
import {
	IConfirmationModalProps,
	IErrorModalProps,
	IModalProps,
	INotificationModalProps,
	IProgressModalProps,
	IMetaModelManagerModalProps,
	IGitTokenManagerModalProps,
	INotificationPopupProps,
} from 'src/interfaces/IModalProps'

const confirm = (): IConfirm => ({
	type: modalManagerTypes.CONFIRM,
})
const cancel = (): ICancel => ({
	type: modalManagerTypes.CANCEL,
})
const decline = (): IDecline => ({
	type: modalManagerTypes.DECLINE,
})

const showModal = (modalType: Exclude<modalTypes, modalTypes.PROGRESS>, modalProps: IModalProps): IShowModal => {
	return {
		type: modalManagerTypes.SHOW_MODAL,
		modalType,
		modalProps,
	}
}

const hideModal = (id: string): IHideModal => {
	return {
		type: modalManagerTypes.HIDE_MODAL,
		id,
	}
}

const showConfirmationModal = (modalProps: IConfirmationModalProps) => showModal(modalTypes.CONFIRMATION, modalProps)
const showNotificationModal = (modalProps: INotificationModalProps) => showModal(modalTypes.NOTIFICATION, modalProps)
const showMetaModelManagerModal = (modalProps: IMetaModelManagerModalProps) =>
	showModal(modalTypes.META_MODEL_MANAGER, modalProps)
const showGitTokenManagerModal = (modalProps: IGitTokenManagerModalProps) =>
	showModal(modalTypes.GIT_TOKEN_MANAGER, modalProps)
const showErrorModal = (error: Error, buttonText?: string, onClose?: () => void) => {
	const modalProps: IErrorModalProps = {
		title: 'Error',
		message: error.message,
		actions: [
			{
				name: buttonText ? buttonText : 'Reload',
				onExecute: () => {
					if (onClose) {
						return onClose()
					} else {
						return window.location.reload()
					}
				},
			},
		],
	}
	return showModal(modalTypes.ERROR, modalProps)
}
const showNotificationPopup = (modalProps: INotificationPopupProps) =>
	showModal(modalTypes.NOTIFICATION_POPUP, modalProps)

const showProgress = (modalProps: IProgressModalProps): IShowProgress => {
	return {
		type: modalManagerTypes.SHOW_PROGRESS,
		modalProps,
	}
}
const hideProgress = (): IHideProgress => {
	return {
		type: modalManagerTypes.HIDE_PROGRESS,
	}
}

export const modalManagerActions = {
	confirm,
	cancel,
	decline,

	showNotificationPopup,
	showErrorModal,
	showConfirmationModal,
	showNotificationModal,
	showMetaModelManagerModal,
	showGitTokenManagerModal,
	hideModal,

	showProgress,
	hideProgress,
}
