import React, { useState } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import type { MenuItem } from './MenuItem.types'
import { e_MenuItemType } from './MenuItem.types'
import { PopupMenuElement } from './PopupMenuElement'

const classes = createStyle((theme) => ({
	menuControl: {
		outline: 'none',
		backgroundColor: theme.colors.menu.background,
		boxShadow: theme.shadows.medium,
		overflow: 'hidden',
		borderRadius: theme.controls.button.borderRadius,
		'& > div:first-child': {
			marginTop: '0.35em',
		},
		'& > div:last-child  ': {
			marginBottom: '0.35em',
		},
		overflowY: 'auto',
		maxHeight: '100vh',
	},
}))
interface IProps {
	items: MenuItem[]
	onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void
	blockReturnFocus?: boolean
	anchorEl?: React.RefObject<HTMLElement | undefined>
	iconColorClass?: string
	menuStyle?: React.CSSProperties
}

export const PopupMenuContent = React.forwardRef((props: IProps, ref: React.Ref<HTMLDivElement>) => {
	const [selectedIndex, setSelectedIndex] = useState<number>()

	const setSelectedIndexInMenu = (index?: number) => {
		setSelectedIndex(index === undefined ? undefined : index)
	}

	const closeWithFocus = (e: React.KeyboardEvent | React.MouseEvent) => {
		props.onClose?.(e)
		if (!props.blockReturnFocus) {
			props.anchorEl?.current?.focus()
		}
	}
	const handleKeyDown = (e: React.KeyboardEvent) => {
		const hasItems = props.items.length > 0

		switch (e.key) {
			case 'ArrowDown': {
				if (selectedIndex === undefined) {
					setSelectedIndexInMenu(0)
				} else {
					let newIndex = selectedIndex + 1
					if (newIndex > props.items.length - 1) {
						newIndex = 0
					}
					if (props.items[newIndex].type === e_MenuItemType.divider) {
						newIndex++
					}
					if (hasItems && newIndex > props.items.length - 1) {
						newIndex = 0
					}
					setSelectedIndexInMenu(newIndex)
				}

				e.preventDefault()
				e.stopPropagation()
				break
			}
			case 'ArrowUp': {
				if (hasItems && selectedIndex === undefined) {
					setSelectedIndexInMenu(props.items.length - 1)
				} else if (selectedIndex) {
					let newIndex = selectedIndex - 1
					if (hasItems && newIndex < 0) {
						newIndex = props.items.length - 1
					}
					if (props.items[newIndex].type === e_MenuItemType.divider) {
						newIndex--
					}
					if (hasItems && newIndex < 0) {
						newIndex = props.items.length - 1
					}
					setSelectedIndexInMenu(newIndex >= 0 ? newIndex : undefined)
				}

				e.preventDefault()
				e.stopPropagation()
				break
			}
			case 'ArrowLeft':
			case 'Escape': {
				closeWithFocus(e)

				e.preventDefault()
				e.stopPropagation()
				break
			}
		}
	}

	const menuItems = props.items
	return (
		<div
			className={classNames(classes.menuControl)}
			style={{ ...props.menuStyle }}
			ref={ref}
			onKeyDown={handleKeyDown}
			tabIndex={0}
			onMouseDown={(e) => e.stopPropagation()}
			data-cy="popup-menu-content"
		>
			{menuItems &&
				menuItems.map((item, index) => {
					return (
						<PopupMenuElement
							onActivate={closeWithFocus}
							isSelected={index === selectedIndex}
							iconColorClass={props.iconColorClass}
							key={`${item.type}_${index}`}
							index={index}
							item={item}
						/>
					)
				})}
		</div>
	)
})

PopupMenuContent.displayName = 'PopupMenuContent'
