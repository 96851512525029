import React, { forwardRef, useRef } from 'react'
import clsx from 'clsx'
import { e_LabelContentLayout } from '../../../FormControl'
import { Icon } from '../../../Icon'
import { Link } from '../../../Link'
import { e_RenderType } from '../../Table.types'
import { CELL_PADDING } from '../../consts'
import { createStyle } from '../../../../theming'
import { useCombineRefs } from '../../../utils/useCombineRefs'

interface ICellIconProps {
	nodeId: string
	iconColor: string | undefined
	iconName: string | undefined
	position: 'before' | 'after'
	renderType: e_RenderType
	onIconClick?: (targetEl: React.RefObject<HTMLElement>) => void
	dataAttributes?: Record<string, string>
	className?: string
}

const classes = createStyle({
	link: {
		display: 'flex',
	},
})

const syncedHoverClass = 'synced-hover-skmqu'

export const CellIcon = forwardRef((props: ICellIconProps, forwardedRef) => {
	const { nodeId, iconName } = props

	const iconButtonRef = useRef<HTMLAnchorElement>(null)
	const ref = useCombineRefs(iconButtonRef, forwardedRef)

	if (!iconName) {
		return null
	}

	const paddingSide = props.position === 'before' ? { paddingRight: CELL_PADDING } : { paddingLeft: CELL_PADDING }
	const cellIconStyle = props.renderType === e_RenderType.none ? { padding: `0 ${CELL_PADDING}px` } : paddingSide

	if (props.onIconClick) {
		return (
			<Link
				key={`${nodeId}-${iconName}`}
				icon={iconName}
				ref={ref}
				onActivate={() => props.onIconClick?.(iconButtonRef)}
				url={'#'}
				displayName={''}
				contentLayout={e_LabelContentLayout.icon}
				className={clsx(classes.link, props.className, syncedHoverClass)}
				color={props.iconColor}
				dataAttributes={props.dataAttributes}
			/>
		)
	}

	return (
		<Icon
			ref={ref}
			className={clsx(props.className, syncedHoverClass)}
			key={`${nodeId}-${iconName}`}
			color={props.iconColor}
			iconClassName={iconName}
			style={cellIconStyle}
			dataAttributes={props.dataAttributes}
		/>
	)
})

CellIcon.displayName = 'CellIcon'
