import { e_DataType } from '../enums/e_DataType'
import { e_Interpretation } from '../enums/e_Interpretation'
import { e_ComparatorUsage, makeComparator } from './makeComparator'
import type {
	Column,
	GetQuickFilterTextParams,
	ITooltipParams,
	KeyCreatorParams,
	IRowNode,
	SuppressKeyboardEventParams,
	ValueFormatterParams,
	ValueGetterParams,
	ColumnState,
	ColDef,
} from '@ag-grid-community/core'
import type { TData, CellData, Value, IFormatCellValue } from '../Table.types'
import { formatGroupColumnOrGroupRowValue } from './formatGroupCellValue'
import { hasCellAggregatedData } from './hasCellAggregatedData'

type FilterTypes = 'agTextColumnFilter' | 'agDateColumnFilter' | 'agNumberColumnFilter' | 'agSetColumnFilter'

// Function to return one of three Simple Filters for filtering strings, numbers and dates, provided by the grid.
export const getFilter = (dataType: e_DataType | undefined, interpretation: e_Interpretation | undefined) => {
	if (interpretation === e_Interpretation.domainReference) {
		return 'agTextColumnFilter'
	}

	switch (dataType) {
		case e_DataType.date:
		case e_DataType.dateTime:
			return 'agDateColumnFilter'
		case e_DataType.int:
		case e_DataType.float:
			return 'agNumberColumnFilter'
		default:
			return 'agTextColumnFilter'
	}
}

// Function to return a string key for a value.
export const keyCreator = (params: KeyCreatorParams<TData, CellData | Value>) => {
	const value = getFormattedValue(params.value)
	if (value == null || value === '') {
		return '---'
	}

	return value.toString()
}

type CellValues = CellData | Date | string | number | null

// Override the default sorting order by providing a custom sort comparator.
export const getComparator = (dataType = e_DataType.string, reverse = false, filterType?: FilterTypes) => {
	const comparator = makeComparator(dataType, filterType ? e_ComparatorUsage.filtering : e_ComparatorUsage.sorting)

	return (
		valueA: CellValues,
		valueB: CellValues,
		nodeA: IRowNode<TData> | undefined,
		nodeB: IRowNode<TData> | undefined
	) => {
		const a =
			valueA !== null && filterType && typeof valueA === 'object' && 'value' in valueA
				? getFilterValue(valueA, filterType)
				: getSortingValue(valueA, nodeA, dataType)
		const b =
			valueB !== null && filterType && typeof valueB === 'object' && 'value' in valueB
				? getFilterValue(valueB, filterType)
				: getSortingValue(valueB, nodeB, dataType)

		return reverse ? comparator(b, a) : comparator(a, b)
	}
}

export const getSortingValue = (value: CellValues, node: IRowNode<TData> | undefined, dataType: e_DataType) => {
	if (node?.group) {
		if (node.groupData && Object.values(node.groupData)?.[0].sortValue !== undefined) {
			// This is okey for now, but we might have several layers of groupData and this would then just get a random one.
			// At some point we might figure out exactly when ag-Grid-AutoColumn is used and if we can rely on that as the key.
			// There have been cases where this is not the key, so we need to be careful.
			return Object.values(node.groupData)[0].sortValue as CellData['sortValue']
		}
		return node.key
	}
	if (value == null) {
		return value
	}
	if (typeof value === 'string' || typeof value === 'number') {
		return value
	}
	if (value instanceof Date) {
		return value.toISOString()
	}
	if (typeof value === 'object' && 'sortValue' in value && value.sortValue !== undefined) {
		return value.sortValue
	}
	if (
		dataType === e_DataType.int ||
		dataType === e_DataType.float ||
		dataType === e_DataType.date ||
		dataType === e_DataType.dateTime
	) {
		return value?.value
	}
	return value?.formattedValue ?? value?.value
}

export const getFilterValue = (value: CellData, filtering: FilterTypes) => {
	if (filtering === 'agTextColumnFilter') {
		return value.formattedValue ?? value.value
	}

	return value.value
}

export type FormatterTValue = CellData | { value: Value; values: Value[] } | Value
type FormatterParams = ValueFormatterParams<TData, FormatterTValue>

const applyFormatting = (value: Value, params: FormatterParams): Value => {
	const formatCellValue = params.colDef.cellRendererParams?.formatCellValue as IFormatCellValue | undefined
	if (formatCellValue) {
		return formatCellValue(value)
	}
	return value
}

const getValueFromFormatterTValue = (value: FormatterTValue) => {
	if (typeof value === 'object') {
		return value?.value
	}
	return value
}

const formatValueWithCallback = (value: FormatterTValue, params: FormatterParams) => {
	return applyFormatting(getValueFromFormatterTValue(value), params)
}

// A function or expression to format a value, should return a string.
export const getValueFormatter =
	(isCheckMarkControl: boolean, hasSummaryFunction: boolean, tcvi: (t: string) => string) =>
	(params: FormatterParams) => {
		if (params.node?.footer) {
			return formatFooterCellValue(hasSummaryFunction, params)
		}
		if (params.node?.group) {
			return formatGroupColumnOrGroupRowValue(
				params.data,
				params.value,
				params.node,
				params.colDef,
				params.column,
				tcvi
			)
		}

		return formatNormalCell(params.value, params.colDef.cellRendererParams?.formatCellValue, isCheckMarkControl, tcvi)
	}

/**
 * Checks if the cell is the type that shows aggregated data for a group when the group is collapsed, but lacks data.
 * @param node
 * @param colDef
 * @param column
 * @returns
 */
export const isGroupRowAggregationCellWithoutData = (
	node: IRowNode<TData> | null,
	colDef: ColDef<TData, FormatterTValue> | ColDef<TData, CellData | string>,
	column: Column<FormatterTValue>
) => {
	return node?.group && !column.isRowGroupActive() && !hasCellAggregatedData(node, colDef) && !node.footer
}

const formatFooterCellValue = (hasSummaryFunction: boolean, formatterParams: FormatterParams): string => {
	if (!hasSummaryFunction) {
		return ''
	}
	return formatValueWithCallback(formatterParams.value, formatterParams)?.toString() ?? ''
}

export const formatNormalCell = (
	cellDataValue: FormatterTValue,
	cellRendererParamsFormatCellValue: IFormatCellValue | undefined,
	isCheckMarkControl: boolean,
	tcvi: (t: string) => string
): string => {
	if (isCheckMarkControl) {
		return getValueFromFormatterTValue(cellDataValue) ? tcvi('GENERAL:YES') : tcvi('GENERAL:NO')
	}
	if (cellDataValue === undefined || cellDataValue === null) {
		return ''
	}
	if (typeof cellDataValue === 'object' && 'values' in cellDataValue) {
		return cellDataValue.values.join(', ')
	}
	if (typeof cellDataValue === 'object' && 'value' in cellDataValue) {
		return getFormattedValue(cellDataValue).toString()
	}
	if (cellRendererParamsFormatCellValue) {
		return cellRendererParamsFormatCellValue(cellDataValue)?.toString() ?? ''
	}

	return cellDataValue.toString()
}

export const getFormattedValue = (value: CellData | Value) => {
	if (typeof value === 'object') {
		return value?.formattedValue ?? value?.value ?? ''
	}
	return value ?? ''
}

export const getTooltipValueGetter =
	(
		isAvatarCell: boolean,
		isCheckmarkCell: boolean,
		getTooltip: ((nodeId: string | undefined, colId: string | undefined) => string | undefined) | undefined,
		toolTip: string | undefined,
		tcvi: (t: string) => string
	) =>
	(params: ITooltipParams<TData, CellData>) => {
		if (!params.node) {
			return
		}

		const { value } = params

		if (params.node.group || params.node.footer) {
			return (params.valueFormatted ?? value?.value)?.toString()
		}

		const screenTip = getTooltip?.(params.node?.id, (params.column as Column | undefined)?.getId?.()) ?? toolTip

		const booleanString = value?.value ? tcvi('GENERAL:YES') : tcvi('GENERAL:NO')
		const checkmarkTitle = isCheckmarkCell ? booleanString : (value?.formattedValue ?? value?.value)?.toString()

		const defaultTitle = isAvatarCell ? value?.avatarLabel : checkmarkTitle

		return value?.error ?? screenTip ?? defaultTitle
	}

// Params to be passed to the filter component specified in filter or filterFramework.
export const getFilterParams = (
	dataType: e_DataType | undefined,
	interpretation: e_Interpretation | undefined,
	isCheckMarkControl: boolean,
	hasSummaryFunction: boolean,
	tcvi: (t: string) => string
) => {
	const filter = getFilter(dataType, interpretation)

	// PBU 07.12.2023: From the second code snippet here https://www.ag-grid.com/react-data-grid/filter-multi/#enabling-the-multi-filter
	// we can see that the compare function is reversed in the date filter. I don't know why AgGrid ha chosen to do it like this, but that's the reason why the comparator is reversed here.
	// The confirmation that the comparator is used like this can be found in scalarFilter.mjs of the AgGrid source code in evaluateNonNullValue. Especially the IN_RANGE case makes this clear.
	return {
		filters: [
			{
				filter: filter,
				display: 'subMenu',
				filterParams: {
					buttons: ['apply', 'reset'],
					closeOnApply: true,
					excelMode: 'windows',
					newRowsAction: 'keep',
					textFormatter: (params: Value) => {
						if (params === undefined || params === null) {
							return ''
						} else if (typeof params === 'string') {
							return params.toLocaleLowerCase()
						} else {
							return params.toString()
						}
					},
					comparator: filter === 'agDateColumnFilter' ? getComparator(e_DataType.date, true, filter) : undefined,
				},
			},
			{
				filter: 'agSetColumnFilter',
				filterParams: {
					buttons: ['apply', 'reset'],
					closeOnApply: true,
					excelMode: 'windows',
					newRowsAction: 'keep',
					valueFormatter: (params: FormatterParams) => {
						return getValueFormatter(isCheckMarkControl, hasSummaryFunction, tcvi)(params)
					},
					comparator: filter === 'agDateColumnFilter' ? getComparator(e_DataType.date, false, filter) : undefined,
				},
			},
		],
	}
}

// Suppress certain keyboard events in the grid cell.
export const suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
	const code = params.event.code
	if (params.event.ctrlKey && (code === 'ArrowUp' || code === 'ArrowDown' || code === 'Space')) {
		params.event.preventDefault()
		return true
	}

	return false
}

export const getQuickFilterText = (params: GetQuickFilterTextParams<TData, CellData | Value>) => {
	if (typeof params.value === 'object' && params.value != null) {
		return getFormattedValue(params.value).toString()
	}
	return params.value?.toString() ?? ''
}

export const getCellDataFromGroupNodeChild = (rowNode: IRowNode<TData> | null) => {
	if (rowNode?.field) {
		return rowNode?.allLeafChildren?.[0]?.data?.[rowNode.field]
	}
}

export const getAutoGroupValueGetter = (isTreeData: boolean | undefined) => {
	if (!isTreeData) {
		return
	}
	return (params: ValueGetterParams<TData, string | CellData>) => {
		if (params.data?.rowName) {
			return params.data.rowName ?? null
		}
		return null
	}
}
export const removeUpdatedConfigs = (columnDefMap: Record<string, ColDef>, columnState: ColumnState[]) => {
	return columnState.map((cs) => {
		if (columnDefMap[cs.colId]?.sortable === false && cs.sort) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { sort, sortIndex, ...rest } = cs
			return { ...rest }
		}
		return cs
	})
}
