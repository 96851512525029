import { useSelector } from 'react-redux'
import { e_EnvironmentType } from 'src/enums/e_EnvironmentType'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'

const useOperatorRuntime = () => {
	const operatorRuntime = useSelector(kubernetesSelectors.selectK8sRuntimesIncludingOperator).find(
		(runtime) => runtime.environmentType === e_EnvironmentType.operator
	)
	if (operatorRuntime === undefined) {
		throw new Error('Operator runtime not found')
	}

	return operatorRuntime
}

export const operatorDeploymentUtils = {
	useOperatorRuntime,
}
