import Ajv, { AnySchema } from 'ajv'
import yaml from 'js-yaml'

const ajv = new Ajv({ allErrors: true, verbose: true, allowUnionTypes: true, strict: false })

export const validateHelmValues = (jsonSchema: AnySchema, values: string) => {
	try {
		if (!jsonSchema) {
			const message = 'Invalid schema'
			const validity = false
			return { message, validity }
		}

		const data = yaml.load(values)

		if (!data) {
			const message = 'Invalid schema'
			const validity = false
			return { message, validity }
		}

		const validate = ajv.compile(jsonSchema)
		const valid = validate(data)

		if (!valid) {
			//validate.errors
			const message = 'The Helm values you provided are not valid. Please correct the errors and try again.'
			const validity = false
			return { message, validity, errors: validate.errors || [] }
		}

		const message = 'All good!'
		const validity = true
		return { message, validity }
	} catch (err) {
		const message = (accessPropertyOfAnyTypedObject(err, 'message') as string) ?? 'unknown' //Er dette string?
		const validity = false
		return { message, validity }
	}
}

// utils

const isValidObj = (obj: any): obj is object => obj && obj === 'object'
const objHasProperty = <K extends PropertyKey>(obj: object, key: K): obj is { [x in typeof key]: any } =>
	Object.hasOwn(obj, key)
const accessPropertyOfAnyTypedObject = (obj: any, key: PropertyKey) =>
	(isValidObj(obj) && objHasProperty(obj, key) && obj[key]) || undefined
