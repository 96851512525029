import type { CSSProperties } from 'react'
import React, { useState, useEffect, useCallback, useRef } from 'react'

// Enums and Interfaces
import { e_SortOrder } from '../../enums/e_SortOrder'
import type { ITableContext, TData } from '../../Table.types'
import type { IHeaderParams } from '@ag-grid-community/core'
import { ColumnHeaderFilter } from './components/ColumnHeaderFilter'
import { ColumnHeaderContent } from './components/ColumnHeaderContent'
import { HeaderCheckMarkWrapper } from './components/HeaderCheckMarkWrapper'
import { VerticalColumnHeader } from './components/VerticalColumnHeader'
import { HorizontalColumnHeaderWrapper } from './components/HorizontalColumnHeaderWrapper'
import { GROUP_AUTO_COLUMN_ID } from '@ag-grid-community/core'

export interface ITableColumnHeaderProps {
	textAlignment?: 'right' | 'left' | 'center'
	iconPlacement?: 'before' | 'after'
	screenTip?: string
	iconName?: string
	hideLabel?: boolean
	disableFilter?: boolean
	disableSorting?: boolean
	inPreviewMode?: boolean
	isVertical?: boolean
	multiSelect?: boolean
	cellStyle?: CSSProperties
	alwaysShowMenu?: boolean
}

export const ColumnHeader = (props: ITableColumnHeaderProps & IHeaderParams<TData, ITableContext>) => {
	const { hideLabel = false, textAlignment = 'left' } = props

	const [sorting, setSorting] = useState(props.column.getSort() ?? undefined)

	const columnId = props.column.getColId()

	const filterIsAllowed = props.column.isFilterAllowed() && !props.disableFilter

	const menuButtonAnchor = useRef<HTMLDivElement>(null)
	const mounted = useRef(true)

	const handleHeaderClick = useCallback(
		(e: React.MouseEvent | React.TouchEvent | KeyboardEvent) => {
			if (props.disableSorting) {
				return
			}

			switch (sorting) {
				case e_SortOrder.asc:
					props.setSort(e_SortOrder.desc, e.shiftKey)
					break
				case e_SortOrder.desc:
					props.setSort(e_SortOrder.asc, e.shiftKey)
					break
				default:
					props.setSort(e_SortOrder.asc, e.shiftKey)
					break
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.setSort, sorting]
	)

	const onContextMenu = useCallback(
		(event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (!filterIsAllowed) {
				return
			}

			event?.preventDefault()

			const anchor = event?.currentTarget || menuButtonAnchor.current

			if (anchor) {
				props.showColumnMenu(anchor)
			}
		},
		[filterIsAllowed, props]
	)

	const onKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				if (e.ctrlKey) {
					onContextMenu()

					return
				}

				handleHeaderClick(e)
			}
		},
		[handleHeaderClick, onContextMenu]
	)

	const onSortChange = useCallback(() => {
		const currentSorting = props.column.getSort()

		switch (currentSorting) {
			case e_SortOrder.asc:
				mounted.current && setSorting(e_SortOrder.asc)
				break
			case e_SortOrder.desc:
				mounted.current && setSorting(e_SortOrder.desc)
				break
			case null:
			default:
				mounted.current && setSorting(undefined)
				break
		}
	}, [props.column])

	useEffect(() => {
		props.eGridHeader.addEventListener('keydown', onKeyDown)
		props.column.addEventListener('sortChanged', onSortChange)
		return () => {
			props.eGridHeader.removeEventListener('keydown', onKeyDown)
			props.column.removeEventListener('sortChanged', onSortChange)
		}
	}, [onSortChange, onKeyDown, props.eGridHeader, props.column])

	useEffect(() => {
		return () => {
			mounted.current = false
		}
	}, [])

	const isAutoGroup = columnId === GROUP_AUTO_COLUMN_ID

	return props.isVertical ? (
		<VerticalColumnHeader
			displayName={props.displayName}
			sorting={sorting}
			onClick={handleHeaderClick}
			screenTip={props.screenTip}
			hideLabel={hideLabel}
			iconName={props.iconName}
			iconPlacement={props.iconPlacement}
		/>
	) : (
		<HorizontalColumnHeaderWrapper
			api={props.api}
			columnId={columnId}
			hasDrag={!!props.context.onDragStart}
			content={
				<ColumnHeaderContent
					displayName={props.displayName}
					hideLabel={hideLabel}
					iconName={props.iconName}
					iconPlacement={props.iconPlacement}
					screenTip={props.screenTip}
					sorting={sorting}
					textAlignment={textAlignment}
					onClick={handleHeaderClick}
					cellStyle={props.cellStyle}
					isAutoGroup={isAutoGroup}
				/>
			}
			disableSorting={props.disableSorting}
			filter={
				<ColumnHeaderFilter
					ref={menuButtonAnchor}
					column={props.column}
					alwaysShowMenu={props.alwaysShowMenu}
					textAlignment={textAlignment}
					onColumnMenuClick={() => props.showColumnMenu(menuButtonAnchor.current as HTMLElement)}
				/>
			}
			column={props.column}
			filterIsAllowed={filterIsAllowed}
			multiSelectCheckMark={
				<HeaderCheckMarkWrapper
					api={props.api}
					columnId={columnId}
					context={props.context}
					inPreviewMode={props.inPreviewMode}
					eGridHeader={props.eGridHeader}
					multiSelect={props.multiSelect}
				/>
			}
		/>
	)
}
