import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { Dialog } from '@genusbiz/web-ui/controls/Dialog'
import { modalTypes } from './modalTypes'
import { modalManagerActions } from './duck'
import { useTranslation } from 'react-i18next'
import { TextInput } from '@genusbiz/web-ui/controls'
import { operatorApi } from 'src/modules/operatorApi'
import { operatorFrontendActions } from '../OperatorFrontend/duck/operatorFrontendActions'

interface IGitTokenManagerModal {
	hideModal: (type: modalTypes) => void
}

export const GitTokenManagerModal = (props: IGitTokenManagerModal) => {
	const dispatch = useDispatch()

	const [gitToken, setGitToken] = useState('')

	const { t } = useTranslation()

	const onConfirm = () => {
		props.hideModal(modalTypes.NOTIFICATION)
		operatorApi
			.setGitPersonalAccessToken(gitToken)
			.catch((error: Error) => dispatch(modalManagerActions.showErrorModal(error)))

		dispatch(operatorFrontendActions.setWarningMessage())
		dispatch(modalManagerActions.confirm())
	}
	const onCancel = () => {
		props.hideModal(modalTypes.NOTIFICATION)
		dispatch(modalManagerActions.confirm())
	}

	return (
		<Dialog
			isOpen
			title={t('GIT_TOKEN:GIT_TOKEN')}
			width={800}
			height={'auto'}
			buttonDescriptions={[
				{
					title: 'Cancel',
					isEnabled: true,
					isDefault: false,
					isCancel: true,
					onClick: onCancel,
				},
				{
					title: 'OK',
					isEnabled: gitToken.length > 0,
					isDefault: true,
					isCancel: false,
					onClick: onConfirm,
				},
			]}
			disableCancelOnClickOutside
			disableCancelOnEscape
		>
			<TextInput label={t('GIT_TOKEN:NEW_GIT_TOKEN')} value={gitToken} onChange={(str) => setGitToken(str)} />
		</Dialog>
	)
}
