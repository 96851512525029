import React from 'react'
import { e_DeploymentJobState } from 'src/enums/e_DeploymentJobState'
import { createStyle } from '@genusbiz/web-ui/theming'
import { Icon } from '@genusbiz/web-ui/controls'
import classNames from 'clsx'

const classes = createStyle((theme) => ({
	spinner: {
		width: '20px',
		height: '20px',
		padding: '4px',
		marginLeft: '10px',
		aspectRatio: 1,
		borderRadius: '50%',
		background: theme.palette.primary.themePrimary,
		'--_m': 'conic-gradient(#0000 10%,#000), linear-gradient(#000 0 0) content-box',
		mask: 'var(--_m)',
		maskComposite: 'subtract',
		animation: 'loadSpinner 1s infinite linear',
		animationName: '$loadSpinner',
	},
	'@keyframes loadSpinner': { to: { transform: 'rotate(1turn)' } },
	invertedColorSpinner: {
		background: theme.palette.background.neutralLighter,
	},

	success: {
		'--background-color': theme.palette.success.successDark,
		fontSize: 'smaller',
		color: theme.palette.background.neutralLighter,
	},

	error: {
		'--background-color': theme.palette.error.errorPrimary,
		fontSize: 'large',
		color: theme.palette.background.neutralLighter,
	},

	roundBackground: {
		'&::before': {
			background: 'var(--background-color)',
			borderRadius: '20px',
			display: 'block',
			width: '20px',
			height: '20px',
			boxShadow: '0px 0px 1px 2px white',
			textAlign: 'center',
			fontWeight: '900',
		},
	},
}))

interface IStateIconProps {
	state: e_DeploymentJobState
	className?: string
	invertSpinner?: boolean
}

export const StateIcon = (props: IStateIconProps) => {
	switch (props.state) {
		case e_DeploymentJobState.pending:
		case e_DeploymentJobState.inProgress:
			return (
				<div
					className={classNames(classes.spinner, props.className, props.invertSpinner && classes.invertedColorSpinner)}
				/>
			)
		case e_DeploymentJobState.succeeded:
			return (
				<Icon
					iconName={'Fluent-CheckMark'}
					size="size20"
					className={classNames(props.className, classes.roundBackground, classes.success)}
				/>
			)
		case e_DeploymentJobState.failed:
			return (
				<Icon
					iconName={'Fluent-StatusCircleErrorX'}
					size="size20"
					className={classNames(props.className, classes.roundBackground, classes.error)}
				/>
			)
		default:
			return <></>
	}
}
