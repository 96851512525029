import { semverCompare } from 'src/utils/semverCompare'
import semver from 'semver'

const findNewerVersion = (version: string, versions: string[]) => {
	const currentVersion = semver.parse(version)

	if (!currentVersion) {
		return
	}

	const parsedVersions = versions.map((version) => (semver.valid(version) ? semver.parse(version) : null))
	const validVersions = filterNullish(parsedVersions)
	const correctMajorMinorVersions = validVersions?.filter(
		(version) => version.major === currentVersion.major && version.minor === currentVersion.minor
	)

	const sortedVersions = correctMajorMinorVersions.toSorted((a, b) => semverCompare(a, b))
	const newestVersion = sortedVersions.at(0)

	if (!newestVersion) {
		return
	}

	const isNewer = semverCompare(currentVersion, newestVersion)

	if (isNewer === 1) {
		return newestVersion
	}
}

const filterNullish = <T>(values: (T | null | undefined)[]) => values.filter((value) => value) as T[]

export const versioningUtils = {
	findNewerVersion,
}
