import { ICommonRootState } from 'src/interfaces/ICommonRootState'

const selectDeploymentVersions = (state: ICommonRootState) => state.versionDeployment.deploymentVersions

const selectVersionOptions = (state: ICommonRootState) => state.versionDeployment.versionOptions
const selectOperatorVersionOptions = (state: ICommonRootState) => state.versionDeployment.operatorVersionOptions

const selectJobStates = (state: ICommonRootState) => state.versionDeployment.jobStates

const selectVersionSchema = (state: ICommonRootState) => state.versionDeployment.versionSchema
const selectRuntimesValues = (state: ICommonRootState) => state.versionDeployment.runtimeValues
const selectRuntimesValueErrors = (state: ICommonRootState) => state.versionDeployment.runtimeValueErrors

const selectChosenOperatingTypes = (state: ICommonRootState) => state.versionDeployment.chosenOperatingTypes
const selectChosenVersion = (state: ICommonRootState) => state.versionDeployment.chosenVersion
const selectChosenVersionGroup = (state: ICommonRootState) => state.versionDeployment.chosenVersionGroup

const selectChosenOperatorVersion = (state: ICommonRootState) => state.versionDeployment.chosenOperatorVersion
const selectChosenOperatorVersionGroup = (state: ICommonRootState) => state.versionDeployment.chosenOperatorVersionGroup

const selectRuntimeSelectedForConfiguration = (state: ICommonRootState) =>
	state.versionDeployment.runtimeSelectedForConfiguration

const selectIsUnfinishedDeployDispatch = (state: ICommonRootState) => state.versionDeployment.isUnfinishedDeployDispatch

export const versionDeploymentSelectors = {
	selectDeploymentVersions,
	selectVersionOptions,
	selectOperatorVersionOptions,
	selectJobStates,
	selectVersionSchema,
	selectRuntimesValues,
	selectRuntimesValueErrors,
	selectChosenOperatingTypes,
	selectChosenVersion,
	selectChosenVersionGroup,
	selectChosenOperatorVersion,
	selectChosenOperatorVersionGroup,
	selectRuntimeSelectedForConfiguration,
	selectIsUnfinishedDeployDispatch,
}
