import { createStyle } from 'src/theming'

const CompactFormatKey = '@media (max-width: 600px)'

const classes = createStyle((theme) => ({
	smallButton: {
		height: '25px',
		padding: `${theme.spacing.size0_5} ${theme.spacing.size1}`,
		width: 'fit-content',
	},
	button: {
		margin: theme.spacing.size1,
		marginLeft: theme.spacing.size0_5,
		width: '120px',
		height: '35px',
	},
	deploymentContainer: {
		margin: theme.spacing.size6,
		rowGap: theme.spacing.size2,
		[CompactFormatKey]: {
			margin: `${theme.spacing.size5} ${theme.spacing.size2}`,
		},
	},
	sectionContentWrapper: {
		margin: `0px ${theme.spacing.size1}`,
	},
	sectionHeader: {
		marginTop: theme.spacing.size7,
	},
	pageHeader: {
		marginBottom: theme.spacing.size2,
	},
	horizontal_divider: { height: '2px', marginTop: theme.spacing.size5 },
	versionNumber: {
		fontSize: theme.typography.h4.fontSize,
		fontWeight: theme.typography.body1.fontWeight,
	},
	h3Font: {
		fontSize: theme.typography.h3.fontSize,
		fontWeight: theme.typography.h3.fontWeight,
	},
	h5Font: {
		fontSize: theme.typography.h5.fontSize,
		fontWeight: theme.typography.h5.fontWeight,
	},
	h6Font: {
		fontSize: theme.typography.h6.fontSize,
		fontWeight: theme.typography.h6.fontWeight,
	},
}))

export const commonStyles = {
	classes,
}
