import { all } from 'redux-saga/effects'
import { authenticationSagas } from './features/Authentication/duck'
import { kubernetesSagas } from './features/Kubernetes/duck/kubernetesSagas'
import { operatorFrontendSagas } from './features/OperatorFrontend/duck/operatorFrontendSagas'
import { userSettingsSagas } from './features/UserSettings/duck/userSettingsSagas'
import { versionDeploymentSagas } from './features/VersionDeployment/duck/versionDeploymentSagas'

// Create and export a root saga
export function* rootSaga() {
	yield all([
		operatorFrontendSagas.watcherSagas(),
		authenticationSagas.watcherSagas(),
		kubernetesSagas.watcherSagas(),
		userSettingsSagas.watcherSagas(),
		versionDeploymentSagas.watcherSagas(),
	])
}
