import { DropdownOptionType } from '@genusbiz/web-ui/controls/Dropdown/Dropdown.types'

export interface IContainerTags {
	imageName: string
	tags: IContainerTag[]
}

export interface IContainerTag {
	name: string
	digest: string
	createdTime: string
	lastUpdateTime: string
}

// Temp?

export type TVersion = `${number}.${number}`
export interface IVersionTag {
	value: IContainerTag
	label: string
	type: DropdownOptionType.item
}

export interface IVersionGroups {
	[version: TVersion]: IVersionTag[]
}

export enum e_ChartName {
	genus = 'genus',
	genusOperator = 'genus-operator',
}
