export class BoundDataTransfer {
	constructor(private dataTransfer: DataTransfer) {}

	public setData: DataTransfer['setData'] = (format, data) => {
		this.dataTransfer.setData(format, data)
	}

	public getData: DataTransfer['getData'] = (format) => {
		return this.dataTransfer.getData(format)
	}

	public setDragImage: DataTransfer['setDragImage'] = (image, x, y) => {
		this.dataTransfer.setDragImage(image, x, y)
	}

	public get types() {
		return this.dataTransfer.types
	}
}
