import { e_DeploymentJobState } from 'src/enums/e_DeploymentJobState'
import { IDeploymentJobStatus } from 'src/interfaces/IDeployJob'

const includesKnownDeploymentStatus = (states: { targetName: string; status: IDeploymentJobStatus }[]) =>
	states.some((state) => state.status.deploymentJobState !== e_DeploymentJobState.unknown)

const findDisplayState = (jobState: { targetName: string; status: IDeploymentJobStatus }[]) => {
	const sortedOptions = [
		e_DeploymentJobState.failed,
		e_DeploymentJobState.inProgress,
		e_DeploymentJobState.pending,
		e_DeploymentJobState.succeeded,
		e_DeploymentJobState.unknown,
	]
	const states = jobState.map((state) => state.status.deploymentJobState)

	const stateToDisplay = sortedOptions.find((option) => states.includes(option)) ?? e_DeploymentJobState.unknown
	return stateToDisplay
}

export const deploymentStatusUtils = {
	includesKnownDeploymentStatus,
	findDisplayState,
}
