import React from 'react'

// Web UI
import { createStyle } from '@genusbiz/web-ui/theming'
import { Button } from '@genusbiz/web-ui/controls'
import { useTranslation } from 'react-i18next'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { useDispatch } from 'react-redux'

const classes = createStyle({
	codeSnippet: {
		backgroundColor: '#e3e3e3',
		padding: '8px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'start',
		justifyContent: 'space-between',
	},
	codeSnippetText: {
		fontFamily: 'monospace',
		whiteSpace: 'pre-line',
		margin: '0px',
	},
})

interface ICodeSnippetProps {
	code: string
}

export const CodeSnippet = (props: ICodeSnippetProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).catch(() => {
			dispatch(
				modalManagerActions.showNotificationPopup({ message: t('GENERAL:FAILED_TO_COPY'), autoHideDuration: 2000 })
			)
		})
		dispatch(
			modalManagerActions.showNotificationPopup({ message: t('GENERAL:COPIED_TO_CLIPBOARD'), autoHideDuration: 2000 })
		)
	}

	return (
		<div className={classes.codeSnippet}>
			<pre className={classes.codeSnippetText}>{props.code}</pre>
			<Button
				variant="inline"
				icon="Fluent-Copy"
				label=""
				screenTip={t('GENERAL:COPY')}
				onClick={() => copyToClipboard(props.code)}
			/>
		</div>
	)
}
