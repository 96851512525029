export enum operatorFrontendTypes {
	START = 'OPERATOR/START',
	FETCH_CONFIG = 'OPERATOR/FETCH_CONFIG',
	CONTINUOUS_UPDATE = 'OPERATOR/CONTINUOUS_UPDATE',
	SET_CONFIG = 'OPERATOR/SET_CONFIG',
	SET_INITIALIZED = 'OPERATOR/SET_INITIALIZED',
	SET_SELECTED_K8S_RUNTIME_NAMES = 'OPERATOR/SET_SELECTED_K8S_RUNTIME_NAMES',
	SET_SELECTED_PODS = 'OPERATOR/SET_SELECTED_PODS',
	SET_SELECTED_WORKLOAD_CONTROLLER = 'OPERATOR/SET_SELECTED_WORKLOAD_CONTROLLER',
	SET_SELECTED_K8S_RUNTIME = 'OPERATOR/SET_SELECTED_K8S_RUNTIME',
	SET_SELECTED_K8S_RUNTIME_NAME = 'OPERATOR/SET_SELECTED_K8S_RUNTIME_NAME',
	SET_NAVIGATION_ITEM = 'OPERATOR/SET_NAVIGATION_ITEM',
	SET_DISPLAY_LOG = 'OPERATOR/SET_DISPLAY_LOG',
	SET_DISPLAY_EVENTS = 'OPERATOR/SET_DISPLAY_EVENTS',
	SET_CONTROL_LOOP_STATUS = 'OPERATOR/SET_CONTROL_LOOP_STATUS',
	SET_AUTO_RELOAD = 'OPERATOR/SET_AUTO_RELOAD',
	SET_OPERATOR_ERROR_LOG = 'OPERATOR/SET_OPERATOR_ERROR_LOG',
	FETCH_OPERATOR_ERROR_LOG = 'operator/FETCH_OPERATOR_ERROR_LOG',
	CLEAR_OPERATOR_ERROR_LOG = 'operator/CLEAR_OPERATOR_ERROR_LOG',
	SET_LANGUAGE = 'OPERATOR/SET_LANGUAGE',
	SET_FORMATTING_CULTURE = 'OPERATOR/SET_FORMATTING_CULTURE',
	FETCH_K8S_RUNTIMES = 'OPERATOR/FETCH_K8S_RUNTIMES',
	FETCH_OPERATOR_ENDPOINTS = 'OPERATOR/FETCH_OPERATOR_ENDPOINTS',
	SET_KUBERNETES_VERSION = 'OPERATOR/SET_KUBERNETES_VERSION',
	SET_USER_INFO = 'OPERATOR/SET_USER_INFO',

	SET_WARNING_MESSAGE = 'OPERATOR/SET_WARNING_MESSAGE',
}
