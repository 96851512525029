import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { e_ChartName, IContainerTag, TVersion } from 'src/interfaces/IContainerRegistry'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { versionDeploymentActions } from 'src/features/VersionDeployment/duck/versionDeploymentActions'
import { useTranslation } from 'react-i18next'
import { GroupedDropdownInput } from '../components/GroupedDropdownInput'
import { createStyle } from '@genusbiz/web-ui/theming'
import { versionSelectorUtils } from '../utils/versionSelectorUtils'
import { semverCompare } from 'src/utils/semverCompare'
import classNames from 'clsx'
import { commonStyles } from '../utils/commonStyle'

const classes = createStyle({
	versionSelector: { maxWidth: '600px' },
})

export const RuntimeVersionSelector = () => {
	const { t } = useTranslation()
	useTriggerVersionOptionsFetch()

	const versions = useSelector(versionDeploymentSelectors.selectVersionOptions)
	const options = versionSelectorUtils.getOptions(versions?.tags ?? [])

	const version = useSelector(versionDeploymentSelectors.selectChosenVersion)
	const versionGroup = useSelector(versionDeploymentSelectors.selectChosenVersionGroup) as TVersion

	const dispatch = useDispatch()

	const setVersion = (selectedVersion: IContainerTag | undefined) =>
		dispatch(versionDeploymentActions.setChosenVersion(selectedVersion))
	const setVersionGroup = (group: TVersion | undefined) =>
		dispatch(versionDeploymentActions.setChosenVersionGroup(group))

	const displayTime = versionSelectorUtils.getSelectedVersionEditTimes(version)
	return (
		<div className={classNames(classes.versionSelector, commonStyles.classes.sectionContentWrapper)}>
			<GroupedDropdownInput
				options={options}
				getKey={versionSelectorUtils.getKey}
				groupValue={versionGroup}
				setGroupValue={setVersionGroup}
				groupOptionLabel={t('UPGRADE:VERSION')}
				value={version}
				setValue={setVersion}
				optionLabel={t('UPGRADE:PRODUCT_RELEASE')}
				optionSubText={displayTime}
				sortOptions={(a, b) => semverCompare(a.name, b.name)}
				sortGroupOptions={semverCompare}
			/>
		</div>
	)
}

const useTriggerVersionOptionsFetch = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(versionDeploymentActions.fetchVersionOptions(e_ChartName.genus))
	}, [])
}
