export const getLineSpanOfSubString = (lineIndex: number, str: string, subStr: string) => {
	const line = lineIndex
	const from = str.indexOf(subStr) ?? 0
	const to = from + subStr.length

	return spreadFromTo({ line, from, to })
}

export const spreadFromTo = ({ line, from, to }: { line: number; from: number; to: number }) => ({
	from: { line, ch: from },
	to: { line, ch: to },
})
