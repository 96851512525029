import React, { createContext, useContext, useMemo, useRef } from 'react'

import type { IFormControl, ValidationTextPositionType } from './FormControl.types'
import { Label } from './Label'
import { FormControlBorder } from './FormControlBorder'
import { FormControlSubText } from './FormControlSubText'

import { createStyle } from '../../theming'
import { useMergeStyles } from '../utils/useMergeStyles'
import { isRunningiOS } from '../utils/platform'
import clsx from 'clsx'
import { e_SubTextType } from '../../enums/e_SubTextType'
import { extractNumericValueWithPixelUnit } from '../../utils/extractNumericValueWithPixelUnit'
import { useStyleContext } from '../utils/Style.context'
import { e_RequiredIndicatorStyle } from '../../enums/e_RequiredIndicatorStyle'
import { e_LabelContentLayout } from './Label.types'
import { useLeftLabel } from '../utils/useLeftLabel'
import { e_LabelPosition } from '../../enums/e_LabelPosition'
import { useIncludeFieldContainerProps } from '../../surfaces/FieldContainer/FieldContainerContext'

const classes = createStyle((theme) => ({
	formControl: {
		display: 'flex',
		flexDirection: 'column',
	},

	controlAlignment: {
		'& input': { width: '100%' },
		'& input, & textarea': {
			flex: 1,
			outline: 'none',
			borderStyle: 'none',
			padding: theme.controls.field.padding,
			color: theme.controls.field.colors.color,
		},
		'& input:disabled, & textarea:disabled': {
			color: theme.controls.field.disabledColors.color,
		},

		'& > textarea': {
			resize: 'none',
			color: 'inherit',
			background: 'inherit',
		},
		'& ::placeholder': {
			color: theme.controls.field.placeholderTextColor,
			fontStyle: theme.controls.field.placeholderTextFontStyle,
		},
	},
	leftLabel: {
		flexDirection: 'row',
		alignItems: 'baseline',
	},
	rightLabel: {
		flexDirection: 'row-reverse',
		alignItems: 'baseline',
	},
	stretch: {
		width: '100%',
	},
	formControlContent: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 0,
	},

	labelLeftContentAlignment: {
		alignItems: theme.controls.field.editControlAlignment,
	},

	// If we render a wrapper around label + content, let content expand in width
	formControlContentWithWrapper: {
		flexGrow: 1,
	},

	dynamicsStyle: {
		// Need to target hover on div specifically, to avoid
		// input:hover style when hovering label (due to label/input:hover-relationship)
		// We're also assuming dynamicsStyle never will use the "disableBorder" prop
		'& > div:not(:hover):not(:focus-within) input': {
			background: 'none',
		},
	},
	contentWrapper: {
		display: 'flex',
		height: '100%',
	},

	control: {
		display: 'flex',
		flex: 'unset',
	},
	scaledInputWrapper: {
		position: 'relative',
		flex: 1,
		margin: theme.controls.field.padding,
		'& input:not([disabled]), & textarea:not([disabled])': {
			position: 'absolute',
			width: `calc(100% * (1 + (var(--input-font-size, 14) / 100))) !important`,
			height: `calc(100% * (1 + (var(--input-font-size, 14) / 100))) !important`,
			fontSize: 16,
			transformOrigin: 'top left',
			transform: 'scale(calc(var(--input-font-size, 14) / 16))',
			padding: '0 !important',
		},
	},
	hideDummyInput: {
		flex: '0 !important',
		width: '0px !important',
		paddingRight: '0 !important',
		paddingLeft: '0 !important',
		margin: '0 !important',
	},
	icon: { alignItems: 'center' },
	controlWidth: {
		maxWidth: '100%',
		width: theme.controls.field.editControlWidth,
	},
}))

const childIsInput = (c: React.ReactChild | React.ReactFragment) =>
	typeof c === 'object' && (c as { type?: string }).type === 'input'

const childIsTextArea = (c: React.ReactChild | React.ReactFragment) =>
	typeof c === 'object' && (c as { type?: string }).type === 'textarea'

const childIsTextInput = (c: React.ReactChild | React.ReactFragment) => childIsInput(c) || childIsTextArea(c)

export const shouldShowValidationAboveContent = (
	validationText: string | undefined | null,
	validationTextPosition: ValidationTextPositionType
): boolean => {
	if (!validationText) {
		return false
	}

	if (validationTextPosition === 'aboveField') {
		return true
	}

	return false
}

export const shouldShowValidationBelowSubText = (
	validationText: string | undefined | null,
	validationTextPosition: ValidationTextPositionType
): boolean => {
	if (!validationText) {
		return false
	}

	if (validationTextPosition === 'belowSubText') {
		return true
	}

	return false
}

export const shouldShowSubText = (
	helperText: string | undefined | null,
	validationText: string | undefined | null,
	validationTextPosition: ValidationTextPositionType,
	reserveHelperTextSpace: 'showWhenRequired' | 'reserveSpace' | 'off' | true | false | undefined
): boolean => {
	const hasHelperText = typeof helperText === 'string' && helperText.length > 0
	const hasValidationTextInSubTextArea =
		typeof validationText === 'string' && validationText.length > 0 && validationTextPosition === 'combineWithSubText'

	// We should display helper text in the following cases:
	// - 'showWhenRequired' || undefined: when helperText has value
	// - 'reserveSpace' || true: always
	// - 'off' || false : never
	switch (reserveHelperTextSpace) {
		case 'showWhenRequired':
		case undefined: {
			return hasHelperText || hasValidationTextInSubTextArea
		}

		case 'reserveSpace':
		case true: {
			return true
		}

		case 'off':
		case false: {
			return false
		}
	}
}

const getDefaultFontSize = () => {
	const bodyStyles = window.getComputedStyle(document.body)
	const bodyFontSize = bodyStyles.getPropertyValue('font-size')

	return typeof bodyFontSize === 'string' ? extractNumericValueWithPixelUnit(bodyFontSize) : bodyFontSize
}

const FormControlContext = createContext({
	isLabelVisible: false,
	isSubTextVisible: false,
})

export function useFormControlContext() {
	return useContext(FormControlContext)
}

const shouldHideLabel = (
	labelContentLayout: e_LabelContentLayout | undefined,
	label: string | undefined,
	icon: string | undefined
) => {
	switch (labelContentLayout) {
		case e_LabelContentLayout.icon:
			return !icon
		case e_LabelContentLayout.text:
			return !label
		default:
			return !icon && !label
	}
}

const getLabelProps = (id?: string, label?: string, position?: e_LabelPosition, deps?: unknown[]) => ({
	id: id,
	label: label ?? '',
	position: position ?? e_LabelPosition.left,
	deps: deps ?? [],
})

const useResolveLeftLabel = (props: IFormControl, labelPosition: e_LabelPosition | undefined) => {
	const newProps = getLabelProps(
		props.syncWidthProps?.groupId,
		props.label,
		labelPosition,
		props.syncWidthProps?.deps as unknown[]
	)
	const emptyProps = getLabelProps()

	const labelProps = props.syncWidthProps ? newProps : emptyProps

	// useLeftLabel returns undefined if id is undefined, thus leftLabel className in labelProps is used
	const newLabelClass = useLeftLabel(labelProps.id, labelProps.label, labelProps.position, labelProps.deps)

	const labelClassObject = newLabelClass
		? { className: clsx(newLabelClass?.className, props.syncWidthProps?.className) }
		: props.labelProps

	return labelClassObject
}

export const FormControl = React.forwardRef((props: IFormControl, ref: React.Ref<HTMLDivElement>) => {
	const outerMarginStyle = useMergeStyles({ margin: props.margin }, [props.margin])

	const tick = useRef<number>(0)
	const focused = useRef(false)
	const hovered = useRef(false)

	const { validationTextPosition = 'combineWithSubText' } = props

	const combinedProps = useIncludeFieldContainerProps(props)

	const fieldStyle = { ...useStyleContext().style?.field }

	const {
		colonAfterLabel,
		dynamicsStyle,
		labelBold = props.labelBold || combinedProps.labelBold,
		labelPosition = combinedProps.labelPosition,
		readOnlyIndicatorStyle,
		requiredIndicatorStyle = e_RequiredIndicatorStyle.displaySymbolAfterLabel,
	} = fieldStyle

	const labelProps = useResolveLeftLabel(combinedProps, labelPosition)

	const setHoveredOrFocused = (isFocused = false, isHovered = false) => {
		focused.current = isFocused
		hovered.current = isHovered

		// Use setTimeout to process two blur/focus events triggered by the same user action
		// as one action, and only send one focus/hover update
		tick.current && window.clearInterval(tick.current)
		tick.current = window.setTimeout(() => {
			props.onFocusedOrHovered?.(focused.current || hovered.current)
		}, 0)
	}

	// Inputs with less than 16px font size will be zoomed automatically on focus on iOS devices,
	// to circumvent this set all inputs to 16px and transform-scale down by a factor of fontSize/16

	let children = props.children

	if (isRunningiOS) {
		const fontSize = getDefaultFontSize() //##css-var - trenger bedre logikk for å håndtere henting av fontstørrelse

		if (fontSize > -1 && fontSize < 16 && React.Children.toArray(props.children).some(childIsTextInput)) {
			children = React.Children.map(props.children, (child) =>
				React.isValidElement(child) && childIsTextInput(child) ? (
					<>
						{childIsInput(child) && <input disabled className={classes.hideDummyInput} placeholder=" " aria-hidden />}
						{childIsTextArea(child) && (
							<textarea
								disabled
								className={classes.hideDummyInput}
								rows={(child.props as { rows?: number }).rows}
								placeholder=" "
								aria-hidden
							/>
						)}
						{/** @ts-expect-error CSS variables are valid style object keys */}
						<div style={{ '--input-font-size': fontSize }} className={classes.scaledInputWrapper}>
							{child}
						</div>
					</>
				) : (
					child
				)
			)
		}
	}

	const showValidationAboveContent = shouldShowValidationAboveContent(props.validationText, validationTextPosition)
	const showValidationBelowSubText = shouldShowValidationBelowSubText(props.validationText, validationTextPosition)

	const showValidationAsSubText = validationTextPosition === 'combineWithSubText' && props.validationText
	const showSubText = shouldShowSubText(
		props.subText,
		props.validationText,
		validationTextPosition,
		props.reserveHelperTextSpace
	)

	const isLabelHidden = props.hideLabel || shouldHideLabel(props.labelContentLayout, props.label, props.labelIcon)
	const isLabelLeft = !isLabelHidden && labelPosition === e_LabelPosition.left
	const isLabelRight = !isLabelHidden && labelPosition === e_LabelPosition.right

	const dataAttributes = props.dataAttributes ?? { 'data-cy': 'formControl' }

	const formControlContextValue = useMemo(
		() => ({ isLabelVisible: !isLabelHidden, isSubTextVisible: showSubText }),
		[isLabelHidden, showSubText]
	)

	const content = props.disableBorder ? (
		<div
			className={clsx(classes.control, classes.controlAlignment, classes.controlWidth, props.className, {
				[classes.stretch]: props.stretch,
			})}
			ref={ref}
			onMouseEnter={() => setHoveredOrFocused(focused.current, true)}
			onMouseLeave={() => setHoveredOrFocused(focused.current, false)}
			onFocus={() => setHoveredOrFocused(true, hovered.current)}
			onBlur={() => setHoveredOrFocused(false, hovered.current)}
		>
			{children}
		</div>
	) : (
		<FormControlBorder
			disabled={props.disabled}
			readOnly={props.readOnly}
			ref={ref}
			error={props.error}
			warning={props.warning}
			logicalHover={props.logicalHover}
			logicalFocus={props.logicalFocus}
			className={props.borderClassName}
			onMouseEnter={() => setHoveredOrFocused(focused.current, true)}
			onMouseLeave={() => setHoveredOrFocused(focused.current, false)}
			onFocus={() => setHoveredOrFocused(true, hovered.current)}
			onBlur={() => setHoveredOrFocused(false, hovered.current)}
			assignWidth={isLabelLeft || isLabelRight}
		>
			{children}
		</FormControlBorder>
	)

	const renderInnerContent = (renderAsFormControlRoot: boolean) => {
		const rootDataAttributes = renderAsFormControlRoot ? dataAttributes : undefined
		return (
			<div
				title={renderAsFormControlRoot && props.screenTip ? props.screenTip : undefined}
				{...rootDataAttributes}
				{...props.contentDataAttributes}
				className={clsx(
					classes.formControlContent,
					classes.controlAlignment,
					{
						[classes.stretch]: props.stretch,
						[classes.formControlContentWithWrapper]: !isLabelHidden,
						[classes.dynamicsStyle]: dynamicsStyle,
						[classes.labelLeftContentAlignment]: isLabelLeft || isLabelRight,
					},
					renderAsFormControlRoot && props.className
				)}
				onClick={props.onClick}
				onMouseDown={props.onMouseDown}
				style={renderAsFormControlRoot ? outerMarginStyle : undefined}
			>
				{showValidationAboveContent && (
					<FormControlSubText
						id={props.id}
						subText={props.validationText}
						error={props.error}
						warning={props.warning}
						type={e_SubTextType.validationTop}
					/>
				)}

				<FormControlContext.Provider value={formControlContextValue}>{content}</FormControlContext.Provider>
				{showSubText && (
					<FormControlSubText
						id={props.id}
						subText={showValidationAsSubText ? props.validationText : props.subText}
						error={showValidationAsSubText ? props.error : undefined}
						warning={showValidationAsSubText ? props.warning : undefined}
						type={e_SubTextType.subtext}
					/>
				)}
				{showValidationBelowSubText && (
					<FormControlSubText
						id={props.id}
						subText={props.validationText}
						error={props.error}
						warning={props.warning}
						type={e_SubTextType.validationBottom}
					/>
				)}
			</div>
		)
	}

	if (isLabelHidden) {
		return renderInnerContent(true)
	}

	return (
		<div
			title={props.screenTip ? props.screenTip : undefined}
			{...dataAttributes}
			className={clsx(
				classes.formControl,
				classes.controlAlignment,
				{
					[classes.leftLabel]: isLabelLeft,
					[classes.rightLabel]: isLabelRight,
					[classes.stretch]: props.stretch,
					[classes.icon]: props.label === undefined && props.labelIcon && (isLabelLeft || isLabelRight),
				},
				props.className
			)}
			style={outerMarginStyle}
		>
			<Label
				id={props.id}
				text={props.label}
				labelSubText={props.labelSubText}
				required={props.required}
				position={labelPosition}
				labelProps={{ ...labelProps, ...props.labelDataAttributes }}
				style={props.labelStyle}
				disabled={props.disabled}
				readOnly={props.readOnly}
				icon={props.labelIcon}
				iconColor={props.labelIconColor}
				labelContentLayout={props.labelContentLayout}
				isLabelHidden={isLabelHidden}
				indicateReadOnly
				colonAfterLabel={colonAfterLabel}
				readOnlyIndicatorStyle={readOnlyIndicatorStyle}
				requiredIndicatorStyle={requiredIndicatorStyle}
				labelBold={labelBold}
				disableLabelIdLinking={props.disableLabelIdLinking}
			/>
			{renderInnerContent(false)}
		</div>
	)
})

FormControl.displayName = 'FormControl'
