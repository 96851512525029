import { styleTags, tags } from '@lezer/highlight'
import { LRLanguage } from '@codemirror/language'
import { parser } from './yaml_parser'

const extendedParser = parser.configure({
	props: [
		styleTags({
			ObjectIdentifier: tags.name,
			ValueIdentifier: tags.name,
			StringValue: tags.string,
			NumberValue: tags.number,
			BooleanValue: tags.bool,
			Comment: tags.comment,
		}),
	],
})

export const helmValueParser = LRLanguage.define({ parser: extendedParser })
