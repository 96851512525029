import { all, put, takeEvery, takeLatest, call, delay, select } from 'redux-saga/effects'
import { DATA_RELOAD_INTERVAL } from 'src/consts/constants'
import { e_NavigationItem } from 'src/enums/e_NavigationItems'
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { authenticationActions } from 'src/features/Authentication/duck'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { IConfig } from 'src/interfaces/IConfig'
import { IUserInfo } from 'src/interfaces/IUserInfo'
import { IControlLoopStatus } from 'src/interfaces/IControlLoopStatus'
import { IOperatorErrorLog } from 'src/interfaces/IErrorLog'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { operatorApi } from 'src/modules/operatorApi'

import { initializeOperatorApiConfig } from 'src/modules/operatorApi/configHelpers'
import { sessionStorageService } from 'src/modules/sessionStorageService/sessionStorageService'
import { IOperatorRootState } from 'src/reducers'
import { generateGuid } from 'src/utils/generateGuid'
import { getStore } from 'src/utils/store'
import { translator } from 'src/utils/locale'
import { ISetNavigationItem } from './IOperatorFrontendActions'
import { operatorFrontendActions } from './operatorFrontendActions'
import { operatorFrontendTypes } from './operatorFrontendTypes'
import { e_EnvironmentOperatingType, e_EnvironmentOperatingTypeName } from 'src/enums/e_EnvironmentOperatingTypes'
import { IEnvironmentResourceOverview } from 'src/interfaces/IEnvironmentResourceOverview'
import { e_EnvironmentType, e_EnvironmentTypeName } from 'src/enums/e_EnvironmentType'
import { isActive } from 'src/utils/publicationIsActive'
import { kubernetesSagas } from 'src/features/Kubernetes/duck/kubernetesSagas'
import { IEndpoint } from 'src/interfaces/IEndpoint'

function* onInitializeOperator() {
	const store = getStore()

	initializeOperatorApiConfig(store)

	//##VKA This must be on it's own since this will send us to signin window when 401
	yield call(onFetchConfig)

	yield all([
		call(kubernetesSagas.fetchEndpoints),
		call(kubernetesSagas.fetchEnvironmentAvailabilitySchedule),
		call(onFetchOperatorErrorLog),
		call(onFetchKubernetesVersion),
		call(onFetchUserInfo),
		call(onFetchK8sRuntimes),
	])

	const persistSession = sessionStorageService.read(e_OperatorStorageKeys.persistSession) as boolean

	if (persistSession) {
		yield put(authenticationActions.setSignedIn(true))
	}

	yield put(operatorFrontendActions.startContinuousUpdate())
	const navigationItem = window.location.pathname.substring(1) as e_NavigationItem
	yield put(operatorFrontendActions.setNavigationItem(navigationItem))

	yield put(operatorFrontendActions.setInitialized())
}

function* onFetchConfig() {
	const config = (yield call(operatorApi.fetchOperatorConfig)) as IConfig

	yield put(operatorFrontendActions.setConfig(config))
}

function* onFetchKubernetesVersion() {
	const kubernetesVersion: string = yield call(operatorApi.fetchKubernetesVersion)
	yield put(operatorFrontendActions.setKubernetesVersion(kubernetesVersion))
}

function* onFetchUserInfo() {
	const userInfo: IUserInfo = yield call(operatorApi.fetchUserInfo)
	yield put(operatorFrontendActions.setUserInfo(userInfo))
}

function* onFetchOperatorEndpoints() {
	const endpoints: IEndpoint[] = yield call(operatorApi.fetchOperatorEndpoints)

	if (!endpoints || !endpoints.length) {
		yield put(
			modalManagerActions.showNotificationPopup({
				message: translator.translate('GENERAL:NETWORK_ERROR'),
			})
		)
		yield delay(DATA_RELOAD_INTERVAL * 1000)
		return
	}

	yield put(kubernetesActions.setEndpoints(endpoints))
}

function* onFetchK8sRuntimes() {
	const state = (yield select()) as IOperatorRootState
	const k8sRuntimes: IK8sRuntime[] = yield call(operatorApi.fetchOperatorK8sRuntimes)

	if (!k8sRuntimes || !k8sRuntimes.length) {
		yield put(
			modalManagerActions.showNotificationPopup({
				message: translator.translate('GENERAL:NETWORK_ERROR'),
			})
		)
		yield delay(DATA_RELOAD_INTERVAL * 1000)
		return
	}

	const activeK8sRuntimes = state.kubernetes.k8sRuntimes.filter((k8sRuntime) => isActive(k8sRuntime.currentPublication))
	const newActiveK8sRuntimes = k8sRuntimes.filter((k8sRuntime) => isActive(k8sRuntime.currentPublication))
	if (
		activeK8sRuntimes.length > 0 &&
		activeK8sRuntimes[0].environmentTypeName !== newActiveK8sRuntimes[0].environmentTypeName
	) {
		yield put(
			modalManagerActions.showNotificationPopup({
				message: translator.translate('CONTROL_LOOP:ACTIVE_ENV_CHANGED'),
			})
		)
	}
	const operatorRuntime = {
		name: getOperatorRuntimeName(),
		environmentType: e_EnvironmentType.operator,
		currentlyOperatingAs: e_EnvironmentOperatingType.operator,
	}

	yield put(kubernetesActions.setK8sRuntimes(k8sRuntimes, operatorRuntime))

	const k8sRuntimesIncludingOperator = [...k8sRuntimes, operatorRuntime]
	// Check if selectedK8sruntimeName must be set from path. Must be done after K8sruntimes are fetched, to validate the path
	if (!state.operatorFrontend.selectedK8sRuntimeName) {
		const path = window.location.pathname
		if (path.indexOf(e_NavigationItem.workloads + '/')) {
			const pathElements = path.split(e_NavigationItem.workloads + '/')
			const pathK8sRuntime = pathElements[1]
			if (pathK8sRuntime && k8sRuntimesIncludingOperator.some((k8sRuntime) => k8sRuntime.name === pathK8sRuntime)) {
				yield put(operatorFrontendActions.setSelectedK8sRuntimeName(pathK8sRuntime))
			}
		}
	}
}

function* continuousUpdate() {
	try {
		do {
			/**
			 * Update control loop
			 */
			const controlLoopStatus: IControlLoopStatus = yield call(operatorApi.fetchControlLoopStatus)

			if (!controlLoopStatus || !controlLoopStatus.taskReports) {
				yield put(
					modalManagerActions.showNotificationPopup({
						message: translator.translate('GENERAL:NETWORK_ERROR'),
					})
				)
				yield delay(DATA_RELOAD_INTERVAL * 1000)
				return
			}

			controlLoopStatus.taskReports.forEach((report) => {
				report.events.forEach((event) => {
					event.id = generateGuid()
				})
			})

			yield put(operatorFrontendActions.setControlLoopStatus(controlLoopStatus))

			/**
			 * Update k8sRuntimes to find active runtime
			 */

			yield put(operatorFrontendActions.fetchK8sRuntimes())
			/**
			 * Get resourceOverview for all environments to see status in navigationpane
			 */
			for (const operatingTypeName of Object.keys(e_EnvironmentOperatingTypeName)) {
				// No resourceoverview for operator runtime yet
				if (
					operatingTypeName === e_EnvironmentTypeName.operator ||
					operatingTypeName === e_EnvironmentTypeName.unknown
				) {
					continue
				}
				const operatingType = e_EnvironmentOperatingType[operatingTypeName as keyof typeof e_EnvironmentOperatingType]
				const resourceOverview: IEnvironmentResourceOverview = yield call(
					operatorApi.fetchEnvironmentResourceOverview,
					operatingType
				)
				yield put(kubernetesActions.setEnvironmentResourceOverview(operatingType, resourceOverview))
			}
			/**
			 * Start over
			 */
			yield delay(DATA_RELOAD_INTERVAL * 1000)
		} while (true)
	} catch (error) {
		yield put(modalManagerActions.showErrorModal(error as Error))
	}
}

function* onNavigate(action: ISetNavigationItem) {
	if (action.selection) {
		const navigationItem = action.navigationItem

		// Set selectedK8sRuntimeName from path. onNavigate is called after refreshing the page, so the state is not set yet
		switch (navigationItem) {
			case e_NavigationItem.workloads:
				yield put(operatorFrontendActions.setSelectedK8sRuntimeName(action.selection))
				break
			default:
				break
		}
	}
}

function* onFetchOperatorErrorLog() {
	try {
		const errorLog: IOperatorErrorLog = yield call(operatorApi.fetchOperatorErrorLog)

		errorLog.logs.forEach((error, index) => {
			error.hierarchy = [index.toString()]
			error.isBreadcrumb = false

			error.breadcrumbs.forEach((crumb, index2) => {
				crumb.hierarchy = [index.toString(), index2.toString()]
				crumb.isBreadcrumb = true
				errorLog.logs.push(crumb)
			})
		})
		yield put(operatorFrontendActions.setOperatorErrorLog(errorLog))
	} catch (error) {
		yield put(modalManagerActions.showErrorModal(error as Error, translator.translate('GENERAL:OK'), () => {}))
	}
}

function* onClearOperatorErrorLog() {
	try {
		yield call(operatorApi.clearOperatorErrorLog)

		yield put(operatorFrontendActions.fetchOperatorErrorLog())
	} catch (error) {
		yield put(modalManagerActions.showErrorModal(error as Error, translator.translate('GENERAL:OK'), () => {}))
	}
}

function* watcherSagas() {
	yield all([
		takeLatest(operatorFrontendTypes.START, onInitializeOperator),
		takeEvery(operatorFrontendTypes.CONTINUOUS_UPDATE, continuousUpdate),
		takeEvery(operatorFrontendTypes.SET_NAVIGATION_ITEM, onNavigate),
		takeEvery(operatorFrontendTypes.FETCH_CONFIG, onFetchConfig),
		takeEvery(operatorFrontendTypes.FETCH_OPERATOR_ERROR_LOG, onFetchOperatorErrorLog),
		takeEvery(operatorFrontendTypes.CLEAR_OPERATOR_ERROR_LOG, onClearOperatorErrorLog),
		takeEvery(operatorFrontendTypes.FETCH_K8S_RUNTIMES, onFetchK8sRuntimes),
		takeEvery(operatorFrontendTypes.FETCH_OPERATOR_ENDPOINTS, onFetchOperatorEndpoints),
	])
}

const getOperatorRuntimeName = () => {
	if (process.env.NODE_ENV === 'development') {
		return window.env.MODEL_IDENTIFIER + '-operator'
	} else {
		return window.env.K8S_RUNTIME as string
	}
}

export const operatorFrontendSagas = {
	watcherSagas,
}
