import { useLayoutEffect } from 'react'
import { formatPixel } from '../utils'
import { type DialogDimension, PADDING_WIDTH, type DialogDimensionType } from '../DialogTypes'

interface IDialogSizingProps {
	width?: DialogDimension
	height?: DialogDimension
	widthType?: DialogDimensionType
	heightType?: DialogDimensionType
	isOpen: boolean
}

export const useDialogSizing = (
	domDialog: React.RefObject<HTMLDivElement>,
	props: IDialogSizingProps,
	shallFitInnerSize: {
		width: boolean
		height: boolean
	},
	fullScreen: React.MutableRefObject<boolean>
) => {
	const { widthType = 'outer', heightType = 'outer' } = props

	const innerWidth = typeof props.width === 'number' ? props.width : 0
	const innerHeight = typeof props.height === 'number' ? props.height : 0

	const isValidInnerHeight = heightType === 'inner' && typeof props.height === 'number'
	const isValidInnerWidth = widthType === 'inner' && typeof props.width === 'number'

	const width = props.width === undefined || props.width === 'auto' || isValidInnerWidth ? undefined : props.width
	const height = props.height === 'auto' || isValidInnerHeight ? undefined : props.height

	const shallFitInnerHeight = shallFitInnerSize.height && isValidInnerHeight && !fullScreen.current
	const shallFitInnerWidth = shallFitInnerSize.width && isValidInnerWidth && !fullScreen.current

	const isAutoHeight = (props.height === 'auto' || shallFitInnerHeight) && !fullScreen.current

	const innerSizing = {
		height: shallFitInnerHeight ? formatPixel(innerHeight) : undefined,
		width: shallFitInnerWidth ? formatPixel(innerWidth + 2 * PADDING_WIDTH) : undefined,
		overflow: 'hidden',
	}

	useLayoutEffect(() => {
		if (!domDialog.current || !props.isOpen) {
			return
		}

		const style = domDialog.current.style

		if (width) {
			style.width = formatPixel(width)
			style.setProperty('--width', formatPixel(width))
		}

		if (height) {
			style.height = formatPixel(height)
			style.setProperty('--height', formatPixel(height))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isOpen])

	return {
		width,
		height,
		isAutoHeight,
		innerSizing,
	}
}
