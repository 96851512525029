import { useState } from 'react'
import type { XYCoord } from 'react-dnd'
import type { IDropTargetOnCanDropCallback, IDropTargetOnDropCallback } from './DropTarget'
import type { BoundDataTransfer } from './dragdrop'

export interface IInsertIndex {
	index: number
	groupIndex?: number
}

const defaultGetInsertIndexFromPosition = (
	clientOffset: XYCoord | null,
	items: { id?: string; children?: { id: string }[] }[],
	getInsertIndexFromId?: (id: string) => IInsertIndex
) => {
	if (!clientOffset) {
		return
	}
	const htmlDomElementsFromPoint = document.elementsFromPoint(clientOffset.x, clientOffset.y)
	const itemElement = htmlDomElementsFromPoint.find((el) => {
		return el instanceof HTMLElement && el.dataset.rowid
	}) as HTMLElement | undefined
	if (!itemElement) {
		return
	}

	let groupIndex: number | undefined = undefined
	let hoverIndex: number
	if (getInsertIndexFromId) {
		const insertIndexFromId = getInsertIndexFromId(itemElement.dataset.rowid!)
		groupIndex = insertIndexFromId.groupIndex
		hoverIndex = insertIndexFromId.index
	} else {
		const groupElement = htmlDomElementsFromPoint.find((el) => {
			return el instanceof HTMLElement && el.dataset.groupIndex
		}) as HTMLElement | undefined
		if (groupElement) {
			groupIndex = parseInt(groupElement.dataset.groupIndex!)
			const groupItem = items[groupIndex]
			hoverIndex =
				'children' in groupItem && groupItem.children?.length
					? groupItem.children.findIndex((item) => item.id === itemElement.dataset.rowid)
					: 0
		} else {
			hoverIndex = items.findIndex((item) => item.id === itemElement.dataset.rowid)
		}
	}

	const itemElementRect = itemElement.getBoundingClientRect()
	//if in the top half of the drop element
	if (clientOffset.y < itemElementRect.y + itemElementRect.height / 2) {
		return { groupIndex, index: hoverIndex }
	}
	// if in the bottom half of the element
	if (clientOffset.y >= itemElementRect.y + itemElementRect.height / 2) {
		return { groupIndex, index: hoverIndex + 1 }
	}
}

export interface IDropHandler<T = unknown> {
	(
		insertIndex: IInsertIndex,
		itemType: string | symbol,
		item: T | undefined,
		dataTransfer: BoundDataTransfer | undefined
	): void
}

export interface ICanDropHandler<T = unknown> {
	(
		insertIndex: IInsertIndex,
		itemType: string | symbol | null,
		item: T | undefined,
		dataTransfer: BoundDataTransfer | undefined
	): boolean
}

export const useDropObject = <T>(
	items: { id?: string; children?: { id: string }[] }[],
	onDrop?: IDropHandler<T>,
	onCanDrop?: ICanDropHandler<T>,
	getInsertIndexFromId?: (id: string) => IInsertIndex,
	getInsertIndexFromPosition = defaultGetInsertIndexFromPosition,
	getItemFromIndex?: (index: number, groupIndex?: number) => T | undefined
) => {
	const [insertIndex, setInsertIndex] = useState<IInsertIndex>()

	const handleDropHover = (
		_itemType: string | symbol | null,
		_item: unknown,
		_isOver: boolean,
		_isDirectlyOver: boolean,
		clientOffset: XYCoord | null
	) => {
		const dropPosition = getInsertIndexFromPosition(clientOffset, items, getInsertIndexFromId)
		setInsertIndex(dropPosition)
	}

	const handleDrop: IDropTargetOnDropCallback = (
		itemType,
		_item,
		_isOver,
		_isDirectlyOver,
		_clientOffset,
		_dropResult,
		_id,
		dataTransfer
	) => {
		if (!onDrop) {
			return
		}
		const returnValue = items.length === 0 ? { index: 0 } : insertIndex
		const item =
			getItemFromIndex && returnValue?.index !== undefined
				? getItemFromIndex(returnValue.index, returnValue.groupIndex)
				: undefined
		if (returnValue !== undefined) {
			onDrop(returnValue, itemType as string, item, dataTransfer)
		}
		setInsertIndex(undefined)
	}

	const handleCanDrop: IDropTargetOnCanDropCallback = (
		itemType,
		_item,
		_isOver,
		_isDirectlyOver,
		_clientOffset,
		dataTransfer
	) => {
		if (!onDrop || insertIndex === undefined) {
			return false
		}
		if (!onCanDrop) {
			return true
		}
		const returnValue = items.length === 0 ? { index: 0 } : insertIndex
		const item =
			getItemFromIndex && returnValue?.index !== undefined
				? getItemFromIndex(returnValue.index, returnValue.groupIndex)
				: undefined

		return onCanDrop(insertIndex, itemType, item, dataTransfer)
	}

	const handleDragLeave = () => {
		setInsertIndex(undefined)
	}

	return [insertIndex, handleDropHover, handleDrop, handleDragLeave, handleCanDrop] as const
}
