import React from 'react'
import { ErrorObject } from 'ajv'
import { Icon } from '@genusbiz/web-ui/controls'
import { useTranslation } from 'react-i18next'
import { createStyle } from 'src/theming'
import { getYAMLKeys } from '../utils/parseYAMLLines'

interface IHelmErrorList {
	errors: ErrorObject[]
}

type ErrorType = 'required' | 'additionalProperties' | 'type' | 'enum'

const classes = createStyle((theme) => ({
	errorLine: {
		height: '100%',
		overflow: 'auto',
		display: 'flex',
		flexDirection: 'column',
		rowGap: theme.spacing.size2,
	},
	marginLeft: {
		marginLeft: theme.spacing.size1,
	},
}))

export const HelmErrorList = (props: IHelmErrorList) => {
	const { t } = useTranslation()
	const errors = props.errors

	return (
		<div className={classes.errorLine}>
			{errors.map((error) => (
				<div key={getPath(error)}>
					<Icon iconName={'Fluent-StatusErrorFull'} color={'red'} size="size12" />
					<strong className={classes.marginLeft}>{t(errorInstruction[error.keyword as ErrorType])}: </strong>
					{formatPath(getPath(error))}
					<i>{getRelevantFieldType(error)}</i>
				</div>
			))}
		</div>
	)
}

const errorInstruction = {
	required: 'GENERAL:ADD',
	additionalProperties: 'GENERAL:REMOVE',
	type: 'GENERAL:ALTER',
	enum: 'GENERAL:ALTER',
}

const getPath = (error: ErrorObject) => {
	switch (error.keyword) {
		case 'required':
			return `${error.instancePath}/${error.params.missingProperty}`
		case 'additionalProperties':
			return `${error.instancePath}/${error.params.additionalProperty}`
		case 'enum':
		case 'type':
			return error.instancePath
		default:
			return ''
	}
}

const formatPath = (path: string) => {
	const keys = getYAMLKeys(path)

	let formattedPath = ''

	for (let i = 0; i < keys.length - 1; i++) {
		formattedPath = formattedPath.concat(keys[i], '/')
	}

	return (
		<>
			{formattedPath}
			<strong>{keys.at(-1) ?? ''}</strong>
		</>
	)
}

const getRelevantFieldType = (error: ErrorObject) => {
	switch (error.keyword) {
		case 'required':
			return ` (${getFieldTypeOfRequiredError(error)})`
		case 'type':
			return ` (${error.params.type})`
		case 'enum':
			return ` (${formatEnumEntries(error.params.allowedValues)})`
		default:
			return ''
	}
}

const formatEnumEntries = (entries: string[]) => entries.map((string) => '"' + string + '"').join(' | ')

const getFieldTypeOfRequiredError = (error: ErrorObject) =>
	error.parentSchema?.properties[error.params.missingProperty].type
