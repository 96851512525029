// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {identifier, value} from "./yamlExternalTokenizer.ts"
export const parser = LRParser.deserialize({
  version: 14,
  states: "!jOYQTOOOeQSO'#CjOOQP'#Ch'#ChOOQP'#Cd'#CdOYQTOOQOQSOOOjQSO'#ChOoQUO,59UOOQP-E6b-E6bOOQP,59S,59SOOQP'#Ck'#CkOOQP1G.p1G.p",
  stateData: "z~OZOSUOS~OPUOQPO`TO~O]VO~O]XO~ORYOSYOTYO~O",
  goto: "r`PPPPPPPPaPPPgPkoQSORWSTROSTQOSRZV",
  nodeNames: "⚠ ObjectIdentifier ValueIdentifier StringValue NumberValue BooleanValue Comment YAMLFile",
  maxTerm: 16,
  skippedNodes: [0,6],
  repeatNodeCount: 1,
  tokenData: "#b~R]X^zpqzst!o![!]#W#y#zz$f$gz#BY#BZz$IS$I_z$I|$JOz$JT$JUz$KV$KWz&FU&FVz~~#]~!PYZ~X^zpqz#y#zz$f$gz#BY#BZz$IS$I_z$I|$JOz$JT$JUz$KV$KWz&FU&FVz~!tSU~OY!oZ;'S!o;'S;=`#Q<%lO!o~#TP;=`<%l!o~#]O]~~#bO`~",
  tokenizers: [identifier, value, 0],
  topRules: {"YAMLFile":[0,7]},
  tokenPrec: 0
})
