import React from 'react'
import { Icon, Text } from '@genusbiz/web-ui/controls'
import { createStyle } from 'src/theming'
import { versioningUtils } from '../utils/versioningUtils'
import { commonStyles } from '../utils/commonStyle'

const classes = createStyle((theme) => ({
	versionInfo: {
		display: 'flex',
	},
	versionWarning: {
		marginRight: theme.spacing.size2,
		marginLeft: theme.spacing.size2,
		height: 'fit-content',
		alignSelf: 'center',
	},
}))

interface IVersionWarningProps {
	currentVersions?: string[]
	versionOptions?: string[]
}

export const VersionWarning = (props: IVersionWarningProps) => {
	const versionInfo = analyzeVersion(props.currentVersions ?? [], props.versionOptions ?? [])

	return (
		<div className={classes.versionInfo}>
			<Icon
				iconName={versionInfo ? 'Fluent-Warning' : undefined}
				className={classes.versionWarning}
				size="size24"
				style={{ color: '#ffcc00' }}
			/>
			<Text className={commonStyles.classes.versionNumber}>{versionInfo}</Text>
		</div>
	)
}

const analyzeVersion = (currentVersions: string[], versionOptions: string[]) => {
	if (currentVersions.length > 1) {
		return 'Version mismatch'
	}

	const newerVersion = versioningUtils.findNewerVersion(currentVersions[0], versionOptions)

	if (newerVersion) {
		return `Newer version available: ${newerVersion.raw}`
	}
}
