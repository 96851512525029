import React from 'react'

import { createStyle } from '../../theming'

const classes = createStyle({
	customItem: {
		display: 'flex',
		padding: '0.2em 0.65em',
	},
})

interface IPopupMenuCustomItemProps {
	menuIndex: number
	isSelected: boolean
	// Data attributes were always overwritten to be undefined in the MenuItemCustom interface,
	// typed out explicitly here. Should cleanup types at some point and not intersect with loads
	// of interface properties that should always be undefined
	item: {
		id?: string
		hidden?: boolean
		dataAttributes?: Record<string, string>
	}
	contentRenderer: (renderedIn: 'CommandBar' | 'Menu') => React.ReactNode
}

export const PopupMenuCustomItem = (props: IPopupMenuCustomItemProps) => {
	if (props.item.hidden) {
		return null
	}

	return (
		<div className={classes.customItem} tabIndex={0} id={props.item.id} {...props.item.dataAttributes}>
			{props.contentRenderer('Menu')}
		</div>
	)
}
