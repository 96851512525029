export interface IConfirmationModalProps {
	title: string
	message: string
	confirmText?: string
	declineText?: string
	cancelText?: string
	disableConfirmButton?: boolean
	onConfirm?: () => void
	cancel?: () => void
	decline?: () => void
}

export interface INotificationModalProps {
	title: string
	message: string
	icon?: string
	iconColor?: string
}
export interface IMetaModelManagerModalProps {
	title?: string
	message?: string
	icon?: string
	iconColor?: string
}

export interface IGitTokenManagerModalProps {}

export interface IErrorModalProps {
	title: string
	message: string
	actions: { name: string; onExecute: () => void }[]
}

export enum e_notificationPosition {
	topLeft = 'topLeft',
	topCenter = 'topCenter',
	topRight = 'topRight',
	bottomLeft = 'bottomLeft',
	bottomCenter = 'bottomCenter',
	bottomRight = 'bottomRight',
}

export enum e_notificationSeverity {
	error = 'error',
	warning = 'warning',
	info = 'info',
}
export interface INotificationPopupProps {
	message: string
	title?: string
	showCloseButton?: boolean
	icon?: string
	autoHideDuration?: number
	position?: e_notificationPosition
	severity?: e_notificationSeverity
	colorSetId?: string
}

export type IModalProps =
	| IConfirmationModalProps
	| INotificationModalProps
	| IMetaModelManagerModalProps
	| IGitTokenManagerModalProps
	| IErrorModalProps
	| INotificationPopupProps

export interface IProgressModalProps {
	message?: string
}
