import React from 'react'

import { Button, Icon } from '@genusbiz/web-ui/controls'
import { IIconNames } from '@genusbiz/icon-set/dist/utils'
import { createStyle } from 'src/theming'
import { e_ButtonVariant } from 'src/enums/e_ButtonVariant'

const classes = createStyle((theme) => ({
	designerInformationBar: {
		height: '38px',
		backgroundColor: theme.palette.warning.warningLight,
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid ' + theme.palette.background.neutralQuaternary,
	},
	item: {
		marginLeft: theme.spacing.size1,
	},
	icon: {
		color: theme.palette.warning.warningPrimary,
		marginLeft: theme.spacing.size4,
	},
}))

interface IWarningBarProps {
	title: string
	iconClassName?: IIconNames
	details?: string
	onClickLabel?: string
	onClick?: () => void
}

export const WarningBar = (props: IWarningBarProps) => {
	const icon = props.iconClassName ?? 'Fluent-Warning'

	return (
		<div className={classes.designerInformationBar}>
			<Icon size="size20" className={classes.icon} iconClassName={icon} />
			<b className={classes.item}>{props.title}</b>
			{props.details && <div className={classes.item}>{props.details}</div>}
			{props.onClick && (
				<Button
					className={classes.item}
					onClick={props.onClick}
					variant={e_ButtonVariant.default}
					size="extraSmall"
					label={props.onClickLabel}
				/>
			)}
		</div>
	)
}
