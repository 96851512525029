import { generateTheme } from '@genusbiz/web-ui/theming/generateTheme'
import { defaultColors } from './consts/defaultColors'
import { extendTheme, getTypedThemeUtils } from '@genusbiz/web-ui/theming/themingManager'
import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE, defaultTypography } from '@genusbiz/web-ui/theming/defaults'
import { ITypographicStyleEntries } from '@genusbiz/web-ui/theming/themeProperties/typography'

const customColors = {
	primary: defaultColors.primary,
}
const webUITheme = generateTheme(customColors)

const sheetElement = document.createElement('style')
sheetElement.setAttribute('data-meta', 'genus-globals')
document.head.append(sheetElement)

function applyTypographyToStyleSheet(
	headerStyles = defaultTypography,
	fontFamily = DEFAULT_FONT_FAMILY,
	fontSize = DEFAULT_FONT_SIZE
) {
	document.body.style.fontFamily = fontFamily
	document.body.style.fontSize = `${fontSize}px`
	const styleSheet = sheetElement.sheet
	if (styleSheet) {
		for (let i = 1; i <= 6; i++) {
			const tag = `h${i}` as keyof Partial<ITypographicStyleEntries>
			const tg = headerStyles[tag]
			if (tg) {
				styleSheet.insertRule(`
					${tag} {
						font-size: ${tg.fontSize}px;
						font-weight: ${tg.fontWeight};
					}
				`)
			}
		}
	}
}

applyTypographyToStyleSheet()

const SPACING = {
	size0_5: '3px',
	size1: '6px',
	size2: '12px',
	size3: '18px',
	size4: '24px',
	size5: '30px',
	size6: '36px',
	size7: '42px',
	size8: '48px',
	size9: '54px',
	size10: '60px',
} as const

const extendedTheme = extendTheme(webUITheme, { spacing: SPACING })

export const {
	createStyle, // createUseStyle erstatning
	// createThemeVariation, // oppretter og mounter klasse
	// createThemeVariations, // oppretter og mounter klasser
	// getThemeVariation, // slår opp klassenavn
	// useDynamicThemeVariation, // gerererer style med instansierte css variabler (som objekt)
	// useGetComputedThemeVariation, // brukes for å overføre styler over til portal
	initTheming,
} = getTypedThemeUtils<ITheme>()

export const mergedTheme = initTheming(extendedTheme)
export type ITheme = typeof extendedTheme
