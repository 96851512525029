import React from 'react'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import mapValues from 'lodash/mapValues'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { isActive } from 'src/utils/publicationIsActive'
import { e_EnvironmentType } from 'src/enums/e_EnvironmentType'
import { DeploymentStatusField } from '../components/DeploymentStatusField'
import { deploymentStatusUtils } from '../utils/deploymentStatusUtils'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'

interface IDeploymentStatusPanelProps {
	abortDeployment: (environment: e_EnvironmentOperatingType) => void
}

export const DeploymentStatusPanel = (props: IDeploymentStatusPanelProps) => {
	const jobStates = useSelector(versionDeploymentSelectors.selectJobStates)
	const environmentNames = useOperatingTypeEnvironmentNames()

	const environmentTypes = mapValues(environmentNames, (name?: string) => name ?? 'Unknown type')
	const displayStates = mapValues(jobStates, deploymentStatusUtils.findDisplayState)
	const isSomeRelevantDeploymentStatus = mapValues(jobStates, deploymentStatusUtils.includesKnownDeploymentStatus)

	return (
		<>
			{isSomeRelevantDeploymentStatus.active && (
				<DeploymentStatusField
					label={`Active (${environmentTypes.active})`}
					state={displayStates.active}
					abort={() => props.abortDeployment(e_EnvironmentOperatingType.active)}
				/>
			)}
			{isSomeRelevantDeploymentStatus.passive && (
				<DeploymentStatusField
					label={`Passive (${environmentTypes.passive})`}
					state={displayStates.passive}
					abort={() => props.abortDeployment(e_EnvironmentOperatingType.passive)}
				/>
			)}
			{isSomeRelevantDeploymentStatus.origin && (
				<DeploymentStatusField
					label={`Origin`}
					state={displayStates.origin}
					abort={() => props.abortDeployment(e_EnvironmentOperatingType.origin)}
				/>
			)}
		</>
	)
}

const useOperatingTypeEnvironmentNames = () => {
	const runtimes = useSelector(kubernetesSelectors.selectK8sRuntimes)
	const activeRuntime = runtimes.find((runtime) => isActive(runtime.currentPublication))
	const passiveRuntime = runtimes.find(
		(runtime) => !isActive(runtime.currentPublication) && runtime.environmentType !== e_EnvironmentType.origin
	)

	return {
		active: activeRuntime?.environmentTypeName,
		passive: passiveRuntime?.environmentTypeName,
	}
}
