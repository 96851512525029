import React, { useEffect, useState } from 'react'
import type { CSSProperties } from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Icon } from '../Icon'
import { getBestContrastColor } from '../../theming/themeProperties/colors/colorUtils'

const classes = createStyle((theme) => ({
	root: {
		position: 'relative',
		verticalAlign: 'middle',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		pointerEvents: 'none',
	},
	badge: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box',
		fontSize: '10px',
		lineHeight: '10px',
		color: `var(--badge-filled-textcolor, ${theme.colors.badge.text})`,
		backgroundColor: `var(--badge-filled-backgroundcolor, ${theme.colors.badge.background})`,
		padding: '2px 6px',
		borderRadius: '100px',
		border: '1px solid transparent',
	},

	badgeOutlined: {
		color: `var(--badge-outline-textcolor, ${theme.colors.badge.background})`,
		backgroundColor: `var(--badge-outline-backgroundcolor, ${theme.colors.badge.text})`,
		borderColor: `var(--badge-outline-bordercolor, ${theme.colors.badge.background})`,
	},

	badgeTransparent: {
		color: `var(--badge-transparent-textcolor, ${theme.colors.badge.background})`,
		backgroundColor: 'transparent',
	},

	badgeIcon: {
		fontSize: '14px',
		fontWeight: 'bold',
		lineHeight: '14px',
		padding: '0px 0px',
	},

	badgeCircular: {
		padding: '2px 2px',
	},

	displayValue: {
		minWidth: '10px',
		textAlign: 'center',
		fontWeight: 600,
	},

	dot: {
		minHeight: '7px',
		maxHeight: '7px',
		minWidth: '7px',
		maxWidth: '7px',
		padding: 'unset',
		borderWidth: '2px',
	},

	floating: {
		position: 'absolute',
		zIndex: 99,
	},

	floatingTop: {
		top: 0,
	},
	floatingLeft: {
		left: 0,
	},

	floatingRight: {
		right: 0,
	},
	floatingBottom: {
		bottom: 0,
	},

	wrap: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	fadeEnter: {
		opacity: 0,
		transform: 'scale(0.5,0.5)',
	},
	fadeEnterActive: {
		opacity: 1,
		transform: 'scale(1,1)',
		transition: 'all 200ms',
	},
	fadeExit: {
		opacity: 1,
		transform: 'scale(1,1)',
	},
	fadeExitActive: {
		opacity: 0,
		transform: 'scale(0.5,0.5)',
		transition: 'all 100ms',
	},
}))

interface IBadgeProps {
	/**
	 * The variant of the badge. If the variant is 'value', the badge will display the value. If the variant is 'icon', the badge will display the icon. If the variant is 'dot', the badge will display a dot.
	 */
	variant?: 'value' | 'icon' | 'dot'
	/**
	 * The value to be displayed in the badge. If the value is a number and greater than the maxValue, the badge will display the maxValue followed by a plus sign. Applied only if the variant is 'value'.
	 */
	value?: number | string
	/**
	 * The icon to be displayed in the badge. Applied only if the variant is 'icon'.
	 */
	icon?: string
	/**
	 * The maximum value to be displayed in the badge. If the value is a number and greater than the maxValue, the badge will display the maxValue followed by a plus sign. Applied only if the variant is 'value'.
	 */
	maxValue?: number
	/**
	 * DEPRECATED: Use position='inline' instead.
	 */
	positionInline?: boolean

	/**
	 * The position of the badge relative to the parent element. If the position is 'inline', the badge will be rendered inline with its siblings. If the position is 'floating', the badge will be rendered on top of the contained element (for instance a button).
	 */
	position?:
		| 'inline'
		| 'floating'
		| 'floatingTopRight'
		| 'floatingBottomRight'
		| 'floatingTopLeft'
		| 'floatingBottomLeft'

	/**
	 * The appearance of the badge. If the appearance is 'filled', the badge will have a filled background color. If the appearance is 'outline', the badge will have an outlined background color. If the appearance is 'transparent', the badge will have a transparent background color.
	 */
	appearance?: 'filled' | 'outline' | 'transparent'
	/**
	 * The color of the badge. If the color is provided, the badge will use the color as the background color and the text color will be the best contrast color to the provided color.
	 */
	color?: string

	/**
	 * The contrast color of the badge. If the contrastColor is provided, the badge will use the contrastColor as the text/border color.
	 */
	contrastColor?: string
	/**
	 * The offset from the right edge of the parent element. Applied only if the position is 'floating'.
	 */

	offsetRight?: number
	/**
	 *
	 */
	offsetTop?: number

	/**
	 * The offset from the left edge of the parent element. Applied only if the position is 'floating'.
	 */
	offsetLeft?: number
	/**
	 * The offset from the bottom edge of the parent element. Applied only if the position is 'floating'.
	 */
	offsetBottom?: number

	/**
	 * The children of the badge. The children will be wrapped with the badge if the badge is displayed in a floating position.
	 */
	children?: React.ReactNode | React.ReactNode[]

	/**
	 * Custom class name for styling the badge element
	 */
	className?: string
	/**
	 * Custom class name for positioning the badge element
	 */
	alignmentClassName?: string
}

const isFloatingPosition = (position: IBadgeProps['position']) => {
	return (
		position === 'floating' ||
		position === 'floatingTopRight' ||
		position === 'floatingBottomRight' ||
		position === 'floatingTopLeft' ||
		position === 'floatingBottomLeft'
	)
}

const getFloatingPositionClassNames = (position: IBadgeProps['position']) => {
	switch (position) {
		case 'floating':
		case 'floatingTopRight': {
			return classNames(classes.floating, classes.floatingTop, classes.floatingRight)
		}
		case 'floatingBottomRight': {
			return classNames(classes.floating, classes.floatingBottom, classes.floatingRight)
		}
		case 'floatingTopLeft': {
			return classNames(classes.floating, classes.floatingTop, classes.floatingLeft)
		}
		case 'floatingBottomLeft': {
			return classNames(classes.floating, classes.floatingBottom, classes.floatingLeft)
		}
		default:
			return ''
	}
}

function getFloatingPositionStyle(
	position: string,
	offsetTop: number | undefined,
	offsetRight: number | undefined,
	offsetBottom: number | undefined,
	offsetLeft: number | undefined
) {
	switch (position) {
		case 'floating':
		case 'floatingTopRight': {
			return offsetRight || offsetTop
				? { right: offsetRight ? `${offsetRight}px` : undefined, top: offsetTop ? `${offsetTop}px` : undefined }
				: undefined
		}
		case 'floatingBottomRight': {
			return offsetRight || offsetBottom
				? {
						right: offsetRight ? `${offsetRight}px` : undefined,
						bottom: offsetBottom ? `${offsetBottom}px` : undefined,
				  }
				: undefined
		}
		case 'floatingTopLeft': {
			return offsetLeft || offsetTop
				? { left: offsetLeft ? `${offsetLeft}px` : undefined, top: offsetTop ? `${offsetTop}px` : undefined }
				: undefined
		}
		case 'floatingBottomLeft': {
			return offsetLeft || offsetBottom
				? { left: offsetLeft ? `${offsetLeft}px` : undefined, bottom: offsetBottom ? `${offsetBottom}px` : undefined }
				: undefined
		}
		default:
			return undefined
	}
}

export const Badge = (props: IBadgeProps) => {
	const { variant = 'value', appearance = 'filled' } = props

	const position = props.position || (props.positionInline ? 'inline' : 'floatingTopRight')
	const [displayValue, setDisplayValue] = useState<string | undefined>()

	useEffect(() => {
		if (typeof props.value === 'number') {
			// the value displayed in the badge is numeric. Apply the maxValue if the value is greater than it.
			const numericValue =
				props.maxValue && props.value && props.value > props.maxValue ? `${props.maxValue}+` : props.value.toString()
			setDisplayValue(numericValue)
			return
		}

		// the value is not numeric, that is, a string or undefined
		setDisplayValue(props.value?.toString())
	}, [props.value, props.maxValue])

	const renderBadgeValue = () => {
		const positionOverrideStyle = getFloatingPositionStyle(
			position,
			props.offsetTop,
			props.offsetRight,
			props.offsetBottom,
			props.offsetLeft
		)

		const colorOverrideStyle = props.color
			? ({
					'--badge-filled-backgroundcolor': props.color,
					'--badge-filled-textcolor': props.contrastColor || getBestContrastColor(props.color, ['black', 'white']),
					'--badge-outline-backgroundcolor': props.contrastColor || 'white',
					'--badge-outline-textcolor': props.color,
					'--badge-outline-bordercolor': props.color,
					'--badge-transparent-textcolor': props.color,
			  } as CSSProperties)
			: undefined

		return (
			<SwitchTransition mode="out-in">
				<CSSTransition
					classNames={{
						enter: classes.fadeEnter,
						enterActive: classes.fadeEnterActive,
						exit: classes.fadeExit,
						exitActive: classes.fadeExitActive,
					}}
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false)
					}}
					key={displayValue || props.icon || variant}
				>
					<span
						className={classNames(
							classes.badge,
							appearance === 'outline' && classes.badgeOutlined,
							appearance === 'transparent' && variant !== 'dot' && classes.badgeTransparent,

							variant === 'icon' && props.icon && classes.badgeIcon,
							variant === 'value' && displayValue && displayValue.length === 1 && classes.badgeCircular,

							getFloatingPositionClassNames(position),
							isFloatingPosition(position) && classes.floating,
							variant === 'dot' && classes.dot,
							props.className
						)}
						style={
							positionOverrideStyle || colorOverrideStyle
								? { ...positionOverrideStyle, ...colorOverrideStyle }
								: undefined
						}
						data-cy={'badge'}
					>
						{variant === 'icon' && props.icon && <Icon iconClassName={props.icon} size="inherit" />}
						{variant === 'value' && displayValue && <span className={classes.displayValue}>{displayValue}</span>}
					</span>
				</CSSTransition>
			</SwitchTransition>
		)
	}

	const shouldDisplayBadge = variant === 'dot' || displayValue !== undefined || props.icon

	// render line badge
	if (!isFloatingPosition(position)) {
		return shouldDisplayBadge ? renderBadgeValue() : null
	}

	// render floating badge by wrapping the children with the badge
	if (shouldDisplayBadge) {
		return (
			<div className={classNames(classes.wrap, props.className)} title={props.value?.toString()} data-cy={'wrapper'}>
				<span className={classNames(classes.root, props.alignmentClassName)} data-cy={'root-span'}>
					{props.children}
					{renderBadgeValue()}
				</span>
			</div>
		)
	}

	// render the component without the badge
	return props.children || null
}
