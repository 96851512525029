import React, { useMemo } from 'react'
import { Button, Divider, Text } from '@genusbiz/web-ui/controls'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RuntimeVersionSelector } from './RuntimeVersionSelector'
import { GridLayout } from '@genusbiz/web-ui/surfaces'
import { e_GridTrackType, IGridLayout } from '@genusbiz/web-ui/surfaces/Grid/gridTypes'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { EnvironmentSelectionPanel } from './EnvironmentSelectionPanel'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { DeploymentStatusPanel } from './DeploymentStatusPanel'
import { e_DeploymentJobState } from 'src/enums/e_DeploymentJobState'
import { HelmValueConfigurationPanel } from './HelmValueConfigurationPanel'
import { deploymentUtils } from '../utils/deploymentUtils'
import { useDeploymentNavigation } from '../utils/deploymentNavigation'
import { getVersionDeploymentMethod } from 'src/features/VersionDeployment/utils/getVersionDeploymentEnvVars'
import { e_VersionDeploymentMetod } from 'src/enums/e_VersionDeploymentMethod'
import { commonStyles } from '../utils/commonStyle'
import { createStyle } from 'src/theming'
import classNames from 'clsx'
import { useHasValidGitToken } from 'src/utils/useHasValidGitToken'

const classes = createStyle((theme) => ({
	mainGrid: {
		minHeight: 'fit-content',
	},
	DeploymentDetailWrapper: {
		rowGap: theme.spacing.size1,
		display: 'flex',
		flexDirection: 'column',
	},
	gitTokenErrorPanel: {
		maxWidth: 'max-content',
		background: 'pink',
		border: '1px solid red',
		borderRadius: '6px',
		padding: '3px 6px',
		'&::before': {
			content: '""',
			width: '12px',
			height: '12px',
			background: 'red',
			borderRadius: '6px',
			display: 'inline-block',
			marginRight: '6px',
		},
	},
}))

const GRID_AREAS = {
	error_message: 'error_message',
	operator_button: 'operator_button',
	page_header: 'page_header',
	selection_header: 'selection_header',
	runtimes_selection: 'runtimes_selection',
	header: 'header',
	upper_divider: 'upper_divider',
	configure_header: 'configure_header',
	configure: 'configure',
	version_dropdowns: 'version_dropdowns',
	version_divider: 'version_divider',
	deploy_divider: 'deploy_divider',
	deploy_button: 'deploy_button',
	deployment_divider: 'deployment_divider',
	deployment_header: 'deployment_header',
	deployment_status: 'deployment_status',
}

const MAIN_GRID: IGridLayout[] = [
	{
		rows: [
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.fraction, length: 1 },
		],
		columns: [{ type: e_GridTrackType.fraction, length: 1 }],
		areas: {
			[GRID_AREAS.operator_button]: undefined,
			[GRID_AREAS.page_header]: undefined,
			[GRID_AREAS.selection_header]: undefined,
			[GRID_AREAS.runtimes_selection]: undefined,
			[GRID_AREAS.header]: undefined,
			[GRID_AREAS.version_dropdowns]: undefined,
			[GRID_AREAS.configure_header]: undefined,
			[GRID_AREAS.configure]: undefined,
			[GRID_AREAS.deploy_divider]: undefined,
			[GRID_AREAS.deploy_button]: undefined,
			[GRID_AREAS.deployment_status]: undefined,
		},
	},
]

export const RuntimeDeployment = () => {
	const { t } = useTranslation()

	useHasValidGitToken()

	const deploymentNavigation = useDeploymentNavigation()

	const deploymentDispatchers = deploymentUtils.useDeploymentDispatch()

	const version = useSelector(versionDeploymentSelectors.selectChosenVersion)

	const deploymentHasTargets = useDeploymentHastargets()
	const isSomeRelevantJobStatus = useIsSomeRelevantJobStatus()

	const deploymentMethod = getVersionDeploymentMethod()
	const isGenusOperatorDeployment = deploymentMethod === e_VersionDeploymentMetod.genusOperator

	const isUnfinishedDeployDispatch = useSelector(versionDeploymentSelectors.selectIsUnfinishedDeployDispatch)

	const jobStates = useSelector(versionDeploymentSelectors.selectJobStates)
	const hasLogs =
		[jobStates.active, jobStates.passive, jobStates.origin]
			.flatMap((vals) => vals)
			.filter((val) => val.status.deploymentPodLog).length > 0

	const deploymentMethodMessage = deploymentUtils.getDeploymentMethodMessage()

	return (
		<GridLayout
			gridConfig={MAIN_GRID}
			className={classNames(commonStyles.classes.deploymentContainer, classes.mainGrid)}
		>
			<Text variant="h1" data-gridname={GRID_AREAS.page_header} className={commonStyles.classes.pageHeader}>
				{t('UPGRADE:VERSION_DEPLOYMENT') + deploymentMethodMessage}
			</Text>
			<Text variant="h2" data-gridname={GRID_AREAS.selection_header}>
				{t('UPGRADE:CHOOSE_RUNTIMES')}
			</Text>
			<EnvironmentSelectionPanel data-gridname={GRID_AREAS.runtimes_selection} />
			<Text variant="h2" data-gridname={GRID_AREAS.header} className={commonStyles.classes.sectionHeader}>
				{t('UPGRADE:CHOOSE_VERSION')}
			</Text>
			<RuntimeVersionSelector data-gridname={GRID_AREAS.version_dropdowns} />
			<Text variant="h2" data-gridname={GRID_AREAS.configure_header} className={commonStyles.classes.sectionHeader}>
				{t('UPGRADE:CONFIGURE_HELM_VALUES')}
			</Text>
			<HelmValueConfigurationPanel
				showConfigurationView={deploymentNavigation.showRuntimeHelmConfiguration}
				data-gridname={GRID_AREAS.configure}
			/>
			<div
				data-gridname={GRID_AREAS.deploy_button}
				className={commonStyles.classes.sectionHeader}
				style={{ display: 'flex' }}
			>
				<Button
					className={commonStyles.classes.button}
					variant="primary"
					onClick={deploymentDispatchers.deployChosenTargets}
					disabled={!(version && deploymentHasTargets) || isUnfinishedDeployDispatch}
					label={t('UPGRADE:DEPLOY')}
				/>
				{isGenusOperatorDeployment && (
					<Button
						className={commonStyles.classes.button}
						onClick={deploymentNavigation.showRuntimeLog}
						disabled={!hasLogs}
						label={t('UPGRADE:INSPECT_LOG')}
					/>
				)}
			</div>

			<div data-gridname={GRID_AREAS.deployment_status} className={classes.DeploymentDetailWrapper}>
				{isSomeRelevantJobStatus && (
					<>
						<Divider className={commonStyles.classes.horizontal_divider} />
						<Text variant="h2">{t(`UPGRADE:DEPLOYMENT_STATUS`)}</Text>
						<DeploymentStatusPanel
							abortDeployment={deploymentDispatchers.abortDeployment}
							data-gridname={GRID_AREAS.deployment_status}
						/>
					</>
				)}
			</div>
		</GridLayout>
	)
}

const isRelevantJobStatus = (deploymentJobState: e_DeploymentJobState) =>
	deploymentJobState !== e_DeploymentJobState.unknown

const useIsSomeRelevantJobStatus = () => {
	const jobStates = useSelector(versionDeploymentSelectors.selectJobStates)
	const jobStatesWithoutOperator = Object.entries(jobStates).filter(([key, _]) => key !== 'operator')

	const isSomeRelevantJobStatus = jobStatesWithoutOperator.some(([_, operatingTypeState]) =>
		operatingTypeState.some((state) => isRelevantJobStatus(state.status.deploymentJobState))
	)

	return isSomeRelevantJobStatus
}

const useDeploymentHastargets = () => {
	const chosenTypes = useSelector(versionDeploymentSelectors.selectChosenOperatingTypes)
	const deploymentTargets = useSelector(kubernetesSelectors.selectK8sRuntimes)

	const hasTargets = useMemo(() => {
		return deploymentTargets.some((target) => deploymentUtils.isSelected(chosenTypes, target.currentlyOperatingAs))
	}, [deploymentTargets, chosenTypes])

	return hasTargets
}
