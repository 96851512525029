import React, { useState } from 'react'
import { Button, Divider, Text } from '@genusbiz/web-ui/controls'
import { createStyle } from 'src/theming'
import { useTranslation } from 'react-i18next'
import { LogContainer } from '../../Workloads/Pods/LogContainer'
import { StateIcon } from './StateIcon'
import classNames from 'clsx'
import { IDeploymentJobStatus } from 'src/interfaces/IDeployJob'
import { commonStyles } from '../utils/commonStyle'

const classes = createStyle((theme) => ({
	deploymentLogWrapper: {
		margin: `${theme.spacing.size3} ${theme.spacing.size4}`,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.size3,
	},
	stateIcon: {
		margin: theme.spacing.size1,
		marginLeft: theme.spacing.size2,
		marginRight: theme.spacing.size0_5,
	},
	chip: {
		display: 'flex',
		alignItems: 'center',
		borderColor: '#ddd',
		borderStyle: 'solid',
		borderRadius: '4px',
		background: theme.controls.chip.colors.background,
		color: theme.controls.chip.colors.color,
	},
	selectedChipColor: {
		background: theme.controls.chip.checkedColors.background,
		color: theme.controls.chip.checkedColors.color,
	},
	chipContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		columnGap: theme.spacing.size2,
		rowGap: theme.spacing.size3,
	},
}))

interface IDeploymentProps {
	showMainView: () => void
	logs: { targetName: string; status: IDeploymentJobStatus }[]
}

export const DeploymentLog = (props: IDeploymentProps) => {
	const { t } = useTranslation()

	const [logIndex, setLogIndex] = useState(0)

	const selectedLog = props.logs.at(logIndex)?.status.deploymentPodLog ?? ''

	return (
		<div className={classes.deploymentLogWrapper}>
			<Button onClick={props.showMainView} className={commonStyles.classes.smallButton} label={t('GENERAL:RETURN')} />
			<Text variant="h2">{t('UPGRADE:DEPLOYMENT_LOG')}</Text>
			<div className={classes.chipContainer}>
				{props.logs.map((state, i) => {
					const isSelected = logIndex === i
					return (
						<button
							key={'logChip' + state.targetName}
							onClick={() => setLogIndex(i)}
							className={classNames(classes.chip, isSelected && classes.selectedChipColor)}
						>
							<Text className={commonStyles.classes.h5Font}>{state.targetName}</Text>
							<StateIcon
								state={state.status.deploymentJobState}
								className={classes.stateIcon}
								invertSpinner={isSelected}
							/>
						</button>
					)
				})}
			</div>
			<Divider className="" style={{ height: '2px', margin: '0px -6px' }} />
			<LogContainer log={selectedLog} />
		</div>
	)
}
