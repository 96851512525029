import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { versionDeploymentActions } from 'src/features/VersionDeployment/duck/versionDeploymentActions'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { Button, Divider, Text } from '@genusbiz/web-ui/controls'
import { createStyle } from 'src/theming'
import { useTranslation } from 'react-i18next'
import { OperatorVersionSelector } from './OperatorVersionSelector'
import { e_DeploymentJobState } from 'src/enums/e_DeploymentJobState'
import { deploymentStatusUtils } from '../utils/deploymentStatusUtils'
import { DeploymentStatusField } from '../components/DeploymentStatusField'
import { deploymentUtils } from '../utils/deploymentUtils'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { operatorDeploymentUtils } from '../utils/operatorDeploymentUtils'
import { EditRuntimeValuesButton } from '../components/EditRuntimeValuesButton'
import { useDeploymentNavigation } from '../utils/deploymentNavigation'
import { e_ChartName } from 'src/interfaces/IContainerRegistry'
import { VersionWarning } from '../components/VersionWarning'
import { commonStyles } from '../utils/commonStyle'
import classNames from 'clsx'
import { useHasValidGitToken } from 'src/utils/useHasValidGitToken'

const classes = createStyle((theme) => ({
	DeploymentDetailWrapper: {
		rowGap: theme.spacing.size1,
		display: 'flex',
		flexDirection: 'column',
	},
	runtimeDisplay: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginLeft: theme.spacing.size1,
		columnGap: theme.spacing.size3,
		rowGap: theme.spacing.size3,

		'@media (min-width: 400px)': {
			flexDirection: 'row',
		},
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
}))

const HORIZONTAL_DIVIDER_STYLE = { height: '2px', marginTop: '30px' }

export const OperatorDeployment = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useHasValidGitToken()

	const deploymentNavigation = useDeploymentNavigation()

	const version = useSelector(versionDeploymentSelectors.selectChosenOperatorVersion)
	const versions = useSelector(versionDeploymentSelectors.selectOperatorVersionOptions)
	const versionOptions = versions?.tags.map((tag) => tag.name)

	const operatorRuntime = operatorDeploymentUtils.useOperatorRuntime()

	const operatorVersion = window.env.GENUS_OPERATOR_VERSION_NUMBER

	const deployOperator = () => {
		if (!version) {
			return
		}
		dispatch(versionDeploymentActions.deployVersion(e_ChartName.genusOperator, [operatorRuntime.name], version.name))
	}
	const helmValueErrors = useSelector(versionDeploymentSelectors.selectRuntimesValueErrors)

	const deploymentDispatchers = deploymentUtils.useDeploymentDispatch()

	const jobStates = useSelector(versionDeploymentSelectors.selectJobStates)

	const hasOngoingDeployment = jobStates.operator.some(
		(state) =>
			state.status.deploymentJobState === e_DeploymentJobState.inProgress ||
			state.status.deploymentJobState === e_DeploymentJobState.pending
	)

	const hasLogs = jobStates.operator.flatMap((vals) => vals).filter((val) => val.status.deploymentPodLog).length > 0

	const isSomeRelevantJobStatus = useIsSomeRelevantJobStatus()

	const operatorDisplayState = deploymentStatusUtils.findDisplayState(jobStates.operator)

	const showConfigurationView = () => {
		dispatch(versionDeploymentActions.setRuntimeSelectedForConfiguration(operatorRuntime.name))
		deploymentNavigation.showOperatorHelmConfiguration()
	}

	const deploymentMethodMessage = deploymentUtils.getDeploymentMethodMessage()

	return (
		<div className={classNames(commonStyles.classes.deploymentContainer, classes.container)}>
			<Text variant="h1" className={commonStyles.classes.pageHeader}>
				{t('UPGRADE:OPERATOR_VERSION_DEPLOYMENT') + deploymentMethodMessage}
			</Text>
			<Text variant="h2">{t('UPGRADE:OPERATOR_RUNTIME')}</Text>
			<div className={classes.runtimeDisplay}>
				<Text variant="body1">{operatorRuntime.name}</Text>
				<Text className={commonStyles.classes.versionNumber}>{operatorVersion}</Text>
				<VersionWarning currentVersions={[operatorVersion]} versionOptions={versionOptions} />
			</div>
			<Text variant="h2" className={commonStyles.classes.sectionHeader}>
				{t('UPGRADE:CHOOSE_VERSION')}
			</Text>
			<OperatorVersionSelector />
			<Text variant="h2" className={commonStyles.classes.sectionHeader}>
				{t('UPGRADE:CONFIGURE_HELM_VALUE')}
			</Text>

			<EditRuntimeValuesButton
				isRuntimeWithHelmErrors={runtimeHasErrors(operatorRuntime.name, helmValueErrors)}
				isValidVersion={!!version}
				setChosenHelmTarget={showConfigurationView}
				targetName={operatorRuntime.name}
			/>
			<div style={{ display: 'flex' }} className={commonStyles.classes.sectionHeader}>
				<Button
					className={commonStyles.classes.button}
					variant="primary"
					onClick={deployOperator}
					disabled={!version || hasOngoingDeployment}
					label={t('UPGRADE:DEPLOY')}
				/>
				<Button
					className={commonStyles.classes.button}
					onClick={deploymentNavigation.showOperatorLog}
					disabled={!hasLogs}
					label={t('UPGRADE:INSPECT_LOG')}
				/>
			</div>
			{
				<div className={classes.DeploymentDetailWrapper}>
					{isSomeRelevantJobStatus && (
						<>
							<Divider style={HORIZONTAL_DIVIDER_STYLE} />
							<Text variant="h2">{t(`UPGRADE:DEPLOYMENT_STATUS`)}</Text>
							<DeploymentStatusField
								label={`Operator`}
								state={operatorDisplayState}
								abort={() => deploymentDispatchers.abortDeployment(e_EnvironmentOperatingType.operator)}
							/>
						</>
					)}
				</div>
			}
		</div>
	)
}

type IHelmValidationErrors = ReturnType<typeof versionDeploymentSelectors.selectRuntimesValueErrors>

const runtimeHasErrors = (runtimeName: string, errors: IHelmValidationErrors) =>
	errors[runtimeName] !== undefined && errors[runtimeName].length > 0

const isRelevantJobStatus = (deploymentJobState: e_DeploymentJobState) =>
	deploymentJobState !== e_DeploymentJobState.unknown

const useIsSomeRelevantJobStatus = () => {
	const jobStates = useSelector(versionDeploymentSelectors.selectJobStates)

	const isSomeRelevantJobStatus = jobStates.operator.some((state) =>
		isRelevantJobStatus(state.status.deploymentJobState)
	)

	return isSomeRelevantJobStatus
}
