import React, { useState } from 'react'
import { NavigationPane, NavigationPaneItemType, INavigationPaneItem } from '@genusbiz/web-ui/controls/NavigationPane'
import classNames from 'clsx'
import { e_NavigationItem } from 'enums/e_NavigationItems'
import { defaultIcons } from 'src/consts/defaultIcons'
import { useDispatch, useSelector } from 'react-redux'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { useTranslation } from 'react-i18next'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { useNavigate, useLocation } from 'react-router-dom'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { isActive } from 'src/utils/publicationIsActive'
import { defaultColors } from 'src/consts/defaultColors'
import { createStyle } from '@genusbiz/web-ui/theming'
import sortByValue from 'src/utils/sortByValue'
import { e_EnvironmentType } from 'src/enums/e_EnvironmentType'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { getVersionDeploymentEnabled } from 'src/features/VersionDeployment/utils/getVersionDeploymentEnvVars'

const classes = createStyle((theme) => ({
	sideBar: {
		zIndex: theme.zIndex.drawer + 1,
		display: 'flex',
		flexDirection: 'column',
		background: theme.colors.panel.background,
		borderRight: '1px solid ' + theme.colors.panel.border,
		overflow: 'hidden',
		overflowY: 'auto',
	},
	separator: {
		width: '70%',
		alignSelf: 'center',
	},
	sideBarWidth: {
		minWidth: 200,
	},
	navItemUnreadMessages: {
		fontWeight: 700,
	},
	navItemUnreadErrors: {
		fontWeight: 700,
		color: '#c21018', //theme.webui.palette.error,theme.colors.body.errorText
	},
	navItemUnreadWarnings: {
		fontWeight: 700,
		color: 'orange',
	},
	envIsUp: {
		'& i': { color: defaultColors.successText },
	},
	envIsDown: {
		'& i': { color: 'gray' },
	},
	activeK8sRuntime: {
		fontWeight: 700,
	},
}))

export const OperatorNavigationPane = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const activeNavigationItem = location.pathname.substring(1)
	const errorLogs = useSelector(operatorFrontendSelectors.selectErrorLogs)

	const eventLogUrl = useSelector(operatorFrontendSelectors.selectEventLogUrl)

	const openInNewTab = (url: string) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) {
			newWindow.opener = null
		}
	}
	const k8sRuntimesIncludingOperator = sortByValue(
		useSelector(kubernetesSelectors.selectK8sRuntimesIncludingOperator),
		'environmentType'
	)
	const environmentResourceOverviews = useSelector(kubernetesSelectors.selectAllEnironmentResourceOverviews)
	const navDrawerEntryClick = (id: string) => {
		const item = id
		const [navigationItem, selection] = (item.indexOf('/') ? item.split('/') : [item]) as [e_NavigationItem, string?]

		switch (navigationItem) {
			case e_NavigationItem.workloads:
			case e_NavigationItem.availability:
				dispatch(operatorFrontendActions.setNavigationItem(navigationItem, selection))

				navigate('/' + item)
				break
			case e_NavigationItem.runtimes:
			case e_NavigationItem.endpoints:
			case e_NavigationItem.versionDeployment:
			case e_NavigationItem.errorLog: {
				dispatch(operatorFrontendActions.setNavigationItem(navigationItem))
				navigate('/' + navigationItem)
				break
			}
			case e_NavigationItem.eventLog:
				openInNewTab(eventLogUrl)
				break
			case e_NavigationItem.metaModelManager:
				dispatch(modalManagerActions.showMetaModelManagerModal({}))
				break
		}
	}

	const navDrawerConfigurationItems: INavigationPaneItem[] = [
		{ type: NavigationPaneItemType.header, title: t('NAVIGATION_PANE:CONFIGURATION').toUpperCase() },
		{
			id: e_NavigationItem.runtimes,
			title: t('NAVIGATION_PANE:RUNTIMES_NAVIGATION'),
			type: NavigationPaneItemType.button,
			iconClassName: defaultIcons.page,
		},
		{
			id: e_NavigationItem.endpoints,
			title: t('ADMINISTRATION:ENDPOINTS'),
			type: NavigationPaneItemType.button,
			iconClassName: defaultIcons.sitemapLinkUrl,
		},
	]
	const availabilityWindowsItems: INavigationPaneItem[] = [
		{
			title: t('AVAILABILITY_WINDOW:AVAILABILITY_WINDOWS').toUpperCase(),
			type: NavigationPaneItemType.header,
		},
		...[e_EnvironmentOperatingType.active, e_EnvironmentOperatingType.passive, e_EnvironmentOperatingType.origin].map(
			(environmentOperatingType) => {
				const resourceOverview = environmentResourceOverviews[environmentOperatingType]

				if (resourceOverview) {
					return {
						id: [e_NavigationItem.availability, e_EnvironmentOperatingType[environmentOperatingType]].join('/'),
						title: t(`GENERAL:${e_EnvironmentOperatingType[environmentOperatingType].toUpperCase()}`),
						screenTip:
							t(`GENERAL:${e_EnvironmentOperatingType[environmentOperatingType].toUpperCase()}`) +
							(resourceOverview.shouldBeUp
								? ' (' + t('AVAILABILITY_WINDOW:UP') + ')'
								: ' (' + t('AVAILABILITY_WINDOW:DOWN') + ')'),
						type: NavigationPaneItemType.button,
						iconClassName: resourceOverview.shouldBeUp ? 'Fluent-SkypeCircleCheck' : 'Fluent-DrillDownSolid',
						className: resourceOverview.shouldBeUp ? classNames(classes.envIsUp) : classNames(classes.envIsDown),
					}
				} else {
					return {
						id: [e_NavigationItem.availability, e_EnvironmentOperatingType[environmentOperatingType]].join('/'),
						title: t(`GENERAL:${e_EnvironmentOperatingType[environmentOperatingType].toUpperCase()}`),
						type: NavigationPaneItemType.button,
						iconClassName: defaultIcons.analysisGauge,
					}
				}
			}
		),
	]

	availabilityWindowsItems.push({ type: NavigationPaneItemType.separator })
	availabilityWindowsItems.push({
		id: [e_NavigationItem.availability, 'maintenance'].join('/'),
		title: t('MAINTENANCE:MAINTENANCE'),
		type: NavigationPaneItemType.button,
		iconClassName: defaultIcons.maintenance,
	})

	const navDrawerWorkloadItems: INavigationPaneItem[] = [
		{ type: NavigationPaneItemType.header, title: t('NAVIGATION_PANE:RUNTIMES').toUpperCase() },
		...k8sRuntimesIncludingOperator.map((k8sRuntime: IK8sRuntime) => {
			return {
				id: e_NavigationItem.workloads + '/' + k8sRuntime.name,
				title: k8sRuntime.name,
				screenTip: k8sRuntime.name + (isActive(k8sRuntime.currentPublication) ? ' (' + t('GENERAL:ACTIVE') + ')' : ''),
				type: NavigationPaneItemType.button,
				iconClassName:
					k8sRuntime.environmentType === e_EnvironmentType.operator ? 'Fluent-MasterDatabase' : defaultIcons.database,
				className: isActive(k8sRuntime.currentPublication) ? classNames(classes.activeK8sRuntime) : '',
			}
		}),
	]
	navDrawerWorkloadItems.splice(navDrawerWorkloadItems.length - 1, 0, { type: NavigationPaneItemType.separator })

	const navDrawerEventMonitoring: INavigationPaneItem[] = [
		{
			type: NavigationPaneItemType.header,
			title: t('NAVIGATION_PANE:MONITORING').toUpperCase(),
		},
		{
			id: e_NavigationItem.errorLog,
			title: t('NAVIGATION_PANE:ERROR_LOG'),
			type: NavigationPaneItemType.button,
			iconClassName: defaultIcons.warning,
			badgeValue: errorLogs.filter((errorLog) => errorLog.isBreadcrumb === false).length,
		},
	]
	if (eventLogUrl) {
		navDrawerEventMonitoring.push({
			id: e_NavigationItem.eventLog,
			title: t('NAVIGATION_PANE:EVENT_LOG'),
			type: NavigationPaneItemType.button,
			iconClassName: defaultIcons.open,
		})
	}
	const navDrawerTools: INavigationPaneItem[] = [
		{
			type: NavigationPaneItemType.header,
			title: t('NAVIGATION_PANE:TOOLS').toUpperCase(),
		},
	]
	if (getVersionDeploymentEnabled()) {
		navDrawerTools.push({
			id: e_NavigationItem.versionDeployment,
			title: t('NAVIGATION_PANE:VERSION_DEPLOYMENT'),
			type: NavigationPaneItemType.button,
			iconClassName: defaultIcons.versionDeployment,
		})
	}

	navDrawerTools.push({
		id: e_NavigationItem.metaModelManager,
		title: t('NAVIGATION_PANE:META_MODEL_MANAGER'),
		type: NavigationPaneItemType.button,
		iconClassName: defaultIcons.metaModelManager,
	})

	const navDrawerItems = navDrawerConfigurationItems.concat(
		availabilityWindowsItems,
		navDrawerWorkloadItems,
		navDrawerEventMonitoring,
		navDrawerTools
	)

	const [navDrawerCompactMode, setNavDrawerCompactMode] = useState(false)

	const toggleNavDrawerCompactMode = () => {
		setNavDrawerCompactMode(!navDrawerCompactMode)
	}
	return (
		<div className={classNames(classes.sideBar, !navDrawerCompactMode && classes.sideBarWidth)}>
			<NavigationPane
				entries={navDrawerItems}
				activeItemId={activeNavigationItem}
				onItemClick={navDrawerEntryClick}
				collapsible
				onToggleCollapse={toggleNavDrawerCompactMode}
				defaultCollapsed={navDrawerCompactMode}
				separatorClassName={classes.separator}
			/>
		</div>
	)
}
