import React from 'react'
import { useSelector } from 'react-redux'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { DeploymentLog } from '../components/DeploymentLog'
import { useDeploymentNavigation } from '../utils/deploymentNavigation'

export const RuntimeDeploymentLog = () => {
	const jobStates = useSelector(versionDeploymentSelectors.selectJobStates)

	const deploymentNavigation = useDeploymentNavigation()

	const runtimesWithLog = [jobStates.active, jobStates.passive, jobStates.origin]
		.flatMap((states) => states)
		.filter((state) => state.status.deploymentPodLog)

	const isEmptyLogs = runtimesWithLog.length === 0
	deploymentNavigation.useNavgiateToRuntimeConditionally(isEmptyLogs)

	return <DeploymentLog logs={runtimesWithLog} showMainView={deploymentNavigation.showRuntimeDeployment} />
}
