import type { SyntheticEvent, EventHandler, RefObject } from 'react'
import { useRef, useEffect } from 'react'

export function useEventListener<T extends SyntheticEvent<any>>(
	eventName: string,
	handler: EventHandler<T>,
	element: RefObject<HTMLElement> | EventTarget | null = window,
	options?: AddEventListenerOptions | boolean
) {
	// Create a ref that stores handler
	const savedHandler = useRef<EventHandler<T>>()

	// Update ref.current value if handler changes, so latest handler...
	// ... always is used.
	useEffect(() => {
		savedHandler.current = handler
	}, [handler])

	useEffect(() => {
		if (element === null) {
			return
		}

		let elementToBeListenedTo: EventTarget

		// If element not of type EventTarget, use element.current
		if (!(element instanceof EventTarget)) {
			if (!element.current) {
				return
			}
			elementToBeListenedTo = element.current
		} else {
			elementToBeListenedTo = element
		}

		// Make sure element supports addEventListener
		const isSupported = elementToBeListenedTo?.addEventListener
		if (!isSupported) {
			return
		}

		// Create event listener that calls handler function stored in ref
		const eventListener: EventListener = (event: any) => savedHandler.current?.(event)

		// Add event listener
		elementToBeListenedTo.addEventListener(eventName, eventListener, options)

		// Remove event listener on cleanup
		return () => {
			elementToBeListenedTo?.removeEventListener(eventName, eventListener, options)
		}
	}, [eventName, element]) // Re-run if eventName or element changes
}
