import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { Icon } from '../Icon'
import { e_ItemContentLayout, getContentLayout } from './MenuItem.types'
import type { IContentLayout } from './MenuItem.types'

const classes = createStyle((theme) => ({
	itemContent: {
		display: 'flex',
		flex: 1,
		padding: '0.2em 0.65em',
		cursor: 'pointer',
		color: theme.colors.menu.text,
		borderRadius: theme.controls.button.borderRadius,
		margin: '0em 0.35em',
		'&:hover:not([aria-disabled="true"])': {
			background: theme.controls.field.hoverColors.buttonBackground,
			color: theme.palette.primary.themePrimary,
		},
		'&:hover:active:not([aria-disabled="true"])': {
			background: theme.controls.field.pressedColors.buttonBackground,
		},
	},

	menuIcon: {
		width: 25,
		height: 25,
		display: 'flex',
		alignItems: 'center',
	},

	menuText: {
		whiteSpace: 'nowrap',
		display: 'flex',
		alignItems: 'center',
		flex: 1,
	},

	shortcutText: {
		whiteSpace: 'nowrap',
		display: 'flex',
		alignItems: 'center',
		marginLeft: '16px',
	},

	subMenuIcon: {
		width: 20,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		marginLeft: 5,
	},

	selectedItem: {
		background: theme.controls.field.checkedColors.buttonBackground,
		color: theme.colors.menu.hoveredText,
	},
	disabledMenu: {
		opacity: 0.5,
		cursor: 'default',
	},
}))

interface IMenuItemContentProps {
	iconClassName?: string
	iconString?: string
	displayText?: string
	keyboardShortcutText?: string
	iconColorClass?: string
	isSelected?: boolean
	isChecked?: boolean
	disabled?: boolean
	displaySubItems?: boolean
	contentLayout: IContentLayout | undefined
}

export const MenuItemContent = (props: IMenuItemContentProps) => {
	const getIconComponent = () => {
		if (props.iconClassName && !props.isChecked) {
			return <Icon iconClassName={classNames(props.iconClassName, props.iconColorClass)} />
		}
		if (props.iconString) {
			return props.iconString
		}
		return null
	}

	const contentLayout = getContentLayout(props.contentLayout, 'Menu')

	return (
		<div
			className={classNames(
				classes.itemContent,
				props.isSelected && classes.selectedItem,
				props.disabled && classes.disabledMenu
			)}
			aria-disabled={props.disabled}
		>
			{contentLayout !== e_ItemContentLayout.text && (
				<div className={classes.menuIcon}>
					{props.isChecked && <Icon iconClassName="Fluent-CheckMark" />}
					{getIconComponent()}
				</div>
			)}
			{contentLayout !== e_ItemContentLayout.icon && (
				<>
					<div className={classes.menuText}>{props.displayText}</div>
					{props.keyboardShortcutText && <div className={classes.shortcutText}>{props.keyboardShortcutText}</div>}
				</>
			)}
			<div className={classes.subMenuIcon}>
				{props.displaySubItems && <Icon iconName="Fluent-ChevronRight" size="extraSmall" />}
			</div>
		</div>
	)
}
