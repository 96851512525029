import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { OperatingTypesHelmButtons } from '../components/OperatingTypesHelmButtons'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { Text } from '@genusbiz/web-ui/controls'
import { versionDeploymentActions } from 'src/features/VersionDeployment/duck/versionDeploymentActions'
import { commonStyles } from '../utils/commonStyle'

interface IHelmValueConfigurationPanelProps {
	showConfigurationView: () => void
}

export const HelmValueConfigurationPanel = (props: IHelmValueConfigurationPanelProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const showConfigurationView = (runtimeName: string) => {
		dispatch(versionDeploymentActions.setRuntimeSelectedForConfiguration(runtimeName))
		props.showConfigurationView()
	}

	const version = useSelector(versionDeploymentSelectors.selectChosenVersion)
	const chosenEnvironments = useSelector(versionDeploymentSelectors.selectChosenOperatingTypes)
	const chosenTargets = useChosenTargetsGroupedByOperatingType(chosenEnvironments)

	const hasChosenTargets = chosenTargets.active || chosenTargets.passive || chosenTargets.origin

	return (
		<div className={commonStyles.classes.sectionContentWrapper}>
			{!hasChosenTargets && (
				<Text className={commonStyles.classes.h6Font} style={{ marginTop: '4px' }}>
					{t('UPGRADE:NO_CHOSEN_RUNTIMES')}
				</Text>
			)}
			{chosenTargets.active && (
				<OperatingTypesHelmButtons
					title={t('GENERAL:ACTIVE')}
					isValidVersion={!!version}
					setChosenHelmTarget={showConfigurationView}
					targets={chosenTargets.active}
				/>
			)}
			{chosenTargets.passive && (
				<OperatingTypesHelmButtons
					title={t('GENERAL:PASSIVE')}
					isValidVersion={!!version}
					setChosenHelmTarget={showConfigurationView}
					targets={chosenTargets.passive}
				/>
			)}
			{chosenTargets.origin && (
				<OperatingTypesHelmButtons
					title={t('GENERAL:ORIGIN')}
					isValidVersion={!!version}
					setChosenHelmTarget={showConfigurationView}
					targets={chosenTargets.origin}
				/>
			)}
		</div>
	)
}

type TUpgradeOperatingTypes = 'origin' | 'passive' | 'active'

const useChosenTargetsGroupedByOperatingType = (chosenTypes: Record<TUpgradeOperatingTypes, boolean>) => {
	const deploymentTargets = useSelector(kubernetesSelectors.selectK8sRuntimes)

	const chosenTargets = useMemo(() => {
		const selectedRuntimes = deploymentTargets.filter((target) => isSelected(chosenTypes, target.currentlyOperatingAs))
		const groupedRuntimes = Object.groupBy(
			selectedRuntimes,
			(target) => e_EnvironmentOperatingType[target.currentlyOperatingAs] as TUpgradeOperatingTypes
		)

		return groupedRuntimes
	}, [deploymentTargets, chosenTypes])

	return chosenTargets
}

const isValidOperatingType = (typeName: string): typeName is TUpgradeOperatingTypes =>
	['origin', 'passive', 'active'].includes(typeName)

const isSelected = (selection: Record<TUpgradeOperatingTypes, boolean>, operatingType: e_EnvironmentOperatingType) =>
	isValidOperatingType(e_EnvironmentOperatingType[operatingType]) &&
	selection[e_EnvironmentOperatingType[operatingType]]
