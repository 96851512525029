import moment from 'moment-timezone'
import { defaultIcons } from 'src/consts/defaultIcons'
import { IMaintenanceConfiguration } from 'src/interfaces/IAvailabilityWindow'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { MaintenanceState } from 'src/enums/e_MaintenanceConfigurationType'

export const isMaintenanceDefined = (
	maintenanceConfiguration: IMaintenanceConfiguration
): maintenanceConfiguration is Required<IMaintenanceConfiguration> =>
	maintenanceConfiguration.startTime !== null &&
	maintenanceConfiguration.durationInMinutes !== null &&
	maintenanceConfiguration.externalURL !== null

const IsUp = (maintenanceConfiguration: IMaintenanceConfiguration) => {
	const { startTime, durationInMinutes } = maintenanceConfiguration

	if (!startTime || !durationInMinutes) {
		return false
	}

	const startMoment = moment(startTime)

	const endMoment = startMoment.clone().add(durationInMinutes, 'minutes')

	return moment().isBetween(startMoment, endMoment)
}

const IsScheduled = (maintenanceConfiguration: IMaintenanceConfiguration) => {
	const { startTime } = maintenanceConfiguration
	const startMoment = moment(startTime)
	return moment().isBefore(startMoment)
}

export const resolveMaintenanceState = (maintenanceConfiguration: IMaintenanceConfiguration) => {
	const isScheduled = IsScheduled(maintenanceConfiguration)
	const isUp = IsUp(maintenanceConfiguration)
	if (isUp) {
		return MaintenanceState.Up
	} else if (isScheduled) {
		return MaintenanceState.Scheduled
	}
	return MaintenanceState.Down
}

export const resolveMaintenanceStatusString = (
	maintenanceConfiguration: IMaintenanceConfiguration,
	timezone: string,
	preferredFormattingCulture: string
) => {
	const maintenanceState = resolveMaintenanceState(maintenanceConfiguration)
	const { startTime, durationInMinutes } = maintenanceConfiguration
	const startTimeMoment = moment.tz(startTime, timezone).locale(preferredFormattingCulture)

	switch (maintenanceState) {
		case MaintenanceState.Up:
			return `Maintenance is ongoing until ${startTimeMoment.add(durationInMinutes, 'minutes').format('LLLL')}`
		case MaintenanceState.Scheduled: {
			const end = startTimeMoment.clone().add(durationInMinutes, 'minutes')
			if (!startTimeMoment.isSame(end, 'day')) {
				return `Maintenance is scheduled from ${startTimeMoment.format('LLLL')} to ${end.format('LLLL')}`
			}
			return `Maintenance is scheduled for ${startTimeMoment.format('LLLL')} to ${end.format('LT')}`
		}
		default:
			return 'No maintenance scheduled'
	}
}

export const resolveMaintenanceStatusIcon = (maintenanceState: MaintenanceState) => {
	switch (maintenanceState) {
		case MaintenanceState.Up:
			return defaultIcons.remove
		case MaintenanceState.Scheduled:
			return defaultIcons.clock
		default:
			return ''
	}
}

export const resolveHostNames = (runtimes: IK8sRuntime[]) => {
	const endpoints = runtimes.flatMap((runtime) => runtime.endpoints ?? [])
	const hostNames = endpoints.map((endpoint) => endpoint.hostName)
	return Array.from(new Set(hostNames))
}
