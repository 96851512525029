import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { MaintenanceEditor } from './MaintenanceEditor'
import { MaintenanceStatus } from './MaintenanceStatus'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { createStyle } from 'src/theming'

const classes = createStyle((theme) => ({
	container: {
		margin: `${theme.spacing.size5} ${theme.spacing.size4}`,
	},
	contentContainer: {
		margin: theme.spacing.size1,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing.size2,
	},
}))

export const Maintenance = () => {
	const environmentAvailabilitySchedule = useSelector(kubernetesSelectors.selectEnvironmentAvailabilitySchedule)
	const [maintenanceEditorModalOpen, setMaintenanceEditorModalOpen] = useState(false)

	const maintenanceConfiguration = environmentAvailabilitySchedule.maintenanceConfiguration

	return (
		<div className={classes.container}>
			<MaintenanceStatus
				maintenanceConfiguration={maintenanceConfiguration}
				setNewMaintenanceEditorModalOpen={setMaintenanceEditorModalOpen}
			/>
			<MaintenanceEditor
				maintenanceConfiguration={maintenanceConfiguration}
				maintenanceEditorModalOpen={maintenanceEditorModalOpen}
				onClose={() => setMaintenanceEditorModalOpen(false)}
			/>
		</div>
	)
}
