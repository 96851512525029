import type { Palette } from '../themeProperties/colors/paletteGenerator'
import type { SemanticColors } from '../semanticMapping'
import { cssVar } from '../../theming/themingManager'

export const generateInputColorsFromSemanticPalette = (palette: Palette, semanticPalette: SemanticColors) => {
	return {
		colors: {
			background: cssVar(semanticPalette.input, 'background'),
			buttonBackground: cssVar(semanticPalette.input, 'buttonBackground'),
			color: cssVar(semanticPalette.input, 'text'),
			border: cssVar(semanticPalette.input, 'border'),
			link: cssVar(semanticPalette.links, 'text'),
		},
		hoverColors: {
			background: cssVar(semanticPalette.input, 'background'),
			buttonBackground: cssVar(semanticPalette.button, 'hoveredBackground'),
			color: cssVar(semanticPalette.input, 'text'),
			border: cssVar(semanticPalette.input, 'hoveredBorder'),
			link: cssVar(semanticPalette.links, 'hoveredText'),
		},
		focusedColors: {
			background: cssVar(semanticPalette.input, 'background'),
			color: cssVar(semanticPalette.input, 'text'),
			border: cssVar(semanticPalette.input, 'focusBorder'),
		},
		pressedColors: {
			buttonBackground: cssVar(semanticPalette.button, 'pressedBackground'),
		},
		checkedColors: {
			buttonBackground: cssVar(semanticPalette.button, 'checkedBackground'),
		},
		readOnlyColors: {
			background: cssVar(semanticPalette.input, 'background'),
			color: cssVar(semanticPalette.input, 'text'),
			border: cssVar(semanticPalette.input, 'border'),
		},
		disabledColors: {
			background: cssVar(semanticPalette.input, 'disabledBackground'),
			color: cssVar(semanticPalette.input, 'disabledText'),
			border: cssVar(semanticPalette.input, 'disabledBackground'),
		},
		warningColors: {
			background: cssVar(semanticPalette.input, 'background'),
			color: cssVar(semanticPalette.input, 'text'),
			border: cssVar(palette.warning, 'warningDark'),
		},
		errorColors: {
			background: cssVar(semanticPalette.input, 'background'),
			color: cssVar(semanticPalette.input, 'text'),
			border: cssVar(palette.error, 'errorDark'),
		},
	}
}
