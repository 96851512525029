import React, { useRef, useState } from 'react'

import { Menu } from './Menu'
import { Button } from '../Button'
import type { MenuItem } from './MenuItem.types'
import { e_Placement } from '../../enums/e_Placement'
import { GenericButton } from '../GenericButton'

interface IconButtonMenuProps {
	iconClassName?: string
	screenTip?: string
	disabled?: boolean
	menuItems?: MenuItem[]
	withGenericButton?: boolean
	menuPlacement?: e_Placement
	onOpen?: () => void
	onClose?: () => void
}

export const IconButtonMenu = (props: IconButtonMenuProps) => {
	const anchorEl = useRef<HTMLDivElement>(null)
	const buttonRef = useRef<HTMLButtonElement>(null)

	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const handleOpen = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()

		props.onOpen?.()

		setIsMenuOpen(true)
	}

	const handleClose = () => {
		setIsMenuOpen(false)
		buttonRef.current?.focus()
		props.onClose?.()
	}

	return (
		<div ref={anchorEl} style={{ marginLeft: '4px' }} onContextMenu={() => isMenuOpen && handleClose()}>
			{props.withGenericButton ? (
				<GenericButton
					ref={buttonRef}
					icon={props.iconClassName}
					screenTip={props.screenTip}
					disabled={props.disabled}
					isChecked={isMenuOpen}
					onClick={isMenuOpen ? handleClose : handleOpen}
				/>
			) : (
				<Button
					ref={buttonRef}
					variant="inline"
					size="small"
					icon={props.iconClassName}
					screenTip={props.screenTip}
					disabled={props.disabled}
					isChecked={isMenuOpen}
					onMouseDown={(e) => isMenuOpen && e.preventDefault()}
					onClick={isMenuOpen ? handleClose : handleOpen}
				/>
			)}
			<Menu
				open={isMenuOpen}
				onClose={handleClose}
				items={props.menuItems}
				anchorEl={anchorEl}
				placement={props.menuPlacement ?? e_Placement.bottomEnd}
			/>
		</div>
	)
}
