import { versionDeploymentTypes } from './versionDeploymentTypes'
import { Reducer } from '@reduxjs/toolkit'
import { IVersionDeploymentActions, ISetJobStates } from './IVersionDeploymentActions'
import { IContainerTag, IContainerTags } from 'src/interfaces/IContainerRegistry'
import { IDeploymentJobStatus } from 'src/interfaces/IDeployJob'
import { AnySchema, ErrorObject } from 'ajv'
import { e_DeploymentJobState } from 'src/enums/e_DeploymentJobState'
import { recordFromArray, typedKeysMapValues } from 'src/components/VersionDeployment/utils/utils'

export interface IVersionDeploymentState {
	deploymentVersions: { origin: string[]; active: string[]; passive: string[] }
	versionOptions: IContainerTags | undefined
	operatorVersionOptions: IContainerTags | undefined
	jobStates: {
		origin: { targetName: string; status: IDeploymentJobStatus }[]
		active: { targetName: string; status: IDeploymentJobStatus }[]
		passive: { targetName: string; status: IDeploymentJobStatus }[]
		operator: { targetName: string; status: IDeploymentJobStatus }[]
	}

	versionSchema: { versionName: string; schema: AnySchema | undefined } | undefined
	runtimeValues: { [runtimeName: string]: string }
	runtimeValueErrors: { [runtimeName: string]: ErrorObject[] }

	chosenOperatingTypes: { origin: boolean; active: boolean; passive: boolean }
	chosenVersionGroup: string | undefined
	chosenVersion: IContainerTag | undefined

	chosenOperatorVersionGroup: string | undefined
	chosenOperatorVersion: IContainerTag | undefined

	runtimeSelectedForConfiguration: string | undefined

	isUnfinishedDeployDispatch: boolean
}
const initalState: IVersionDeploymentState = {
	deploymentVersions: { origin: [], active: [], passive: [] },
	versionOptions: undefined,
	operatorVersionOptions: undefined,
	jobStates: { origin: [], active: [], passive: [], operator: [] },
	versionSchema: undefined,
	runtimeValues: {},
	runtimeValueErrors: {},

	chosenOperatingTypes: { origin: true, active: false, passive: true },
	chosenVersionGroup: undefined,
	chosenVersion: undefined,

	chosenOperatorVersionGroup: undefined,
	chosenOperatorVersion: undefined,

	runtimeSelectedForConfiguration: undefined,

	isUnfinishedDeployDispatch: false,
}

export const versionDeploymentReducer: Reducer<IVersionDeploymentState, IVersionDeploymentActions> = (
	state = initalState,
	action: IVersionDeploymentActions
): IVersionDeploymentState => {
	switch (action.type) {
		case versionDeploymentTypes.SET_DEPLOYMENT_VERSIONS:
			return {
				...state,
				deploymentVersions: action.deploymentVersions,
			}
		case versionDeploymentTypes.SET_VERSION_OPTIONS:
			return {
				...state,
				versionOptions: action.options,
			}
		case versionDeploymentTypes.SET_OPERATOR_VERSION_OPTIONS:
			return {
				...state,
				operatorVersionOptions: action.options,
			}
		case versionDeploymentTypes.SET_JOB_STATES:
			return {
				...state,
				jobStates: updateJobState(action, state.jobStates, action.canSetUnknown),
			}
		case versionDeploymentTypes.SET_VERSION_SCHEMA:
			return {
				...state,
				versionSchema: action.versionName ? { versionName: action.versionName, schema: action.schema } : undefined,
			}
		case versionDeploymentTypes.ADD_RUNTIME_HELM_VALUES:
			return {
				...state,
				runtimeValues: { ...state.runtimeValues, [action.runtimeName]: action.values },
			}
		case versionDeploymentTypes.SET_RUNTIME_HELM_VALUES:
			return {
				...state,
				runtimeValues: action.values,
			}
		case versionDeploymentTypes.SET_RUNTIME_VALUE_ERRORS:
			return {
				...state,
				runtimeValueErrors: { ...state.runtimeValueErrors, [action.runtimeName]: action.errors },
			}
		case versionDeploymentTypes.SET_CHOSEN_OPERATING_TYPES:
			return {
				...state,
				chosenOperatingTypes: action.chosenOperatingTypes,
			}
		case versionDeploymentTypes.SET_CHOSEN_VERSION:
			return {
				...state,
				chosenVersion: action.chosenVersion,
				runtimeValueErrors: {},
			}
		case versionDeploymentTypes.SET_CHOSEN_VERSION_GROUP:
			return {
				...state,
				chosenVersionGroup: action.chosenVersionGroup,
				runtimeValueErrors: {},
			}
		case versionDeploymentTypes.SET_CHOSEN_OPERATOR_VERSION:
			return {
				...state,
				chosenOperatorVersion: action.chosenOperatorVersion,
				runtimeValueErrors: {},
			}
		case versionDeploymentTypes.SET_CHOSEN_OPERATOR_VERSION_GROUP:
			return {
				...state,
				chosenOperatorVersionGroup: action.chosenOperatorVersionGroup,
				runtimeValueErrors: {},
			}
		case versionDeploymentTypes.SET_RUNTIME_SELECTED_FOR_CONFIGURATION:
			return {
				...state,
				runtimeSelectedForConfiguration: action.runtimeSelectedForConfiguration,
			}
		case versionDeploymentTypes.SET_IS_UNFINISHED_DEPLOY_DISPATCH:
			return {
				...state,
				isUnfinishedDeployDispatch: action.isUnfinishedDeployDispatch,
			}

		default:
			return state
	}
}

const updateJobState = (action: ISetJobStates, oldStates: ISetJobStates['states'], canSetUnknown?: boolean) => {
	// debugger
	return typedKeysMapValues(action.states, (operatingTypeStates, key) => {
		const oldStatus = recordFromArray(
			oldStates[key],
			(state) => state.targetName,
			(state) => state.status
		)

		return operatingTypeStates.map((state) => ({
			...state,
			status: getRelevantStatus(state.status, oldStatus[state.targetName], canSetUnknown),
		}))
	})
}

const getRelevantStatus = (
	newStatus: IDeploymentJobStatus,
	oldStatus: IDeploymentJobStatus | undefined,
	canSetUnknown?: boolean
): IDeploymentJobStatus => (!canSetUnknown && isUnknownState(newStatus) && oldStatus ? oldStatus : newStatus)

const isUnknownState = (status: IDeploymentJobStatus) => status.deploymentJobState === e_DeploymentJobState.unknown
