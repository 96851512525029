import React from 'react'
import ReactDOM from 'react-dom'

import { NotificationPopup } from '@genusbiz/web-ui/surfaces/'

import clsx from 'clsx'
import { createStyle } from 'src/theming'
import { MOBILE_WIDTH } from 'src/consts/cssBreakpoints'
import { e_notificationPosition, INotificationPopupProps } from 'src/interfaces/IModalProps'
import { modalTypes } from './modalTypes'

export interface IINotificationPopupComponentProps extends INotificationPopupProps {
	hideModal?: (type: modalTypes) => void
	id?: string
}

export interface INotificationListProps {
	notificationModalProps: IINotificationPopupComponentProps[]
}

const classes = createStyle((theme) => ({
	notificationLayer: {
		position: 'fixed',
		zIndex: theme.zIndex.popper,
		pointerEvents: 'none',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		[`@media (max-width: ${MOBILE_WIDTH}px)`]: { alignItems: 'stretch' },
	},

	notificationArea: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: '90px 8px 8px 8px',
		display: 'flex',
	},

	topLeft: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		[`@media (max-width: ${MOBILE_WIDTH}px)`]: { alignItems: 'stretch' },
	},

	topCenter: {
		flexDirection: 'column',
		alignItems: 'center',
		[`@media (max-width: ${MOBILE_WIDTH}px)`]: { alignItems: 'stretch' },
	},

	topRight: {
		flexDirection: 'column',
		alignItems: 'flex-end',
		[`@media (max-width: ${MOBILE_WIDTH}px)`]: { alignItems: 'stretch' },
	},

	bottomLeft: {
		flexDirection: 'column-reverse',
		alignItems: 'flex-start',
		[`@media (max-width: ${MOBILE_WIDTH}px)`]: { alignItems: 'stretch' },
	},

	bottomCenter: {
		flexDirection: 'column-reverse',
		alignItems: 'center',
		[`@media (max-width: ${MOBILE_WIDTH}px)`]: { alignItems: 'stretch' },
	},

	bottomRight: {
		flexDirection: 'column-reverse',
		alignItems: 'flex-end',
		[`@media (max-width: ${MOBILE_WIDTH}px)`]: { alignItems: 'stretch' },
	},

	notification: {
		opacity: 1,
		maxWidth: '75%',
		[`@media (max-width: ${MOBILE_WIDTH}px)`]: { maxWidth: '100%' },
		marginTop: '4px',
		pointerEvents: 'auto',
	},
}))

export const NotificationSurface = (props: INotificationListProps) => {
	const groupedNotificationProps = Object.groupBy(
		props.notificationModalProps,
		(notificationProps) => notificationProps.position ?? e_notificationPosition.topCenter
	)

	const generateNotifications = (notifications: IINotificationPopupComponentProps[], outerClassName: string) => {
		if (notifications.length === 0) {
			return null
		}

		return (
			<div className={clsx(classes.notificationArea, outerClassName)}>
				{notifications.map((notification) => {
					const handleCloseClick = () => notification.hideModal?.(modalTypes.NOTIFICATION_POPUP)

					return (
						<span className={classes.notification} key={notification.id ?? notification.message}>
							<NotificationPopup
								showCloseButton={notification.showCloseButton}
								onClose={handleCloseClick}
								autoHideDuration={notification.autoHideDuration}
								title={notification.title}
								message={notification.message}
								iconClassName={notification.icon}
								severity={notification.severity}
							/>
						</span>
					)
				})}
			</div>
		)
	}

	return ReactDOM.createPortal(
		<div className={classes.notificationLayer}>
			{groupedNotificationProps.topLeft && generateNotifications(groupedNotificationProps.topLeft, classes.topLeft)}
			{groupedNotificationProps.topCenter &&
				generateNotifications(groupedNotificationProps.topCenter, classes.topCenter)}
			{groupedNotificationProps.topRight && generateNotifications(groupedNotificationProps.topRight, classes.topRight)}
			{groupedNotificationProps.bottomLeft &&
				generateNotifications(groupedNotificationProps.bottomLeft, classes.bottomLeft)}
			{groupedNotificationProps.bottomCenter &&
				generateNotifications(groupedNotificationProps.bottomCenter, classes.bottomCenter)}
			{groupedNotificationProps.bottomRight &&
				generateNotifications(groupedNotificationProps.bottomRight, classes.bottomRight)}
		</div>,
		window.document.body
	)
}
