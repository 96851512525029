import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import type { IGridLayout, IGridAreaProps } from './gridTypes'
import { useResponsiveLayout } from './useResponsiveLayout'
import { useStyledCells } from './useStyledCells'

const classes = createStyle({
	gridLayout: {
		display: 'grid',
		// min-width and -height defaults to auto, thus content size overrides specified fraction size if larger
		// https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
		minWidth: '0',
		minHeight: '0',
	},
})

interface IGridLayoutProps extends IGridAreaProps {
	children?: React.ReactNode | React.ReactNode[]

	className?: string
	gridConfig: IGridLayout[]
}

export const GridLayout = (props: IGridLayoutProps) => {
	const { layoutRef, layout } = useResponsiveLayout(props.gridConfig)
	const cells = useStyledCells(props.children, layout)

	const gridClasses = classNames(classes.gridLayout, props.className)

	return (
		<div ref={layoutRef} className={gridClasses} style={props.style}>
			{cells}
		</div>
	)
}
