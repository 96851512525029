import { DropdownInput, Text, Link, e_LabelContentLayout } from '@genusbiz/web-ui/controls'
import { e_LabelPosition } from '@genusbiz/web-ui/enums/e_LabelPosition'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultColors } from 'src/consts/defaultColors'
import { createStyle } from 'src/theming'
import { getTimezoneOptions } from 'src/utils/getTimezoneOptions'

const classes = createStyle((theme) => ({
	timezoneWarning: {
		color: defaultColors.warningText,
	},
	linkContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing.size1,
	},
}))

interface ITimezonePickerProps {
	timezone: string
	setTimezone: (timezone: string) => void
	selectedTimezone: string
}

export const TimezonePicker = (props: ITimezonePickerProps) => {
	const { t } = useTranslation()
	const { timezone, setTimezone, selectedTimezone } = props
	const [showTimezonePicker, setShowTimezonePicker] = useState(false)

	return (
		<>
			{showTimezonePicker ? (
				<DropdownInput
					label={t('AVAILABILITY_WINDOW:TIMEZONE')}
					labelPosition={e_LabelPosition.top}
					value={timezone}
					options={getTimezoneOptions()}
					onChange={(value: string) => {
						setTimezone(value)
					}}
				/>
			) : (
				<div className={classes.linkContainer}>
					<Text>
						{t('AVAILABILITY_WINDOW:TIMEZONE')}: <br />
						{timezone}
					</Text>
					<Link
						onActivate={() => {
							setShowTimezonePicker(true)
						}}
						url="#"
						contentLayout={e_LabelContentLayout.text}
						displayName={t('AVAILABILITY_WINDOW:CHANGE_TIMEZONE')}
					/>
				</div>
			)}
			{timezone !== selectedTimezone && (
				<div className={classes.timezoneWarning}>
					<span>{t('AVAILABILITY_WINDOW:DIFFERENT_TIMEZONE_WARNING')}</span>
				</div>
			)}
		</>
	)
}
