import React, { useEffect, useState } from 'react'
import { defaultIcons } from 'src/consts/defaultIcons'
import { CommandBar } from '@genusbiz/web-ui/controls'
import { e_MenuItemType, MenuItem } from '@genusbiz/web-ui/controls/Menu'
import { useDispatch, useSelector } from 'react-redux'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { operatorApi } from 'src/modules/operatorApi'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { IPod } from 'src/interfaces/IPod'
import { useTranslation } from 'react-i18next'
import { e_TableContentType } from 'src/enums/e_TableContentType'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { e_EnvironmentType } from 'src/enums/e_EnvironmentType'
import { IBaseK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { createStyle } from '@genusbiz/web-ui/theming'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { AxiosError } from 'axios'

interface IProps {
	reload: (emptyBeforeRefresh?: boolean) => void
	tableFilter?: e_TableContentType
}
const classes = createStyle((theme) => ({
	operatorCommandBar: {
		backgroundColor: theme.colors.panel.background,
		display: 'flex',
		borderBottom: '1px solid ' + theme.colors.panel.border,
		justifyContent: 'space-between',
	},
	fullWidth: { float: 'left' },
	rightCommandBar: { float: 'right', marginRight: 16 },
}))

export const WorkloadCommandBar = (props: IProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const selectedPods = useSelector(operatorFrontendSelectors.selectSelectedPods)
	const selectedK8sRuntimeName = useSelector(operatorFrontendSelectors.selectSelectedK8sRuntimeName)
	const [currentK8sRuntime, setcurrentK8sRuntime] = useState<IBaseK8sRuntime | undefined>()
	const [commandBarItems, setCommandBarItems] = useState<MenuItem[]>([])

	const k8sRuntimes = useSelector(kubernetesSelectors.selectK8sRuntimesIncludingOperator)
	const restartPods = (k8sRuntime: string, pods: IPod[]) => {
		const partOfGenusPodNames = pods.filter((pod) => pod.runtimeConfig.isPartOfGenus).map((pod) => pod.name)

		void operatorApi
			.deletePodsInRuntimeByPodName(k8sRuntime, partOfGenusPodNames)
			.then(() => {
				dispatch(
					modalManagerActions.showNotificationPopup({ message: 'Pod restart initialized', icon: defaultIcons.check })
				)
			})
			.catch((err) => {
				const error = err as AxiosError
				if (error.response?.status === 400) {
					dispatch(modalManagerActions.showNotificationPopup({ message: 'Pod not found', icon: defaultIcons.error }))
				} else {
					dispatch(modalManagerActions.showErrorModal(error))
				}
			})
			.finally(() => {
				setTimeout(() => {
					dispatch(operatorFrontendActions.setSelectedPods([]))
					props.reload(true)
				}, 500)
			})
	}

	const assertRestartSelectedDisabled = (pods: IPod[]) => {
		if (pods.length === 0) {
			return true
		}
		const partOfGenusPods = pods.filter((pod) => pod.runtimeConfig.isPartOfGenus)
		return partOfGenusPods.length !== pods.length
	}

	useEffect(() => {
		const k8sRuntime = k8sRuntimes.find((runtime) => runtime.name === selectedK8sRuntimeName)
		setcurrentK8sRuntime(k8sRuntime)
	}, [selectedK8sRuntimeName, k8sRuntimes])

	useEffect(() => {
		if (currentK8sRuntime) {
			const refreshMenuItem: MenuItem[] = [
				{ type: e_MenuItemType.divider },
				{
					type: e_MenuItemType.action,
					name: t('GENERAL:REFRESH'),
					iconClassName: 'Fluent-Refresh',
					onClick: () => {
						props.reload(false)
					},
				},
			]

			const podsMenuItem: MenuItem[] = [
				{ type: e_MenuItemType.divider },
				{
					type: e_MenuItemType.action,
					name: t('COMMAND_BAR:RESTART_SELECTED_PODS'),
					screenTip: t('COMMAND_BAR:RESTART_SELECTED_PODS_SCREEN_TIP'),
					disabled: assertRestartSelectedDisabled(selectedPods) ? true : false,
					iconClassName: defaultIcons.delete,
					onClick: () => {
						if (selectedPods) {
							dispatch(
								modalManagerActions.showConfirmationModal({
									title: t('GENERAL:CONFIRM'),
									confirmText: t('GENERAL:YES'),
									cancelText: t('GENERAL:CANCEL'),
									message: t('PODS:CONFIRM_DELETE') + '\n' + selectedPods.map((pod) => pod.name).join(', \n') + '?',
									onConfirm: () => {
										restartPods(selectedPods[0].runtimeConfig.k8sRuntimeId, selectedPods)
									},
								})
							)
						}
					},
				},
			]

			const workloadsMenuItem: MenuItem[] = [{ type: e_MenuItemType.divider }]

			let items: MenuItem[] = []
			if (currentK8sRuntime.environmentType === e_EnvironmentType.operator) {
				if (props.tableFilter && props.tableFilter === e_TableContentType.workloadControllers) {
					items = refreshMenuItem
				} else {
					items = refreshMenuItem.concat(podsMenuItem)
				}
			} else {
				if (props.tableFilter && props.tableFilter === e_TableContentType.pods) {
					items = refreshMenuItem.concat(podsMenuItem)
				} else if (props.tableFilter && props.tableFilter === e_TableContentType.workloadControllers) {
					items = refreshMenuItem.concat(workloadsMenuItem)
				} else {
					items = refreshMenuItem.concat(podsMenuItem, workloadsMenuItem)
				}
			}
			setCommandBarItems(items)
		}
	}, [currentK8sRuntime, selectedPods, props.tableFilter])

	return (
		<div className={classes.operatorCommandBar}>
			<div className={classes.fullWidth}>
				<CommandBar items={commandBarItems} />
			</div>
		</div>
	)
}
