import React, { useState, useLayoutEffect, useCallback } from 'react'
import './codemirror-operator-theme.css'

import CodeMirror from '@uiw/react-codemirror'
import { EditorView } from 'codemirror'
import { EditorState, StateField, StateEffect, Range } from '@codemirror/state'
import { Decoration } from '@codemirror/view'
import { tags as t } from '@lezer/highlight'

import { createStyle } from '@genusbiz/web-ui/theming'
import { createTheme } from '@uiw/codemirror-themes'

import { LRLanguage } from '@codemirror/language'

interface ITextSection {
	from: { line: number; ch: number }
	to: { line: number; ch: number }
}

interface IProps {
	log: string /* Log container strips carriage return (\r) from internally stored value. 
		Supplying a string formatted with carriage return will result in the text being reinserted by react-codemirror, as this system will detect the difference in text between the one supplyed and the one stored locally.
		This will further be interpretted as new text by codemirror thus marks will be removed, specifically in the value.map(tr.changes) line of update */
	highlightSections?: {
		from: { line: number; ch: number }
		to: { line: number; ch: number }
	}[]
	unparsedFrom?: number
	parser?: LRLanguage
	onChange?: (text: string) => void
}
const classes = createStyle({
	cmEditor: {
		flex: '1',
	},
	cmTheme: {
		height: '100%',
	},
	logContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minHeight: '0px',
		'--cm-unparsed-text-color': '#777',
		'--cm-number-color': '#00d600',
		'--cm-string-color': '#dd6a1d',
		'--cm-bool-color': 'blue',
		'--cm-name-color': '#0004d6',
		'--cm-comment-color': '#529955',
	},
})

const editorTheme = createTheme({
	theme: 'light',
	settings: {
		background: '#fdfdfd',
		backgroundImage: '',
		foreground: '#040a1d',
		caret: '#5d00ff',
		selection: '#036dd626',
		selectionMatch: '#036dd626',
		lineHighlight: '#8a91991a',
		gutterBackground: '#fdfdfd',
		gutterForeground: '#8a919966',
	},
	styles: [
		{ tag: t.bool, color: 'var(--cm-bool-color)', fontWeight: 'bold' },
		{ tag: t.number, color: 'var(--cm-number-color)' },
		{ tag: t.string, color: 'var(--cm-string-color)' },
		{ tag: t.name, color: 'var(--cm-name-color)' },
		{ tag: t.comment, color: 'var(--cm-comment-color)' },
	],
})
export const LogContainer = (props: IProps) => {
	const [view, setView] = useState<EditorView>()

	const errorMarks = getErrorMarks(view, props.highlightSections)
	const unparsedTextMark = getUnparsedTextMark(view, props.unparsedFrom)

	useMarks(view, [...errorMarks, ...unparsedTextMark])

	const onChange = useCallback((value: string) => props.onChange?.(value), [props.onChange])

	return (
		<div className={classes.logContainer}>
			<CodeMirror
				className={classes.cmTheme}
				value={props.log}
				theme={editorTheme}
				height="100%"
				extensions={[
					!props.onChange ? READ_ONLY_CODEMIRROR_EXTENSION : [],
					[markField, errorUnderlineTheme],
					props.parser ?? [],
				]}
				onChange={onChange}
				onCreateEditor={(view) => setView(view)}
			/>
		</div>
	)
}

const READ_ONLY_CODEMIRROR_EXTENSION = EditorState.readOnly.of(true)

const posToOffset = (
	doc: { line: (n: number) => { from: number }; lines: number; length: number },
	{ line, ch }: { line: number; ch: number }
) => {
	const lineFrom = line < doc.lines ? doc.line(line + 1).from : doc.length
	return lineFrom + ch
}

const addMarks = StateEffect.define<Range<Decoration>[]>()
const markField = StateField.define({
	create: () => Decoration.none,
	update: (value, tr) => {
		value = value.map(tr.changes)

		tr.effects.forEach((effect) => {
			if (effect.is(addMarks)) {
				value = value.update({ filter: () => false })
				value = value.update({ add: effect.value, sort: true })
			}
		})

		return value
	},
	provide: (field) => EditorView.decorations.from(field),
})

const errorUnderlineTheme = EditorView.theme({
	'&.cm-focused': {
		outline: 'none',
	},
	'.cm-underline': { position: 'relative' },
	'.cm-underline::before': {
		color: 'red',
		content:
			"'~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~'",
		fontSize: '0.8em',
		fontWeight: 'bolder',
		fontFamily: 'Times New Roman, Serif',
		width: '100%',
		position: 'absolute',
		top: '6px',
		overflow: 'hidden',
		pointerEvents: 'none',
	},
	'.cm-grey-text': {
		width: '100%',
		height: '100%',
		'--cm-number-color': 'var(--cm-unparsed-text-color)',
		'--cm-string-color': 'var(--cm-unparsed-text-color)',
		'--cm-bool-color': 'var(--cm-unparsed-text-color)',
		'--cm-name-color': 'var(--cm-unparsed-text-color)',
		'--cm-comment-color': 'var(--cm-unparsed-text-color)',
	},
	'.cm-unparsed-start': {},
	'.cm-unparsed-start::after': {
		color: 'red',
		content: "'˅'",
	},
})
const strikeMark = Decoration.mark({ class: 'cm-underline' })

const getErrorMarks = (view: EditorView | undefined, sections: ITextSection[] | undefined) => {
	if (!view || !sections) {
		return []
	}

	const spans = sections.map((section) => ({
		from: posToOffset(view.state.doc, section.from),
		to: posToOffset(view.state.doc, section.to),
	}))
	const validSpans = spans.filter((span) => span.from < span.to && span.to < view.state.doc.length - 1)
	const marks = validSpans.map(({ from, to }) => strikeMark.range(from, to))

	return marks
}

const greyText = Decoration.mark({ class: 'cm-grey-text' })
const unparsedTextStart = Decoration.mark({ class: 'cm-unparsed-start' })

const getUnparsedTextMark = (view: EditorView | undefined, start?: number) => {
	const length = view?.state.doc.length

	if (!length || !start || start >= length - 1) {
		return []
	}

	const uparsedTextStartMark = unparsedTextStart.range(start - 1, start)
	const unparsedTextMark = greyText.range(start, length - 1)

	return [uparsedTextStartMark, unparsedTextMark]
}

const useMarks = (view: EditorView | undefined, marks: Range<Decoration>[]) =>
	useLayoutEffect(() => {
		if (!view) {
			return
		}

		const validMarks = marks.filter((mark) => isValidMark(view, mark))
		const effects = [addMarks.of(validMarks)]
		view?.dispatch({ effects })
	}, [view, marks])

const isValidMark = (view: EditorView, mark: Range<Decoration>) =>
	mark.from < mark.to && mark.to < view.state.doc.length
