import React, { useRef, useEffect, useState } from 'react'

import { Button } from '../../controls/Button'
import { Icon } from '../../controls/Icon'
import classNames from 'clsx'
import { createStyle } from '../../theming'

const TRANSITIONTIMEOUT = 300

const OPACITY_EASINGFUNCTION = 'ease-in-out' // ease-in-out
const TRANSFORM_EASINGFUNCTION = 'cubic-bezier(0.68, -0.6, 0.32, 1.6)' // ease-in

const DEFAULT_AUTO_HIDE_DURATION_MS = 5000

const classes = createStyle((theme) => ({
	notification: {
		border: '1px solid ' + theme.colors.notificationPopup.background,
		borderRadius: theme.controls.notification.borderRadius,
		background: theme.colors.notificationPopup.background,
		color: theme.colors.notificationPopup.text,
		boxShadow: theme.shadows.light + ' !important',
		opacity: 0,
		transform: 'scale(0)',
		transition: `opacity ${TRANSITIONTIMEOUT}ms ${OPACITY_EASINGFUNCTION}, transform ${TRANSITIONTIMEOUT}ms ${TRANSFORM_EASINGFUNCTION}`,
	},

	notificationContent: {
		display: 'flex',
		flexDirection: 'row',
		padding: '12px',
		minWidth: '275px',
	},

	content: {
		display: 'grid',
		flex: 1,
		gridTemplateColumns: 'auto minmax(min-content, 1fr) auto',
		gridTemplateRows: 'auto auto',

		gridTemplateAreas: `
        "icon title cmd"
        "empty message cmd"
      `,
	},

	visible: {
		opacity: 1,
		transform: 'scale(1)',
	},

	error: {
		background: theme.colors.body.error.primary.background,
		color: theme.colors.body.error.primary.text,
		borderColor: theme.colors.body.error.primary.background,
	},

	warning: {
		background: theme.colors.body.warning.light.background,
		color: theme.colors.body.warning.light.text,
		borderColor: theme.colors.body.warning.light.background,
	},
	title: {
		fontSize: theme.controls.notification.titleFontSize,
		fontWeight: theme.controls.notification.titleFontWeight,
		textTransform: theme.controls.notification.titleTextTransform,
	},

	message: {
		fontSize: theme.controls.notification.messageFontSize,
		fontWeight: theme.controls.notification.messageFontWeight,
	},
}))

interface INotificationPopupProps {
	onClose: () => void
	iconClassName?: string
	title?: string
	message?: string
	showCloseButton?: boolean
	autoHideDuration?: number // the dialog will close after the specified amount of time (in milliseconds)
	severity?: 'error' | 'warning' | 'info'
}
enum TransitionStage {
	initial = 'initial',
	initShowing = 'initShowing',
	showing = 'showing',
	visible = 'visible',
	hiding = 'hiding',
	hidden = 'hidden',
}

export const NotificationPopup = (props: INotificationPopupProps) => {
	const closeTimer = useRef<NodeJS.Timeout>()

	const { severity = 'info' } = props

	const [transitionStage, setTransitionStage] = useState(TransitionStage.initial)

	useEffect(() => {
		if (closeTimer.current) {
			clearInterval(closeTimer.current)
		}

		if (!props.showCloseButton) {
			closeTimer.current = setInterval(handleClose, props.autoHideDuration || DEFAULT_AUTO_HIDE_DURATION_MS)
		}
	}, [props.autoHideDuration, props.showCloseButton])

	useEffect(() => {
		switch (transitionStage) {
			case TransitionStage.initial: {
				setTransitionStage(TransitionStage.initShowing)
				break
			}
			case TransitionStage.initShowing: {
				setTransitionStage(TransitionStage.showing)

				setTimeout(() => {
					setTransitionStage(TransitionStage.visible)
				}, TRANSITIONTIMEOUT + 100)

				break
			}

			case TransitionStage.hiding: {
				setTimeout(() => {
					setTransitionStage(TransitionStage.hidden)
				}, TRANSITIONTIMEOUT + 100)

				break
			}
			case TransitionStage.hidden: {
				setTimeout(() => {
					props.onClose()
				}, 0)

				break
			}
		}
	}, [props, transitionStage])

	const handleClose = () => {
		if (closeTimer.current) {
			clearInterval(closeTimer.current)
		}
		setTransitionStage(TransitionStage.hiding)
	}

	const content = (
		<>
			{props.iconClassName && <Icon style={{ gridArea: 'icon', margin: '4px' }} iconClassName={props.iconClassName} />}
			{props.title && (
				<span style={{ gridArea: 'title', margin: '4px' }} className={classes.title}>
					{props.title}
				</span>
			)}
			{props.message && (
				<div style={{ gridArea: props.title ? 'message' : 'title', margin: '4px' }} className={classes.message}>
					{props.message}
				</div>
			)}
			{props.showCloseButton ? (
				<Button
					style={{ gridArea: 'cmd', margin: '4px', alignSelf: 'center' }}
					icon="Fluent-ChromeClose"
					variant="inline"
					onClick={handleClose}
				/>
			) : (
				<div />
			)}
		</>
	)

	const contentDelayed = useRef<JSX.Element | string>()

	useEffect(() => {
		//The ref is set in a use effect because screen readers need the content element to be empty on first render to announce change
		contentDelayed.current = content
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (transitionStage === TransitionStage.initial || transitionStage === TransitionStage.hidden) {
		return null
	}

	return (
		<div
			className={classNames(
				classes.notification,
				severity === 'error' && classes.error,
				severity === 'warning' && classes.warning,
				transitionStage === TransitionStage.showing || (transitionStage === TransitionStage.visible && classes.visible)
			)}
		>
			<div className={classes.notificationContent}>
				<div className={classes.content} aria-live="polite" role={'alert'}>
					{contentDelayed.current}
				</div>
			</div>
		</div>
	)
}
