import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { useStyleContext } from '../utils/Style.context'

const classes = createStyle((theme) => ({
	formControlBorder: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
		alignItems: 'stretch',
		outlineWidth: theme.controls.field.focusBorderWidth,
		outlineStyle: 'solid',
		outlineOffset: `calc(-1*${theme.controls.field.focusBorderWidth})`,
		outlineColor: 'transparent',
		borderStyle: 'solid',
		background: theme.controls.field.colors.background,
		color: theme.controls.field.colors.color,
		borderColor: theme.controls.field.colors.border,
		borderWidth: theme.controls.field.borderWidth,
		borderRadius: theme.controls.field.borderRadius,
		transition: `border-color ${theme.transitions.duration.short}, outline-color ${theme.transitions.duration.short}`,
		overflow: 'hidden',
		boxShadow: theme.controls.field.dropShadow, // ##css-var Funker ikke
		'& input, & textarea, & label, & div:not([role="button"])': {
			background: 'transparent',
		},
	},

	controlWidth: {
		maxWidth: '100%',
		width: theme.controls.field.editControlWidth,
	},
	enabled: {
		'&:hover': {
			background: theme.controls.field.hoverColors.background,
			color: theme.controls.field.hoverColors.color,
			borderColor: theme.controls.field.hoverColors.border,
			'& input, & textarea': { color: theme.controls.field.hoverColors.color },
		},
		'&:focus-within': {
			background: theme.controls.field.focusedColors.background,
			color: theme.controls.field.focusedColors.color,
			outlineColor: theme.controls.field.focusedColors.border,
			'& input, & textarea': { color: `${theme.controls.field.focusedColors.color} !important` },
		},
	},
	disabled: {
		background: theme.controls.field.disabledColors.background,
		color: theme.controls.field.disabledColors.color,
		borderColor: theme.controls.field.disabledColors.border,
	},

	readOnly: {
		background: theme.controls.field.readOnlyColors.background,
		'& input, & textarea, & label, & div': {
			color: theme.controls.field.readOnlyColors.color,
			fontStyle: theme.controls.field.readOnlyFontStyle,
		},
		color: theme.controls.field.readOnlyColors.color,
		borderColor: theme.controls.field.readOnlyColors.border,
	},

	warning: {
		borderColor: theme.controls.field.warningColors.border,
		'&:focus-within': { outlineColor: theme.controls.field.warningColors.border },
	},

	error: {
		borderColor: theme.controls.field.errorColors.border,
		'&:focus-within': { outlineColor: theme.controls.field.errorColors.border },
	},
	dynamicsStyle: { borderColor: 'transparent' },
	dynamicsStyleplaceholder: {
		transition: 'none',
		'& input:focus::placeholder': {
			opacity: 0,
		},
	},
	dynamicsStyleEnabled: {
		'&:hover': { background: theme.controls.field.hoverColors.background },
		'& input, & textarea, & label, & div': {
			WebkitTextStrokeWidth: '0.4px',
		},
		'&:hover input, &:hover textarea, &:hover div, &:focus-within input, &:focus-within textarea, &:hover label, &:focus-within label':
			{
				WebkitTextStrokeWidth: '0px',
			},
	},
	logicalHoverAndFocus: {
		borderColor: theme.controls.field.focusedColors.border + ' !important',
	},
}))

interface IFormControlBorderProps {
	disabled?: boolean
	readOnly?: boolean
	error?: boolean
	warning?: boolean
	className?: string
	children?: React.ReactNode
	assignWidth?: boolean
	logicalHover?: boolean
	logicalFocus?: boolean
	onClick?: (e: React.MouseEvent) => void
	onFocus?: () => void
	onBlur?: () => void
	onMouseEnter?: (e: React.MouseEvent) => void
	onMouseLeave?: (e: React.MouseEvent) => void
	onMouseDown?: (e: React.MouseEvent) => void
}

export const FormControlBorder = React.forwardRef((props: IFormControlBorderProps, ref: React.Ref<HTMLDivElement>) => {
	const { dynamicsStyle, placeholder } = {
		...useStyleContext().style?.field,
	}

	const classnames = classNames(
		classes.formControlBorder,
		{
			[classes.enabled]: !props.disabled && !props.error && !(props.logicalHover || props.logicalFocus),
			[classes.readOnly]: props.readOnly && !props.disabled,
			[classes.disabled]: props.disabled,
			[classes.warning]: props.warning && !props.disabled,
			[classes.error]: props.error && !props.disabled,
			[classes.dynamicsStyle]: dynamicsStyle,
			[classes.dynamicsStyleplaceholder]: !!placeholder,
			[classes.dynamicsStyleEnabled]: dynamicsStyle && !props.disabled,
			[classes.logicalHoverAndFocus]: props.logicalHover || props.logicalFocus,
			[classes.controlWidth]: props.assignWidth,
		},
		props.className
	)

	return (
		<div
			className={classnames}
			ref={ref}
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			onMouseDown={props.onMouseDown}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		>
			{props.children}
		</div>
	)
})

FormControlBorder.displayName = 'FormControlBorder'
