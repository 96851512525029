import { e_LifespanName } from 'src/enums/e_LifespanName'
import { e_Weekday } from 'src/enums/e_Weekday'

export interface IEnvironmentAvailabilitySchedule {
	id: string
	environmentAvailabilities: IEnvironmentAvailability[]
	bufferConfiguration: IBufferConfiguration
	maintenanceConfiguration: IMaintenanceConfiguration
}

export interface IBufferConfiguration {
	enableAvailabilityWindowBuffering: boolean
	enableOperatingRoleSwitchBuffering: boolean
	startupBufferInMinutes: number
	shutdownBufferInMinutes: number
}

export interface IMaintenanceConfiguration {
	startTime: string | null
	durationInMinutes: number | null
	externalURL: string | null
}

export interface IEnvironmentAvailability {
	environmentOperatingType: number
	environmentOperatingTypeName: string
	isAlwaysUp: boolean
	availabilityWindows: IAvailability[]
	availabilityGroups: IAvailabilityGroup[]
}

export interface IAvailability {
	description?: string //Deprecated
	comment: string
	from: IWeekdayTime
	to: IWeekdayTime
	durationInMinutes?: number
	id: string
	groupId: string
	enabled: boolean
	windowType: {
		lifespanName: e_LifespanName
		effectiveFromUtc?: string
		effectiveToUtc?: string
	}
}

export interface IAvailabilityGroup {
	id: string
	fromDays: e_Weekday[]
	windowIds: { [weekday: string]: string }
	fromTime: number
	toDay: e_Weekday | e_RelativeWeekDay
	toTime: number
	enabled: boolean
	comment: string
	timezone: string
	windowType: {
		lifespanName: e_LifespanName
		effectiveFromUtc?: string
		effectiveToUtc?: string
	}
}

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>
export type AvailabilityGroupCreating = Optional<IAvailabilityGroup, 'toDay' | 'toTime'>

export interface IWeekdayTime {
	weekDayName: e_Weekday
	relativeWeekDay?: e_RelativeWeekDay
	timezone: string
	time: number
	timeUtc?: number
}

export enum e_RelativeWeekDay {
	sameDay = 'sameDay',
	nextDay = 'nextDay',
}
