import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { operatorApi } from 'src/modules/operatorApi'

/**
 * @remarks Dispatches a warning message if the user has not added a git token
 */
export const useHasValidGitToken = () => {
	const dispatch = useDispatch()
	useEffect(() => {
		operatorApi
			.gitPersonalAccessTokenExists()
			.then((response) => {
				if (!response) {
					dispatch(
						operatorFrontendActions.setWarningMessage({
							title: 'Missing Git token: ',
							details: 'Add a Git token to access helm values and version schemas',
							onClickLabel: 'Add Git token',
							onClick: () => dispatch(modalManagerActions.showGitTokenManagerModal({})),
						})
					)
				}
			})
			.catch((error: Error) => dispatch(modalManagerActions.showErrorModal(error)))
	}, [])
}
