import { useEffect, useMemo, useRef, useState } from 'react'
import { useResizeObserver } from '../../controls/utils/useResizeObserver'
import { e_GridTrackType, type IGridLayout, type IGridTrack } from './gridTypes'

//---- Layout Sorter

const resolveUndefinedMaxWidth = (width?: number) => width ?? Number.MAX_VALUE
const layoutWidthComparator = (a: IGridLayout, b: IGridLayout) =>
	resolveUndefinedMaxWidth(a.maxWidth) - resolveUndefinedMaxWidth(b.maxWidth)

const sortLayouts = (config: IGridLayout[]) => config.toSorted(layoutWidthComparator)

const getLast = <T>(array: T[]) => array.at(array.length - 1)

//---- Track Formater

const formatRepeatingTracks = (val: string, repeat?: number) => (repeat ? `repeat(${repeat}, ${val})` : val)

const formatTrack = (track: IGridTrack) => {
	switch (track.type) {
		case e_GridTrackType.fraction:
			return formatRepeatingTracks(`${track.length}fr`, track.repeat)
		case e_GridTrackType.pixel:
			return formatRepeatingTracks(`${track.length}px`, track.repeat)
		case e_GridTrackType.auto:
			return formatRepeatingTracks(`fit-content(100%)`, track.repeat)
	}
}

const trackFormatAccumulator = (accumulator: string, track: IGridTrack) => `${accumulator} ${formatTrack(track)}`
const formatTracks = (tracks: IGridTrack[]) => tracks.reduce(trackFormatAccumulator, '')

const updateGridLayout = (style?: CSSStyleDeclaration, layout?: IGridLayout) => {
	if (!style || !layout) {
		return
	}
	style.gridTemplateColumns = formatTracks(layout.columns)
	style.gridTemplateRows = formatTracks(layout.rows)
}

//---- Find Current Layout

const getElementWidth = (element: HTMLDivElement | null) => element?.getBoundingClientRect().width ?? 0

const findWidestValidLayout = (width: number, descendingLayouts: IGridLayout[]) =>
	descendingLayouts.find((layout) => width <= resolveUndefinedMaxWidth(layout.maxWidth))

const isDifferent = (newLayout?: IGridLayout, layout?: IGridLayout) => newLayout?.maxWidth !== layout?.maxWidth
const setNewLayout = (setLayout: (layout: IGridLayout) => void, newLayout?: IGridLayout, layout?: IGridLayout) =>
	newLayout && isDifferent(newLayout, layout) && setLayout(newLayout) //Only compare layout.maxWidth

export const useResponsiveLayout = (layouts: IGridLayout[]) => {
	const layoutRef = useRef<HTMLDivElement>(null)
	const [layout, setLayout] = useState(layouts.at(0))

	const sortedLayouts = useMemo(() => {
		const sortedLayouts = sortLayouts(layouts)
		const widestLayout = getLast(sortedLayouts)
		setLayout(widestLayout)

		return sortedLayouts
	}, [layouts])

	const findCurrentLayout = () => {
		const gridWidth = getElementWidth(layoutRef.current)
		const newLayout = findWidestValidLayout(gridWidth, sortedLayouts)
		setNewLayout(setLayout, newLayout, layout)
	}

	useResizeObserver(findCurrentLayout, layoutRef)

	useEffect(() => updateGridLayout(layoutRef.current?.style, layout), [layout])

	return { layoutRef, layout }
}
