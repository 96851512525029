import type { Palette } from '../themeProperties/colors/paletteGenerator'

export type ListColors = ReturnType<typeof generateListColorsFromPalette>

export const generateListColorsFromPalette = (palette: Palette) => {
	const { foreground, background } = palette

	return {
		checkedColors: {
			background: background.neutralLight,
			color: foreground.neutralPrimary,
		},
		checkedHoverColors: {
			background: background.neutralTertiaryAlt,
			color: foreground.neutralPrimary,
		},
	}
}
