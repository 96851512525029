import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { e_NavigationItem } from 'src/enums/e_NavigationItems'

export const useDeploymentNavigation = () => {
	const navigate = useNavigate()

	const showRuntimeDeployment = () => navigate('/' + e_NavigationItem.versionDeployment)
	const showRuntimeHelmConfiguration = () => navigate('/' + e_NavigationItem.versionDeploymentHelmConfiguration)
	const showRuntimeLog = () => navigate('/' + e_NavigationItem.versionDeploymentLog)

	const showOperatorDeployment = () => navigate('/' + e_NavigationItem.operatorVersionDeployment)
	const showOperatorHelmConfiguration = () =>
		navigate('/' + e_NavigationItem.operatorVersionDeploymentHelmConfiguration)
	const showOperatorLog = () => navigate('/' + e_NavigationItem.operatorVersionDeploymentLog)

	const useNavgiateToRuntimeConditionally = (shallNavigate: boolean) =>
		useTriggerFunctionConditionally(showRuntimeDeployment, shallNavigate)

	const useNavgiateToOperatorConditionally = (shallNavigate: boolean) =>
		useTriggerFunctionConditionally(showOperatorDeployment, shallNavigate)

	const deploymentNavigation = {
		showRuntimeDeployment,
		showRuntimeHelmConfiguration,
		showRuntimeLog,

		showOperatorDeployment,
		showOperatorHelmConfiguration,
		showOperatorLog,

		useNavgiateToRuntimeConditionally,
		useNavgiateToOperatorConditionally,
	}

	return deploymentNavigation
}

const useTriggerFunctionConditionally = (func: () => void, shallNavigate: boolean) =>
	useEffect(() => {
		if (shallNavigate) {
			func()
		}
	}, [shallNavigate])
