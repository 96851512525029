import React from 'react'
import { PopupMenuGroupItem } from './PopupMenuGroupItem'
import { PopupMenuItem } from './PopupMenuItem'
import { PopupMenuCustomItem } from './PopupMenuCustomItem'
import { PopupMenuDivider } from './PopupMenuDivider'
import { PopupMenuLinkItem } from './PopupMenuLinkItem'

import type { MenuItem as IMenuItem, MenuItemSection } from './MenuItem.types'
import { e_MenuItemType } from './MenuItem.types'

interface IPopupMenuElementProps {
	item: IMenuItem
	index: number
	isSelected: boolean
	iconColorClass?: string

	onActivate?: (e: React.MouseEvent | React.KeyboardEvent) => void
}

export const PopupMenuElement = (props: IPopupMenuElementProps) => {
	switch (props.item.type) {
		case e_MenuItemType.section: {
			return (
				<PopupMenuGroupItem
					onActivate={props.onActivate}
					isSelected={props.isSelected}
					item={props.item as MenuItemSection}
					iconColorClass={props.iconColorClass}
					menuIndex={props.index}
				/>
			)
		}
		case e_MenuItemType.divider: {
			return <PopupMenuDivider />
		}
		case e_MenuItemType.custom: {
			return (
				<PopupMenuCustomItem
					menuIndex={props.index}
					item={props.item}
					isSelected={props.isSelected}
					contentRenderer={props.item.render}
				/>
			)
		}
		case e_MenuItemType.link:
			return (
				<PopupMenuLinkItem
					menuIndex={props.index}
					iconColorClass={props.iconColorClass}
					isSelected={props.isSelected}
					item={props.item}
					onActivate={props.onActivate}
				/>
			)
		case e_MenuItemType.action:
		default: {
			return (
				<PopupMenuItem
					menuIndex={props.index}
					iconColorClass={props.iconColorClass}
					isSelected={props.isSelected}
					item={props.item}
					onActivate={props.onActivate}
				/>
			)
		}
	}
}
