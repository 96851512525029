import { useMemo } from 'react'
import type { CellClickedEvent, ColDef, CellClassParams } from '@ag-grid-community/core'
import type { ITableColumnHeaderProps } from '../components/ColumnHeader/ColumnHeader'
import type { ICellRendererProps, IGroupColumnDef, TData, Value } from '../Table.types'
import { AutoGroupColumnHeader } from '../components/AutoGroupColumnHeader'
import { GroupCellRenderer } from '../components/CellRenderer/GroupCellRenderer'
import { formatGroupColumnOrGroupRowValue } from '../utils/formatGroupCellValue'
import { getAutoGroupValueGetter } from '../utils/colDefUtils'
import { isNodeGroup } from '../utils/isNodeGroup'

export const useAutoGroupColumnDef = (
	groupHeaderName: string | undefined,
	tcvi: (t: string) => string,
	isMultiSelect: boolean,
	groupColumnDef: IGroupColumnDef | undefined,
	treeData: boolean | undefined,
	columnDef: ColDef<TData> | undefined,
	/**
	 * Disables filtering and sorting for the table. Defaults to true.
	 * @default true
	 */
	enableTableSorting = true
) => {
	return useMemo(
		() => {
			const onCellClicked = (e: CellClickedEvent<TData>) => {
				if (e.event?.defaultPrevented) {
					return
				}
				if ((e.node.allChildrenCount ?? 0) > 0) {
					e.node.setExpanded(!e.node.expanded)
				}
			}

			const headerComponentParams: ITableColumnHeaderProps = {
				multiSelect: isMultiSelect,
			}

			const isSortingEnabledForColumn = groupColumnDef?.enableSorting ?? true

			const autoGroupColumnDef: ColDef<TData> = {
				headerComponent: AutoGroupColumnHeader,
				headerComponentParams,
				headerName: groupHeaderName ?? tcvi('GENERAL:GROUP'),
				resizable: true,
				minWidth: groupColumnDef?.minWidth || 54,
				width: getWidth(groupColumnDef?.width),
				sortable: enableTableSorting && isSortingEnabledForColumn,
				maxWidth: 400,
				onCellClicked,
				...columnDef,
				cellClassRules: {
					'group-cell': (params: CellClassParams<TData, Value>) => !!isNodeGroup(params.node),
					'total-cell': (params: CellClassParams<TData, Value>) => !!params.node.footer,
					...(columnDef?.cellClassRules as Record<string, (params: any) => boolean>),
				},
				cellRendererParams: {
					...(columnDef?.cellRendererParams as ICellRendererProps),
					suppressCount: true,
					prefixGroup: false,
					multiSelect: isMultiSelect,
					innerRenderer: GroupCellRenderer,
				},
				pinned: true,
				lockPinned: true,
				cellRenderer: 'agGroupCellRenderer',
				// If this is not set to undefined, we will inherit the cellRendererSelector from source column
				cellRendererSelector: undefined,
				lockPosition: true,
				valueGetter: getAutoGroupValueGetter(treeData),
				valueFormatter: (params) => {
					return formatGroupColumnOrGroupRowValue(
						params.data,
						params.value,
						params.node,
						params.colDef,
						params.column,
						tcvi
					)
				},
			}

			return autoGroupColumnDef
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)
}
function getWidth(width: number | null | undefined): number | undefined {
	if (width === null) {
		return undefined
	}
	return width || 300
}
