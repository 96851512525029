import React, { useState, useEffect, useRef } from 'react'
import { createStyle } from '../../theming'
import type { MenuItemSection } from './MenuItem.types'
import { MenuItemContent } from './MenuItemContent'
import { PopupMenuControl } from './PopupMenuControl'
import { e_Placement } from '../../enums/e_Placement'

const classes = createStyle({
	item: {
		display: 'flex',
		'&:focus': {
			outline: 'none',
		},
	},
})

interface IPopupMenuGroupItemProps {
	menuIndex: number

	item: MenuItemSection

	isSelected: boolean
	iconColorClass?: string

	onActivate?: (e: React.MouseEvent | React.KeyboardEvent) => void
}

export const PopupMenuGroupItem = (props: IPopupMenuGroupItemProps) => {
	const menuItem = props.item

	const menuItemRef = useRef<HTMLDivElement>(null)
	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
	const showSubMenuTimer = useRef<NodeJS.Timeout>()
	const hideSubMenuTimer = useRef<NodeJS.Timeout>()

	useEffect(() => {
		!menuItem.hidden && setFocusIfSelected()
	}, [props.isSelected])

	if (menuItem.hidden) {
		return null
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		// We should only handle tthe menu item specific keys here, the panel takes care of closing end navigating between items
		switch (e.key) {
			case 'Enter':
			case 'ArrowRight':
				{
					if (!menuItem.disabled) {
						setIsSubMenuOpen(true)
					}
					e.preventDefault()
					e.stopPropagation()
				}
				break
		}
	}

	const handleItemClick = (e: React.MouseEvent) => {
		if (!menuItem.disabled) {
			setIsSubMenuOpen(true)
		}
		e.preventDefault()
		e.stopPropagation()
	}

	const delayedShowSubMenu = () => {
		if (hideSubMenuTimer.current) {
			clearTimeout(hideSubMenuTimer.current)
		}

		if (isSubMenuOpen) {
			return
		}

		showSubMenuTimer.current = setTimeout(() => setIsSubMenuOpen(true), 100)
	}

	const delayedHideSubMenu = () => {
		if (showSubMenuTimer.current) {
			clearTimeout(showSubMenuTimer.current)
		}

		hideSubMenuTimer.current = setTimeout(() => {
			if (menuItemRef.current) {
				setIsSubMenuOpen(false)
			}
		}, 100)
	}

	const handleMouseEnter = () => {
		!menuItem.disabled && delayedShowSubMenu()
	}

	const handleMouseLeave = () => {
		delayedHideSubMenu()
	}

	const closeSubMenu = () => {
		setIsSubMenuOpen(false)
		setFocusIfSelected()
	}

	const handleClose = (e: React.MouseEvent | React.KeyboardEvent) => {
		if ('key' in e) {
			if (e.key === 'Escape' || e.key === 'ArrowLeft') {
				closeSubMenu()
			}
		} else {
			props.onActivate?.(e)
		}
	}

	const setFocusIfSelected = () => {
		if (props.isSelected && menuItemRef.current) {
			menuItemRef.current.focus()
		}
	}

	return (
		<div
			className={classes.item}
			onClick={handleItemClick}
			onKeyDown={handleKeyDown}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			ref={menuItemRef}
			id={menuItem.id}
			tabIndex={0}
			{...menuItem.dataAttributes}
		>
			<MenuItemContent
				iconClassName={menuItem.iconClassName}
				iconString={menuItem.iconString}
				displayText={menuItem.name}
				iconColorClass={props.iconColorClass}
				isSelected={props.isSelected}
				isChecked={menuItem.isChecked}
				disabled={menuItem.disabled}
				displaySubItems
				contentLayout={menuItem.contentLayout}
			/>

			{menuItem && !menuItem.disabled && menuItem.items && menuItem.items.length > 0 && (
				<PopupMenuControl
					isOpen={isSubMenuOpen}
					onClose={handleClose}
					items={menuItem.items}
					anchorEl={menuItemRef}
					placement={e_Placement.rightStart}
					shouldFocus={isSubMenuOpen}
				/>
			)}
		</div>
	)
}
