import { ROW_HEIGHT, ROW_HEIGHT_COMPACT } from '../consts'

export const getTableStyle = (
	zebraStripes: boolean | undefined,
	zebraColorSet: { foreground?: string; background?: string; primary?: string } | undefined,
	groupRowColorSet: { foreground?: string; background?: string } | undefined,
	verticalHeader: boolean,
	compact: boolean
) => {
	const zebraBackgroundColor = zebraStripes ? zebraColorSet?.background : undefined
	const zebraForegroundColor = zebraStripes ? zebraColorSet?.foreground : undefined
	const zebraOddBackgroundColor = zebraStripes ? zebraColorSet?.primary : undefined

	return {
		['--genus-background-color']: zebraBackgroundColor,
		['--genus-foreground-color']: zebraForegroundColor,
		['--genus-odd-row-background-color']: zebraOddBackgroundColor,
		['--genus-header-handle-height']: verticalHeader ? '100%' : '50%',
		['--genus-row-min-height']: `${compact ? ROW_HEIGHT_COMPACT : ROW_HEIGHT}px`,
		['--genus-group-row-background-color']: groupRowColorSet?.background,
		['--genus-group-row-foreground-color']: groupRowColorSet?.foreground,
	}
}
