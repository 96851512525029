import React, { useCallback, useEffect, useRef, useState } from 'react'
import type { IFormControl } from '../FormControl'
import { FormControl } from '../FormControl'
import { useId } from '../hooks/useId'
import { InputHeight } from '../utils/InputHeight'
import type { ISliderOption } from './Slider.types'
import numeral from 'numeral'
import classNames from 'clsx'
import { createStyle } from '../../theming'

const classes = createStyle((theme) => ({
	formControl: {
		alignItems: 'center',
	},
	slider: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		margin: 1,
		touchAction: 'none',
		outlineColor: 'transparent',
		transition: `outline-color ${theme.transitions.duration.shorter}`,
		'&:focus': {
			outlineColor: theme.colors.body.focusBorder,
			'&[aria-orientation="vertical"]': {
				height: '500px',
			},
		},
		'&:focus-within': {
			'& $lineContainer': {
				background: theme.palette.primary.themeTertiary,
			},
			'& $thumb': {
				borderColor: theme.palette.primary.themeTertiary,
			},
		},
	},
	sliderLine: {
		display: 'flex',
		position: 'relative',
		background: theme.palette.background.neutralLight,
		cursor: 'pointer',
		margin: '0 8px',
		borderRadius: 2,
		'&:hover $lineContainer': {
			background: theme.colors.input.checkedHoveredBackground,
		},
		'&:hover $thumb': {
			borderColor: theme.colors.input.checkedHoveredBackground,
		},
		'$slider[aria-orientation="horizontal"] &': {
			width: '100%',
		},
		'$slider[aria-orientation="vertical"] &': {
			height: '100%',
			flexDirection: 'column-reverse',
		},
		'$slider[aria-disabled="true"] &': {
			pointerEvents: 'none',
			opacity: 0.4,
			cursor: 'auto',
		},
	},
	thumb: {
		borderWidth: '2px',
		borderStyle: 'solid',
		borderRadius: '10px',
		borderColor: theme.colors.button.checkedText,
		background: theme.controls.button.colors.primaryColor,
		display: 'block',
		width: '16px',
		height: '16px',
		touchAction: 'none',
		position: 'absolute',
		transform: 'translateX(-50%)',
		'user-select': 'none',
		WebkitTouchCallout: 'none',
		'$slider[aria-orientation="horizontal"] &': {
			top: '-6px',
		},
		'$slider[aria-orientation="vertical"] &': {
			left: '-6px',
			transform: 'translateY(8px)',
		},
	},
	thumbDragging: {
		borderColor: theme.colors.input.checkedHoveredBackground,
	},
	lineContainer: {
		borderRadius: '4px',
		boxSizing: 'border-box',
		background: theme.colors.button.checkedText,
		'$slider[aria-orientation="horizontal"] &': {
			height: '4px',
		},
		'$slider[aria-orientation="vertical"] &': {
			width: '4px',
			verticalAlign: 'baseline',
		},
	},
	lineContainerDragging: {
		background: theme.colors.input.checkedHoveredBackground,
	},
	sliderValue: {
		textAlign: 'right',
		whiteSpace: 'nowrap',
		'user-select': 'none',
		alignSelf: 'center',
		WebkitTouchCallout: 'none',
	},
	sliderValueDisabled: {
		color: theme.colors.body.disabledText,
	},
	clickFocusCatcher: {
		display: 'flex',
		width: '100%',
		height: '100%',
		alignItems: 'center',
		'&:focus': { outline: 0 },
	},
}))

interface ISliderProps extends IFormControl {
	onValueChanged?: (value: number) => void
	value?: number | null
	min?: number
	max?: number
	step?: number
	orientation?: 'horizontal' | 'vertical'
	interactionType?: 'slide' | 'tap'
	isPercentNumber?: boolean
	options?: ISliderOption<number>[]
	dataAttributes: Record<string, string>
	showValue?: boolean

	// isDomainReference?: boolean  For future code domain support
	// referenceDomainValues: any
	// loadAllReferenceDomainValues: () => void
}

const getScreenScaleValue = (
	orientation: 'horizontal' | 'vertical',
	ref: React.RefObject<HTMLDivElement>,
	min: number,
	max: number
) => {
	const dimension =
		orientation === 'vertical'
			? ref.current!.getBoundingClientRect().height
			: ref.current!.getBoundingClientRect().width

	return (max - min) / dimension
}

export const Slider = (props: ISliderProps) => {
	const maxDefaultPercent = props.isPercentNumber ? 1 : props.max || 10
	const {
		subText = '',
		min = 0,
		max = props.options ? props.options.length - 1 : maxDefaultPercent,
		step = props.isPercentNumber ? 0.01 : 1,
		orientation = 'horizontal',
	} = props
	const id = useId('slider', props.id)

	const [localValue, setLocalValue] = useState<number>(0)
	const localValueRef = useRef(localValue)
	const [isDragging, setIsDragging] = useState(false)

	const sliderBoxRef = useRef<HTMLDivElement>(null)
	const sliderRef = useRef<HTMLDivElement>(null)

	const updateLocalValue = useCallback(
		(value: number) => {
			const valueNotUndefined = value ?? min
			const clampedValue = Math.max(min, Math.min(max, valueNotUndefined))

			localValueRef.current = clampedValue
			setLocalValue(clampedValue)
		},
		[min, max]
	)

	useEffect(() => {
		updateLocalValue(props.value || 0)
	}, [props.value, updateLocalValue])

	const currentThumbPosition = ((localValue - min) / (max - min)) * 100

	// try to calculate an approximate required width of the value label
	const digitCount = props.isPercentNumber ? (100 * max).toString().length + 3 : max.toString().length + 1

	const getValueLabel = () => {
		const valueLabel = orientation === 'vertical' ? max - localValue : localValue
		if ((props.value === undefined || props.value === null) && !isDragging) {
			return '-'
		}
		if (props.isPercentNumber) {
			return `${numeral(valueLabel).multiply(100).value()} %`
		}
		if (props.options) {
			return props.options[localValue].label
		}

		return valueLabel
	}

	const getNewThumbValue = (value: number, isDraggingThumb: boolean) => {
		let calculatedValue = 0.0

		if (!isDraggingThumb && props.interactionType === 'tap' && value > localValueRef.current) {
			calculatedValue = numeral(localValueRef.current).add(step).value()
		} else if (!isDraggingThumb && props.interactionType === 'tap' && value < localValueRef.current) {
			calculatedValue = numeral(localValueRef.current).subtract(step).value()
		} else {
			//slide
			const diff = value % step
			if (diff < step / 2) {
				calculatedValue = value - diff
			} else {
				calculatedValue = value - diff + step
			}
		}

		// Avoid floating point precision errors
		if (step % 1 !== 0) {
			const numberOfDecimals = step.toString().split('.')[1].length
			calculatedValue = parseFloat(calculatedValue.toFixed(numberOfDecimals))
		}

		return Math.max(Math.min(calculatedValue, max), min)
	}

	const handleKeyPress = (event: React.KeyboardEvent) => {
		if (
			event.key === 'ArrowRight' ||
			event.key === 'ArrowLeft' ||
			event.key === 'ArrowDown' ||
			event.key === 'ArrowUp'
		) {
			sliderBoxRef.current?.focus()
			const direction = event.key === 'ArrowRight' || event.key === 'ArrowUp' ? 1 : -1

			const calculatedValue = numeral(localValue).add(numeral(step).multiply(direction).value()).value()
			props.onValueChanged?.(Math.max(Math.min(calculatedValue, max), min))

			event.preventDefault()
			event.stopPropagation()
		}
	}

	const completePointerOperation = (updateValue: boolean) => {
		setTimeout(() => {
			document.removeEventListener('pointerup', handlePointerUp)
			document.removeEventListener('pointermove', handlePointerMove)
			document.removeEventListener('pointercancel', handlePointerCancel)

			if (updateValue) {
				props.onValueChanged?.(localValueRef.current)
			} else {
				// reset local value to prop value
			}

			setIsDragging(false)
		}, 0)
	}

	const getPointerPosition = (x: number, y: number) => {
		if (!sliderRef.current) {
			return undefined
		}

		const sliderBounds = sliderRef.current.getBoundingClientRect()

		return { x: x - sliderBounds.x, y: y - sliderBounds.y }
	}

	const updateThumbValueFromPointer = (
		pointerPosition: { x: number; y: number },
		isDraggingThumb: boolean,
		updateValue: boolean
	) => {
		const valuePosition = orientation === 'vertical' ? pointerPosition.y : pointerPosition.x

		const scale = getScreenScaleValue(orientation, sliderRef, min, max)

		const newValue = getNewThumbValue(valuePosition * scale + min, isDraggingThumb)

		updateLocalValue(newValue)

		if (updateValue) {
			props.onValueChanged?.(newValue)
		}
	}

	const handlePointerUp = (event: PointerEvent) => {
		completePointerOperation(true)

		event.preventDefault()
		event.stopPropagation()
	}

	const handlePointerMove = (event: PointerEvent) => {
		const pointerPosition = getPointerPosition(event.clientX, event.clientY)

		event.preventDefault()
		event.stopPropagation()

		if (!pointerPosition) {
			return
		}
		updateThumbValueFromPointer(pointerPosition, true, false)
	}

	const handlePointerCancel = () => {
		completePointerOperation(false)
	}

	const handlePointerDown = (event: React.PointerEvent) => {
		setIsDragging(true)

		event.preventDefault()
		event.stopPropagation()

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		event.currentTarget.setPointerCapture(event.pointerId)

		document.addEventListener('pointerup', handlePointerUp)
		document.addEventListener('pointermove', handlePointerMove)
		document.addEventListener('pointercancel', handlePointerCancel)

		sliderBoxRef.current?.focus()

		if (isDragging) {
			return
		}
		const pointerPosition = getPointerPosition(event.clientX, event.clientY)

		if (!pointerPosition) {
			return
		}

		updateThumbValueFromPointer(pointerPosition, false, true)
	}

	return (
		<FormControl
			id={id}
			label={props.label}
			labelPosition={props.labelPosition}
			hideLabel={props.hideLabel}
			labelProps={props.labelProps}
			labelIcon={props.labelIcon}
			labelIconColor={props.labelIconColor}
			labelBold={props.labelBold}
			labelContentLayout={props.labelContentLayout}
			disabled={props.disabled}
			error={props.error}
			warning={props.warning}
			screenTip={props.screenTip}
			labelSubText={props.labelSubText}
			validationText={props.validationText}
			validationTextPosition={props.validationTextPosition}
			subText={subText}
			reserveHelperTextSpace={props.reserveHelperTextSpace}
			disableBorder
			margin={props.margin}
			readOnly={props.readOnly}
			required={props.required}
			className={props.className}
		>
			<div
				className={classes.slider}
				onKeyDown={!props.disabled && !props.readOnly ? handleKeyPress : undefined}
				role="slider"
				ref={sliderBoxRef}
				id={id}
				tabIndex={!props.disabled ? 0 : -1}
				aria-disabled={props.disabled}
				aria-orientation={orientation}
				aria-valuemin={min}
				aria-valuemax={max}
				aria-valuenow={currentThumbPosition}
				aria-valuetext={String(getValueLabel())}
				aria-label={props.label}
				{...props.dataAttributes}
			>
				<div
					className={classes.clickFocusCatcher}
					tabIndex={-1}
					onPointerDown={!props.disabled && !props.readOnly ? handlePointerDown : undefined}
				>
					<InputHeight />
					<div className={classNames(classes.sliderLine)} ref={sliderRef}>
						<span
							key="thumb"
							className={classNames(classes.thumb, isDragging && classes.thumbDragging)}
							style={
								orientation === 'vertical'
									? { bottom: `${100 - currentThumbPosition}%` }
									: { left: `${currentThumbPosition}%` }
							}
						/>
						<span
							className={classNames(classes.lineContainer, isDragging && classes.lineContainerDragging)}
							style={
								orientation === 'vertical'
									? { height: `${100 - currentThumbPosition}%` }
									: { width: `${currentThumbPosition}%` }
							}
						/>
					</div>
				</div>
			</div>
			{props.showValue && (
				<label
					className={classNames(classes.sliderValue, props.disabled && classes.sliderValueDisabled)}
					style={{ width: `${digitCount}em` }}
					aria-hidden
				>
					{getValueLabel()}
				</label>
			)}
		</FormControl>
	)
}
