import { ExternalTokenizer } from '@lezer/lr'
import { ObjectIdentifier, ValueIdentifier, StringValue, BooleanValue, NumberValue } from './yaml_parser.terms'

const newLine = 10,
	space = 32,
	colon = 58,
	hash = 35,
	endOfFile = -1

const EOF_ERROR = 'End of file'

const tryAdvance = (input: InputStream) => {
	const next = input.next
	if (next === endOfFile) {
		throw new Error(EOF_ERROR)
	} else {
		input.advance()
	}
}

const getEOFSafeExternalTokenizer = (token: (input: InputStream) => void) =>
	new ExternalTokenizer((input) => {
		try {
			token(input)
		} catch (e) {
			if (e instanceof Error && e.message === EOF_ERROR) {
				return
			} else {
				throw e
			}
		}
	})

type InputStream = {
	next: number
	peek: (n: number) => number
	advance: (n?: number) => number
	acceptToken: (n: number) => void
}

const followingCharsAreAssignment = (input: InputStream) =>
	input.peek(0) === colon && (input.peek(1) === newLine || input.peek(1) === space)

export const identifier = getEOFSafeExternalTokenizer((input) => {
	if (input.next === hash || input.next === space || input.next === newLine) {
		return
	}

	while (!followingCharsAreAssignment(input)) {
		tryAdvance(input)
	}
	if (input.peek(1) === newLine) {
		input.acceptToken(ObjectIdentifier)
	} else if (input.peek(1) === space) {
		input.acceptToken(ValueIdentifier)
	}
})

const doubleQuotationMark = 34
const singleQuotationMark = 39
const backslash = 92

export const value = getEOFSafeExternalTokenizer((input) => {
	if (input.next === space) {
		return
	}

	if (input.next === doubleQuotationMark) {
		advanceThroughString(input, doubleQuotationMark)
		input.acceptToken(StringValue)
	} else if (input.next === singleQuotationMark) {
		advanceThroughString(input, singleQuotationMark)
		input.acceptToken(StringValue)
	} else {
		const value = getRestOfLine(input)

		if (followingLineIsContent(input)) {
			advanceThroughRemainingContent(input)
			input.acceptToken(StringValue)
		} else {
			const valueType = getValueType(value)
			input.acceptToken(valueType)
		}
	}
})

const advanceThroughString = (input: InputStream, endOfStringChar: number) => {
	tryAdvance(input)
	while (input.peek(0) !== endOfStringChar || input.peek(-1) === backslash) {
		tryAdvance(input)
	}
	tryAdvance(input)
}

const advanceThroughRemainingContent = (input: InputStream) => {
	while (followingLineIsContent(input)) {
		tryAdvance(input)
		getRestOfLine(input)
	}
}

const getRestOfLine = (input: InputStream) => {
	let value = ''
	while (input.next !== newLine && !isTrailingSpace(input)) {
		value += String.fromCharCode(input.next)
		tryAdvance(input)
	}
	return value
}

const isTrailingSpace = (input: InputStream) => {
	let spaceCount = 0
	while (input.peek(spaceCount) === space) {
		spaceCount++
	}

	const isFollowedByContentEnd = input.peek(spaceCount) === newLine || input.peek(spaceCount) === hash

	return spaceCount > 0 && isFollowedByContentEnd
}

const followingLineIsContent = (input: InputStream) => {
	let charIndex = 1

	while (input.peek(charIndex) === space) {
		charIndex++
	}

	if (input.peek(charIndex) === newLine || input.peek(charIndex) === hash) {
		return false
	}

	while (input.peek(charIndex) !== newLine) {
		if (isAssignemnt(input, charIndex) || input.peek(charIndex) === endOfFile) {
			return false
		}
		charIndex++
	}

	return true
}

const isAssignemnt = (input: InputStream, i: number) =>
	input.peek(i) === colon && (input.peek(i + 1) === space || input.peek(i + 1) === newLine)

const getValueType = (value: string) => {
	if (isBoolean(value)) {
		return BooleanValue
	} else if (isNumber(value)) {
		return NumberValue
	} else {
		return StringValue
	}
}

// The following regex is taken from https://yaml.org/type/

const YAML_YES_NO = `y|Y|yes|Yes|YES|n|N|no|No|NO`
const YAML_TRUE_FALSE = `true|True|TRUE|false|False|FALSE`
const YAML_ON_OFF = `on|On|ON|off|Off|OFF`
const YAML_BOOLEAN = `^(?:${YAML_YES_NO}|${YAML_TRUE_FALSE}|${YAML_ON_OFF})$`

const isBoolean = (value: string) => new RegExp(YAML_BOOLEAN, 'u').test(value)

const YAML_BASE_10_FLOAT = `[-+]?([0-9][0-9_]*)?\\.[0-9.]*([eE][-+][0-9]+)?`
const YAML_BASE_60_FLOAT = `[-+]?[0-9][0-9_]*(:[0-5]?[0-9])+\\.[0-9_]*`
const YAML_INF = `[-+]?\\.(inf|Inf|INF)`
const YAML_NAN = `\\.(nan|NaN|NAN)`
const YAML_FLOAT = `^(?:${YAML_BASE_10_FLOAT}|${YAML_BASE_60_FLOAT}|${YAML_INF}|${YAML_NAN})$`

const YAML_BASE_2_INT = `[-+]?0b[0-1_]+`
const YAML_BASE_8_INT = `[-+]?0[0-7_]+`
const YAML_BASE_10_INT = `[-+]?(0|[1-9][0-9_]*)`
const YAML_BASE_16_INT = `[-+]?0x[0-9a-fA-F_]+`
const YAML_BASE_60_INT = `[-+]?[1-9][0-9_]*(:[0-5]?[0-9])+`
const YAML_INT = `^(?:${YAML_BASE_2_INT}|${YAML_BASE_8_INT}|${YAML_BASE_10_INT}|${YAML_BASE_16_INT}|${YAML_BASE_60_INT})$`

const isFloat = (value: string) => new RegExp(YAML_FLOAT, 'u').test(value)
const isInt = (value: string) => new RegExp(YAML_INT, 'u').test(value)

const isNumber = (value: string) => isFloat(value) || isInt(value)
