import React from 'react'
import { type ISwitchProps } from './Switch'
import classNames from 'clsx'

import { ValueLabel, useFormControlContext } from '../FormControl'
import { createStyle } from '../../theming'
import { InputHeight } from '../utils/InputHeight'

const classes = createStyle((theme) => ({
	button: {
		fontWeight: 'inherit',
		width: '100%',
		background: 0,
		padding: '0px 1px',
		margin: 0,
		alignSelf: 'flex-start',
		display: 'flex',
		cursor: 'pointer',
		color: 'inherit',
		border: 'none',

		outlineStyle: 'solid',
		outlineColor: 'transparent',
		outlineWidth: 1,
		outlineOffset: -1,

		transition: `outline-color ${theme.transitions.duration.shorter}`,
		'&:disabled, &[aria-readonly="true"]': {
			cursor: 'default',
		},
		'&:disabled, &[aria-readonly="true"], &[aria-disabled="true"]': {
			cursor: 'default',
		},
		'&:focus-visible': { outlineColor: theme.colors.body.focusBorder },
	},
	noLabel: { alignSelf: 'center' },
	buttonInner: {
		display: 'flex',
		alignItems: 'baseline',
	},
	switchSize16: {
		width: 35,
		height: 16,
		margin: 0,
		borderRadius: 8,
	},
	switchSize22: {
		width: 45,
		height: 22,
		margin: 0,
		borderRadius: 11,
	},
	switchSize28: {
		width: 60,
		height: 28,
		margin: 0,
		borderRadius: 14,
	},
	switch: {
		padding: '1px 3px',
		alignSelf: 'center',
		transition: 'background, border',
		transitionDuration: theme.transitions.duration.shorter,
		borderWidth: '1px',
		borderStyle: 'solid',
		flex: '0 0 auto',

		background: theme.controls.field.colors.background,
		borderColor: theme.controls.field.colors.color,
		'$button[aria-checked="true"] &': {
			background: theme.controls.button.colors.primaryBackground,
			borderColor: theme.controls.button.colors.primaryBackground,
		},
		'$button:disabled &': {
			borderColor: theme.controls.field.colors.color,
			opacity: 0.4,
		},
		'$button:disabled[aria-checked="true"] &': {
			borderColor: theme.controls.button.disabledColors.primaryColor,
			background: theme.controls.button.disabledColors.primaryColor,
		},
	},
	thumbSize16: {
		width: 10,
		height: 10,
		borderRadius: 5,
		'$button[aria-checked="true"] &': {
			transform: 'translateX(17px)',
		},
	},
	thumbSize22: {
		width: 16,
		height: 16,
		borderRadius: 8,
		'$button[aria-checked="true"] &': {
			transform: 'translateX(22px)',
		},
	},
	thumbSize28: {
		width: 22,
		height: 22,
		borderRadius: 11,
		'$button[aria-checked="true"] &': {
			transform: 'translateX(30px)',
		},
	},

	thumb: {
		display: 'block',
		transition: 'background 0.2s',
		marginTop: 1,
		transform: 'translateX(0px)',
		background: theme.controls.field.colors.color,

		'$button[aria-checked="true"] &': {
			background: theme.controls.button.colors.primaryColor,
		},

		'$button:disabled &': {
			background: theme.controls.field.colors.color,
		},
		'$button:disabled[aria-checked="true"] &': {
			background: theme.controls.button.colors.primaryColor,
		},
	},
	thumbTransition: {
		transition: 'transform 0.14s',
	},
	switchLabel: { marginLeft: 4, textAlign: 'left', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' },
	hiddenInput: {
		width: '0px !important',
		paddingRight: '0 !important',
		paddingLeft: '0 !important',
		margin: '0 !important',
		opacity: 0,
	},
	inputNoLineHeight: { height: 0 },
}))

export type ISwitchSize = 16 | 22 | 28

const getSwitchSizingClasses = (size: ISwitchSize) => {
	const classMap = {
		[16]: { thumbSize: classes.thumbSize16, switchSize: classes.switchSize16 },
		[22]: { thumbSize: classes.thumbSize22, switchSize: classes.switchSize22 },
		[28]: { thumbSize: classes.thumbSize28, switchSize: classes.switchSize28 },
	}

	const sizingClasses = classMap[size]

	return sizingClasses
}

export function SwitchContent(props: ISwitchProps) {
	const { id, disabled, switchSize = 16, isInteractive = true } = props
	const { valueLabel = '' } = props

	const formControlContext = useFormControlContext()

	const onClick = () => {
		props.onChange?.(!props.checked)
	}

	const sizingClasses = getSwitchSizingClasses(switchSize)

	const switchClass = classNames(classes.switch, sizingClasses.switchSize)
	const thumbClass = classNames(classes.thumb, sizingClasses.thumbSize, isInteractive && classes.thumbTransition)

	return (
		<button
			id={id}
			{...props.dataAttributes}
			className={classNames(classes.button, (!props.label || props.hideLabel) && classes.noLabel)}
			onClick={!disabled && !props.readOnly ? onClick : undefined}
			role="switch"
			aria-checked={props.checked ? true : false}
			aria-labelledby={id && formControlContext.isLabelVisible ? id + '-label' : undefined}
			aria-describedby={id && formControlContext.isSubTextVisible ? id + '-subtext' : undefined}
			aria-readonly={props.readOnly}
			disabled={disabled}
			name={props.name}
			value={props.checked ? 'true' : 'false'}
		>
			<span className={classes.buttonInner}>
				<InputHeight border />
				<span className={switchClass}>
					<i className={thumbClass} />
				</span>
				{valueLabel && <ValueLabel disabled={disabled} value={valueLabel} />}
			</span>
		</button>
	)
}
