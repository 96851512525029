import React, { useState } from 'react'

import { OperatorLogin } from './OperatorLogin'
import { PersistSession } from './PersistSession'
import { Text } from '@genusbiz/web-ui/controls'
import { IIdentityProvider } from 'src/interfaces/IIdentityProvider'
import { e_OperatorStorageKeys } from 'src/enums/e_OperatorStorageKeys'
import { e_AuthMetod } from 'src/enums/e_AuthMethod'
import { OperatorEntraIdLogin } from './OperatorEntraIdLogin'
import { createStyle } from '@genusbiz/web-ui/theming'
import { e_DisplayState } from 'src/enums/e_DisplayState'
import { getAuthMethod } from 'src/features/Authentication/utils/getAuthMethod'

const classes = createStyle((theme) => ({
	topPadding: {
		flex: 1,
		overFlowX: 'hidden',
	},

	bottomPadding: {
		flex: 2,
	},

	leftPadding: {
		flex: '1 0 0px',
	},

	rightPadding: {
		flex: '1 0 0px',
	},

	centeredContent: {
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '350px',
	},

	authenticationContainer: {
		flex: 1,
		display: 'flex',
		fontSize: '14px',
		position: 'relative',
		color: theme.colors.body.text,
	},

	signinWindow: {
		display: 'flex',
		flexDirection: 'column',
		margin: '24px',
		paddingBottom: '24px',
		flex: 1,
	},
	headerWrapper: {
		textAlign: 'center',
		paddingBottom: '24px',
		marginBottom: '24px',
		borderBottom: '1px solid ' + theme.colors.body.divider,
	},
	header: { margin: 0 },
	subHeader: { margin: '5px 0 0 0' },
	title: { marginTop: 0 },
	providerNativeDivider: {
		alignItems: 'center',
		color: theme.palette.foreground.neutralSecondary,
		marginTop: '24px !important',
		marginBottom: '32px !important',
		'& span': { padding: '0 5px' },
	},
	divider: { flex: 1 },
	link: {
		color: theme.colors.links.text,
		'&:hover': { color: theme.colors.links.hoveredText },
	},
	logo: { width: 55 },
}))

interface ISignInWindowContentProps {
	identityProviders: IIdentityProvider[]
	loginHeader: string
	loginSubHeader?: string
	border?: boolean
	onValidSignIn: () => void
	onInvalidSignIn: (loginResponse: string) => void
	loginResponse?: string
}

export const SignInWindowContent = (props: ISignInWindowContentProps) => {
	const [nativeUserName, setNativeUserName] = useState(sessionStorage.getItem(e_OperatorStorageKeys.username) || '')

	const [displayState, setDisplayState] = useState<e_DisplayState>(e_DisplayState.signinWindow)

	const internalIdentityProvider = props.identityProviders[0]

	const authMethod = getAuthMethod()

	return (
		<div className={classes.signinWindow}>
			<div className={classes.headerWrapper}>
				<Text variant="h1" className={classes.header}>
					{props.loginHeader}
				</Text>
				{props.loginSubHeader && (
					<Text variant="h3" className={classes.subHeader}>
						{props.loginSubHeader}
					</Text>
				)}
			</div>

			<div className={classes.authenticationContainer}>
				<div className={classes.leftPadding} />
				<div className={classes.centeredContent}>
					<div className={classes.topPadding} />
					<div className="vertical spacing horizontal-stretch-items">
						{displayState === e_DisplayState.signinWindow && authMethod === e_AuthMetod.cookie && (
							<OperatorLogin
								provider={internalIdentityProvider}
								userName={nativeUserName}
								onNativeUserNameChanged={(userName: string) => setNativeUserName(userName)}
								setDisplayState={setDisplayState}
								onValidSignIn={props.onValidSignIn}
							/>
						)}
						{displayState === e_DisplayState.signinWindow && authMethod === e_AuthMetod.entraID && (
							<OperatorEntraIdLogin
								onValidSignIn={props.onValidSignIn}
								onInvalidSignIn={props.onInvalidSignIn}
								loginResponse={props.loginResponse}
							/>
						)}
						{displayState === e_DisplayState.persistSession && <PersistSession onValidSignIn={props.onValidSignIn} />}
					</div>
					<div className={classes.bottomPadding} />
				</div>
				<div className={classes.rightPadding} />
			</div>
		</div>
	)
}
