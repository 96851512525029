import { DropdownInput, DropdownOptionType, IDropdownItem } from '@genusbiz/web-ui/controls'
import React from 'react'

interface IGroupedDropdownInputProps<T, K extends string> {
	options: IDropdownItem<T>[]
	getKey: (version: IDropdownItem<T>) => K

	groupValue?: K
	setGroupValue: (value: K | undefined) => void
	groupOptionLabel: string
	groupOptionSubText?: string
	sortGroupOptions?: (a: K, b: K) => number

	value?: T
	setValue: (value: T | undefined) => void
	optionLabel: string
	optionSubText?: string
	sortOptions?: (a: T, b: T) => number
}

const sortDropdownOptions = <K,>(options?: IDropdownItem<K>[], sortGroupOptions?: (a: K, b: K) => number) =>
	sortGroupOptions ? options?.toSorted((a, b) => sortGroupOptions(a.value, b.value)) : options

export const GroupedDropdownInput = <T, K extends string>(props: IGroupedDropdownInputProps<T, K>) => {
	const resetOptionOnGroupChange = (option: string) =>
		isChangedGroupOption(option, props.groupValue) && props.setValue(undefined)

	const groups = Object.groupBy(props.options, props.getKey)

	const groupOptions = getGroupOptions(groups)
	const sortedGroupOptions = sortDropdownOptions(groupOptions, props.sortGroupOptions)

	const options = getOptionsInGroup(groups, props.groupValue)
	const sortedOptions = sortDropdownOptions(options, props.sortOptions)

	const hasOptions = sortedOptions && sortedOptions.length > 0

	return (
		<>
			<DropdownInput
				value={props.groupValue}
				options={sortedGroupOptions}
				onChange={(option) => {
					resetOptionOnGroupChange(option)
					props.setGroupValue(option)
				}}
				label={props.groupOptionLabel}
				subText={props.groupOptionSubText}
			/>
			<DropdownInput
				disabled={!hasOptions}
				value={props.value}
				options={sortedOptions}
				onChange={props.setValue}
				label={props.optionLabel}
				subText={props.optionSubText}
				reserveHelperTextSpace
			/>
		</>
	)
}

const getOptionsInGroup = <T, K extends string>(groups: Partial<Record<K, IDropdownItem<T>[]>>, selectedGroup?: K) =>
	selectedGroup ? groups[selectedGroup] : []

const getGroupOptions = <T, K extends string>(versionGroups: Partial<Record<K, IDropdownItem<T>[]>>) =>
	getStringUnionKey(versionGroups).map((key) => formatGroupOption(key))

const getStringUnionKey = <K extends string>(versionGroups: Partial<Record<K, unknown>>) =>
	Object.keys(versionGroups) as K[]

const formatGroupOption = <K,>(value: K) =>
	({
		value: value,
		label: value,
		type: DropdownOptionType.item,
	}) as IDropdownItem<K>

const isChangedGroupOption = (newOption: string, option?: string) => newOption !== option
