import React from 'react'
import { IColumnDef, RowTreeData, e_RenderType } from '@genusbiz/web-ui/controls/AgGridTable/Table.types'
import { CommandBar, AgGridTable } from '@genusbiz/web-ui/controls'
import { useDispatch, useSelector } from 'react-redux'
import { operatorFrontendActions } from 'src/features/OperatorFrontend/duck/operatorFrontendActions'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { e_MenuItemType, MenuItem } from '@genusbiz/web-ui/controls/Menu'
import { useTranslation } from 'react-i18next'
import { convertDateTime } from 'src/utils/dateTimeUtils'
import { createStyle } from '@genusbiz/web-ui/theming'

const classes = createStyle((theme) => ({
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		backgroundColor: 'white',
	},
	header: {
		marginLeft: '8px',
	},
	table: {
		width: '100%',
		height: '100%',
	},
	commandBar: {
		backgroundColor: theme.colors.panel.background,
		display: 'flex',
		borderBottom: '1px solid ' + theme.colors.panel.border,
	},
}))

export const OperatorErrorLog = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const errorLogs = useSelector(operatorFrontendSelectors.selectErrorLogs)

	const commandBarItems: MenuItem[] = [
		{
			type: e_MenuItemType.action,
			name: t('GENERAL:REFRESH'),
			iconClassName: 'Fluent-Refresh',
			onClick: () => {
				dispatch(operatorFrontendActions.fetchOperatorErrorLog())
			},
		},
		{
			type: e_MenuItemType.action,
			name: t('COMMAND_BAR:CLEAR_LOG'),
			iconClassName: 'Fluent-Delete',
			onClick: () => {
				dispatch(operatorFrontendActions.clearOperatorErrorLog())
			},
		},
		{ type: e_MenuItemType.divider },
	]

	const columns: IColumnDef[] = [
		{
			headerName: t('GENERAL:ID'),
			field: 'requestId',
			hide: true,
		},
		{
			headerName: t('TABLE_HEADERS:TIMESTAMP'),
			field: 'timestamp',
			width: 170,
			renderType: e_RenderType.date,
			// Hva gjør alle disse propertiene? Ser ikke ut til å ha noen effekt
			// format: e_DateFormat.shortDateTime,
			// dataType: e_DataType.date,
			// formatValue: (value) => {
			// 	console.log(value)
			// 	return value
			// },
			// formatValue: (value) => convertDateTime(value?.toString(), 'LT L'),
		},
		{
			headerName: t('TABLE_HEADERS:LEVEL'),
			field: 'level',
			width: 70,
			onRenderCell: (rowData) => {
				const value = rowData?.columns?.level?.value ?? ''
				return (
					<span>
						{value === 10 && <i style={{ color: '#cc3300', fontSize: '18px' }} className="Fluent-Error" />}
						{value === 20 && <i style={{ color: '#ffcc00', fontSize: '18px' }} className="Fluent-Warning" />}
						{value !== 10 && value !== 20 && ''}
					</span>
				)
			},
		},
		{
			headerName: t('TABLE_HEADERS:RUNTIME'),
			field: 'k8sRuntime',
		},
		{
			headerName: t('TABLE_HEADERS:SOURCE'),
			field: 'source',
			width: 100,
		},
		{
			autoHeight: true,
			headerName: t('TABLE_HEADERS:MESSAGE'),
			field: 'message',
			onRenderCell: (rowData) => {
				const value = rowData?.columns?.message?.value ?? ''
				return <pre style={{ margin: '0px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{value}</pre>
			},
		},
	]

	const rowData: RowTreeData[] = errorLogs.map((errorLog) => {
		return {
			id: errorLog.requestId,
			rowHierarchy: errorLog.hierarchy,
			columns: {
				id: { value: errorLog.requestId },
				timestamp: {
					value: errorLog.utc,
					formattedValue: convertDateTime(errorLog.utc?.toString(), 'LTS L'),
					sortValue: Date.parse(errorLog.utc),
				},
				level: { value: errorLog.level },
				k8sRuntime: { value: errorLog.k8sRuntime },
				source: { value: errorLog.source },
				message: { value: errorLog.message },
			},
		}
	})
	return (
		<div className={classes.container}>
			<CommandBar className={classes.commandBar} items={commandBarItems} />
			<h2 className={classes.header}>{t('NAVIGATION_PANE:ERROR_LOG')}</h2>
			<AgGridTable
				columnDefs={columns}
				disableMovableColumns
				disableRowClickSelection
				rowData={rowData}
				enableCellTextSelection
				treeData
				compact
				groupLevelsToExpand={0}
				groupHeaderName=""
				groupColumnDef={{ enableSorting: false, width: 30, minWidth: 30 }}
			/>
		</div>
	)
}
