import { useDispatch, useSelector } from 'react-redux'
import { versionDeploymentActions } from 'src/features/VersionDeployment/duck/versionDeploymentActions'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { useDebounce } from '@genusbiz/web-ui/controls/utils/useDebounce'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { useEffect, useMemo } from 'react'
import { e_EnvironmentOperatingType, OPERATING_TYPE_NAME } from 'src/enums/e_EnvironmentOperatingTypes'
import { e_ChartName } from 'src/interfaces/IContainerRegistry'

const useDeploymentDispatch = () => {
	const deployChosenTargets = useDeployChosenTargetsDispatch()
	const abortDeployment = useAbortDeploymentOnEnvironmentTypeDispatch()

	return { deployChosenTargets, abortDeployment }
}

const useDeployChosenTargetsDispatch = () => {
	const dispatch = useDispatch()
	const chosenNames = useChosenRuntimeNames()
	const versionName = useSelector(versionDeploymentSelectors.selectChosenVersion)?.name

	const deployChosenTargets = useDebounce(
		() => versionName && dispatch(versionDeploymentActions.deployVersion(e_ChartName.genus, chosenNames, versionName)),
		1000
	)

	return deployChosenTargets
}

const useAbortDeploymentOnEnvironmentTypeDispatch = () => {
	const dispatch = useDispatch()

	const jobStates = useSelector(versionDeploymentSelectors.selectJobStates)

	const getJobState = (environmentType: e_EnvironmentOperatingType) =>
		OPERATING_TYPE_NAME[environmentType] !== 'unknown' ? jobStates[OPERATING_TYPE_NAME[environmentType]] : []

	const getDeployingTargetNames = (environmentType: e_EnvironmentOperatingType) =>
		getJobState(environmentType).map((state) => state.targetName)

	const abortDeployment = useDebounce(
		(environmentType: e_EnvironmentOperatingType) =>
			dispatch(versionDeploymentActions.abortDeployment(getDeployingTargetNames(environmentType))),
		1000
	)

	return abortDeployment
}

const useChosenRuntimeNames = () => {
	const chosenTypes = useSelector(versionDeploymentSelectors.selectChosenOperatingTypes)
	const deploymentTargets = useSelector(kubernetesSelectors.selectK8sRuntimes)

	const chosenNames = useMemo(() => {
		const selectedRuntimes = deploymentTargets.filter((target) => isSelected(chosenTypes, target.currentlyOperatingAs))
		const chosenNames = selectedRuntimes.map((target) => target.name)

		return chosenNames
	}, [deploymentTargets, chosenTypes])

	return chosenNames
}

type TChosenTypes = ReturnType<typeof versionDeploymentSelectors.selectChosenOperatingTypes>
type TChosenTypeKeys = keyof TChosenTypes

const isValidOperatingType = (typeName: string): typeName is TChosenTypeKeys =>
	['origin', 'passive', 'active'].includes(typeName)

const isSelected = (selection: TChosenTypes, operatingType: e_EnvironmentOperatingType) =>
	isValidOperatingType(e_EnvironmentOperatingType[operatingType]) &&
	selection[e_EnvironmentOperatingType[operatingType]]

//

const useHelmValuesAndVersionSchema = (chartName: e_ChartName, runtimeName: string, versionName: string) => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(versionDeploymentActions.validateDeployment(chartName, runtimeName, versionName))
	}, [chartName, runtimeName, versionName])

	const schema = useSelector(versionDeploymentSelectors.selectVersionSchema)
	const values = useSelector(versionDeploymentSelectors.selectRuntimesValues)[runtimeName] as string | undefined

	return { schema, values }
}

const getDeploymentMethodMessage = () => {
	const deploymentMethod = window.env.VERSION_DEPLOYMENT_METHOD as string
	const deploymentMethodMessage = deploymentMethod === 'GenusOperator' ? '' : `  (${deploymentMethod})`
	return deploymentMethodMessage
}

export const deploymentUtils = {
	useDeploymentDispatch,
	isSelected,
	useHelmValuesAndVersionSchema,
	getDeploymentMethodMessage,
}
