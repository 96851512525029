import clsx from 'clsx'
import React, { type CSSProperties, type KeyboardEvent, type MouseEvent } from 'react'
import { ValueLabel } from '../FormControl'
import { createStyle } from '../../theming'
import type { StyleObject } from '../utils/useMergeStyles'
import { InputHeight } from '../utils/InputHeight'
import { useForwardedRef } from '../utils/useForwardedRef'

const classes = createStyle((theme) => ({
	checkbox: {
		background: 0,
		padding: '0px',
		alignItems: 'baseline',
		border: 'none',
		display: 'flex',
		cursor: 'pointer',
		color: 'inherit',
		outlineStyle: 'solid',
		outlineColor: 'transparent',
		outlineWidth: 1,
		outlineOffset: -2,
		fontWeight: 'inherit',
		'&:disabled, &[aria-readonly="true"], &[aria-disabled="true"]': {
			cursor: 'default',
		},
		'&:focus-visible': { outlineColor: theme.colors.body.focusBorder },
	},

	checkboxSquare: {
		fontWeight: 'normal',
		display: 'flex',
		justifyContent: 'center',
		padding: 0,
		marginLeft: 2,
		alignSelf: 'center',
		alignItems: 'center',
		border: '1px solid ' + theme.controls.field.colors.color,
		flex: '0 0 auto',

		'$checkbox:not(:disabled)[aria-checked="true"][aria-disabled="false"][aria-readonly="false"] &': {
			// State: Enabled, checked, readonly=false
			borderColor: theme.controls.button.colors.primaryBackground,
			background: theme.controls.button.colors.primaryBackground,
			'&:hover': {
				borderColor: theme.controls.button.checkedHoverColors.primaryBackground,
				background: theme.controls.button.checkedHoverColors.primaryBackground,
			},
		},
		'$checkbox:not(:disabled)[aria-checked="true"][aria-disabled="false"][aria-readonly="true"] &': {
			// State: Enabled, checked, readonly=true
			borderColor: theme.controls.button.colors.primaryBackground,
			background: theme.controls.button.colors.primaryBackground,
		},

		'$checkbox:not(:disabled)[aria-checked="mixed"][aria-disabled="false"][aria-readonly="false"] &': {
			// State: Enabled, mixed
			// State: Enabled, mixed, readonly=false
			borderColor: theme.controls.button.colors.primaryBackground,
			background: theme.controls.button.colors.primaryBackground,
			'&:hover': {
				borderColor: theme.controls.button.checkedHoverColors.primaryBackground,
				background: theme.controls.button.checkedHoverColors.primaryBackground,
			},
		},

		'$checkbox:not(:disabled)[aria-checked="mixed"][aria-disabled="false"][aria-readonly="true"] &': {
			// State: Enabled, mixed, readonly=true
			borderColor: theme.controls.button.colors.primaryBackground,
			background: theme.controls.button.colors.primaryBackground,
		},
	},
	checkboxSquareBackground: {
		// State: Normal
		background: theme.controls.field.colors.background,
	},
	checkboxSquareRounded: {
		borderRadius: 4,
	},
	checkboxSquareTransition: {
		transition: 'background 0.2s',
	},
	checkboxSquareDisabled: {
		'$checkbox[aria-checked="true"] &': {
			borderColor: theme.controls.button.disabledColors.primaryColor,
			background: theme.controls.button.disabledColors.primaryColor,
		},
		opacity: 0.4,
		borderColor: theme.controls.field.colors.color,
		background: theme.controls.field.colors.background,
	},

	checkboxSquareReadOnly: {
		background: theme.controls.field.colors.background,
	},

	checkmark: {
		opacity: 0,
		color: theme.controls.field.colors.color,

		'$checkbox[aria-checked="true"][aria-disabled="false"] &': {
			// checkmark is displayed against a primary button background
			color: theme.controls.button.colors.primaryColor,
			opacity: 1,
		},
		'$checkbox[aria-checked="true"][aria-disabled="true"] &': {
			color: theme.controls.button.colors.primaryColor,
			opacity: 1,
		},
		'$checkbox[aria-checked="mixed"] &': {
			color: theme.controls.field.colors.color,
			opacity: 1,
			fontWeight: 'bold !important',
		},
		'$checkbox:not(:disabled):hover[aria-checked="false"][aria-disabled="false"][aria-readonly="false"] &': {
			// State: Enabled, unchecked, enabled, not readonly
			opacity: 0.6,
		},
		'$checkbox:disabled[aria-checked="true"] &, $checkbox:disabled[aria-checked="mixed"] &': {
			cursor: 'default',
			color: theme.controls.button.colors.primaryColor,
			opacity: 1,
		},
	},
	checkMarkModern: {
		'$checkbox[aria-checked="true"] &': {
			fontWeight: 'bold !important',
		},
		'$checkbox[aria-checked="mixed"] &': {
			fontWeight: 'bold !important',
		},
		'$checkbox:disabled[aria-checked="true"] &, $checkbox:disabled[aria-checked="mixed"] &': {
			fontWeight: 'bold !important',
		},
	},
	checkmarkTransition: {
		transition: 'opacity ' + theme.transitions.duration.shorter,
	},
	label: {
		display: 'block',
		marginLeft: '0.2rem',
		marginRight: 2,
	},
	wrapLabel: {
		textAlign: 'left',
		whiteSpace: 'pre-wrap',
		overflowWrap: 'break-word',
	},
}))

interface ICheckmarkProps {
	id?: string
	dataAttributes?: Record<string, string>
	disabled?: boolean
	readOnly?: boolean
	disableUniformHeight?: boolean
	wordWrapValueLabel?: boolean
	checkmarkSize: 14 | 16 | 18 | 26 | 38
	className?: string
	style?: StyleObject | CSSProperties
	valueLabel?: string
	suppressAnimation?: boolean
	suppressBackground?: boolean
	isRounded?: boolean
	checked?: boolean | null
	onChange?: (event: { originalEvent: KeyboardEvent | MouseEvent }) => void
	screenTip?: string
}

export const CheckboxInternal = React.forwardRef(
	(props: ICheckmarkProps, forwardedRef: React.Ref<HTMLButtonElement>) => {
		const ref = useForwardedRef<HTMLButtonElement>(forwardedRef)

		const onClick = (e: MouseEvent) => {
			if (props.readOnly) {
				return
			}
			props.onChange?.({ originalEvent: e })
		}

		const checkboxSquareClasses = clsx(classes.checkboxSquare, {
			[classes.checkboxSquareDisabled]: props.disabled,
			[classes.checkboxSquareReadOnly]: props.readOnly,
			[classes.checkboxSquareTransition]: !props.suppressAnimation,
			[classes.checkboxSquareBackground]: !props.suppressBackground,
			[classes.checkboxSquareRounded]: props.isRounded,
		})

		const checkmarkSizeStyle = {
			fontSize: `${props.checked === null ? props.checkmarkSize / 2 : props.checkmarkSize - 4}px`,
			width: `${props.checkmarkSize}px`,
			height: `${props.checkmarkSize}px`,
		}

		const handleOnKeyDown = (e: React.KeyboardEvent) => {
			if (e.key === ' ' && !props.readOnly) {
				e.preventDefault()
				props.onChange?.({ originalEvent: e })
			}
		}

		const checkmarkClass = clsx(classes.checkmark, {
			[classes.checkmarkTransition]: !props.suppressAnimation,
			[classes.checkMarkModern]: props.isRounded,
		})

		return (
			<button
				id={props.id}
				{...props.dataAttributes}
				className={clsx(classes.checkbox, {
					[props.className || '']: props.className !== undefined,
				})}
				onClick={!props.disabled && !props.readOnly ? onClick : undefined}
				onKeyDown={handleOnKeyDown}
				role="checkbox"
				aria-checked={props.checked === null ? 'mixed' : props.checked === true}
				aria-readonly={props.readOnly ? true : false}
				aria-labelledby={`${props.id}-label`}
				aria-describedby={`${props.id}-subText`}
				aria-disabled={props.disabled ? true : false}
				ref={ref}
				tabIndex={!props.disabled ? 0 : undefined}
				disabled={props.disabled}
				style={props.style}
				title={props.screenTip}
			>
				{!props.disableUniformHeight && <InputHeight border />}
				<span className={checkboxSquareClasses} style={checkmarkSizeStyle}>
					{props.checked !== null && <i aria-hidden="true" className={checkmarkClass + ' Fluent-CheckMark'} />}
					{props.checked === null && <i aria-hidden="true" className={checkmarkClass + ' Fluent-Remove'} />}
				</span>
				{props.valueLabel && (
					<ValueLabel value={props.valueLabel} disabled={props.disabled} wordWrap={props.wordWrapValueLabel} />
				)}
			</button>
		)
	}
)

CheckboxInternal.displayName = 'CheckboxInternal'
