import { versionDeploymentTypes } from './versionDeploymentTypes'
import { ISetDeploymentVersions } from './IVersionDeploymentActions'
import { e_ChartName, IContainerTag, IContainerTags } from 'src/interfaces/IContainerRegistry'
import { IDeploymentJobStatus } from 'src/interfaces/IDeployJob'
import { AnySchema, ErrorObject } from 'ajv'

const setDeploymentVersions = (deploymentVersions: {
	origin: string[]
	active: string[]
	passive: string[]
}): ISetDeploymentVersions => ({
	type: versionDeploymentTypes.SET_DEPLOYMENT_VERSIONS,
	deploymentVersions,
})

const setVersionOptions = (options: IContainerTags | undefined) => ({
	type: versionDeploymentTypes.SET_VERSION_OPTIONS,
	options,
})

const setOperatorVersionOptions = (options: IContainerTags | undefined) => ({
	type: versionDeploymentTypes.SET_OPERATOR_VERSION_OPTIONS,
	options,
})

const fetchConfiguration = (chartName: e_ChartName, versionName: string, runtimeName: string) => ({
	type: versionDeploymentTypes.FETCH_CONFIGURAION,
	chartName,
	versionName,
	runtimeName,
})

const setVersionSchema = (versionName: string, schema?: AnySchema) => ({
	type: versionDeploymentTypes.SET_VERSION_SCHEMA,
	versionName,
	schema,
})

const setRuntimeHelmValues = (values: { [runtimeName: string]: string }) => ({
	type: versionDeploymentTypes.SET_RUNTIME_HELM_VALUES,
	values,
})

const addRuntimeHelmValues = (runtimeName: string, values: string) => ({
	type: versionDeploymentTypes.ADD_RUNTIME_HELM_VALUES,
	runtimeName,
	values,
})

const fetchVersionOptions = (chartName = e_ChartName.genus) => ({
	type: versionDeploymentTypes.FETCH_VERSION_OPTIONS,
	chartName,
})

const setJobStates = (
	states: {
		origin: { targetName: string; status: IDeploymentJobStatus }[]
		active: { targetName: string; status: IDeploymentJobStatus }[]
		passive: { targetName: string; status: IDeploymentJobStatus }[]
		operator: { targetName: string; status: IDeploymentJobStatus }[]
	},
	canSetUnknown?: boolean
) => ({
	type: versionDeploymentTypes.SET_JOB_STATES,
	states,
	canSetUnknown,
})

const setRuntimeValueErrors = (runtimeName: string, errors: ErrorObject[]) => ({
	type: versionDeploymentTypes.SET_RUNTIME_VALUE_ERRORS,
	runtimeName,
	errors,
})

const deployVersion = (chartName: e_ChartName, runtimeNames: string[], versionName: string) => ({
	type: versionDeploymentTypes.DEPLOY_VERSION,
	chartName,
	runtimeNames,
	versionName,
})

const validateDeployment = (chartName: e_ChartName, runtimeName: string, versionName: string) => ({
	type: versionDeploymentTypes.VALIDATE_DEPLOYMENT,
	chartName,
	runtimeName,
	versionName,
})

const abortDeployment = (runtimeNames: string[]) => ({
	type: versionDeploymentTypes.ABORT_DEPLOYMENT,
	runtimeNames,
})

const setChosenOperatingTypes = (chosenOperatingTypes: { origin: boolean; passive: boolean; active: boolean }) => ({
	type: versionDeploymentTypes.SET_CHOSEN_OPERATING_TYPES,
	chosenOperatingTypes,
})

const setChosenVersion = (chosenVersion: IContainerTag | undefined) => ({
	type: versionDeploymentTypes.SET_CHOSEN_VERSION,
	chosenVersion,
})

const setChosenVersionGroup = (chosenVersionGroup: string | undefined) => ({
	type: versionDeploymentTypes.SET_CHOSEN_VERSION_GROUP,
	chosenVersionGroup,
})

const setChosenOperatorVersion = (chosenOperatorVersion: IContainerTag | undefined) => ({
	type: versionDeploymentTypes.SET_CHOSEN_OPERATOR_VERSION,
	chosenOperatorVersion,
})

const setChosenOperatorVersionGroup = (chosenOperatorVersionGroup: string | undefined) => ({
	type: versionDeploymentTypes.SET_CHOSEN_OPERATOR_VERSION_GROUP,
	chosenOperatorVersionGroup,
})

const setRuntimeSelectedForConfiguration = (runtimeSelectedForConfiguration: string | undefined) => ({
	type: versionDeploymentTypes.SET_RUNTIME_SELECTED_FOR_CONFIGURATION,
	runtimeSelectedForConfiguration,
})

const setIsUnfinishedDeployDispatch = (isUnfinishedDeployDispatch: boolean) => ({
	type: versionDeploymentTypes.SET_IS_UNFINISHED_DEPLOY_DISPATCH,
	isUnfinishedDeployDispatch,
})

export const versionDeploymentActions = {
	setDeploymentVersions,
	setVersionOptions,
	setOperatorVersionOptions,
	fetchVersionOptions,
	setJobStates,
	fetchConfiguration,
	setVersionSchema,
	setRuntimeHelmValues,
	addRuntimeHelmValues,
	setRuntimeValueErrors,
	deployVersion,
	validateDeployment,
	abortDeployment,
	setChosenOperatingTypes,
	setChosenVersion,
	setChosenOperatorVersion,
	setChosenOperatorVersionGroup,
	setChosenVersionGroup,

	setRuntimeSelectedForConfiguration,

	setIsUnfinishedDeployDispatch,
}
