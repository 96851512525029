import type { SemanticColors } from '../semanticMapping'
import { cssVar } from '../../theming/themingManager'

export const generateButtonColorsFromSemanticPalette = (semanticPalette: SemanticColors) => {
	return {
		colors: {
			background: cssVar(semanticPalette.button, 'background'),
			border: cssVar(semanticPalette.button, 'border'),
			color: cssVar(semanticPalette.button, 'text'),

			inlineBackground: 'transparent',
			inlineBorder: 'transparent',
			inlineColor: cssVar(semanticPalette.button, 'inlineText'),

			primaryBackground: cssVar(semanticPalette.button, 'primary'),
			primaryBorder: cssVar(semanticPalette.button, 'primary'),
			primaryColor: cssVar(semanticPalette.button, 'primaryColor'),
		},
		hoverColors: {
			background: cssVar(semanticPalette.button, 'hoveredBackground'),
			border: cssVar(semanticPalette.button, 'border'),
			color: cssVar(semanticPalette.button, 'hoveredText'),

			inlineBackground: cssVar(semanticPalette.button, 'hoveredInlineBackground'),
			inlineBorder: 'transparent',
			inlineColor: cssVar(semanticPalette.button, 'hoveredInlineText'),

			primaryBackground: cssVar(semanticPalette.button, 'primaryHoverBackground'),
			primaryBorder: cssVar(semanticPalette.button, 'primaryHoverBackground'),
			primaryColor: cssVar(semanticPalette.button, 'primaryColor'),
		},
		focusedColors: {
			background: cssVar(semanticPalette.button, 'background'),
			border: cssVar(semanticPalette.button, 'focusBorder'),
			color: cssVar(semanticPalette.button, 'text'),
			outline: cssVar(semanticPalette.button, 'outline'),

			inlineBackground: 'transparent',
			inlineBorder: 'transparent',
			inlineColor: cssVar(semanticPalette.button, 'inlineText'),
			inlineOutline: cssVar(semanticPalette.button, 'outline'),

			primaryBackground: cssVar(semanticPalette.button, 'primary'),
			primaryBorder: cssVar(semanticPalette.button, 'primary'),
			primaryColor: cssVar(semanticPalette.button, 'primaryColor'),
			primaryOutline: cssVar(semanticPalette.button, 'background'),
		},
		pressedColors: {
			background: cssVar(semanticPalette.button, 'pressedBackground'),
			border: cssVar(semanticPalette.button, 'border'),
			color: cssVar(semanticPalette.button, 'pressedText'),

			inlineBackground: cssVar(semanticPalette.button, 'pressedInlineBackground'),
			inlineBorder: 'transparent',
			inlineColor: cssVar(semanticPalette.button, 'pressedInlineText'),

			primaryBackground: cssVar(semanticPalette.button, 'primaryPressedBackground'),
			primaryBorder: cssVar(semanticPalette.button, 'primaryPressedBackground'),
			primaryColor: cssVar(semanticPalette.button, 'primaryColor'),
		},
		checkedColors: {
			background: cssVar(semanticPalette.button, 'checkedBackground'),
			border: cssVar(semanticPalette.button, 'border'),
			color: cssVar(semanticPalette.button, 'checkedText'),

			inlineBackground: cssVar(semanticPalette.button, 'checkedInlineBackground'),
			inlineBorder: 'transparent',
			inlineColor: cssVar(semanticPalette.button, 'checkedInlineText'),

			primaryBackground: cssVar(semanticPalette.button, 'primaryCheckedBackground'),
			primaryBorder: cssVar(semanticPalette.button, 'primaryCheckedBackground'),
			primaryColor: cssVar(semanticPalette.button, 'primaryColor'),
		},
		checkedHoverColors: {
			background: cssVar(semanticPalette.button, 'checkedHoveredBackground'),
			border: cssVar(semanticPalette.button, 'border'),
			color: cssVar(semanticPalette.button, 'checkedHoveredText'),

			inlineBackground: cssVar(semanticPalette.button, 'checkedHoveredInlineBackground'),
			inlineBorder: 'transparent',
			inlineColor: cssVar(semanticPalette.button, 'checkedHoveredInlineText'),

			primaryBackground: cssVar(semanticPalette.button, 'primaryCheckedHoveredBackground'),
			primaryBorder: cssVar(semanticPalette.button, 'primaryCheckedBackground'),
			primaryColor: cssVar(semanticPalette.button, 'primaryColor'),
		},
		disabledColors: {
			background: cssVar(semanticPalette.button, 'disabledBackground'),
			border: cssVar(semanticPalette.button, 'disabledBorder'),
			color: cssVar(semanticPalette.button, 'disabledText'),

			inlineBackground: 'transparent',
			inlineBorder: 'transparent',
			inlineColor: cssVar(semanticPalette.button, 'disabledInlineText'),

			primaryBackground: cssVar(semanticPalette.button, 'disabledBackground'),
			primaryBorder: cssVar(semanticPalette.button, 'disabledBorder'),
			primaryColor: cssVar(semanticPalette.button, 'disabledText'),
		},
	}
}
