import mapValues from 'lodash/mapValues'

/**
 *
 * @param objs array of objects
 * @param getKey callback deciding propertyKey of entry
 * @param getValue optional callback deciding value of entry, defaults to obj if not provided
 * @returns record of the same objects
 */

export const recordFromArray = <T extends { [key in keyof T]: T[key] }, K extends PropertyKey, R = T>(
	objs: T[],
	getKey: (obj: T, index: number) => K,
	getValue?: (obj: T, index: number) => R
) =>
	objs.reduce(
		(prev, curr, index) => ({ ...prev, [getKey(curr, index)]: getValue === undefined ? curr : getValue(curr, index) }),
		{} as { [Key in K]: R }
	)

/**
 *
 * @param map
 * @param obj
 * @param objs rest argument for additional objects
 * @returns
 */
export const mapMultipleValues = <K extends PropertyKey, V, REST_V extends any[], R>(
	map: (value: V, ...values: REST_V) => R,
	obj: { [Key in K]: V },
	...objs: { [I in keyof REST_V]: { [Key in K]: REST_V[I] } }
) =>
	recordFromArray(
		Object.keys(obj).map((key) => [key, map(obj[key as K], ...(objs.map((obj) => obj[key as K]) as REST_V))] as [K, R]),
		([key, _]) => key,
		([_, value]) => value
	)

export const getKeysOfValidObjects = <K extends PropertyKey, V>(
	obj: { [Key in K]: V },
	isValid: (value: V) => boolean
) =>
	Object.entries<V>(obj)
		.filter(([_, value]) => isValid(value))
		.map(([key, _]) => key) as Extract<K, string>[]

export const typedKeysMapValues = <T extends { [key in keyof T]: T[key] }, R>(
	obj: T,
	map: (value: T[keyof T], key: keyof T) => R
) => mapValues(obj, (value, key) => map(value, key as keyof T))
