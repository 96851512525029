import semver from 'semver'

enum releaseStages {
	preAlpha = 'pre-alpha',
	alpha = 'alpha',
	beta = 'beta',
	rc = 'rc',
	release = 'release',
	custom = 'custom',
	latest = 'latest',
}

/**
 * returns -1 if a is greater than b, 1 if b is greater than a, 0 if equal
 */
export const semverCompare = <U = { [key: string]: string }>(
	a: string | U,
	b: string | U,
	compareKey: keyof U = 'version' as keyof U
) => {
	let rawVersionA
	let rawVersionB

	if (typeof a === 'string') {
		rawVersionA = a
	} else if (compareKey) {
		rawVersionA = a[compareKey] as unknown as string
	} else {
		throw new Error('Missing compareKey')
	}

	if (typeof b === 'string') {
		rawVersionB = b
	} else if (compareKey) {
		rawVersionB = b[compareKey] as unknown as string
	} else {
		throw new Error('Missing compareKey')
	}

	// Special treatment of latest
	if (rawVersionA.indexOf('latest') !== -1 && rawVersionB.indexOf('latest') === -1) {
		return -1
	} else if (rawVersionA.indexOf('latest') === -1 && rawVersionB.indexOf('latest') !== -1) {
		return 1
	} else if (rawVersionA === rawVersionB) {
		return 0
	}

	// Special treatment of feature
	if (rawVersionA.indexOf('feat') !== -1 && rawVersionB.indexOf('feat') === -1) {
		return -1
	} else if (rawVersionA.indexOf('feat') === -1 && rawVersionB.indexOf('feat') !== -1) {
		return 1
	} else if (rawVersionA === rawVersionB) {
		return 0
	}

	let versionA, versionB
	try {
		versionA = semver.coerce(rawVersionA)?.version
		if (!versionA) {
			throw new Error('Invalid version')
		}
	} catch (err) {
		throw new Error('Invalid version ' + rawVersionA)
	}
	try {
		versionB = semver.coerce(rawVersionB)?.version
		if (!versionB) {
			throw new Error('Invalid version')
		}
	} catch (err) {
		throw new Error('Invalid version ' + rawVersionB)
	}

	if (semver.gt(versionA, versionB)) {
		return -1
	} else if (semver.lt(versionA, versionB)) {
		return 1
	} else {
		const preReleaseA = semver.prerelease(rawVersionA)
		const preReleaseB = semver.prerelease(rawVersionB)
		if (!preReleaseA) {
			if (!preReleaseB) {
				return 0
			}
			return -1
		} else if (!preReleaseB) {
			if (!preReleaseA) {
				return 0
			}
			return 1
		}

		const releaseStageA = preReleaseA[0].toString()
		const buildNumberA = preReleaseA[1]
		const releaseStageB = preReleaseB[0].toString()
		const buildNumberB = preReleaseB[1]

		if (Object.keys(releaseStages).indexOf(releaseStageA) > Object.keys(releaseStages).indexOf(releaseStageB)) {
			return -1
		} else if (Object.keys(releaseStages).indexOf(releaseStageA) < Object.keys(releaseStages).indexOf(releaseStageB)) {
			return 1
		} else {
			if (buildNumberA > buildNumberB) {
				return -1
			} else if (buildNumberA < buildNumberB) {
				return 1
			} else {
				return 0
			}
		}
	}
}
