export const defaultIcons: { [key: string]: string } = {
	// Nav menu
	app: 'Fluent-AppIconDefault',
	page: 'Fluent-TextDocument',
	component: 'Fluent-Package',

	form: 'Fluent-TextDocument',
	view: 'Fluent-Table',
	canvas: 'Fluent-Favicon',
	analysis: 'Fluent-BarChartVerticalFilter',

	landingPage: 'Fluent-HomeSolid',
	web: 'Fluent-Globe',
	map: 'Fluent-Nav2DMapView',
	folder: 'Fluent-FolderHorizontal',
	unknown: 'Fluent-Unknown',

	kubernetes: 'Fluent-KeubernetesLogo', // Kubernetes er skrevet feil her, men skal visst være sånn

	metaModelManager: 'Fluent-MasterDatabase',
	versionDeployment: 'Fluent-KeubernetesLogo',

	maintenance: 'Basic-ToolWrench',
	tools: 'Construction-Tools',
	clock: 'Fluent-Clock',
	remove: 'Basic-RemoveButton',

	// Sitemap
	sitemapLevel1: 'Fluent-Section',
	sitemapLevel2: 'Fluent-GroupedList',
	sitemapLinkGroup: 'Fluent-TableGroup',
	sitemapLinkPage: 'Fluent-PreviewLink',
	sitemapLinkAnalysis: 'Fluent-BIDashboard',
	sitemapLinkUrl: 'Fluent-Link',
	desktopForm: 'Fluent-CloneToDesktop',
	desktopTable: 'Fluent-CloneToDesktop',

	// Drawers
	chevronDown: 'Fluent-ChevronDown',
	chevronUp: 'Fluent-ChevronUp',
	chevronLeft: 'Fluent-ChevronLeft',
	chevronRight: 'Fluent-ChevronRight',
	doubleChevronLeft: 'Fluent-DoubleChevronLeft',
	doubleChevronRight: 'Fluent-DoubleChevronRight',
	close: 'Fluent-ChromeClose',
	chevronUnfold: 'Fluent-ChevronUnfold10',
	chevronFold: 'Fluent-ChevronFold10',
	pin: 'Fluent-Pinned',
	unpin: 'Fluent-Unpin',
	move: 'Fluent-Move',

	expandHorizontal: 'Fluent-ScrollUpDown',

	// General
	action: 'Fluent-LightningBolt', //SetAction
	apps: 'Fluent-AppIconDefault',
	search: 'Fluent-Search',
	permissions: 'Fluent-Permissions',
	back: 'Fluent-Back',
	code: 'Fluent-Code',
	menu: 'Fluent-CollapseMenu',
	navBarButton: 'Fluent-GlobalNavButton',
	dropdownMenu: 'Fluent-MoreVertical',
	help: 'Fluent-Help',
	account: 'Fluent-PlayerSettings',
	settings: 'Fluent-Settings',
	logOut: 'Fluent-SignOut',
	fileTree: 'Fluent-BulletedTreeList',
	save: 'Fluent-Save',
	add: 'Fluent-Add',
	delete: 'Fluent-Delete',
	undo: 'Fluent-Undo',
	redo: 'Fluent-Redo',
	show: 'Fluent-RedEye',
	hide: 'Fluent-Hide',
	edit: 'Fluent-Edit',
	editReadOnly: 'Fluent-Uneditable',
	open: 'Fluent-OpenInNewWindow',
	rename: 'Fluent-Rename',
	check: 'Fluent-CheckMark',
	plus: 'Fluent-Add',
	minus: 'Fluent-Remove',
	grid: 'Fluent-LargeGrid',
	layout: 'Fluent-PreviewLink',
	eventHandler: 'Fluent-LightningBolt',
	filter: 'Fluent-Filter',
	more: 'Fluent-More',
	database: 'Fluent-Database',
	dataMart: 'Fluent-AnalyticsView',
	lightDarkMode: 'Fluent-CircleHalfFull',
	reset: 'Fluent-Reset',
	filterOptions: 'Fluent-FilterSettings',

	cut: 'Fluent-Cut',
	copy: 'Fluent-Copy',
	paste: 'Fluent-Paste',
	clone: 'Fluent-Copy',

	copyFormat: 'Fluent-Copy', //'Genus-ClipboardCopyFormat'
	pasteFormat: 'Fluent-Paste', //'Genus-ClipboardPasteFormat'

	copyTile: 'Fluent-Copy', //'Genus-ClipboardCopyComponent'
	pasteTile: 'Fluent-Paste', //'Genus-ClipboardPasteComponent'

	insertContent: 'Genus-InsertContent',
	controlView: 'Genus-ControlView',
	contentLayout: 'Genus-ContentLayout', // denne bør være layout orientert,
	properties: 'Genus-ContentLayout', // denne bør være properties orientert,

	//dialog types
	info: 'Fluent-Info',
	error: 'Fluent-Error',
	warning: 'Fluent-Warning',

	// control specific icons
	controlTableColumn: 'Fluent-Column',

	// Analysis Components
	analysis2Dgrid: 'Genus-2dgrid',
	analysis3Dgrid: 'Genus-3dgrid',
	analysisAreas: 'Genus-Areas',
	analysisBarChart: 'Genus-Barchart',
	analysisBarrel: 'Genus-Barrel',
	analysisBubbles: 'Genus-Bubbles',
	analysisCalendarHeatMap: 'Fluent-Calendar',
	analysisCategoryList: 'Genus-CategoryList',
	analysisChangeList: 'Genus-ChangeList',
	analysisCirclePacking: 'Genus-CirclePacking',
	analysisCircularHeatMap: 'Genus-CircularHeatmap',
	analysisCircularProgress: 'Genus-CircularProgress',
	analysisColumns: 'Genus-Columns',
	analysisDoughnut: 'Fluent-DonutChart',
	analysisEmpty: 'Fluent-BorderDot',
	analysisEventLine: 'Genus-EventLine',
	analysisFunnel: 'Genus-Funnel',
	analysisGauge: 'Genus-Gauge',
	analysisGridHeatMap: 'Genus-GridHeatMap',
	analysisHorizontalHistogram: 'Genus-Barchart',
	analysisLines: 'Genus-Lines',
	analysisLinesAreasAndColumns: 'Genus-LinesAreasAndColumns',
	analysisList: 'Fluent-BulletedList',
	analysisMap: 'Genus-Map',
	analysisMarkdown: 'Genus-Markdown',
	analysisMeasure: 'Genus-Measure',
	analysisMeasureChips: 'Genus-Transparent',
	analysisPictorialSizes: 'Fluent-CompareUneven',
	analysisPie: 'Fluent-PieDouble',
	analysisPyramid: 'Genus-Pyramid',
	analysisReportGrid: 'Fluent-GridViewSmall',
	analysisSankey: 'Genus-Sankey',
	analysisScatterPlot: 'Genus-ScatterPlot',
	analysisSelector: 'Genus-Selector',
	analysisSpeedometer: 'Genus-Speedometer',
	analysisSummary: 'Fluent-Label',
	analysisSunburst: 'Fluent-Sunny',
	analysisStackedAreas: 'Genus-StackedAreas',
	analysisStackedBars: 'Genus-StackedBars',
	analysisStackedColumns: 'Genus-StackedColumns',
	analysisStackedPercentageAreas: 'Genus-StackedPercentageAreas',
	analysisStackedPercentageBars: 'Genus-StackedPercentageBars',
	analysisStackedPercentageColumns: 'Genus-StackedPercentageColumns',
	analysisSymbolChart: 'Genus-SymbolChart',
	analysisTable: 'Fluent-Table',
	analysisTicker: 'Genus-ticker',
	analysisTimer: 'Fluent-Timer',
	analysisTimeLine: 'Fluent-Timeline',
	analysisTransposedTable: 'Genus-TransposedTable',
	analysisTreeMap: 'Fluent-FiveTileGrid',
	analysisVariableRadiusPie: 'Genus-VariableRadiusPie',
	analysisVariWide: 'Genus-VariWide',
	analysisWaterfall: 'Genus-Waterfall',
	analysisWordCloud: 'Genus-Wordcloud',
}
