export enum e_Interpretation {
	none = 'none',
	int = 'int',
	float = 'float',
	string = 'string',
	versionNumber = 'versionNumber',
	versionNumberStamp = 'versionNumberStamp',
	bool = 'bool',
	guid = 'guid',
	email = 'email',
	internetUrl = 'internetUrl',
	folderUrl = 'folderUrl',
	password = 'password',
	blob = 'blob',
	fileUrl = 'fileUrl',
	fileExt = 'fileExt',
	fileData = 'fileData',
	fileName = 'fileName',
	fileSize = 'fileSize',
	fileETag = 'fileETag',
	fileReadOnly = 'fileReadOnly',
	fileReference = 'fileReference',
	integerDateYYYY_MM = 'integerDateYYYY_MM',
	integerDateYYYYMMDD = 'integerDateYYYYMMDD',
	integerTime = 'integerTime',
	integerDateYYYY_MMDD = 'integerDateYYYY_MMDD',
	color = 'color',
	rtf = 'rtf',
	image = 'image',
	xml = 'xml',
	rtfExEmbeddedObjects = 'rtfExEmbeddedObjects',
	date = 'date',
	dateTime = 'dateTime',
	dateTimeUtc = 'dateTimeUtc',
	xhtml = 'xhtml',
	durationTicksMinutes = 'durationTicksMinutes',
	anyClassObjectReference = 'anyClassObjectReference',
	phone = 'phone',
	fileUnmanaged = 'fileUnmanaged',
	thumbnailFileData = 'thumbnailFileData',
	thumbnailFileSize = 'thumbnailFileSize',
	mediaDurationTicksMin = 'mediaDurationTicksMin',
	symbol = 'symbol',
	webSymbol = 'webSymbol',
	domainReference = 'domainReference',
}
