type ArrayType<A> = A extends (infer T)[] ? T : never
type ExtractOnType<T, U> = Extract<T, { type: U }>

export type YAMLLine = ArrayType<ReturnType<typeof parseYAMLLines>>

type ObjectToken = ExtractOnType<YAMLLine, 'object'>
type ValueToken = ExtractOnType<YAMLLine, 'value'>
type RemainderToken = ExtractOnType<YAMLLine, 'remainder'>
export type ValueLine = [ValueToken, ...RemainderToken[]]
export type KeyLine = [ObjectToken | ValueToken]

export const parseYAMLLines = (text: string) =>
	text
		.split(/\r?\n/u)
		.map((line, i) => parseLine(line, i))
		.filter((line) => line.type !== 'whitespace')

const REG_INDENT = '^( *)'
const REG_SEQUENCE = '(?:- )?'
const REG_KEY = '(\\S+)'
const REG_VALUE = '(?: *)([^#]+)?'
const REG_COMMENT = '(?:#.*)?'
const REG_REMAINDER = '([^#]*)'
const REG_ASSIGNMENT = `${REG_SEQUENCE}${REG_KEY}:${REG_VALUE}${REG_COMMENT}`
const REG_LINE = `${REG_INDENT}(?:${REG_ASSIGNMENT}|${REG_REMAINDER})`

type TOptionalString = string | undefined
type TMatchArray = [TOptionalString, TOptionalString, TOptionalString, TOptionalString, TOptionalString]
const UNDEFINED_MATCH = [undefined, undefined, undefined, undefined, undefined]

const parseLine = (str: string, index: number) => {
	const regPat = new RegExp(REG_LINE, 'g')
	const matches = str.matchAll(regPat)
	const match = matches.next().value as TMatchArray | undefined

	return formatParsedLine(index, str, match)
}

const formatParsedLine = (index: number, str: string, match?: TMatchArray) => {
	const [_, indent = '', key, value, remainder] = match ?? UNDEFINED_MATCH

	if (key && value) {
		return { type: 'value', index, indent, key, value, str } as const
	} else if (key) {
		return { type: 'object', index, indent, key, str } as const
	} else if (remainder) {
		return { type: 'remainder', index, indent, remainder, str } as const
	} else {
		return { type: 'whitespace' } as const
	}
}

//

export const getYAMLKeys = (valuePath: string) => valuePath.split('/').filter((key) => key !== '')
