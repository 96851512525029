import type { ChangeEvent } from 'react'
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'

import type { DropdownListMenuItem, IDropdownOption } from './Dropdown.types'
import { DropdownList } from './DropdownList'
import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'

import { DropdownOptionsHandler } from './DropdownOptionsHandler'
import { MultiValueInput } from '../MultiValueInput/'

import { useTranslation } from '../../translation'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'
import { useStyleContext } from '../utils/Style.context'
import { useId } from '../hooks/useId'
import { type e_ChipOverflow } from '../../enums/e_Overflow'
import { e_FilterOperator } from '../../enums/e_FilterOperator'
import type { IIconNames } from '@genusbiz/icon-set/dist/utils/IIconNames'

const classes = createStyle({
	clickableValue: {
		textDecoration: 'underline',
	},
	buttonWrapper: { display: 'flex' },
	hidden: { visibility: 'hidden' },
})

interface IDropdownInputMultiSelect<T> extends IFormControl {
	placeholder?: string
	defaultLabels?: { value: T; label: string; iconClassName?: IIconNames }[]
	isLoadingOptions?: boolean
	onOpen?: () => void
	onCtrlClick?: (e: React.MouseEvent, value: T | null | undefined) => void
	options?: IDropdownOption<T>[]
	filterOperator?: e_FilterOperator
	values?: T[]
	onChange?: (values: T[]) => void
	onClose?: () => void
	style?: React.CSSProperties
	className?: string
	inputClassName?: string
	reserveIconSpace?: boolean
	headerLeftItems?: DropdownListMenuItem[]
	headerRightItems?: DropdownListMenuItem[]
	footerLeftItems?: DropdownListMenuItem[]
	footerRightItems?: DropdownListMenuItem[]
	emptyListMessage?: string
	chipOverflow?: e_ChipOverflow
}

export function DropdownInputMultiSelect<T>(props: IDropdownInputMultiSelect<T>) {
	const { defaultLabels = [], filterOperator = e_FilterOperator.startsWith, onClose } = props

	const id = useId('dropdown-input-multi-select', props.id)
	const dropdownListId = `${id}-dropdown-input-multi-select-list`

	const { tcvi } = useTranslation()

	const values = useMemo(() => new Set<T | null>(props.values), [props.values])
	const options = useMemo(
		() => new DropdownOptionsHandler(props.options, false, tcvi, props.emptyListMessage),
		[props.emptyListMessage, props.options, tcvi]
	)

	const [userInput, setUserInput] = useState('')

	const [focusedOrHovered, setFocusedOrHovered] = useState(false)

	const [filteredOptions, setFilteredOptions] = useState(new DropdownOptionsHandler(props.options))
	const [isOpen, setIsOpen] = useState(false)
	const [isFiltering, setIsFiltering] = useState(false)

	const [activeDescendant, setActiveDescendant] = useState<string | undefined>(undefined)

	const displayValues = useMemo(() => {
		if (options?.loaded) {
			return options.getSelectedOptions(values)
		}

		return defaultLabels
	}, [options, values, defaultLabels])

	const inputRef = useRef<HTMLInputElement>(null)

	const anchorElementRef = useRef(null)

	const onOptionSelect = (value: T | null) => {
		if (value === null) {
			return
		}
		if (values.has(value)) {
			values.delete(value)
		} else {
			values.add(value)
		}

		if (props.onChange) {
			props.onChange(Array.from(values) as T[])
		}

		if (isFiltering) {
			closeDropdown()
		}

		setUserInput('')
		inputRef.current?.focus()
	}

	const handleClick = () => {
		if (isOpen) {
			closeDropdown()
		} else {
			openDropdown()
		}
		inputRef.current?.focus()
	}

	const onFocus = () => {
		inputRef.current?.select()
	}

	const onBlur = () => {
		closeDropdown()
	}

	const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setUserInput(e.target.value)
	}

	const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (props.readOnly || props.disabled) {
			return
		}

		if (e.key === 'ArrowDown') {
			openDropdown()
		}
	}

	const openDropdown = () => {
		if (!props.disabled) {
			props.onOpen?.()
			setIsOpen(true)
		}
	}

	const closeDropdown = useCallback(() => {
		onClose?.()
		setIsOpen(false)
	}, [onClose])

	useEffect(() => {
		if (userInput.length) {
			openDropdown()
			setIsFiltering(true)
		} else {
			setIsFiltering(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInput])

	useEffect(() => {
		if (isOpen) {
			if (filterOperator === e_FilterOperator.disabled) {
				setFilteredOptions(options)
			} else {
				setFilteredOptions(options.filter(userInput, filterOperator))
			}
		}
	}, [userInput, isOpen, filterOperator, options])

	const initialLoading = useRef(true)
	useEffect(() => {
		if (filteredOptions.length === 0 && isOpen) {
			if (initialLoading.current) {
				initialLoading.current = false
			} else {
				closeDropdown()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredOptions])

	const { dynamicsStyle, placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.field }

	const renderDropdownButton = !dynamicsStyle || (focusedOrHovered && !props.disabled && !props.readOnly)

	return (
		<React.Fragment>
			<FormControl
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				labelProps={props.labelProps}
				labelIcon={props.labelIcon}
				labelIconColor={props.labelIconColor}
				labelBold={props.labelBold}
				labelContentLayout={props.labelContentLayout}
				hideLabel={props.hideLabel}
				ref={anchorElementRef}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={props.subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disabled={props.disabled}
				readOnly={props.readOnly}
				required={props.required}
				error={props.error}
				warning={props.warning}
				className={props.className}
				stretch={props.stretch}
				disableBorder={props.disableBorder}
				onFocusedOrHovered={setFocusedOrHovered}
				screenTip={props.screenTip}
			>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
				)}
				<MultiValueInput
					id={id}
					dataAttributes={{
						...props.dataAttributes,
						placeholder: props.placeholder ?? placeholder,
					}}
					placeholder={props.placeholder}
					ref={inputRef}
					inputValue={userInput}
					tags={displayValues}
					onSelection={(selectedItemValues) => {
						props.onChange?.(selectedItemValues as T[])
					}}
					defaultAction={openDropdown}
					onMouseDown={(e) => isOpen && e.preventDefault()}
					onClick={handleClick}
					onInputFocus={onFocus}
					onInputBlur={onBlur}
					onInputChange={onInputChange}
					onKeyDown={onKeyDown}
					onCtrlClick={props.onCtrlClick}
					className={props.inputClassName}
					disabled={props.disabled}
					readOnly={props.readOnly}
					name={props.name}
					dropdownIsOpen={isOpen}
					dropdownListId={dropdownListId}
					activeDropdownItem={activeDescendant}
					chipOverflow={props.chipOverflow}
					showInputField={filterOperator !== e_FilterOperator.disabled}
				/>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
				)}

				<div className={classNames(classes.buttonWrapper, { [classes.hidden]: !renderDropdownButton })}>
					<FormControlButton
						disabled={props.disabled || props.readOnly}
						iconClassName="Fluent-ChevronDown"
						showSpinner={props.isLoadingOptions}
						onMouseDown={(e: React.MouseEvent) => isOpen && e.preventDefault()}
						onClick={handleClick}
						tabStop={false}
						iconSize="extraSmall" // to avoid massive chevron
						dataAttributes={props.dataAttributes}
					/>
				</div>
			</FormControl>
			<DropdownList
				isOpen={isOpen}
				anchorElement={anchorElementRef}
				options={filteredOptions}
				values={values}
				onChange={onOptionSelect}
				onClose={closeDropdown}
				style={props.style}
				className={props.className}
				reserveIconSpace={props.reserveIconSpace}
				multiSelect
				headerLeftItems={props.headerLeftItems}
				headerRightItems={props.headerRightItems}
				footerLeftItems={props.footerLeftItems}
				footerRightItems={props.footerRightItems}
				emptyListMessage={props.emptyListMessage}
				id={dropdownListId}
				onActiveItemChange={setActiveDescendant}
				dataAttributes={props.dataAttributes}
			/>
		</React.Fragment>
	)
}
