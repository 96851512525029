/*
	Color generation based on Fabric/Fluent color rules
	For reference on shade indicies see:
	https://github.com/OfficeDev/office-ui-fabric-react/blob/master/packages/office-ui-fabric-react/src/components/ThemeGenerator/ThemeRulesStandard.ts

	For reference on color manipulation see:
	https://github.com/OfficeDev/office-ui-fabric-react/blob/master/packages/office-ui-fabric-react/src/utilities/color/shades.ts
*/

import {
	hex2Rgb,
	rgb2Hsv,
	rgb2Hsl,
	hsv2Hex,
	clamp,
	isHexString,
	isRgbString,
	rgbString2Rgb,
	isNamedCSSColor,
	namedCSSColor2Hex,
	rgb2Hex,
} from './colorUtils'
import type { RGB, HSV, HSL, HEX } from './colorFormats.types'

const whiteShades = [0.537, 0.349, 0.216, 0.184, 0.145, 0.082, 0.043, 0.027]
const blackTints = [0.537, 0.45, 0.349, 0.216, 0.184, 0.145, 0.082, 0.043]

const lowLuminanceTints = [0.88, 0.77, 0.66, 0.55, 0.44, 0.33, 0.22, 0.11]
const highLuminanceShades = [0.11, 0.22, 0.33, 0.44, 0.55, 0.66, 0.77, 0.88]

const colorTints = [0.96, 0.84, 0.7, 0.4, 0.12]
const colorShades = [0.1, 0.24, 0.44]

const backgroundShades = [0.027, 0.043, 0.082, 0.145, 0.184, 0.216, 0.349, 0.537]
const backgroundTints = [0.537, 0.45, 0.349, 0.216, 0.184, 0.145, 0.082, 0.043]

export const primaryShadesIndices = {
	themeDarker: 8,
	themeDark: 7,
	themeDarkAlt: 6,
	themePrimary: 0,
	themeSecondary: 5,
	themeTertiary: 4,
	themeLight: 3,
	themeLighter: 2,
	themeLighterAlt: 1,
}

const foregroundShadesIndices = {
	black: 8,
	neutralDark: 7,
	neutralPrimary: 0,
	neutralPrimaryAlt: 5, // 6 -- old value,
	neutralSecondary: 4, // 5 -- old value,
	neutralTertiary: 3,
}

export const backgroundShadesIndices = {
	neutralTertiaryAlt: 6,
	neutralQuaternary: 5,
	neutralQuaternaryAlt: 4,
	neutralLight: 3,
	neutralLighter: 2,
	neutralLighterAlt: 1,
	white: 0,
}

const errorColorShadesIndices = {
	errorDark: 7,
	errorPrimary: 0,
	errorLight: 3,
}

const warningColorShadesIndices = {
	warningDark: 7,
	warningPrimary: 0,
	warningLight: 3,
}

const successColorShadesIndices = {
	successDark: 7,
	successPrimary: 0,
	successLight: 3,
}

const infoColorShadesIndices = {
	infoDark: 2,
	infoPrimary: 0,
	infoLight: 1,
}

export type Palette = RequiredValues<ReturnType<typeof paletteGenerator>>
type RequiredValues<T> = { [key in keyof T]: Exclude<T[key], undefined> }

export function paletteGenerator<
	T extends Partial<{
		primary?: HEX
		foreground?: HEX
		background?: HEX
		controlForeground?: HEX
		controlBackground?: HEX
		error?: HEX
		warning?: HEX
		success?: HEX
		info?: HEX
	}>,
>(colors: T) {
	const hexColors = convertColorsToHex(colors)
	const primary = generateColorsFromShades(hexColors.primary as T['primary'], primaryShadesIndices)
	const foreground = generateColorsFromShades(hexColors.foreground as T['foreground'], foregroundShadesIndices)
	const background = generateColorsFromShades(hexColors.background as T['background'], backgroundShadesIndices, true)
	const controlForeground = generateColorsFromShades(
		hexColors.controlForeground as T['foreground'],
		foregroundShadesIndices
	)
	const controlBackground = generateColorsFromShades(
		hexColors.controlBackground as T['background'],
		backgroundShadesIndices,
		true
	)
	const error = generateColorsFromShades(hexColors.error as T['error'], errorColorShadesIndices)
	const warning = generateColorsFromShades(hexColors.warning as T['warning'], warningColorShadesIndices)
	const success = generateColorsFromShades(hexColors.success as T['success'], successColorShadesIndices)
	const info = generateColorsFromShades(hexColors.info as T['info'], infoColorShadesIndices)

	return {
		primary,
		foreground,
		background,
		controlForeground,
		controlBackground,
		error,
		warning,
		success,
		info,
	}
}

function generateColorsFromShades<
	C extends string | undefined,
	S extends Record<string, number>,
	R = C extends undefined ? undefined : Record<keyof S, string>,
>(color: C, shades: S, isBackgroundColor?: boolean): R {
	if (!color) {
		return undefined as R
	}
	const shadeFn = isBackgroundColor ? createBackgroundColorByShade : createColorByShade
	const colors: Record<string, string> = {}
	Object.entries(shades).forEach(([name, shade]) => {
		colors[name] = shadeFn(color, shade)
	})
	return colors as R
}

export function createColorByShade(hex: HEX, shade: number) {
	if (shade === 0 || hex === 'transparent') {
		return hex
	}

	const shadeIndex = shade - 1
	const rgb = hex2Rgb(hex)
	const hsl = rgb2Hsl(rgb)

	let hsv = rgb2Hsv(rgb)

	if (isWhite(rgb)) {
		hsv = darken(hsv, whiteShades[shadeIndex])
	} else if (isBlack(rgb)) {
		hsv = lighten(hsv, blackTints[shadeIndex])
	} else if (hasHighLuminance(hsl)) {
		hsv = darken(hsv, highLuminanceShades[shadeIndex])
	} else if (hasLowLuminance(hsl)) {
		hsv = lighten(hsv, lowLuminanceTints[shadeIndex])
	} else {
		if (shadeIndex < colorTints.length) {
			hsv = lighten(hsv, colorTints[shadeIndex])
		} else {
			hsv = darken(hsv, colorShades[shadeIndex - colorTints.length])
		}
	}

	return hsv2Hex(hsv)
}

export function createBackgroundColorByShade(hex: HEX, shade: number) {
	if (shade === 0 || hex === 'transparent') {
		return hex
	}

	const shadeIndex = shade - 1
	const rgb = hex2Rgb(hex)
	const hsl = rgb2Hsl(rgb)
	let hsv = rgb2Hsv(rgb)

	if (isDark(hsl)) {
		hsv = lighten(hsv, backgroundTints[backgroundTints.length - 1 - shadeIndex])
	} else {
		hsv = darken(hsv, backgroundShades[shadeIndex])
	}

	return hsv2Hex(hsv)
}

function darken(hsv: HSV, factor: number) {
	return {
		h: hsv.h,
		s: hsv.s,
		v: clamp(hsv.v - hsv.v * factor, 0, 1),
	}
}

function lighten(hsv: HSV, factor: number) {
	return {
		h: hsv.h,
		s: clamp(hsv.s - hsv.s * factor, 0, 1),
		v: clamp(hsv.v + (1 - hsv.v) * factor, 0, 1),
	}
}

function hasHighLuminance(hsl: HSL) {
	return hsl.l > 0.8
}

function hasLowLuminance(hsl: HSL) {
	return hsl.l < 0.2
}

function isDark(hsl: HSL) {
	return hsl.l < 0.5
}

function isWhite(rgb: RGB) {
	return rgb.r === 255 && rgb.g === 255 && rgb.b === 255
}

function isBlack(rgb: RGB) {
	return rgb.r === 0 && rgb.g === 0 && rgb.b === 0
}

function convertColorsToHex<
	T extends Partial<{
		primary?: HEX
		foreground?: HEX
		background?: HEX
		controlBackground?: HEX
		error?: HEX
		warning?: HEX
		success?: HEX
		info?: HEX
	}>,
>(colors: T) {
	const hexColors: Record<string, string> = {}
	Object.entries(colors).forEach(([name, color]) => {
		if (color === undefined) {
			return
		}

		if (isHexString(color)) {
			hexColors[name] = color
			return
		}

		if (isRgbString(color)) {
			const rgb = rgbString2Rgb(color)
			hexColors[name] = rgb2Hex(rgb)
			return
		}

		if (isNamedCSSColor(color)) {
			hexColors[name] = namedCSSColor2Hex(color)
			return
		}

		if (color === 'transparent') {
			hexColors[name] = 'transparent'
			return
		}
	})

	return hexColors as Record<keyof T, HEX>
}
