export enum e_NavigationItem {
	runtimes = 'runtimes',
	workloads = 'workloads',
	endpoints = 'endpoints',
	availability = 'availability',
	eventLog = 'eventlog',
	errorLog = 'errorlog',
	metaModelManager = 'metamodelmanager',

	versionDeployment = 'version-deployment',
	versionDeploymentHelmConfiguration = 'version-deployment/helm-values',
	versionDeploymentLog = 'version-deployment/log',

	operatorVersionDeployment = 'operator-version-deployment',
	operatorVersionDeploymentHelmConfiguration = 'operator-version-deployment/helm-values',
	operatorVersionDeploymentLog = 'operator-version-deployment/log',
}
