import { DateTimeInput, Dialog, DurationInput, TextInput, DurationValueType } from '@genusbiz/web-ui/controls'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { defaultIcons } from 'src/consts/defaultIcons'
import { IMaintenanceConfiguration } from 'src/interfaces/IAvailabilityWindow'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { isValidUrl } from 'src/utils/urlUtils'
import moment from 'moment-timezone'
import { userSettingsSelectors } from 'src/features/UserSettings/duck/userSettingsSelectors'
import { createStyle } from 'src/theming'
import { TimezonePicker } from './TimezonePicker'
import { millisecondsToMinutes, minutesToMilliseconds } from './utils/timeUtils'

interface IMaintenanceEditorProps {
	maintenanceConfiguration: IMaintenanceConfiguration
	maintenanceEditorModalOpen: boolean
	onClose: () => void
}

type FormError = {
	externalURLError?: string
	startTimeError?: string
	durationError?: string
}

const classes = createStyle((theme) => ({
	container: {
		display: 'flex',
		margin: theme.spacing.size1,
		padding: theme.spacing.size1,
		flexDirection: 'column',
		justifyContent: 'space-around',
		width: '500px',
	},
	inputFieldContainer: {
		alignItems: 'center',
		paddingTop: theme.spacing.size1,
		paddingBottom: theme.spacing.size1,
	},
	buttonGroup: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	button: {
		marginLeft: theme.spacing.size1,
		marginTop: theme.spacing.size2,
	},
}))

export const MaintenanceEditor = (props: IMaintenanceEditorProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { maintenanceConfiguration, onClose, maintenanceEditorModalOpen } = props

	const selectedTimezone = useSelector(userSettingsSelectors.selectSelecedTimezone)
	const [timezone, setTimezone] = useState(selectedTimezone)
	const preferredFormattingCulture = useSelector(userSettingsSelectors.selectPreferredFormattingCulture)

	const initialValues = {
		startTime: maintenanceConfiguration?.startTime
			? moment.tz(maintenanceConfiguration.startTime, selectedTimezone).toISOString()
			: moment.tz(selectedTimezone).toISOString(),
		duration: maintenanceConfiguration?.durationInMinutes
			? minutesToMilliseconds(maintenanceConfiguration.durationInMinutes)
			: 0,
		externalURL: maintenanceConfiguration?.externalURL ?? '',
	}

	const [startTime, setStartTime] = useState<string | undefined>(initialValues.startTime)
	const [duration, setDuration] = useState<DurationValueType>(initialValues.duration)
	const [externalURL, setExternalURL] = useState(initialValues.externalURL)
	const [error, setError] = useState<FormError>({
		externalURLError: undefined,
		startTimeError: undefined,
		durationError: undefined,
	})

	const isEditing = initialValues.duration !== 0 && initialValues.externalURL !== ''

	const validateDuration = (value: DurationValueType) => {
		if (!value) {
			setError((prevError) => ({ ...prevError, durationError: t('MAINTENANCE:DURATION_EMPTY') }))
			return false
		}
		setError((prevError) => ({ ...prevError, durationError: undefined }))
		return true
	}

	const validateExternalURL = (value: string) => {
		if (!isValidUrl(value)) {
			setError((prevError) => ({ ...prevError, externalURLError: t('ENDPOINTS:INVALID_HOSTNAME') }))
			return false
		}
		setError((prevError) => ({ ...prevError, externalURLError: undefined }))
		return true
	}

	const validateStartTime = (value: string | undefined) => {
		if (!value) {
			setError((prevError) => ({ ...prevError, startTimeError: t('MAINTENANCE:START_TIME_EMPTY') }))
			return false
		}
		setError((prevError) => ({ ...prevError, startTimeError: undefined }))
		return true
	}

	const handleSubmit = () => {
		if (!validateDuration(duration)) {
			return
		}
		if (!validateExternalURL(externalURL)) {
			return
		}
		if (!validateStartTime(startTime)) {
			return
		}

		const newMaintenanceConfiguration: IMaintenanceConfiguration = {
			startTime: moment(startTime).tz(timezone, true).seconds(0).utc().toISOString(),
			durationInMinutes: millisecondsToMinutes(duration as number),
			externalURL: externalURL,
		}

		dispatch(kubernetesActions.setEnvironmentAvailabilityMaintenance(newMaintenanceConfiguration))

		dispatch(
			modalManagerActions.showNotificationPopup({
				message: isEditing ? t('MAINTENANCE:UPDATED') : t('MAINTENANCE:SCHEDULED'),
				icon: defaultIcons.check,
			})
		)
		onClose()
	}

	return (
		<Dialog
			isOpen={maintenanceEditorModalOpen}
			title={isEditing ? t('MAINTENANCE:EDIT_MAINTENANCE') : t('MAINTENANCE:SCHEDULE_MAINTENANCE')}
			modalClassName={classes.container}
			disableCancelOnClickOutside
			handleClose={onClose}
			buttonDescriptions={[
				{ title: t('GENERAL:SAVE'), onClick: handleSubmit, isEnabled: true, isDefault: true },
				{
					title: t('GENERAL:CANCEL'),
					onClick: onClose,
					isCancel: true,
					isEnabled: true,
				},
			]}
		>
			<TimezonePicker timezone={timezone} setTimezone={setTimezone} selectedTimezone={selectedTimezone} />
			<div className={classes.inputFieldContainer}>
				<DateTimeInput
					label={t('MAINTENANCE:START_TIME')}
					placeholder={moment().tz(timezone).locale(preferredFormattingCulture).format('L')}
					value={startTime}
					dateFormat={'dd/MM/yyyy'}
					onChange={(value) => setStartTime(value)}
					required
					error={error.startTimeError !== undefined}
					validationText={error.startTimeError}
					validationTextPosition="combineWithSubText"
					onBlur={(value) => validateStartTime(value)}
				/>
				<DurationInput
					label={t('MAINTENANCE:DURATION')}
					placeholder={'00:00'}
					onChange={(value) => setDuration(value)}
					value={duration}
					required
					error={error.durationError !== undefined}
					validationText={error.durationError}
					onBlur={(value) => validateDuration(value)}
				/>
				<TextInput
					label={t('MAINTENANCE:EXTERNAL_URL')}
					onChange={(value) => setExternalURL(value)}
					value={externalURL}
					placeholder="---"
					error={error.externalURLError !== undefined}
					validationText={error.externalURLError}
					required
					textType="url"
					screenTip={t('MAINTENANCE:EXTERNAL_URL_SCREENTIP')}
					onBlur={(value) => validateExternalURL(value)}
				/>
			</div>
		</Dialog>
	)
}
