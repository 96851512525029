import { e_VersionDeploymentMetod } from 'src/enums/e_VersionDeploymentMethod'

export const getVersionDeploymentEnabled: () => boolean = () => {
	if (process.env.NODE_ENV === 'development') {
		return process.env.VERSION_DEPLOYMENT_ENABLED === 'true'
	} else {
		return window.env.VERSION_DEPLOYMENT_ENABLED === 'true'
	}
}

export const getVersionDeploymentMethod: () => e_VersionDeploymentMetod = () => {
	if (process.env.NODE_ENV === 'development') {
		return process.env.VERSION_DEPLOYMENT_METHOD as e_VersionDeploymentMetod
	} else {
		return window.env.VERSION_DEPLOYMENT_METHOD as e_VersionDeploymentMetod
	}
}
