import React, { useState } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { convertToCase } from '../utils/convertToCase'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useId } from '../hooks/useId'
import { useStyleContext } from '../utils/Style.context'

import type { IFormControl } from '../FormControl'
import { FormControl, FormControlButton } from '../FormControl'
import { e_ReadOnlyIndicatorStyle } from '../../enums/e_ReadOnlyIndicatorStyle'
import { ReadOnlyIndicator } from '../FormControl/ReadOnlyIndicator'
import { type e_TextAlignment } from '../../enums/e_TextAlignment'
import { Icon } from '../Icon'
import type { IIconProps } from '../Icon'

const classes = createStyle((theme) => ({
	disableFlexGrow: { flexGrow: 0 },
	textAreaStretchHeight: {
		height: '100%',
		'& [class^="formControlContent"]': {
			height: '100%',
		},
	},
	textAreaStretchHeightLeftLabel: {
		'& > :last-child': { alignSelf: 'stretch' },
		'& label': { paddingTop: 4 },
	},
	alignRight: { textAlign: 'right' },
	alignCenter: { textAlign: 'center' },
	warning: { color: theme.colors.body.warningText + ' !important' },
	link: {
		textDecoration: 'underline',
		color: theme.colors.actionLinks.text,
		cursor: 'pointer',
	},
	buttonTop: {
		alignSelf: 'flex-start',
		height: 26,
	},
	input: {
		fontWeight: 'inherit',
	},
	alignIcon: {
		alignSelf: 'center',
		padding: '0px 4px',
	},
}))

enum linkFormatting {
	email = 'mailto:',
	phone = 'tel:',
}

enum e_inputModes {
	phone = 'tel',
	email = 'email',
	text = 'text',
	url = 'url',
}

function getResizeOption(option: 'none' | 'both' | 'height' | 'width' | undefined) {
	switch (option) {
		case 'none':
			return 'none'
		case 'both':
			return 'both'
		case 'width':
			return 'horizontal'
		case 'height':
			return 'vertical'
	}

	return undefined
}

const REGEX_DICTIONARY: { [key: string]: RegExp } = {}

const createRegExp = (prohibitedChars: string): RegExp => {
	const chars = prohibitedChars.split('')
	const joined = chars.join(',')
	const regExp = new RegExp('[' + joined + ']', 'g')

	REGEX_DICTIONARY[prohibitedChars] = regExp

	return regExp
}

const getRegExp = (prohibitedChars: string): RegExp => {
	return REGEX_DICTIONARY[prohibitedChars] || createRegExp(prohibitedChars)
}

const isProhibitedKey = (key: string, prohibitedChars: string) => {
	return prohibitedChars.includes(key)
}

const removeProhibitedChars = (value: string, prohibitedChars: string) => {
	return value.replace(getRegExp(prohibitedChars), '')
}

interface ITextInput extends IFormControl {
	value?: string
	casing?: 'upperCase' | 'lowerCase'
	onChange?: (value: string) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	onBlur?: (value: string) => void
	placeholder?: string
	textAlignment?: e_TextAlignment
	prohibitedChars?: string
	maxLength?: number
	openUrlInNewTab?: boolean
	actionButtonRef?: React.Ref<HTMLButtonElement>
	icon?: IIconProps
}

interface ISingleLineTextInput extends ITextInput {
	textType?: 'email' | 'phone' | 'url' | 'text' | 'password'
	widthChars?: number
	multiline?: false
	autoComplete?: string
	autoCapitalize?: 'none' | 'off' | 'on' | 'sentences' | 'words' | 'characters'
}

interface IMultilineTextInput extends ITextInput {
	stretchHeight?: boolean
	rows?: number
	multiline: true
	allowResize?: 'none' | 'both' | 'height' | 'width'
}

type InputTypes = HTMLInputElement | HTMLTextAreaElement

export const TextInput = React.forwardRef(
	(props: ISingleLineTextInput | IMultilineTextInput, forwardedRef: React.Ref<InputTypes>) => {
		const id = useId('text-input', props.id)
		const ref = useForwardedRef<InputTypes>(forwardedRef)

		const { openUrlInNewTab = true } = props

		const [showPassword, setShowPassword] = useState(false)

		const onChange = (e: React.ChangeEvent<InputTypes>) => {
			if (props.readOnly) {
				return
			}

			if (props.onChange) {
				const newValue = props.prohibitedChars
					? removeProhibitedChars(e.currentTarget.value, props.prohibitedChars)
					: e.currentTarget.value
				props.onChange(convertToCase(newValue, props.casing))
			}
		}

		const onPaste = (e: React.ClipboardEvent<InputTypes>) => {
			if (props.readOnly) {
				return
			}

			if (props.prohibitedChars && props.onChange && ref.current) {
				const clipboardText = removeProhibitedChars(e.clipboardData.getData('text'), props.prohibitedChars)

				const selectionStart = ref.current.selectionStart || 0

				ref.current.setRangeText(clipboardText)
				ref.current.setSelectionRange(selectionStart, selectionStart + clipboardText.length, 'forward')

				e.preventDefault()
				e.stopPropagation()
			}
		}

		const onKeyUp = (e: React.KeyboardEvent) => {
			if (e.key === 'Enter') {
				if (!props.multiline || e.ctrlKey) {
					props.defaultAction?.()
				}
			}
		}

		const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (props.prohibitedChars && isProhibitedKey(e.key, props.prohibitedChars)) {
				e.preventDefault()
				e.stopPropagation()
				return
			}

			props.onKeyDown?.(e)
		}

		const onBlur = (e: React.ChangeEvent<InputTypes>) => {
			if (props.readOnly) {
				return
			}

			props.onBlur?.(e.target.value)
		}

		const onClick = () => {
			if (props.defaultAction) {
				props.defaultAction()
				return
			}

			if (!props.value) {
				return
			}

			if (!props.multiline) {
				switch (props.textType) {
					case 'url': {
						const link =
							props.value?.startsWith('http://') || props.value?.startsWith('https://')
								? props.value
								: '//' + props.value
						window.open(link, openUrlInNewTab ? '_blank' : '_self')
						break
					}
					case 'email':
					case 'phone':
						props.value && window.open(linkFormatting[props.textType] + props.value)
				}
			}
		}

		const contentCSS = classNames(
			{
				[classes.alignRight]: props.textAlignment === 'right',
				[classes.alignCenter]: props.textAlignment === 'center',
			},
			classes.input
		)

		let buttonIconClassName
		if (!props.multiline) {
			switch (props.textType) {
				case 'url':
					buttonIconClassName = 'Fluent-Globe'
					break
				case 'phone':
					buttonIconClassName = 'Fluent-Phone'
					break
				case 'email':
					buttonIconClassName = 'Fluent-EditMail'
					break
				default:
					if (props.defaultAction) {
						buttonIconClassName = props.actionIcon === null ? null : props.actionIcon || 'Fluent-Forward'
					}
			}
		} else {
			if (props.defaultAction) {
				buttonIconClassName = props.actionIcon || 'Fluent-Forward'
			}
		}

		const formControlClassNames = classNames(props.className, {
			[classes.textAreaStretchHeight]: props.multiline && props.stretchHeight,
			[classes.textAreaStretchHeightLeftLabel]:
				props.multiline && props.stretchHeight && !props.hideLabel && props.labelPosition === 'left',
		})

		const formButtonClassNames = classNames(props.multiline && classes.buttonTop)

		const { placeholder, readOnlyIndicatorStyle } = { ...useStyleContext().style?.field }

		const iconClassName = classNames(props.icon?.className, classes.alignIcon)

		return (
			<FormControl
				id={id}
				disabled={props.disabled}
				label={props.label}
				labelPosition={props.labelPosition}
				labelProps={props.labelProps}
				labelIcon={props.labelIcon}
				labelIconColor={props.labelIconColor}
				labelBold={props.labelBold}
				labelContentLayout={props.labelContentLayout}
				hideLabel={props.hideLabel}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={props.subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				error={props.error}
				warning={props.warning}
				margin={props.margin}
				readOnly={props.readOnly}
				required={props.required}
				className={formControlClassNames}
				borderClassName={props.borderClassName}
				screenTip={props.screenTip}
				disableBorder={props.disableBorder}
				onClick={props.onClick}
				onMouseDown={props.onMouseDown}
				syncWidthProps={props.syncWidthProps}
			>
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolBeforeValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignLeft />
				)}
				{!props.multiline && (
					<input
						type={props.textType === 'password' && !showPassword ? 'password' : 'text'}
						inputMode={props.textType && props.textType !== 'password' ? e_inputModes[props.textType] : undefined}
						disabled={props.disabled}
						spellCheck="false"
						autoComplete={props.autoComplete || 'off'}
						autoCapitalize={
							props.autoCapitalize ||
							(props.textType && ['email', 'phone', 'url'].includes(props.textType) ? 'none' : undefined)
						}
						className={contentCSS}
						id={id}
						{...props.dataAttributes}
						ref={ref as React.Ref<HTMLInputElement>}
						size={props.widthChars}
						placeholder={props.placeholder ?? placeholder}
						value={convertToCase(props.value || '', props.casing) || ''}
						onChange={onChange}
						onPaste={onPaste}
						onKeyUp={onKeyUp}
						onKeyDown={onKeyDown}
						onBlur={onBlur}
						maxLength={props.maxLength}
						readOnly={props.readOnly}
						name={props.name}
					/>
				)}
				{props.multiline && (
					<textarea
						ref={ref as React.Ref<HTMLTextAreaElement>}
						placeholder={props.placeholder ?? placeholder}
						value={convertToCase(props.value || '', props.casing) || ''}
						spellCheck="false"
						autoComplete="off" // autoComplete="off" does now work with Chrome
						className={contentCSS}
						style={{
							whiteSpace: 'pre-wrap',
							resize: props.stretchHeight ? 'none' : getResizeOption(props.allowResize),
						}}
						id={id}
						{...props.dataAttributes}
						rows={props.rows}
						onChange={onChange}
						onPaste={onPaste}
						onBlur={onBlur}
						maxLength={props.maxLength}
						disabled={props.disabled}
						readOnly={props.readOnly}
						onKeyUp={onKeyUp}
					/>
				)}
				{readOnlyIndicatorStyle === e_ReadOnlyIndicatorStyle.displaySymbolAfterValue && (
					<ReadOnlyIndicator isReadOnly={props.readOnly === true} alignRight />
				)}

				{(buttonIconClassName === null || !!buttonIconClassName) && !props.hideActionButton && (
					<FormControlButton
						iconClassName={buttonIconClassName}
						screenTip={props.actionScreenTip}
						onClick={onClick}
						disabled={props.disabled}
						className={formButtonClassNames}
						ref={props.actionButtonRef}
						dataAttributes={props.dataAttributes}
					/>
				)}
				{!props.multiline && props.textType === 'password' && (
					<FormControlButton
						iconClassName={!showPassword ? 'Basic-Eye' : 'Basic-EyeNo'}
						screenTip={!showPassword ? 'Show password' : 'Hide password'}
						onClick={() => {
							setShowPassword((prevState) => !prevState)
						}}
						disabled={!props.value || props.disabled}
						className={formButtonClassNames}
						dataAttributes={props.dataAttributes}
					/>
				)}
				{props.icon && <Icon {...props.icon} className={iconClassName} />}
			</FormControl>
		)
	}
)

TextInput.displayName = 'TextInput'
