import React from 'react'
import { IMaintenanceConfiguration } from 'src/interfaces/IAvailabilityWindow'
import { resolveMaintenanceState, resolveMaintenanceStatusIcon } from './utils/maintenanceHelpers'
import { useDispatch, useSelector } from 'react-redux'
import { defaultColors } from 'src/consts/defaultColors'
import { Icon, Text } from '@genusbiz/web-ui/controls'
import { createStyle } from 'src/theming'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { useTranslation } from 'react-i18next'
import { kubernetesActions } from 'src/features/Kubernetes/duck/kubernetesActions'
import { MaintenanceStatusDetails } from './MaintenanceStatusDetails'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { defaultIcons } from 'src/consts/defaultIcons'

interface IMaintenanceStatusProps {
	maintenanceConfiguration: IMaintenanceConfiguration
	setNewMaintenanceEditorModalOpen: (open: boolean) => void
}
const classes = createStyle((theme) => ({
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: theme.spacing.size2,
	},
	icon: {
		marginLeft: theme.spacing.size1,
	},
}))

export const MaintenanceStatus = (props: IMaintenanceStatusProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { maintenanceConfiguration, setNewMaintenanceEditorModalOpen } = props
	const maintenanceState = resolveMaintenanceState(maintenanceConfiguration)
	const runtimes = useSelector(kubernetesSelectors.selectK8sRuntimes)
	const iconClassName = resolveMaintenanceStatusIcon(maintenanceState)

	const confirmCancelMaintenance = () => {
		dispatch(
			modalManagerActions.showConfirmationModal({
				title: t('MAINTENANCE:CANCEL_TITLE'),
				confirmText: t('GENERAL:YES'),
				cancelText: t('GENERAL:CANCEL'),
				message: t('MAINTENANCE:CANCEL_TEXT_PROMPT'),
				onConfirm: () => {
					dispatch(
						kubernetesActions.setEnvironmentAvailabilityMaintenance({
							startTime: null,
							durationInMinutes: null,
							externalURL: null,
						})
					)
					dispatch(
						modalManagerActions.showNotificationPopup({
							message: t('MAINTENANCE:CANCELLED'),
							icon: defaultIcons.check,
						})
					)
				},
			})
		)
	}

	return (
		<>
			<div className={classes.header}>
				<Text variant="h2">{t('MAINTENANCE:MAINTENANCE')}</Text>
				<Icon className={classes.icon} iconClassName={iconClassName} size="size24" color={defaultColors.successText} />
			</div>
			<MaintenanceStatusDetails
				maintenanceConfiguration={maintenanceConfiguration}
				setNewMaintenanceEditorModalOpen={setNewMaintenanceEditorModalOpen}
				confirmCancelMaintenance={confirmCancelMaintenance}
				runtimes={runtimes}
				maintenanceState={maintenanceState}
			/>
		</>
	)
}
