import React from 'react'
import { FieldContainer } from '@genusbiz/web-ui/surfaces/FieldContainer/FieldContainer'
import { Button, Text } from '@genusbiz/web-ui/controls'
import { StateIcon } from './StateIcon'
import { createStyle } from 'src/theming'
import { e_DeploymentJobState } from 'src/enums/e_DeploymentJobState'
import { useTranslation } from 'react-i18next'
import { commonStyles } from '../utils/commonStyle'

const classes = createStyle((theme) => ({
	configItemText: {
		width: '180px',
		margin: theme.spacing.size1,
	},
	configItemContainer: {
		display: 'flex',
		alignItems: 'center',
	},
}))

const FIELD_CONTAINER_STYLE_PROPS = { direction: 'row', gap: 18, alignItems: 'center' } as const

interface IDeploymentStatusFieldProps {
	label: string
	state: e_DeploymentJobState
	abort: () => void
}

export const DeploymentStatusField = (props: IDeploymentStatusFieldProps) => {
	const { t } = useTranslation()

	return (
		<div className={classes.configItemContainer}>
			<Text variant="body1" className={classes.configItemText}>
				{props.label}
			</Text>
			<FieldContainer groupId="all" {...FIELD_CONTAINER_STYLE_PROPS}>
				<StateIcon state={props.state} />
				<Button className={commonStyles.classes.smallButton} onClick={props.abort} disabled={!isDeploying(props.state)}>
					{t('UPGRADE:ABORT')}
				</Button>
			</FieldContainer>
		</div>
	)
}

const isDeploying = (state: e_DeploymentJobState) =>
	state === e_DeploymentJobState.inProgress || state === e_DeploymentJobState.pending
