import React, { useRef } from 'react'
import { createStyle } from '../../theming'
import type { MenuItemAction } from './MenuItem.types'
import { MenuItemContent } from './MenuItemContent'
import { useFocusSelected } from './hooks/useFocusSelected'
import { generateMenuScreenTip } from './generateMenuScreenTip'
import { getKeyboardShortcutText } from '../utils/keyboard/getKeyboardShortcutText'

const classes = createStyle({
	item: {
		display: 'flex',
		'&:focus': {
			outline: 'none',
		},
	},
})

interface IPopupMenuItemProps {
	menuIndex: number
	item: MenuItemAction

	isSelected: boolean
	iconColorClass?: string
	onActivate?: (e: React.MouseEvent | React.KeyboardEvent) => void
}

export const PopupMenuItem = (props: IPopupMenuItemProps) => {
	const menuItem = props.item

	const menuItemRef = useRef<HTMLDivElement>(null)

	useFocusSelected(props.isSelected, menuItemRef)

	if (menuItem.hidden) {
		return null
	}

	const handleActivate = (e: React.MouseEvent | React.KeyboardEvent) => {
		menuItem.onClick?.(e)

		if (props.onActivate && !e.isPropagationStopped()) {
			props.onActivate(e)
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent) => {
		// We should only handle the menu item specific keys here, the panel takes care of closing end navigating between items
		if (e.key === 'Enter') {
			e.preventDefault()
			handleActivate(e)
			e.stopPropagation()
		}
	}

	const handleItemClick = (e: React.MouseEvent) => {
		e.preventDefault()
		handleActivate(e)
		e.stopPropagation()
	}

	return (
		<div
			className={classes.item}
			id={menuItem.id}
			onClick={!menuItem.disabled ? handleItemClick : undefined}
			onKeyDown={!menuItem.disabled ? handleKeyDown : undefined}
			ref={menuItemRef}
			tabIndex={0}
			title={generateMenuScreenTip(props.item.screenTip, props.item.keyboardShortcut)}
			{...menuItem.dataAttributes}
		>
			<MenuItemContent
				iconClassName={menuItem.iconClassName}
				iconString={menuItem.iconString}
				displayText={menuItem.name}
				keyboardShortcutText={getKeyboardShortcutText(props.item.keyboardShortcut)}
				iconColorClass={props.iconColorClass}
				isSelected={props.isSelected}
				isChecked={menuItem.isChecked}
				disabled={menuItem.disabled}
				contentLayout={menuItem.contentLayout}
			/>
		</div>
	)
}
