import { useEffect, useRef } from 'react'
import { topWindow } from '../../utils/topWindow'

export const useResetPreviousFocusOnDismount = () => {
	const previousActiveElement = useRef<HTMLElement | null>(topWindow.document.activeElement as HTMLElement | null)

	useEffect(() => {
		previousActiveElement.current = topWindow.document.activeElement as HTMLElement | null
		return () => previousActiveElement.current?.focus()
	}, [])
}
