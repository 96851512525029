import { e_ThemeColors } from '../enums/e_ThemeColors'

export const defaultColors = {
	[e_ThemeColors.primary]: '#0078d4',
	[e_ThemeColors.foreground]: '#323130',
	[e_ThemeColors.background]: '#ffffff',
	[e_ThemeColors.controlForeground]: '#323130',
	[e_ThemeColors.controlBackground]: '#ffffff',
	[e_ThemeColors.success]: '#10c251',
	[e_ThemeColors.warning]: '#e8b828',
	[e_ThemeColors.error]: '#c21018',
	[e_ThemeColors.info]: '#fff7b7',
} as const

export const defaultDarkColors = {
	[e_ThemeColors.primary]: '#0078d4',
	[e_ThemeColors.foreground]: '#ffffff',
	[e_ThemeColors.background]: '#0f0f0f',
	[e_ThemeColors.controlForeground]: '#ffffff',
	[e_ThemeColors.controlBackground]: '#0f0f0f',
	[e_ThemeColors.success]: '#10c251',
	[e_ThemeColors.warning]: '#e8b828',
	[e_ThemeColors.error]: '#c21018',
	[e_ThemeColors.info]: '#fff7b7',
} as const

export const defaultBorderRadius = 2

export const fontSizes = {
	super: 42,
	extraExtraLarge: 28,
	extraLargePlus: 24,
	extraLarge: 20,
	large: 17,
	mediumPlus: 15,
	medium: 14,
	smallPlus: 13,
	small: 12,
	extraSmall: 11,
	minimum: 10,
}

export const fontWeights = {
	light: 100,
	semilight: 300,
	regular: 400,
	semibold: 600,
	bold: 700,
}

export const DEFAULT_FONT_FAMILY =
	'"Segoe UI Web (West European)", "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif'
export const DEFAULT_FONT_SIZE = 14

export const defaultTypography = {
	h1: { fontSize: 32, fontWeight: fontWeights.semibold },
	h2: { fontSize: 28, fontWeight: fontWeights.semibold },
	h3: { fontSize: 24, fontWeight: fontWeights.semibold },
	h4: { fontSize: 20, fontWeight: fontWeights.semibold },
	h5: { fontSize: 16, fontWeight: fontWeights.semibold },
	h6: { fontSize: 14, fontWeight: fontWeights.semibold },
	subtitle1: { fontSize: 14, fontWeight: fontWeights.semibold },
	subtitle2: { fontSize: 12, fontWeight: fontWeights.semibold },
	body1: { fontSize: 14, fontWeight: fontWeights.regular },
	body2: { fontSize: 14, fontWeight: fontWeights.regular },
	button: { fontSize: 14, fontWeight: fontWeights.semibold },
	caption: { fontSize: 12, fontWeight: fontWeights.regular },
	largeTitle: { fontSize: 40, fontWeight: fontWeights.semibold },
	display: { fontSize: 68, fontWeight: fontWeights.semibold },
}
