import React from 'react'
import { useSelector } from 'react-redux'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { HelmConfiguration } from '../components/HelmConfiguration'
import { operatorDeploymentUtils } from '../utils/operatorDeploymentUtils'
import { deploymentUtils } from '../utils/deploymentUtils'
import { useDeploymentNavigation } from '../utils/deploymentNavigation'
import { e_ChartName } from 'src/interfaces/IContainerRegistry'

export const OperatorHelmConfiguration = () => {
	const versionName = useSelector(versionDeploymentSelectors.selectChosenOperatorVersion)?.name
	const runtimeName = operatorDeploymentUtils.useOperatorRuntime().name

	const deploymentNavigation = useDeploymentNavigation()

	const isUndefinedConfigurationParameters = !versionName || !runtimeName
	deploymentNavigation.useNavgiateToOperatorConditionally(isUndefinedConfigurationParameters)

	const { schema, values } = deploymentUtils.useHelmValuesAndVersionSchema(
		e_ChartName.genusOperator,
		runtimeName ?? '',
		versionName ?? ''
	)

	const versionSchema = schema?.versionName !== undefined && schema.schema === undefined ? false : schema?.schema

	if (versionName && runtimeName) {
		return (
			<HelmConfiguration
				versionName={versionName}
				runtimeName={runtimeName}
				schema={versionSchema}
				values={values}
				chartName={e_ChartName.genusOperator}
				showMain={deploymentNavigation.showOperatorDeployment}
			/>
		)
	}
}
