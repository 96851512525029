import type { Palette } from '../themeProperties/colors/paletteGenerator'
import { mapSemanticColorsFromPalette } from '../semanticMapping'
import type { SemanticColors } from '../semanticMapping'
import { hex2Hsl } from '../themeProperties/colors/colorUtils'
import { cssVar } from '../themingManager'

export type NavPaneColors = ReturnType<typeof generateNavigationPaneColorsFromPalette>

export const generateNavigationPaneColorsFromPalette = (palette: Palette, semanticPalette?: SemanticColors) => {
	const semanticColors = semanticPalette ?? mapSemanticColorsFromPalette(palette)
	const { primary, foreground, background } = palette

	const backgroundIsDark = hex2Hsl(background.white).l < 0.5

	const hoverColor = backgroundIsDark ? primary.themeTertiary : primary.themePrimary
	return {
		colors: {
			background: background.neutralLighter,
			color: foreground.neutralPrimary,
			border: cssVar(semanticColors.panel, 'border'),
		},
		hoverColors: {
			background: backgroundIsDark ? background.neutralQuaternaryAlt : background.neutralLight,
			color: hoverColor,
		},
		focusedColors: {
			background: backgroundIsDark ? primary.themeDarkAlt : background.neutralLight,
			color: undefined,
		},
		pressedColors: {
			background: backgroundIsDark ? background.neutralQuaternary : background.neutralQuaternaryAlt,
			color: hoverColor,
		},
		checkedColors: {
			background: backgroundIsDark ? background.neutralQuaternary : background.white,
			color: undefined,
		},
		checkedHoverColors: {
			background: backgroundIsDark ? background.neutralTertiaryAlt : background.neutralLight,
			color: hoverColor,
		},
		disabledColors: {
			background: undefined,
			color: undefined,
		},
	}
}
