import { ErrorObject } from 'ajv'
import { recordFromArray } from './utils'
import { getYAMLKeys, type KeyLine, parseYAMLLines, type ValueLine, type YAMLLine } from './parseYAMLLines'
import { getLineSpanOfSubString, spreadFromTo } from './CodeMirrorUtils'

export const getErrorHighlights = (text: string | undefined, errors: ErrorObject[]) => {
	if (!text) {
		return
	}

	return _getErrorHighlights(text, errors)
}

type TokenLine = YAMLLine | EndToken
type EndToken = { type: 'END' }

const END_OF_TEXT = { type: 'END' as const }

const _getErrorHighlights = (text: string, errors: ErrorObject[]) => {
	const lines = [...parseYAMLLines(text), END_OF_TEXT]

	const highlights = [] as ReturnType<typeof spreadFromTo>[]

	for (const error of errors) {
		const path = error.instancePath

		switch (error.keyword) {
			case 'required': {
				const keys = getYAMLKeys(path)
				const machine = getKeyMachine(keys)

				const result = iterateThroughStateMachine(lines, machine) as { index: number; value: KeyLine } | undefined

				if (result?.index === SUCCESS_TOKEN) {
					const [statement] = result.value

					highlights.push(getLineSpanOfSubString(statement.index, statement.str, statement.key))
				}
				break
			}
			case 'additionalProperties': {
				const additionalProperty = error.params.additionalProperty as string
				const keys = getYAMLKeys(path + `/${additionalProperty}`)
				const machine = getKeyMachine(keys)

				const result = iterateThroughStateMachine(lines, machine) as { index: number; value: KeyLine } | undefined

				if (result?.index === SUCCESS_TOKEN) {
					const [statement] = result.value

					highlights.push(getLineSpanOfSubString(statement.index, statement.str, statement.key))
				}
				break
			}
			case 'type': {
				const keys = getYAMLKeys(path)
				const machine = getValueMachine(keys)

				const result = iterateThroughStateMachine(lines, machine) as { index: number; value: ValueLine } | undefined

				if (result?.index === SUCCESS_TOKEN) {
					const [statement, ...remainders] = result.value

					highlights.push(getLineSpanOfSubString(statement.index, statement.str, statement.value ?? ''))
					remainders.forEach((line) => highlights.push(getLineSpanOfSubString(line.index, line.str, line.remainder)))
				}
				break
			}
		}
	}

	return highlights
}

// State Machines used for iterating through tokenized lines

const ABORT_TOKEN = -1
const SUCCESS_TOKEN = -2

const getValueMachine = (keys: string[]) => {
	const keyTransitionFunctions = keys.map((key, index) => formatKeyTransition(transitionKey(index, key)))
	const transitionFunctions = [...keyTransitionFunctions, formatRemainderTransition(transitionRemainder(keys.length))]

	return getMachine(transitionFunctions)
}

const getKeyMachine = (keys: string[]) => {
	const keyTransitionFunctions = keys.map((key, index) => formatKeyTransition(transitionKey(index, key)))
	const transitionFunctions = [...keyTransitionFunctions, formatRemainderTransition(() => SUCCESS_TOKEN)]

	return getMachine(transitionFunctions)
}

const getMachine = (
	transitionFunctions: ((
		line: TokenLine,
		values?: TokenLine[]
	) => {
		index: number
		value: TokenLine[]
	})[]
) => ({
	transitions: recordFromArray(
		transitionFunctions,
		(_, index) => index,
		(transition) => transition
	),
	endTokens: [ABORT_TOKEN, SUCCESS_TOKEN],
})

const INDENT_UNIT_LENGTH = 2

const transitionKey = (index: number, targetKey: string) => _transitionKey(index, index * INDENT_UNIT_LENGTH, targetKey)

const _transitionKey = (index: number, targetIndentLength: number, targetKey: string) => (line: TokenLine) => {
	if (line.type === 'END') {
		return ABORT_TOKEN
	}

	if (line.indent.length < targetIndentLength) {
		return ABORT_TOKEN
	}

	if (line.indent.length === targetIndentLength && targetKey === line?.key) {
		return index + 1
	}

	return index
}

const transitionRemainder = (index: number) => _transitionRemainder(index, index * INDENT_UNIT_LENGTH)

const _transitionRemainder = (index: number, targetIndentLength: number) => (line: TokenLine) => {
	if (line.type === 'END') {
		return SUCCESS_TOKEN
	}

	if (line.indent.length < targetIndentLength) {
		return SUCCESS_TOKEN
	}

	if (line.type !== 'remainder') {
		return SUCCESS_TOKEN
	}

	return index
}

const formatKeyTransition = (getNextState: (line: TokenLine) => number) => (line: TokenLine) => ({
	index: getNextState(line),
	value: [line],
})

const formatRemainderTransition =
	(getNextState: (line: TokenLine) => number) => (line: TokenLine, values?: TokenLine[]) => {
		const index = getNextState(line)
		const value = index === SUCCESS_TOKEN ? (values ?? []) : appendValue(line, values)

		return {
			index,
			value,
		}
	}

const appendValue = <T>(newValue: T, values?: T[]) => [...(values ?? []), newValue]

// State machine iterator

interface IStateMachine<I, T> {
	transitions: Record<number, (line: I, value?: T) => { index: number; value: T }>
	endTokens: number[]
}

const iterateThroughStateMachine = <I, T>(
	iterator: Generator<I, unknown, unknown> | I[],
	machine: IStateMachine<I, T>
) => {
	let index = 0
	let value: T | undefined

	for (const iteration of iterator) {
		const state = machine.transitions[index](iteration, value)

		index = state.index
		value = state.value

		if (machine.endTokens.includes(index)) {
			return state
		}
	}
}
