import type { ReactNode } from 'react'
import React from 'react'

import { CustomHTML5Backend } from './CustomHTML5Backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { MouseTransition, TouchTransition, DndProvider, type MultiBackendOptions } from 'react-dnd-multi-backend'
import { CustomDragPreview } from './CustomDragPreview'

interface IDragDropProvider {
	children?: ReactNode | ReactNode[]
}

export const DragDropProvider = (props: IDragDropProvider) => {
	const options: MultiBackendOptions = {
		backends: [
			{
				id: 'html5',
				backend: CustomHTML5Backend,
				transition: MouseTransition, // Awaiting upgrade of Dnd solution for proper typing
			},
			{
				id: 'touch',
				backend: TouchBackend,
				options: {
					enableMouseEvents: true,
					delayTouchStart: 200,
					ignoreContextMernu: true,
				},
				preview: true,
				transition: TouchTransition, // Awaiting upgrade of Dnd solution for proper typing
			},
		],
	}

	return (
		<>
			{/* @ts-error  Awaiting package update before fix. typing mismatch between beginDrag in interfaces of @types.. dnd-multi-backend.dnd core and dnd-core (optional parameter in latter).*/}
			<DndProvider options={options}>{props.children}</DndProvider>
			{/* The Preview Element used on dataTransfer needs to already be rendered in the viewport*/}
			<CustomDragPreview />
		</>
	)
}
