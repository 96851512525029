import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { IAvailability, IEnvironmentAvailabilitySchedule } from 'src/interfaces/IAvailabilityWindow'
import { IOperatorRootState } from 'src/reducers'
import { getDurationBetweenWeekdaysAndTimes, getWeekdayFromRelativeDay } from './dateTimeUtils'
import { generateGuid } from './generateGuid'

export const serializeEnvironmentAvailabilitySchedule = (
	state: IOperatorRootState
): IEnvironmentAvailabilitySchedule => {
	const environmentAvailabilitySchedule = kubernetesSelectors.selectEnvironmentAvailabilitySchedule(state)

	return {
		id: environmentAvailabilitySchedule.id,
		bufferConfiguration: environmentAvailabilitySchedule.bufferConfiguration,
		maintenanceConfiguration: environmentAvailabilitySchedule.maintenanceConfiguration,
		environmentAvailabilities: environmentAvailabilitySchedule.environmentAvailabilities.map((availability) => {
			const availabilityWindows: IAvailability[] = []
			availability.availabilityGroups.forEach((availabilityGroup) => {
				availabilityGroup.fromDays.forEach((fromDay) => {
					const to = {
						weekDayName: getWeekdayFromRelativeDay(fromDay, availabilityGroup.toDay),
						time: availabilityGroup.toTime,
						timezone: availabilityGroup.timezone || 'UTC',
					}
					const from = {
						weekDayName: fromDay,
						time: availabilityGroup.fromTime,
						timezone: availabilityGroup.timezone || 'UTC',
					}
					const durationInMinutes = getDurationBetweenWeekdaysAndTimes(from, to)
					const id = availabilityGroup.windowIds[fromDay] || generateGuid()
					availabilityWindows.push({
						comment: availabilityGroup.comment,
						id: id,
						durationInMinutes: durationInMinutes,
						from: from,
						to: to,
						windowType: availabilityGroup.windowType,
						groupId: availabilityGroup.id,
						enabled: availabilityGroup.enabled,
					})
				})
			})
			return {
				...availability,
				availabilityWindows: availabilityWindows,
			}
		}),
	}
}
