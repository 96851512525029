import type { MouseEvent } from 'react'
import { useCallback, useRef } from 'react'
import isEqual from 'lodash/isEqual'

// AG Grid
import type {
	CellContextMenuEvent,
	CellClickedEvent,
	CellKeyDownEvent,
	CellMouseDownEvent,
	CellPosition,
	ColDef,
	ColGroupDef,
	Column,
	ColumnMovedEvent,
	ColumnPinnedEvent,
	ColumnResizedEvent,
	ColumnRowGroupChangedEvent,
	ColumnState,
	ColumnVisibleEvent,
	ExpandCollapseAllEvent,
	FilterChangedEvent,
	FirstDataRenderedEvent,
	GetDataPath,
	GetLocaleTextParams,
	GetMainMenuItemsParams,
	GetRowIdFunc,
	GridApi,
	GridReadyEvent,
	IRowNode,
	IsGroupOpenByDefaultParams,
	NavigateToNextCellParams,
	PostSortRowsParams,
	ProcessCellForExportParams,
	RowClickedEvent,
	RowDoubleClickedEvent,
	RowGroupOpenedEvent,
	SelectionChangedEvent,
	SortChangedEvent,
} from '@ag-grid-community/core'

// Utils
import { getCheckboxState } from './getCheckboxState'
import { setHeaderHeight } from './setHeaderHeight'
import { tDataToRowData } from './dataConverting'
import { useUpdateGroupCheckMark } from '../hooks'
import { refreshCheckMarkColumn, createSetSelectionMouse, setInitialSelection } from './selectionUtils'

// Hooks
import { useSearchForNode } from '../hooks/useSearchForNode'

// Storage utils
import {
	deleteExpandedRowNodesFromSessionStorage,
	readColumnStateFromLocalStorage,
	readFilterModelFromSessionStorage,
	readExpandedRowNodesFromSessionStorage,
	writeFilterModelToSessionStorage,
	writeExpandedRowNodesToSessionStorage,
	deleteColumnStateFromLocalStorage,
	deleteFilterModelFromSessionStorage,
} from './storageUtils'

// Enums and Interfaces
import type { e_InitialSelection } from '../enums/e_InitialSelection'
import type {
	CellData,
	ColumnWidthType,
	ICellRendererProps,
	IGroupingField,
	ISortedField,
	ITableApi,
	ITableContextRef,
	ITableRowData,
	ProcessCellCallbackArgs,
	RowData,
	TData,
	Value,
} from '../Table.types'
import { e_RenderType, e_RowSelectionType } from '../Table.types'
import { SELECTION_SUPPRESS_FINISH_ACTIONS } from '../consts'
import { getCellDataFromGroupNodeChild, getFormattedValue, removeUpdatedConfigs } from './colDefUtils'
import { e_SortOrder } from '../enums/e_SortOrder'
import { tableUtils } from '.'
import { GROUP_AUTO_COLUMN_ID } from '@ag-grid-community/core'

// A callback for localising text within the grid.
export const getLocaleText = (params: GetLocaleTextParams<TData>, tcvi: (t: string) => string) =>
	tcvi(`CONTROL:AG_${params.key.toUpperCase()}`)

// For customising the context menu. Return an empty array to use custom Context Menu.
export const getContextMenuItemsDummy = () => []

// For customising the main 'column header' menu.
export const getMainMenuItems =
	(
		onExportToFileClick: (() => void) | undefined,
		onResetButtonClick: (gridApi: GridApi) => void,
		tcvi: (t: string) => string
	) =>
	(params: GetMainMenuItemsParams) => {
		const menuItems: (string | { name: string; icon: string; action: () => void })[] = params.defaultItems.filter(
			(item) => item !== 'resetColumns'
		)

		menuItems.push({
			name: tcvi('GENERAL:RESET_VIEW'),
			icon: '<i class="Fluent-Reset"/>',
			action: () => onResetButtonClick(params.api),
		})

		if (onExportToFileClick) {
			menuItems.push({
				name: tcvi('GENERAL:DOWNLOAD_AS_EXCEL'),
				icon: '<i class="Fluent-Download"/>',
				action: onExportToFileClick,
			})
		}

		return menuItems
	}

// Callback to be used when working with Tree Data when `treeData = true`.
export const getDataPath: (treeData?: boolean) => GetDataPath<TData> | undefined = (treeData) =>
	treeData ? (data) => data.rowHierarchy ?? [] : undefined

// Allows you to set the ID for a particular row node based on the data.
export const getRowId: (treeData?: boolean) => GetRowIdFunc<TData> | undefined = (treeData) =>
	!treeData ? (row) => row.data.id : undefined

const autoSizeColumns = (id: string | undefined, api: GridApi, autoGroupColumnDef: ColDef<TData>) => {
	const resizedColIds = id ? readColumnStateFromLocalStorage(id)?.resizedColumnIds : undefined

	// Coumns (that have width = fitToContent/fitToLabelAndContent) that have not been resized, will be autosized.
	// We do this here as well as in `onFirstDataRendered`, as we want to autosize the initial grid as well as when the grid have initial data.
	api.getColumns()?.forEach((col) => {
		const colDef = col.getColDef() as ColDef<TData>
		const width = (colDef.cellRendererParams as ICellRendererProps).widthFromProps

		if (typeof width !== 'number' && !(colDef.field && resizedColIds?.includes(colDef.field))) {
			api.autoSizeColumns([col.getId()], width !== 'fitToLabelAndContent')
		}
	})
	api.autoSizeColumns(
		[GROUP_AUTO_COLUMN_ID],
		(autoGroupColumnDef.cellRendererParams as ICellRendererProps)?.widthFromProps !== 'fitToLabelAndContent'
	)

	const colDefs = api.getColumnDefs() as ColDef<TData>[]
	api.getColumns()?.forEach((col) => {
		if (col.isVisible()) {
			const colDef = col.getColDef()

			const columnDef = colDefs.find((gcd) => gcd.field === colDef.field)
			if (columnDef) {
				// Remove min/max width after initial sizing (as it is "initial min/max width")
				columnDef.minWidth = undefined
				columnDef.maxWidth = undefined
			}
		}
	})

	// Update Column Defs without min/max width
	api.setGridOption('columnDefs', colDefs)
}

// The grid has initialised and is ready for most api calls, but may not be fully rendered yet.
export const useOnGridReady = (
	ref: React.RefObject<HTMLDivElement>,
	id: string | undefined,
	verticalHeader: boolean | undefined,
	autoHeight: boolean | undefined,
	tableContextRef: ITableContextRef,
	autoGroupColumnDef: ColDef<TData>,
	callbacks: {
		onColumnStateChanged: (columnState: ColumnState[]) => void
		onResetButtonClick: (gridApi: GridApi) => void
		onGridReady?: (customApi: ITableApi) => void
		updateFirstColumn?: (api: GridApi<TData>) => void
		updateSortOrder?: (api: GridApi<TData>) => void
	}
) => {
	const { onColumnStateChanged, onResetButtonClick, onGridReady, updateFirstColumn, updateSortOrder } = callbacks
	return (e: GridReadyEvent<TData>) => {
		// Update (custom) first column if needed
		updateFirstColumn?.(e.api)

		updateSortOrder?.(e.api)

		if (verticalHeader) {
			setHeaderHeight(ref, e.api)
		}

		e.api.setGridOption('domLayout', autoHeight ? 'autoHeight' : 'normal')

		onGridReady?.({
			setQuickFilter: setQuickFilter(e.api),
			setColumnWidth: setColumnWidth(e.api),
			showLoadingOverlay: () => e.api.showLoadingOverlay(),
			hideOverlay: () => e.api.hideOverlay(),
			_gridApi: e.api,
			resetView: () => onResetButtonClick(e.api),
			// TODO: Autosizing and min/max handling is done in several functions (with somewhat similar code), and code should be generalized at some point.
			autoSizeColumns: () => autoSizeColumns(id, e.api, autoGroupColumnDef),
			exportDataAsExcel: (exportArgs) => {
				const {
					author,
					fileName = 'Table',
					sheetName = 'Sheet1',
					processCellCallback = (processCellArgs: ProcessCellCallbackArgs) => {
						if (typeof processCellArgs.value === 'string') {
							return processCellArgs.value
						}
						return processCellArgs.value?.value?.toString() ?? ''
					},
					...rest
				} = { ...exportArgs }

				e.api.exportDataAsExcel({ author, fileName, sheetName, processCellCallback, ...rest })
			},
			redrawRows: () => e.api.redrawRows(),
			setFocusToFirstSelectedRowOrFirstRow: () => {
				const selectedRows = e.api.getSelectedNodes()
				const selectedRow =
					selectedRows.length &&
					selectedRows.reduce((prevRow, currentRow) => {
						if (currentRow.rowIndex !== null && prevRow.rowIndex !== null) {
							return currentRow.rowIndex < prevRow.rowIndex ? currentRow : prevRow
						}
						return currentRow
					}, selectedRows[0])

				const firstColumn = e.api.getAllDisplayedColumns()[0]
				if (!firstColumn) {
					return
				}
				if (selectedRow && selectedRow.rowIndex !== null) {
					e.api.ensureIndexVisible(selectedRow.rowIndex)
					window.setTimeout(
						() => selectedRow.rowIndex !== null && e.api.setFocusedCell(selectedRow.rowIndex, firstColumn),
						150
					)
				} else {
					e.api.ensureIndexVisible(0)
					window.setTimeout(() => e.api.setFocusedCell(0, firstColumn), 150)
				}
			},
			scrollToBottom: () => {
				// Need to loop through row nodes, to find the highest row index.
				let maxIndex = -1
				e.api.forEachNodeAfterFilter((row) => {
					if (row.rowIndex !== null && row.rowIndex > maxIndex) {
						maxIndex = row.rowIndex
					}
				})
				if (maxIndex !== -1) {
					e.api.ensureIndexVisible(maxIndex)
				}
			},
			scrollToTop: () => e.api.ensureIndexVisible(0),
			scrollToLeft: () => {
				// "displayed columns" works since column virtualization is turned off
				const displayedColumns = e.api.getAllDisplayedColumns()
				displayedColumns.length && e.api.ensureColumnVisible(displayedColumns[0])
			},
			scrollToRight: () => {
				const displayedColumns = e.api.getAllDisplayedColumns()
				displayedColumns.length && e.api.ensureColumnVisible(displayedColumns[displayedColumns.length - 1])
			},
			setFilterModel: (filterModel) => {
				e.api.setFilterModel(filterModel)
			},
		})

		const columntateFromModel = e.api.getColumnState()

		autoSizeColumns(id, e.api, autoGroupColumnDef)

		// Only handle session storage when Table id is included
		if (id) {
			const colState = readColumnStateFromLocalStorage(id)

			if (colState) {
				const previousStateFromModel = colState.originalColumnState
				Object.values(previousStateFromModel).forEach((s) => {
					delete s['width']
				})

				Object.values(columntateFromModel).forEach((s) => {
					delete s['width']
				})

				if (!isEqual(previousStateFromModel, columntateFromModel)) {
					// Original column state has been modified, delte old session storage.
					deleteColumnStateFromLocalStorage(id)
					deleteFilterModelFromSessionStorage(id)
					deleteExpandedRowNodesFromSessionStorage(id)
				} else {
					const columnDefMap = e.api
						.getColumns()
						?.map((c) => c.getColDef())
						.reduce(
							(acc, col) => {
								if (!col.field) {
									return acc
								}
								return { ...acc, [col.field]: col }
							},
							{} as Record<string, ColDef>
						)
					if (columnDefMap) {
						e.api.applyColumnState({
							state: removeUpdatedConfigs(columnDefMap, colState.columnState),
							applyOrder: true,
						})
					}
				}
			}

			onColumnStateChanged(columntateFromModel)

			const rowGroupState = readExpandedRowNodesFromSessionStorage(id)
			if (rowGroupState) {
				tableContextRef.current.openGroupNodes = Object.keys(rowGroupState).filter((row) => rowGroupState[row])

				updateFirstColumn?.(e.api)
			}
		}
	}
}

// Fired the first time data is rendered into the grid.
export const useOnFirstDataRendered = (
	id: string | undefined,
	selection: string[] | undefined,
	initialSelection: e_InitialSelection | undefined,
	selectableRowCountAfterFilter: React.MutableRefObject<number>,
	focusFirstCellOnRender: boolean | undefined,
	tableContextRef: ITableContextRef,
	onInitialAutosizeFinished?: () => void
) => {
	return (e: FirstDataRenderedEvent<TData>) => {
		const resizedColumnIds = id ? readColumnStateFromLocalStorage(id)?.resizedColumnIds : undefined

		if (id) {
			const filterModel = readFilterModelFromSessionStorage(id)
			if (filterModel) {
				e.api.setFilterModel(filterModel)
			}
		}

		addMinMaxSizeFromProps(e.api, resizedColumnIds)

		// Wrap in timeout to ensure that initially data is actually finished to render, before we try to autosize
		window.setTimeout(() => {
			resizeColumns(e.api, resizedColumnIds)

			removeMinMaxSizeFromProps(e.api)

			// Store new state in session storage.
			// After the Grid recieves data for the first time, all (visible) Column widths are stored in storage as actual numbers...
			// ...and will therefor not be autosized more than once per session
			// TODO: PBU - 18.06.2024 - The next two lines are commented out, as new functionality requires so.
			// This might degrade performance (due to autosizing alll columns each time the grid mounts). This was avoided before.
			// The reason for having this previously might have been to stabilize the resizing of columns, which now might be handled by columns only resizing to become larger.
			// This can be removed if we have not experienced any issues by 18.09.2024 (3 months)
			// const allVisibleColIds = getAllVisibleColumns(e.api).map((col) => col.getId())
			// onColumnStateChanged(e.api.getColumnState(), allVisibleColIds)

			// Callback function that can be used to handle any changes after Columns in the Grid is "positioned"
			onInitialAutosizeFinished?.()
		})

		setInitialSelection(e.api, initialSelection)

		if (focusFirstCellOnRender) {
			const rowToFocus = e.api.getFirstDisplayedRow()
			const columnToFocus = e.api.getAllDisplayedColumns()[0]
			e.api.setFocusedCell(rowToFocus, columnToFocus.getColId())
		}

		const selected = new Set(selection)
		// @ts-ignore
		selected.forEach((id) => e.api.getRowNode(id)?.setSelected(true, false, SELECTION_SUPPRESS_FINISH_ACTIONS))

		refreshCheckMarkColumn(e.api)

		const newHeaderCheckMarkState = getCheckboxState(selectableRowCountAfterFilter.current, e.api)
		if (newHeaderCheckMarkState !== tableContextRef.current.checkMarkValue) {
			tableContextRef.current.checkMarkValue = newHeaderCheckMarkState
		}
	}
}

// The client has updated data for the grid by either a) setting new Row Data or b) Applying a Row Transaction.
export const getOnRowDataUpdated = (onRowDataUpdated?: () => void) => () => {
	onRowDataUpdated?.()
}

// Row selection is changed.
export const useOnSelectionChanged = (
	selectableRowCountAfterFilter: React.MutableRefObject<number>,
	newSelectionFromArrowEvents: React.MutableRefObject<boolean>,
	tableContextRef: ITableContextRef,
	onSelectionChange?: (ids: string[]) => void,
	multiSelect?: boolean
) => {
	const updateGroupCheckMark = useUpdateGroupCheckMark()

	return (e: SelectionChangedEvent<TData>) => {
		// @ts-ignore
		if (e.source === SELECTION_SUPPRESS_FINISH_ACTIONS) {
			return
		}

		// get all selected nodes
		const selectedNodes = e.api.getSelectedNodes()

		// // Refresh checkbox cells
		if (!newSelectionFromArrowEvents.current) {
			const checkBoxColumn = multiSelect
				? e.api
						.getColumns()
						?.find((column: Column<TData>) => isCustomColumn(column.getColDef()))
						?.getId()
				: undefined

			if (checkBoxColumn) {
				e.api.refreshCells({ force: true, columns: [checkBoxColumn] })
			}
		}

		// Refresh group rows and header
		if (multiSelect) {
			const selectedNodesWithParents = selectedNodes.filter((node) => !!node.parent && node.level > 0)
			updateGroupCheckMark(selectedNodesWithParents, e.api)

			// Update header check mark context
			const newHeaderCheckMarkState = getCheckboxState(selectableRowCountAfterFilter.current, e.api)
			if (newHeaderCheckMarkState !== tableContextRef.current.checkMarkValue) {
				tableContextRef.current.checkMarkValue = newHeaderCheckMarkState
			}
		}

		// Handle onSelectionChange from arrow events om key up (in useArrowSelectionOnKeyUp.ts)
		if (newSelectionFromArrowEvents.current) {
			return
		}

		if (!onSelectionChange) {
			return
		}

		// get all selected node ids.
		// if select all is used (either by clicking the header checkbox or using Ctrl+A), we want to use the order to reflect the visual order in the table
		const selectedIds: string[] =
			e.source === 'apiSelectAllFiltered'
				? orderSelectedNodeIdsByVisualOrder(e.api)
				: selectedNodes.map((row) => row.data?.id as string)

		onSelectionChange(selectedIds)
	}
}

// Row is double clicked.
export const getOnRowDoubleClicked =
	(onDblClick?: ITableRowData['onDblClick']) => (e: RowDoubleClickedEvent<TData>) => {
		if (e.event?.defaultPrevented || e.data?.disableSelection) {
			// Preventing double clicking a check mark to run onActivate
			return
		}

		const rowData = tDataToRowData(e.data)
		rowData !== undefined && onDblClick?.(rowData)
	}

// Row is clicked.
export const useOnRowClicked = (
	selectionType: e_RowSelectionType,
	disableRowClickSelection: boolean,
	toggleSelectionOnRowClick: boolean,
	treeData: boolean,
	onClick?: ITableRowData['onClick']
) => {
	const previouslyClickedRow = useRef<IRowNode<TData>>()
	const setSelection = createSetSelectionMouse(previouslyClickedRow, selectionType, toggleSelectionOnRowClick)

	return (e: RowClickedEvent<TData>) => {
		if (e.event?.defaultPrevented === false) {
			if (!e.data?.disableSelection && !disableRowClickSelection) {
				if (!e.node.group && !(treeData && (e.node.allChildrenCount ?? 0) > 0)) {
					const event = e.event as unknown as MouseEvent

					setSelection(event, e.node, e.rowIndex || -1, e.api)
				}
			}

			const rowData = tDataToRowData(e.data)

			// Wrap onClick in timeout to ensure selection callback events will trigger first
			rowData &&
				window.setTimeout(() => {
					onClick?.(rowData)
				})
		}
	}
}

export const createHandleCellClicked = (treeData: boolean) => (e: CellClickedEvent<TData>) => {
	if (e.event?.defaultPrevented || (e.event?.target as HTMLElement)?.tagName === 'A') {
		return
	}
	if (e.colDef.singleClickEdit) {
		startEditing(e)
		return
	}

	if (e.colDef.showRowGroup) {
		return
	}

	if (!treeData && (e.node.group || e.node.master)) {
		const nodeIsExpanded = e.node.expanded
		e.node.setExpanded(!nodeIsExpanded)
	}
}

// A row group was opened or closed.
export const useOnRowGroupOpened = (id: string | undefined, tableContext: ITableContextRef, treeData?: boolean) => {
	return (params: RowGroupOpenedEvent<TData>) => {
		let nodeKey = params.node.key
		let parent = params.node.parent
		while (parent?.key) {
			nodeKey += `_${parent.key}`
			parent = parent.parent
		}

		if (tableContext.current.openGroupNodes !== undefined && nodeKey && !treeData) {
			const newNodes = [...tableContext.current.openGroupNodes]

			params.expanded ? newNodes.push(nodeKey) : newNodes.splice(newNodes.indexOf(nodeKey), 1)

			tableContext.current.openGroupNodes = newNodes
		}
		if (params.node.expanded) {
			window.setTimeout(() => {
				const resizedColumnIds = id ? readColumnStateFromLocalStorage(id)?.resizedColumnIds : undefined
				addMinMaxSizeFromProps(params.api, resizedColumnIds, true)
				if (params.node.expanded) {
					resizeColumns(params.api, resizedColumnIds)
				}
				removeMinMaxSizeFromProps(params.api)
			})
		}
		if (id) {
			const expanded = params.node.expanded
			nodeKey && writeExpandedRowNodesToSessionStorage(id, { [nodeKey]: expanded })
		}
	}
}

// Fired when calling either of the API methods `expandAll()` or `collapseAll()`.
export const useOnExpandOrCollapseAll = (id: string | undefined, tableContextRef: ITableContextRef) => {
	return (params: ExpandCollapseAllEvent<TData>) => {
		const rowIds: string[] = []
		let expandAll = false

		params.api.forEachNode((node) => {
			let nodeKey = node.key
			let parent = node.parent
			while (parent?.key) {
				nodeKey += `_${parent.key}`
				parent = parent.parent
			}
			nodeKey && rowIds.push(nodeKey)
		})

		if (params.source === 'expandAll') {
			tableContextRef.current.openGroupNodes = rowIds
			expandAll = true
		} else {
			tableContextRef.current.openGroupNodes = []
		}

		if (expandAll) {
			window.setTimeout(() => {
				const resizedColumnIds = id ? readColumnStateFromLocalStorage(id)?.resizedColumnIds : undefined
				addMinMaxSizeFromProps(params.api, resizedColumnIds, true)
				resizeColumns(params.api, resizedColumnIds)
				removeMinMaxSizeFromProps(params.api)
			})
		}
		if (id && rowIds.length > 0) {
			const initialValue: { [id: string]: boolean } = {}
			const sessionValue = rowIds.reduce(
				(prevValue, currentValue) => ({ ...prevValue, [currentValue]: expandAll }),
				initialValue
			)
			writeExpandedRowNodesToSessionStorage(id, sessionValue)
		}
	}
}

// DOM event on mouse down on cell
export const getOnCellMouseDown =
	(onCellMouseDown?: (data: RowData, columnId: string) => void) => (e: CellMouseDownEvent<TData>) => {
		const rowData = tDataToRowData(e.data)
		rowData && onCellMouseDown?.(rowData, e.column.getId())
	}

// DOM event keyDown happened on a cell.
export const useOnCellKeyDown = (
	selectionType: e_RowSelectionType,
	disableSelectionOnNavigation: boolean,
	onChange?: (rowData: RowData, columnId: string, newValue: Value) => void,
	onDelete?: ITableRowData['onDelete'],
	onEscape?: ITableRowData['onEscape']
) => {
	const searchForNode = useSearchForNode(disableSelectionOnNavigation)

	return (e: CellKeyDownEvent<TData, CellData>) => {
		if (!e.event) {
			return
		}

		const { api, column, node } = e

		const event = e.event as KeyboardEvent

		if (event.key.length === 1 && /[A-ZÆØÅÄÖa-zæøåäö0-9 ]/.test(event.key)) {
			searchForNode(e)
		}

		switch (event.key) {
			case 'Delete':
				if (onDelete) {
					onDelete()
				} else if (
					onChange &&
					e.data &&
					e.colDef.cellRendererParams &&
					!(e.colDef.cellRendererParams as ICellRendererProps).readOnly &&
					(e.colDef.cellRendererParams as ICellRendererProps).allowNull
				) {
					const rowData = tDataToRowData(e.data)
					if (rowData) {
						onChange(rowData, column.getId(), null)
					}
				}
				break
			case 'Enter':
				if (node.group || (node.master && column.getLeft() !== 0)) {
					node.setExpanded(!node.expanded)
				}

				break
			case 'ArrowUp':
			case 'ArrowDown': {
				const focusedCell = api.getFocusedCell()
				if (focusedCell === null) {
					return
				}

				const nextRowNode = api.getDisplayedRowAtIndex(focusedCell.rowIndex)
				if (nextRowNode === undefined || nextRowNode.data?.disableSelection) {
					return
				} else {
					if (event.ctrlKey) {
						const move = event.key === 'ArrowDown' ? 1 : -1
						api.setFocusedCell(focusedCell.rowIndex + move, focusedCell.column.getColId())
					}
				}

				break
			}
			case 'a':
				if (selectionType === e_RowSelectionType.multi && event.ctrlKey) {
					e.api.selectAllFiltered()
				}
				break
			case ' ': {
				if (selectionType !== e_RowSelectionType.none) {
					if (event.ctrlKey) {
						node.setSelected(true, true)
					}
					refreshCheckMarkColumn(e.api)
				}
				break
			}
			case 'Escape': {
				const rowData = tDataToRowData(node.data)
				rowData && onEscape?.(rowData)
				break
			}
		}
	}
}

// Cell is right clicked.
export const getOnCellContextMenu =
	(
		selectionType: e_RowSelectionType,
		onContextMenu: (e: React.MouseEvent, id: string | undefined, columnId: string) => void
	) =>
	(e: CellContextMenuEvent<TData>) => {
		if (e.node.footer) {
			return
		}

		if (!e.data?.disableSelection) {
			selectionType !== e_RowSelectionType.none && e.node.setSelected(true, true)
		}

		e.event && onContextMenu(e.event as unknown as React.MouseEvent, e.node.id, e.column.getId())
	}

// Sort has changed. The grid also listens for this and updates the model.
export const getOnSortChanged =
	(
		onColumnStateChanged: (columnState: ColumnState[]) => void,
		onColumnSortChanged?: ((columns: ISortedField[]) => void) | undefined
	) =>
	(event: SortChangedEvent<TData>) => {
		onColumnStateChanged(event.api.getColumnState())

		if (onColumnSortChanged) {
			// determine order of column sorting

			const currentColumnState = event.api.getColumnState()
			const sortedColumns = currentColumnState.filter((col) => col.sortIndex !== null)

			sortedColumns.sort((col1, col2) => col1.sortIndex! - col2.sortIndex!)

			const sortSetup: ISortedField[] = sortedColumns.map((sortCol) => ({
				id: sortCol.colId,
				order: sortCol.sort === 'desc' ? e_SortOrder.desc : e_SortOrder.asc,
			}))

			onColumnSortChanged(sortSetup)
		}
	}

// Column moved
export const getOnColumnMoved =
	(onColumnStateChanged: (columnState: ColumnState[]) => void, updateFirstColumn?: (api: GridApi<TData>) => void) =>
	(e: ColumnMovedEvent<TData>) => {
		// Update (custom) first column if needed
		updateFirstColumn?.(e.api)

		onColumnStateChanged(e.api.getColumnState())
		e.api.refreshHeader()
	}

// A column was resized.
export const getOnColumnResized =
	(
		id: string | undefined,
		onColumnStateChanged: (columnState: ColumnState[], resizedColumnIds: string[]) => void,
		onColumnsWidthChange: () => void
	) =>
	(e: ColumnResizedEvent<TData>) => {
		// Wait till resizing is complete to avoid rapid updates.
		if (!e.finished) {
			return
		}

		onColumnsWidthChange()

		if (!id) {
			return
		}

		// Only write user initiated actions to session storage
		if (e.source === 'api') {
			return
		}

		const resizedColumnIds = readColumnStateFromLocalStorage(id)?.resizedColumnIds || []

		// When one or more columns are autosized, remove them from `resizedColumnIds` in session storage.
		if (e.source === 'autosizeColumns') {
			return
		}

		// When a column is resized, add the column and only that column to `resizedColumnIds` in session storage.
		const resizedColumnId = e.column?.getColId()

		if (resizedColumnId && !resizedColumnIds.includes(resizedColumnId)) {
			resizedColumnIds.push(resizedColumnId)
		}

		onColumnStateChanged(e.api.getColumnState(), resizedColumnIds)
	}

// Column hidden/visible
export const getOnColumnVisible =
	(
		id: string | undefined,
		onColumnStateChanged: (columnState: ColumnState[], resizedColumnIds: string[]) => void,
		updateFirstColumn?: (api: GridApi<TData>) => void
	) =>
	(e: ColumnVisibleEvent<TData>) => {
		if (!e.visible) {
			const remainingVisibleColumns = e.api.getColumnDefs()?.filter((cD: ColDef) => !cD.rowGroup && !cD.hide)
			if (remainingVisibleColumns?.length === 0) {
				// Set a column visible again (avoid empty Grid)
				e.column?.setVisible(true, 'columnMenu')
			}
		}

		if (!e.column) {
			return
		}

		// Autosize columns that changes to visible after Grid has recieved first data
		const resizedColumnIds = id ? readColumnStateFromLocalStorage(id)?.resizedColumnIds || [] : []
		const colDef = e.column.getColDef() as ColDef<TData>
		const colId = e.column.getId()

		const width = (colDef.cellRendererParams as ICellRendererProps).widthFromProps

		if (typeof width !== 'number' && !(colDef.field && resizedColumnIds?.includes(colDef.field))) {
			e?.api.autoSizeColumns([colId], width !== 'fitToLabelAndContent')
		}

		const gridColumnDefs = e.api.getColumnDefs() as ColDef<TData>[]
		const gridColDef = gridColumnDefs.find((gcd) => gcd.field === colDef.field)
		if (gridColDef) {
			// Remove min/max width after initial sizing (as it is "initial min/max width")
			gridColDef.minWidth = undefined
			gridColDef.maxWidth = undefined
		}

		// Update Column Defs without min/max width
		e.api.setGridOption('columnDefs', gridColumnDefs)

		// Update (custom) first column if needed
		updateFirstColumn?.(e.api)

		onColumnStateChanged(e.api.getColumnState(), resizedColumnIds.concat(colId))
	}

// A column, or group of columns, was pinned / unpinned.
export const getOnColumnPinned =
	(onColumnStateChanged: (columnState: ColumnState[]) => void) => (event: ColumnPinnedEvent<TData>) => {
		onColumnStateChanged(event.api.getColumnState())
	}

export function startEditing(props: { node: IRowNode; column?: Column; api: GridApi }) {
	const rowIndex = props.node.rowIndex
	const colKey = props.column?.getId()
	if (rowIndex != null && colKey) {
		props.api.clearFocusedCell()
		props.api.startEditingCell({ rowIndex, colKey })
	}
}

/**
 * Check if there are any grouped columns in the grid. If the grid api is not available, we assume that the col defs will be the new state.
 * @param api grid api
 * @param colDefs the new column defs. These are used if the grid api is not available. Typically used when the grid is not yet initialized.
 */
export function existsGroupedColumns(
	api: GridApi<TData> | undefined,
	colDefs: (ColDef<TData, any> | ColGroupDef<TData>)[]
): boolean
export function existsGroupedColumns(api: GridApi<TData>): boolean
export function existsGroupedColumns(
	api: GridApi<TData> | undefined,
	colDefs?: (ColDef<TData, any> | ColGroupDef<TData>)[]
): boolean {
	if (!api) {
		return !!colDefs?.some((col) => !tableUtils.isColGroupDef(col) && col.rowGroup)
	}
	return api.getColumnState().some((col) => col.rowGroup)
}

// Row grouping changed
export const useOnColumnRowGroupChanged = (
	id: string | undefined,
	groupLevelsToExpand: number,
	tableContextRef: ITableContextRef,
	autoGroupColumnDef: ColDef<TData> | ColGroupDef<TData> | undefined,
	onColumnStateChanged: (columnState: ColumnState[]) => void,
	onColumnsWidthChange: () => void
) => {
	return (e: ColumnRowGroupChangedEvent<TData>) => {
		const rowGroupState = id ? readExpandedRowNodesFromSessionStorage(id) : undefined
		if (!rowGroupState) {
			const openGroupNodes: string[] = []

			// Add default open Row Nodes (expanded from grid api, based on `groupLevelsToExpand`)
			e.api.forEachNode((node) => {
				if ((node.rowGroupIndex ?? -1) < groupLevelsToExpand && node.key) {
					openGroupNodes.push(node.key)
				}
			})

			tableContextRef.current.openGroupNodes = openGroupNodes
		}

		if (!e.columns?.length && id) {
			// Delete storage when row groups are removed
			deleteExpandedRowNodesFromSessionStorage(id)
		}
		const columnDefs = e.api.getColumnDefs()
		const firstColumn = columnDefs?.[0]
		if (autoGroupColumnDef && firstColumn && 'field' in firstColumn && 'field' in autoGroupColumnDef) {
			if (existsGroupedColumns(e.api)) {
				if (firstColumn.field === autoGroupColumnDef.field) {
					e.api.setGridOption('columnDefs', columnDefs.slice(1))
				}
			} else {
				if (firstColumn.field !== autoGroupColumnDef.field) {
					setTimeout(() => {
						if (autoGroupColumnDef.field) {
							e.api.autoSizeColumns(
								[autoGroupColumnDef.field],
								autoGroupColumnDef.cellRendererParams.widthFromProps !== 'fitToLabelAndContent'
							)
						}
					})
					e.api.setGridOption('columnDefs', [autoGroupColumnDef, ...columnDefs])
				}
			}
		}

		onColumnStateChanged(e.api.getColumnState())

		onColumnsWidthChange()
	}
}

// Filter is changed and applied
export const useOnFilterChanged = (
	id: string | undefined,
	selectableRowCountAfterFilter: React.MutableRefObject<number>,
	selectionType: e_RowSelectionType,
	tableContextRef: ITableContextRef,
	onFilter?: ITableRowData['onFilter']
) => {
	return (e: FilterChangedEvent<TData>) => {
		// Update rowCountAfterFilter ref
		let i = 0
		const visibleRowsAfterFilter: RowData[] = []
		e.api.forEachNodeAfterFilter((node) => {
			if (!node.group) {
				if (!node.data?.disableSelection) {
					i++
				}
				if (node.data) {
					const rowData = tDataToRowData(node.data)
					visibleRowsAfterFilter.push(rowData!)
				}
			}
		})

		selectableRowCountAfterFilter.current = i

		// Update header check mark context
		const newHeaderCheckMarkState = getCheckboxState(selectableRowCountAfterFilter.current, e.api)
		if (newHeaderCheckMarkState !== tableContextRef.current.checkMarkValue) {
			tableContextRef.current.checkMarkValue = newHeaderCheckMarkState
		}

		// Deselect nodes not in filter
		if (selectionType === e_RowSelectionType.multi) {
			const leafNodesAfterFilter = getAllLeafNodesAfterFilter(e.api)
			const selectedRows = e.api.getSelectedNodes()
			selectedRows.forEach((node) => {
				if (!leafNodesAfterFilter.has(node)) {
					node.setSelected(false, false)
				}
			})
		} else if (selectionType === e_RowSelectionType.single) {
			const selectedRow = e.api.getSelectedNodes()[0]
			selectedRow && !selectedRow.displayed && selectedRow.setSelected(false)
		}
		// The group cell renderer updates before the filter is applied, so we need to refresh the cells to update the checkbox
		e.api.refreshCells({ force: true, columns: [GROUP_AUTO_COLUMN_ID], suppressFlash: true })

		//Send callback if exists
		onFilter?.(visibleRowsAfterFilter)

		if (id) {
			writeFilterModelToSessionStorage(id, e.api.getFilterModel())
		}
	}
}

// This callback is invoked each time a group is created.
export const getIsGroupOpenByDefault =
	(id: string | undefined, groupLevelsToExpand: number, grouping: IGroupingField[] | undefined) =>
	(params: IsGroupOpenByDefaultParams<TData>): boolean => {
		const rowGroupState = id ? readExpandedRowNodesFromSessionStorage(id) : undefined

		const columnId = params.field
		const initialGroupStateForColumn = grouping ? grouping.find((g) => g.id === columnId)?.expanded : undefined

		let nodeKey = params.rowNode.key
		let parent = params.rowNode.parent
		while (parent?.key) {
			nodeKey += `_${parent.key}`
			parent = parent.parent
		}

		return (!!nodeKey && rowGroupState?.[nodeKey]) ?? initialGroupStateForColumn ?? groupLevelsToExpand > params.level
	}

// Callback to be used to determine which rows are selectable. By default rows are selectable, so return `false` to make a row un-selectable.
export const getIsRowSelectable = (rowSelectionType?: e_RowSelectionType) => (rowNode: IRowNode<TData>) =>
	!rowNode.group && !rowNode.data?.disableSelection && rowSelectionType !== e_RowSelectionType.none

export const processCellForClipboard = (e: ProcessCellForExportParams<TData>): Value => {
	if (e.node?.footer) {
		if (!e.column) {
			return
		}

		return e.node.aggData[e.column.getId()]?.value
	}

	if (e.node?.group) {
		const cellData = getCellDataFromGroupNodeChild(e.node)
		if (!cellData) {
			throw new Error('No cell data found in group node. A group should always have at least one child.')
		}
		return getValueByRenderType(cellData, e.column)
	}

	return getValueByRenderType(e.value, e.column)
}

export const getProcessCellForClipboard = (onCopy?: (value: Value) => void) => {
	if (onCopy) {
		return (e: ProcessCellForExportParams<TData>) => {
			const value = processCellForClipboard(e)
			onCopy(value)
			return value
		}
	}
	return processCellForClipboard
}

const getValueByRenderType = (value: CellData, column: Column) => {
	if ((column.getColDef().cellRendererParams as ICellRendererProps | undefined)?.renderType === e_RenderType.number) {
		return value.value
	}

	return getFormattedValue(value)
}

// Callback to perform additional sorting after the grid has sorted the rows.
export const usePostSortRows = (portSortRows?: (params: PostSortRowsParams<TData>) => void) =>
	useCallback((params: PostSortRowsParams<TData>) => portSortRows?.(params), [portSortRows])

const handleSelectOne = (
	multiSelect: boolean,
	params: NavigateToNextCellParams<TData>,
	prevNode: IRowNode<TData> | undefined,
	nextNode: IRowNode<TData>,
	newSelectionFromArrowEvents: React.MutableRefObject<boolean>
) => {
	if (nextNode.data?.disableSelection) {
		return
	}

	newSelectionFromArrowEvents.current = true
	const selectedNodes = params.api.getSelectedNodes()
	nextNode.setSelected(true, true)

	// Refresh checkbox cells
	const checkBoxColumn = multiSelect
		? params.api
				.getColumns()
				?.find((column: Column<TData>) => isCustomColumn(column.getColDef()))
				?.getId()
		: undefined

	if (checkBoxColumn) {
		const rowNodesToUpdate = [...selectedNodes, nextNode]
		prevNode && rowNodesToUpdate.push(prevNode)
		params.api.refreshCells({ force: true, columns: [checkBoxColumn], rowNodes: rowNodesToUpdate })
	}
}
const handleAddNextToSelection = (
	params: NavigateToNextCellParams<TData>,
	previousCell: CellPosition,
	nextNode: IRowNode<TData>,
	newSelectionFromArrowEvents: React.MutableRefObject<boolean>
) => {
	const previousNode = previousCell ? params.api.getDisplayedRowAtIndex(previousCell.rowIndex) : undefined

	const currentNodeIsSelected = nextNode.isSelected()
	const previousNodeIsSelected = previousNode?.isSelected()
	if (previousNodeIsSelected && currentNodeIsSelected) {
		if (previousNode?.data?.disableSelection) {
			return
		}

		newSelectionFromArrowEvents.current = true
		previousNode?.setSelected(false)

		// Refresh checkbox cells
		const checkBoxColumn = params.api
			.getColumns()
			?.find((column: Column<TData>) => isCustomColumn(column.getColDef()))
			?.getId()

		if (checkBoxColumn && previousNode) {
			const rowNodesToUpdate = [previousNode]
			params.api.refreshCells({ force: true, columns: [checkBoxColumn], rowNodes: rowNodesToUpdate })
		}
	} else {
		if (nextNode?.data?.disableSelection) {
			return
		}

		newSelectionFromArrowEvents.current = true
		nextNode.setSelected(!currentNodeIsSelected)

		// Refresh checkbox cells
		const checkBoxColumn = params.api
			.getColumns()
			?.find((column: Column<TData>) => isCustomColumn(column.getColDef()))
			?.getId()

		if (checkBoxColumn) {
			const rowNodesToUpdate = [nextNode]
			params.api.refreshCells({ force: true, columns: [checkBoxColumn], rowNodes: rowNodesToUpdate })
		}
	}
}

// Allows overriding the default behaviour for when user hits navigation (arrow) key when a cell is focused.
export const getNavigateToNextCell =
	(
		multiSelect: boolean,
		selectionType: e_RowSelectionType | undefined,
		newSelectionFromArrowEvents: React.MutableRefObject<boolean>,
		disableSelectionOnNavigation: boolean
	) =>
	(params: NavigateToNextCellParams<TData>) => {
		const previousCell = params.previousCellPosition
		const suggestedNextCell = params.nextCellPosition

		const KEY_UP = 'ArrowUp'
		const KEY_DOWN = 'ArrowDown'

		const shift = params.event?.shiftKey
		const ctrl = params.event?.ctrlKey || params.event?.metaKey
		const alt = params.event?.altKey

		const noUpOrDownKey = params.key !== KEY_DOWN && params.key !== KEY_UP
		if (noUpOrDownKey) {
			return suggestedNextCell
		}

		if (selectionType === e_RowSelectionType.none || disableSelectionOnNavigation) {
			return suggestedNextCell
		}

		if (shift && alt) {
			return previousCell
		}

		const prevNode = previousCell ? params.api.getDisplayedRowAtIndex(previousCell.rowIndex) : undefined
		const nextNode = suggestedNextCell ? params.api.getDisplayedRowAtIndex(suggestedNextCell.rowIndex) : undefined

		if (!nextNode) {
			return suggestedNextCell
		}

		if (selectionType === e_RowSelectionType.single || (!shift && !ctrl)) {
			handleSelectOne(multiSelect, params, prevNode, nextNode, newSelectionFromArrowEvents)
			return suggestedNextCell
		}

		if (shift && !ctrl) {
			handleAddNextToSelection(params, previousCell, nextNode, newSelectionFromArrowEvents)
			return suggestedNextCell
		}

		return suggestedNextCell
	}

const setQuickFilter = (api: GridApi) => (newFilter: string) => {
	api.setGridOption('quickFilterText', newFilter)
}

const setColumnWidth = (api: GridApi) => (columnSizes: ColumnWidthType) => {
	if (columnSizes === 'auto') {
		api?.autoSizeAllColumns()
	} else if (columnSizes === 'fit') {
		api?.sizeColumnsToFit()
	} else {
		const columnsWidthFixedSize = columnSizes.filter((column) => column.newWidth !== 'auto')
		columnsWidthFixedSize.length > 0 &&
			api.setColumnWidths(columnsWidthFixedSize as { key: string; newWidth: number }[])

		const columnsToAutoSize = columnSizes.filter((column) => column.newWidth === 'auto').map((column) => column.key)
		columnsToAutoSize.length > 0 && api.autoSizeColumns(columnsToAutoSize)
	}
}

export const isCustomColumn = (column: ColDef<TData>) => {
	const isFirstColumn = (column.cellRendererParams as ICellRendererProps).isFirstColumn
	const hasContextMenuButton = (column.cellRendererParams as ICellRendererProps).hasContextMenuButton
	const isMultiSelect = (column.cellRendererParams as ICellRendererProps).multiSelect

	return isFirstColumn && (hasContextMenuButton || isMultiSelect)
}

function resizeColumns(api: GridApi<TData> | undefined, sessionStorageResizedColumnIds: string[] | undefined) {
	api?.getColumns()?.forEach((col) => {
		const colId = col.getId()
		const colDef = col.getColDef() as ColDef<TData>

		// Autosize visible columns (that have width = fitToContent/fitToLabelAndContent)
		if (col.isVisible()) {
			const width = (colDef.cellRendererParams as ICellRendererProps).widthFromProps

			if (typeof width !== 'number' && !(colDef.field && sessionStorageResizedColumnIds?.includes(colDef.field))) {
				api?.autoSizeColumns([colId], width !== 'fitToLabelAndContent')
			}
		}
	})
	api?.autoSizeColumns([GROUP_AUTO_COLUMN_ID], false)
}

function removeMinMaxSizeFromProps(api: GridApi<TData>) {
	const updatedGridColumnDefs = api.getColumnDefs() as ColDef<TData>[]
	api.getColumns()?.forEach((col) => {
		const colDef = col.getColDef()

		if (col.isVisible()) {
			const gridColDef = updatedGridColumnDefs.find((gcd) => gcd.field === colDef.field)
			if (gridColDef) {
				// Remove min/max width after initial sizing (as it is "initial min/max width")
				gridColDef.minWidth = undefined
				gridColDef.maxWidth = undefined
			}
		}
	})

	// Update Column Defs without min/max width
	api.setGridOption('columnDefs', updatedGridColumnDefs)
}

function addMinMaxSizeFromProps(
	api: GridApi<TData>,
	resizedColumnIds: string[] | undefined,
	lowerLimitIsCurrentWidth = false
) {
	const gridColumnDefs = api.getColumnDefs() as ColDef<TData>[] | undefined
	if (!gridColumnDefs) {
		return
	}
	api.getColumns()?.forEach((col) => {
		const colDef = col.getColDef()

		const gridColDef = gridColumnDefs.find((gcd) => gcd.field === colDef.field)
		if (gridColDef && !(gridColDef.field && resizedColumnIds?.includes(gridColDef.field))) {
			// Re-add min/max width before autosizing with first data
			gridColDef.minWidth = (colDef.cellRendererParams as ICellRendererProps).minWidthFromProps
			if (lowerLimitIsCurrentWidth) {
				gridColDef.minWidth = Math.max(col.getActualWidth(), gridColDef.minWidth ?? 0)
			}
			gridColDef.maxWidth = (colDef.cellRendererParams as ICellRendererProps).maxWidthFromProps
		}
	})
	// Update Column Defs to include min/max width
	api.setGridOption('columnDefs', gridColumnDefs)
}

export function getAllLeafChildrenAfterFilter(node: IRowNode<TData>, allLeafChildren: IRowNode<TData>[] = []) {
	if (node.group) {
		for (const child of node.childrenAfterFilter ?? []) {
			if (child.group) {
				getAllLeafChildrenAfterFilter(child, allLeafChildren)
			} else {
				allLeafChildren.push(child)
			}
		}
	} else {
		allLeafChildren.push(node)
	}
	return allLeafChildren
}

export function getAllLeafNodesAfterFilter(api: GridApi<TData>) {
	const leafNodesAfterFilter = new Set<IRowNode<TData>>()
	api.forEachNodeAfterFilter((node) => {
		if (!node.group) {
			leafNodesAfterFilter.add(node)
		}
	})
	return leafNodesAfterFilter
}

export function selectChildRows(node: IRowNode<TData>) {
	const leafChildren = getAllLeafChildrenAfterFilter(node)
	const isFilteredSelected = getIsSelected(node, leafChildren)
	leafChildren.forEach((childNode) => {
		childNode.setSelected(!isFilteredSelected)
	})
}

export function getIsSelected(node: IRowNode<TData>, leafChildren: IRowNode<TData>[]) {
	if (node.group) {
		let hasFoundSelectedChild = false
		let hasFoundUnselectedChild = false
		for (const child of leafChildren) {
			if (child.isSelected()) {
				if (hasFoundUnselectedChild) {
					return undefined
				}
				hasFoundSelectedChild = true
			} else {
				if (hasFoundSelectedChild) {
					return undefined
				}
				hasFoundUnselectedChild = true
			}
		}
		return hasFoundSelectedChild
	} else {
		return node.isSelected()
	}
}
const orderSelectedNodeIdsByVisualOrder = (api: GridApi<TData>) => {
	const orderedSelectedNodeIds: string[] = []
	api.forEachNodeAfterFilterAndSort((node) => {
		// make sure to exclude group nodes (and footer nodes) as they are included in the api result
		if (!node.group && !node.footer && node.isSelected() && node.id) {
			orderedSelectedNodeIds.push(node.id)
		}
	})

	return orderedSelectedNodeIds
}
