import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { createStyle } from '../../theming'
import classNames from 'clsx'
import { ListNode } from './ListNode'
import type { IContextMenu } from '../ContextMenu/IContextMenu'
import { useContextMenu } from '../ContextMenu'
import { DropTarget } from '../utils/DropTarget'
import { useDropObject } from '../utils/useDropObject'
import { e_ListNode, type IListItem, type IListNode } from './IListNode'
import { useForwardedRef } from '../utils/useForwardedRef'
import { useSelectionFocusActivationModel } from './useSelectionFocusActivationModel'
import type { ICanDropHandler, IDropHandler, IInsertIndex } from '../utils/useDropObject'
import type { IDragSourceMonitor } from '../utils/dragdrop/DragDropTypes'
import type { BoundDataTransfer } from '../utils/dragdrop'

interface IListProps<T = never> {
	items: IListNode<T>[]

	disabled?: boolean

	itemRenderer?: (item: IListItem<T> & { isSelected: boolean; isDisabled: boolean; isFocused?: boolean }) => JSX.Element

	emptyListMessage?: string

	selectedItemIds?: string[]
	selectionMode?: 'multi' | 'single' | 'none' // Default None
	selectionFollowsFocus?: boolean // Should only be used with single-selection.
	preventEmptySelection?: boolean // Default False
	rowClickSelectionMode?: 'toggle' | 'replace' | 'none'
	displayCheckbox?: boolean // Default False
	checkboxRounded?: boolean // Default False
	placeCheckboxRight?: boolean // Default False

	onSelectionChange?: (itemIds: string[]) => void

	onActivate?: (itemId: string) => void
	activateOnSingleClick?: boolean // Defaults to True if selectionMode is none otherwise false(double click required).

	cycleKeyboardNavigation?: boolean // Default True
	isSearchMatch?: (item: IListItem, search: string) => boolean

	onListKeyDown?: (event: React.KeyboardEvent) => void

	// Styling
	borderBetweenItems?: boolean // Default False
	hideSelectionColors?: boolean // Default False
	zebraStripes?: boolean // Default False

	// Default Item Renderer
	itemPadding?: string | number
	multilineText?: number // Default 2

	// DnD props
	handleOnDrop?: IDropHandler<IListNode<T>>
	handleCanDrop?: ICanDropHandler<IListNode<T>>
	dropTargetTypes?: string[]
	dragSourceType?: string
	onStartDrag?: (activeItemId: string | undefined, dataTransfer: BoundDataTransfer | undefined) => void
	dragCompleted?: () => void
	customPreview?: { element: Element; offsetX?: number; offsetY?: number }
	useDragHandle?: boolean
	placeDragHandleLeft?: boolean

	// Context Menu
	contextMenu?: IContextMenu
	buildContextMenuContent?: (selectedItemIds: string[]) => IContextMenu
	onContextMenu?: (id: string, e: React.MouseEvent) => void

	screenTip?: string

	// A11y
	aria?: {
		label?: string
		labeledBy?: string
	}
	tabStop?: boolean

	dataAttributes?: Record<string, string>
	listRef?: React.Ref<HTMLDivElement>
}

const classes = createStyle((theme) => ({
	// General
	list: {
		flex: 1,
		overflow: 'auto',
		userSelect: 'none',
		outline: 'none',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
	disabled: {
		background: theme.colors.input.disabledBackground,
		color: theme.colors.button.disabledText,
	},
	emptyListMessage: {
		position: 'absolute',
		inset: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center	',
		fontStyle: 'italic',
		color: theme.palette.foreground.neutralDark,
	},
	// DnD
	dropTarget: {
		display: 'contents',
	},
	paddingBottomForDropIndicator: {
		paddingBottom: '1px',
	},
}))

export function List<T = never>(props: IListProps<T>) {
	const {
		items,
		disabled,
		selectionMode = 'none',
		rowClickSelectionMode = 'toggle',
		selectionFollowsFocus = selectionMode === 'single' || rowClickSelectionMode === 'replace',
		activateOnSingleClick = selectionMode === 'none',
		cycleKeyboardNavigation = true,
		preventEmptySelection = false,
		hideSelectionColors = props.displayCheckbox,
		isSearchMatch,
		multilineText = 2,
		onListKeyDown,
		onStartDrag,
	} = props
	const {
		onKeyDown,
		onKeyUp,
		onItemClick,
		selectedIdsRef,
		setFocusedItemId,
		onCheck,
		focusedItemId,
		setItemFocusOnListReceivesFocus,
		firstSelectedIdRef,
		setScrollToFirstSelected,
		scrollToFirstSelected,
	} = useSelectionFocusActivationModel({
		items,
		rowClickSelectionMode,
		selectionMode,
		selectionFollowsFocus,
		preventEmptySelection,
		activateOnSingleClick,
		cycleKeyboardNavigation,
		selectedIds: props.selectedItemIds,
		onSelectionChange: props.onSelectionChange,
		onActivate: props.onActivate,
		isSearchMatch,
		onListKeyDown,
		disabled,
	})

	// Context Menu
	const contextMenu = props.buildContextMenuContent
		? props.buildContextMenuContent(selectedIdsRef.current)
		: props.contextMenu
	const onContextMenu = useContextMenu(contextMenu?.items ? contextMenu.items : [])
	const handleOnContextMenu = (e: React.MouseEvent) => {
		if (props.onContextMenu) {
			focusedItemId && props.onContextMenu(focusedItemId, e)
		} else if (contextMenu?.items.length) {
			onContextMenu(e)
		}
	}

	// DnD
	const potentialDropIndicator = !!props.handleCanDrop || !!props.handleOnDrop
	const [insertIndex, handleDropHover, handleOnDrop, handleDragLeave, handleCanDrop] = useDropObject<IListNode<T>>(
		props.items,
		props.handleOnDrop,
		props.handleCanDrop
	)

	const startDrag = useCallback(
		(id: string, monitor: IDragSourceMonitor) => {
			if (!onStartDrag) {
				return
			}
			return onStartDrag(id, monitor.internalMonitor?.dataTransfer)
		},
		[onStartDrag]
	)

	const listRef = useForwardedRef(props.listRef)

	// Scroll to first selected item on mount and on selectedItemIds-prop change
	useEffect(() => {
		const firstSelectedId = firstSelectedIdRef.current
		if (scrollToFirstSelected) {
			if (firstSelectedId && listRef.current) {
				const listNode = listRef.current.querySelector(`[data-rowid="${firstSelectedId}"]`)
				if (listNode instanceof HTMLElement && listNode.offsetParent instanceof HTMLElement) {
					const listNodeRect = listNode.getBoundingClientRect()
					const listRect = listRef.current.getBoundingClientRect()
					// Check if the list node is outside the visible area of the list.
					if (listNodeRect.top < listRect.top || listNodeRect.bottom > listRect.bottom) {
						// Can't use element.offsetTop directly because it is relative to the offsetParent which is the wrapping DropIndicator.
						listRef.current.scrollTop = listNode.offsetParent.offsetTop
					}
				}
			}
			setScrollToFirstSelected(false)
		}
	}, [scrollToFirstSelected, listRef, firstSelectedIdRef, setScrollToFirstSelected])

	// Focus handling
	const focusedItemRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		if (focusedItemRef.current && !focusedItemRef.current.contains(document.activeElement)) {
			focusedItemRef.current.focus({ preventScroll: true })
			focusedItemRef.current.scrollIntoView({ block: 'nearest' })
		}
	}, [focusedItemId])

	const onListReceivesFocus = (e: React.FocusEvent) => {
		if (e.target !== listRef.current || listRef.current?.contains(e.relatedTarget)) {
			// Item received or lost focus.
			return
		}
		setItemFocusOnListReceivesFocus()
	}
	const onListLosesFocus = (e: React.FocusEvent) => {
		if (!listRef.current?.contains(e.relatedTarget)) {
			setFocusedItemId(undefined)
		}
	}

	const renderListItem = (item: IListNode<T>, siblingIndex: number, siblingCount: number, groupIndex?: number) => {
		const selectedIds = selectedIdsRef.current
		const isSelected = !!item.id && !!selectedIds.includes(item.id)
		const isFocused = focusedItemId === item.id

		const isFirstItem = siblingIndex === 0 && (groupIndex || 0) === 0
		const firstSelectedId = firstSelectedIdRef.current
		const isFirstSelected = firstSelectedId === item.id
		const tabStop =
			!props.disabled &&
			focusedItemId === undefined &&
			(isFirstSelected || (firstSelectedId === undefined && isFirstItem))

		const dropIndicator = getDropIndicator(insertIndex, siblingIndex, groupIndex)

		return (
			<ListNode<T>
				key={`${siblingIndex}${item.id ? '-' + item.id : ''}`}
				item={item}
				selected={isSelected}
				focused={isFocused}
				multiLine={multilineText}
				borderBelowItem={props.borderBetweenItems && siblingIndex !== siblingCount - 1}
				onClick={onItemClick}
				onDoubleClick={!activateOnSingleClick && props.onActivate ? props.onActivate : undefined}
				onFocus={setFocusedItemId}
				itemRenderer={props.itemRenderer}
				onCheck={onCheck}
				displayCheckbox={props.displayCheckbox}
				checkboxRounded={props.checkboxRounded}
				placeCheckboxRight={props.placeCheckboxRight}
				hideSelectionColor={hideSelectionColors}
				tabStop={tabStop}
				nodeRef={isFocused ? focusedItemRef : undefined}
				enableDrag={props.dragSourceType !== undefined}
				useDragHandle={props.useDragHandle}
				placeDragHandleLeft={props.placeDragHandleLeft}
				dragCompleted={props.dragCompleted}
				startDrag={startDrag}
				customPreview={props.customPreview}
				dragSourceType={props.dragSourceType}
				siblingIndex={siblingIndex}
				siblingCount={siblingCount}
				dropIndicator={dropIndicator}
				zebraStriped={props.zebraStripes && siblingIndex % 2 === 1}
				disabled={disabled}
			>
				{renderListItemChildren(item, groupIndex)}
			</ListNode>
		)
	}
	const renderListItemChildren = (item: IListNode<T>, groupIndex?: number) => {
		if (item.type === e_ListNode.section) {
			return item.children?.map((child, i) => renderListItem(child, i, item.children.length, groupIndex))
		}
	}

	const style = useMemo(
		() =>
			props.itemPadding !== undefined ? ({ '--item-padding': props.itemPadding } as React.CSSProperties) : undefined,
		[props.itemPadding]
	)

	return (
		<DropTarget
			className={classes.dropTarget}
			dropTargetTypes={props.dropTargetTypes}
			onDrop={handleOnDrop}
			onHover={handleDropHover}
			onCanDrop={handleCanDrop}
			onDragLeave={handleDragLeave}
			disableDrop={disabled}
		>
			<div
				className={classNames(
					classes.list,
					disabled && classes.disabled,
					potentialDropIndicator && classes.paddingBottomForDropIndicator
				)}
				role="listbox"
				aria-multiselectable={props.selectionMode === 'multi'}
				aria-disabled={disabled}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
				onFocus={onListReceivesFocus}
				onBlur={onListLosesFocus}
				ref={listRef}
				tabIndex={props.tabStop && !props.disabled ? 0 : undefined}
				onContextMenu={handleOnContextMenu}
				title={props.screenTip}
				style={style}
				{...props.dataAttributes}
			>
				{items.map((item, i) => {
					return renderListItem(item, i, items.length, item.type === e_ListNode.section ? i : undefined)
				})}
				{!items.length && props.emptyListMessage && (
					<div className={classes.emptyListMessage} role="presentation">
						{props.emptyListMessage}
					</div>
				)}
			</div>
		</DropTarget>
	)
}

const getDropIndicator = (insertIndex: IInsertIndex | undefined, siblingIndex: number, groupIndex?: number) => {
	const hasInsertInGroup = insertIndex && insertIndex?.groupIndex === groupIndex
	const insertBeforeItem = hasInsertInGroup && insertIndex?.index === 0 && siblingIndex === 0

	if (insertBeforeItem) {
		return 'top'
	}

	const insertAfterItem = hasInsertInGroup && insertIndex?.index === siblingIndex + 1

	if (insertAfterItem) {
		return 'bottom'
	}

	return undefined
}
