import { Button, Divider, Spinner, Text } from '@genusbiz/web-ui/controls'
import React, { useDeferredValue, useMemo, useState } from 'react'
import { LogContainer } from '../../Workloads/Pods/LogContainer'
import { createStyle } from 'src/theming'
import Ajv, { AnySchema, ValidateFunction } from 'ajv'
import { useDispatch } from 'react-redux'
import { versionDeploymentActions } from 'src/features/VersionDeployment/duck/versionDeploymentActions'
import { useTranslation } from 'react-i18next'
import { GridLayout } from '@genusbiz/web-ui/surfaces'
import { e_GridTrackType, IGridLayout } from '@genusbiz/web-ui/surfaces/Grid/gridTypes'
import { getErrorHighlights } from '../utils/getErrorHighlights'
import yaml, { YAMLException } from 'js-yaml'
import { spreadFromTo } from '../utils/CodeMirrorUtils'
import { helmValueParser } from '../../Workloads/Pods/yamlLr/yamlParser'
import { operatorApi } from 'src/modules/operatorApi/operatorApi'
import { ErrorListPanel } from './ErrorListPanel'
import { e_ChartName } from 'src/interfaces/IContainerRegistry'
import { commonStyles } from '../utils/commonStyle'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import classNames from 'clsx'

interface IHelmConfigurationProps {
	runtimeName: string
	versionName: string
	values: string | undefined
	schema: AnySchema | undefined
	chartName: e_ChartName
	showMain: () => void
}
const classes = createStyle((theme) => ({
	spinner: {
		margin: '40px auto',
	},
	configItemText: {
		margin: theme.spacing.size1,
		justifySelf: 'center',
		width: 'fit-content',
	},
	mainGrid: {
		columnGap: theme.spacing.size5,
		rowGap: theme.spacing.size3,
		margin: `${theme.spacing.size2} ${theme.spacing.size5}`,
		flex: '1px',
		marginBottom: theme.spacing.size10,
	},
	sectionHeader: {
		marginBottom: theme.spacing.size2,
	},
	actionBar: {
		display: 'flex',
		columnGap: theme.spacing.size2,
		marginTop: theme.spacing.size2,
	},
}))

enum e_LoadingGridAreas {
	actionBar = 'action_bar',
	divider = 'divider',
	schemaLoading = 'schema_loading',
	valuesLoading = 'values_loading',
	spinner = 'spinner',
}

const CONFIG_LOADING_GRID: IGridLayout[] = [
	{
		rows: [
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.fraction, length: 1 },
		],
		columns: [{ type: e_GridTrackType.fraction, length: 1 }],
		areas: {
			[e_LoadingGridAreas.actionBar]: undefined,
			[e_LoadingGridAreas.divider]: undefined,
			[e_LoadingGridAreas.schemaLoading]: undefined,
			[e_LoadingGridAreas.valuesLoading]: undefined,
			[e_LoadingGridAreas.spinner]: undefined,
		},
	},
]

enum e_ConfigGridAreas {
	values = 'values',
	errors = 'errors',
	actionBar = 'action_bar',
	divider = 'divider',
}

const CONFIG_GRID: IGridLayout[] = [
	{
		rows: [
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.fraction, length: 1 },
		],
		columns: [
			{ type: e_GridTrackType.fraction, length: 2 },
			{ type: e_GridTrackType.fraction, length: 1 },
		],
		areas: {
			[e_ConfigGridAreas.actionBar]: { row: 1, col: 1, colSpan: 2, rowSpan: 1 },
			[e_ConfigGridAreas.divider]: { row: 2, col: 1, colSpan: 2, rowSpan: 1 },
			[e_ConfigGridAreas.values]: { row: 3, col: 1, colSpan: 1, rowSpan: 1 },
			[e_ConfigGridAreas.errors]: { row: 3, col: 2, colSpan: 1, rowSpan: 1 },
		},
	},
	{
		maxWidth: 600,
		rows: [
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
		],
		columns: [{ type: e_GridTrackType.fraction, length: 1 }],
		areas: {
			[e_ConfigGridAreas.actionBar]: undefined,
			[e_ConfigGridAreas.divider]: undefined,
			[e_ConfigGridAreas.values]: undefined,
			[e_ConfigGridAreas.errors]: undefined,
		},
	},
]

const HORIZONTAL_DIVIDER_STYLE = { height: '2px' }

const ajv = new Ajv({ allErrors: true, verbose: true, allowUnionTypes: true, strict: false })

export const HelmConfiguration = (props: IHelmConfigurationProps) => {
	const { t } = useTranslation()

	const { schema, values, chartName } = props

	const [editableHelmValue, setEditableHelmValue] = useState(values)

	const isLoadingSchemaFailed = schema === false
	const isLoadingValuesFailed = values === '404'

	const isLoading = schema === undefined || !values
	const canShowConfiguration = values && !isLoadingValuesFailed

	const valueErrors = useValueErrors(schema ?? false, editableHelmValue ?? values ?? '')

	const lockableSave = useLockableSave(values, editableHelmValue, chartName, props.runtimeName, props.versionName)

	const saveValueChange = () => {
		lockableSave.saveChanges()
		props.showMain()
	}

	if (!canShowConfiguration) {
		return (
			<GridLayout gridConfig={CONFIG_LOADING_GRID} className={classes.mainGrid}>
				<div data-gridname={e_LoadingGridAreas.actionBar} className={classes.actionBar}>
					<Button onClick={props.showMain} className={commonStyles.classes.smallButton} label={t('GENERAL:RETURN')} />
				</div>

				<Divider style={HORIZONTAL_DIVIDER_STYLE} data-gridname={e_LoadingGridAreas.divider} />

				<Text
					className={classNames(classes.configItemText, commonStyles.classes.h3Font)}
					data-gridname={e_LoadingGridAreas.schemaLoading}
				>
					{isLoadingSchemaFailed ? t('UPGRADE:LOADING_SCHEMA_FAILED') + ` for ${props.versionName}` : null}
				</Text>
				<Text
					className={classNames(classes.configItemText, commonStyles.classes.h3Font)}
					data-gridname={e_LoadingGridAreas.valuesLoading}
				>
					{isLoadingValuesFailed ? t('UPGRADE:LOADING_VALUES_FAILED') + ` for ${props.runtimeName}` : null}
				</Text>
				{isLoading && <Spinner size="large" className={classes.spinner} data-gridname={e_LoadingGridAreas.spinner} />}
			</GridLayout>
		)
	} else {
		return (
			<GridLayout gridConfig={CONFIG_GRID} className={classes.mainGrid}>
				<div data-gridname={e_ConfigGridAreas.actionBar} className={classes.actionBar}>
					<Button onClick={props.showMain} className={commonStyles.classes.smallButton} label={t('GENERAL:CANCEL')} />
					<Button
						disabled={!!valueErrors?.yaml?.error || !editableHelmValue || lockableSave.isLocked}
						variant="primary"
						onClick={saveValueChange}
						className={commonStyles.classes.smallButton}
						label={t('GENERAL:SAVE')}
					/>
				</div>

				<Divider style={HORIZONTAL_DIVIDER_STYLE} data-gridname={e_ConfigGridAreas.divider} />

				<div data-gridname={e_ConfigGridAreas.values} style={{ display: 'flex', flexDirection: 'column' }}>
					<Text className={classNames(classes.sectionHeader, commonStyles.classes.h3Font)}>
						{t('UPGRADE:HELM_VALUES') + ` for ${props.runtimeName}`}
					</Text>
					<LogContainer
						log={editableHelmValue ?? values}
						highlightSections={valueErrors.helm?.marks ?? valueErrors.yaml?.marks}
						unparsedFrom={valueErrors.yaml?.start}
						parser={helmValueParser}
						onChange={(text: string) => setEditableHelmValue(text)}
					/>
				</div>

				<div data-gridname={e_ConfigGridAreas.errors}>
					<ErrorListPanel
						isLoadedSchema={!!schema && !isLoadingSchemaFailed}
						helmErrors={valueErrors.helm?.errors ?? []}
						yamlError={valueErrors.yaml?.error}
					/>
				</div>
			</GridLayout>
		)
	}
}

const useValueErrors = (schema: AnySchema, helmValue: string) => {
	const defferedHelmValue = useDeferredValue(helmValue)
	const validate = useMemo(() => ajv.compile(schema), [schema])

	const valueErrors = useMemo(() => {
		try {
			return { helm: getHelmErrors(validate, defferedHelmValue), yaml: undefined }
		} catch (e) {
			return { yaml: getYamlErrors(e), helm: undefined }
		}
	}, [defferedHelmValue, validate])

	return valueErrors
}

const getHelmErrors = (validate: ValidateFunction, editedValue: string) => {
	const data = yaml.load(editedValue)
	editedValue && validate(data)
	const errors = validate.errors ?? []
	const marks = getErrorHighlights(editedValue, errors)

	return { errors, marks }
}

const getYamlErrors = (e: unknown) => {
	if (!(e instanceof YAMLException)) {
		return
	}

	const { mark, reason } = e
	const spread = spreadFromTo({ line: mark?.line ?? 0, from: 0, to: mark?.column ?? 0 })
	return { marks: [spread], error: reason, start: mark?.position }
}

const useLockableSave = (
	storedValue: string | undefined,
	editedValue: string | undefined,
	chartName: e_ChartName,
	runtimeName: string,
	versionName: string
) => {
	const dispatch = useDispatch()
	const carriageReturnTrimmedValue = useMemo(() => storedValue?.replace(/\r/g, ''), [storedValue])
	const defferedEditedValue = useDeferredValue(editedValue)

	const isLocked = useMemo(() => carriageReturnTrimmedValue === defferedEditedValue, [storedValue, defferedEditedValue])

	const saveChanges = () => {
		if (!defferedEditedValue) {
			return
		}

		dispatch(versionDeploymentActions.setRuntimeHelmValues({ [runtimeName]: defferedEditedValue }))
		dispatch(versionDeploymentActions.validateDeployment(chartName, runtimeName, versionName))
		operatorApi
			.saveValuesForRuntime(runtimeName, defferedEditedValue)
			.catch((error: Error) => dispatch(modalManagerActions.showErrorModal(error)))
	}

	return { saveChanges, isLocked }
}
