import React from 'react'

import type { IFormControl } from '../FormControl'
import { FormControl } from '../FormControl'
import { createStyle } from '../../theming'
import clsx from 'clsx'
import { useDataAttributes } from '../utils/useDataAttributes'
import { useId } from '../hooks/useId'
import { CheckboxInternal } from './CheckboxInternal'

const classes = createStyle((theme) => ({
	checkbox: {
		background: 0,
		padding: '0px',
		alignItems: 'baseline',
		border: 'none',
		display: 'flex',
		cursor: 'pointer',
		color: 'inherit',
		outlineStyle: 'solid',
		outlineColor: 'transparent',
		outlineWidth: 1,
		outlineOffset: -1,
		fontWeight: 'inherit',
		'&:disabled, &[aria-readonly="true"], &[aria-disabled="true"]': {
			cursor: 'default',
		},
		'&:focus-visible': { outlineColor: theme.colors.body.focusBorder },
	},
	checkboxInFormControl: {
		alignSelf: 'flex-start',
		position: 'relative',
		width: '100%',
	},
	checkboxInFormControlLabelReplacement: {
		marginRight: '0.325rem',
	},
	checkBoxPadding: {
		padding: theme.controls.field.padding,
	},
}))

export type ICheckmarkSize = 16 | 18 | 26 | 38

interface ICheckboxProps extends IFormControl {
	index?: number
	className?: string
	valueLabel?: string
	readOnly?: boolean
	screenTip?: string
	hasCheckMarkOnly?: boolean
	disableUniformHeight?: boolean
	wordWrapValueLabel?: boolean
	checkmarkSize?: ICheckmarkSize
	newCheckmark?: boolean
}

interface ICheckboxDeterminateProps extends ICheckboxProps {
	indeterminate?: false
	checked?: boolean
	onChange?: (val: boolean, event: { originalEvent: React.KeyboardEvent | React.MouseEvent }) => void
}

interface ICheckboxIndeterminateProps extends ICheckboxProps {
	indeterminate: true
	checked?: boolean | null
	onChange?: (val: boolean | null, event: { originalEvent: React.KeyboardEvent | React.MouseEvent }) => void
}

export const Checkbox = React.forwardRef(
	(props: ICheckboxDeterminateProps | ICheckboxIndeterminateProps, forwardedRef: React.Ref<HTMLButtonElement>) => {
		const { subText = '', checkmarkSize = 18 } = props

		const id = useId('checkbox', props.id)

		const dataAttributes = useDataAttributes(props.dataAttributes, props.index)

		const onChange = (e: { originalEvent: React.KeyboardEvent | React.MouseEvent }) => {
			if (props.indeterminate) {
				let val: boolean | null
				switch (props.checked) {
					case null:
						val = true
						break
					case false:
						val = null
						break
					default:
						val = false
						break
				}
				props.onChange?.(val, e)
			} else {
				props.onChange?.(!props.checked, e)
			}
		}

		const CheckboxInternalComp = (
			<CheckboxInternal
				id={id}
				ref={forwardedRef}
				dataAttributes={dataAttributes}
				className={clsx(classes.checkbox, {
					[classes.checkBoxPadding]: props.disableUniformHeight && !props.hasCheckMarkOnly,
					[classes.checkboxInFormControl]: !props.hasCheckMarkOnly,
					[classes.checkboxInFormControlLabelReplacement]: !props.hasCheckMarkOnly && !props.valueLabel,
					[props.className || '']: props.className !== undefined,
				})}
				onChange={onChange}
				checkmarkSize={checkmarkSize}
				checked={props.checked}
				disableUniformHeight={props.disableUniformHeight}
				disabled={props.disabled}
				readOnly={props.readOnly}
				valueLabel={props.valueLabel}
				wordWrapValueLabel={props.wordWrapValueLabel}
				isRounded={props.newCheckmark}
				suppressAnimation={props.newCheckmark}
				suppressBackground={props.newCheckmark}
				screenTip={props.screenTip}
			/>
		)

		if (props.hasCheckMarkOnly) {
			return CheckboxInternalComp
		}

		return (
			<FormControl
				id={id}
				label={props.label}
				labelPosition={props.labelPosition}
				hideLabel={props.hideLabel}
				labelProps={props.labelProps}
				labelIcon={props.labelIcon}
				labelIconColor={props.labelIconColor}
				labelBold={props.labelBold}
				labelContentLayout={props.labelContentLayout}
				disabled={props.disabled}
				error={props.error}
				warning={props.warning}
				screenTip={props.screenTip}
				labelSubText={props.labelSubText}
				validationText={props.validationText}
				validationTextPosition={props.validationTextPosition}
				subText={subText}
				reserveHelperTextSpace={props.reserveHelperTextSpace}
				disableBorder
				margin={props.margin}
				readOnly={props.readOnly}
				required={props.required}
				className={props.className}
			>
				{CheckboxInternalComp}
			</FormControl>
		)
	}
)

Checkbox.displayName = 'Checkbox'
