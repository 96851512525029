import { ErrorObject } from 'ajv'
import React from 'react'
import { HelmErrorList } from './HelmErrorList'
import { Icon, Text } from '@genusbiz/web-ui/controls'
import { useTranslation } from 'react-i18next'
import { createStyle } from 'src/theming'
import { commonStyles } from '../utils/commonStyle'
import classNames from 'clsx'

interface IErrorListPanel {
	isLoadedSchema: boolean
	yamlError?: string
	helmErrors: ErrorObject[]
}

const classes = createStyle((theme) => ({
	sectionHeader: {
		marginBottom: theme.spacing.size2,
	},
	centerText: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	yamlIcon: { marginRight: theme.spacing.size2 },
}))

export const ErrorListPanel = (props: IErrorListPanel) => {
	const { t } = useTranslation()

	if (!props.isLoadedSchema) {
		return (
			<div className={classes.centerText}>
				<Text className={classNames(classes.sectionHeader, commonStyles.classes.h3Font)}>
					{t('UPGRADE:LOADING_SCHEMA_FAILED')}
				</Text>
			</div>
		)
	} else if (props.yamlError) {
		return (
			<>
				<Text className={classNames(classes.sectionHeader, commonStyles.classes.h3Font)}>
					{t('UPGRADE:YAML_SYNTAX_ERROR')}
				</Text>
				<Icon iconName={'Fluent-StatusErrorFull'} color={'red'} size="size12" className={classes.yamlIcon} />
				{props.yamlError}
			</>
		)
	} else if (props.helmErrors.length > 0) {
		return (
			<>
				<Text className={classNames(classes.sectionHeader, commonStyles.classes.h3Font)}>
					{t('UPGRADE:VALIDATION_ERRORS')}
				</Text>
				<HelmErrorList errors={props.helmErrors} />
			</>
		)
	} else {
		return (
			<div className={classes.centerText}>
				<Text className={classNames(classes.sectionHeader, commonStyles.classes.h3Font)}>
					{t('UPGRADE:VALID_VALUES')}
				</Text>
			</div>
		)
	}
}
