import { IMaintenanceConfiguration } from 'src/interfaces/IAvailabilityWindow'
import { resolveHostNames, resolveMaintenanceStatusString } from './utils/maintenanceHelpers'
import React from 'react'
import { Button, Text } from '@genusbiz/web-ui/controls'
import { useSelector } from 'react-redux'
import { userSettingsSelectors } from 'src/features/UserSettings/duck/userSettingsSelectors'
import { createStyle } from 'src/theming'
import { defaultColors } from 'src/consts/defaultColors'
import { MaintenanceState } from 'src/enums/e_MaintenanceConfigurationType'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'

interface IMaintenanceStatusDetailsProps {
	maintenanceConfiguration: IMaintenanceConfiguration
	setNewMaintenanceEditorModalOpen: (open: boolean) => void
	confirmCancelMaintenance: () => void
	runtimes: IK8sRuntime[]
	maintenanceState: MaintenanceState
}

const classes = createStyle((theme) => ({
	buttonGroup: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: theme.spacing.size3,
	},
	button: {
		marginRight: theme.spacing.size1,
	},
	deleteButton: {
		borderColor: defaultColors.errorText,
		backgroundColor: defaultColors.errorBackground,
		marginRight: theme.spacing.size1,
	},
	endpointContainer: {
		marginLeft: theme.spacing.size2,
		paddingTop: theme.spacing.size1,
		paddingBottom: theme.spacing.size1,
		width: 'fit-content',
	},
	endpoint: {
		marginTop: theme.spacing.size1,
		marginBottom: theme.spacing.size1,
	},
	icon: {
		marginLeft: theme.spacing.size1,
	},
	container: {
		marginTop: theme.spacing.size2,
		marginLeft: theme.spacing.size1,
	},
}))

export const MaintenanceStatusDetails = (props: IMaintenanceStatusDetailsProps) => {
	const {
		maintenanceConfiguration,
		setNewMaintenanceEditorModalOpen,
		confirmCancelMaintenance,
		runtimes,
		maintenanceState,
	} = props
	const hostNames = resolveHostNames(runtimes)
	const timezone = useSelector(userSettingsSelectors.selectSelecedTimezone)
	const preferredFormattingCulture = useSelector(userSettingsSelectors.selectPreferredFormattingCulture)
	const maintenanceStatusString = resolveMaintenanceStatusString(
		maintenanceConfiguration,
		timezone,
		preferredFormattingCulture
	)

	switch (maintenanceState) {
		case MaintenanceState.Up:
			return (
				<div className={classes.container}>
					<Text variant="body1">{maintenanceStatusString}</Text>
					<div className={classes.endpointContainer}>
						{hostNames.map((hostName) => (
							<div className={classes.endpoint} key={hostName}>
								<Text variant="body1">{hostName}</Text>
							</div>
						))}
						<Text variant="body1">
							{hostNames.length > 1 ? 'are' : 'is'} redirected to{' '}
							<strong>{maintenanceConfiguration.externalURL}</strong>
						</Text>
					</div>
					<div className={classes.buttonGroup}>
						<Button className={classes.button} label="Edit" onClick={() => setNewMaintenanceEditorModalOpen(true)} />
						<Button className={classes.deleteButton} label="Cancel" onClick={confirmCancelMaintenance} />
					</div>
				</div>
			)
		case MaintenanceState.Scheduled:
			return (
				<div className={classes.container}>
					<Text variant="body1">{maintenanceStatusString}</Text>
					<Text variant="body1">
						<strong>Redirect URL: {maintenanceConfiguration.externalURL}</strong>
					</Text>
					<div className={classes.buttonGroup}>
						<Button className={classes.button} label="Edit" onClick={() => setNewMaintenanceEditorModalOpen(true)} />
						<Button className={classes.deleteButton} label="Cancel" onClick={confirmCancelMaintenance} />
					</div>
				</div>
			)
		case MaintenanceState.Down:
			return (
				<div className={classes.container}>
					<Text variant="body1">{maintenanceStatusString}</Text>
					<Button
						variant="primary"
						label="Schedule Maintenance"
						onClick={() => setNewMaintenanceEditorModalOpen(true)}
					/>
				</div>
			)
		default:
			return null
	}
}
