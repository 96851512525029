import React from 'react'
import { useSelector } from 'react-redux'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { HelmConfiguration } from '../components/HelmConfiguration'
import { deploymentUtils } from '../utils/deploymentUtils'
import { useDeploymentNavigation } from '../utils/deploymentNavigation'
import { e_ChartName } from 'src/interfaces/IContainerRegistry'

export const RuntimeHelmConfiguration = () => {
	const versionName = useSelector(versionDeploymentSelectors.selectChosenVersion)?.name
	const runtimeName = useSelector(versionDeploymentSelectors.selectRuntimeSelectedForConfiguration)

	const deploymentNavigation = useDeploymentNavigation()

	const isUndefinedConfigurationParameters = !versionName || !runtimeName
	deploymentNavigation.useNavgiateToRuntimeConditionally(isUndefinedConfigurationParameters)

	const { schema, values } = deploymentUtils.useHelmValuesAndVersionSchema(
		e_ChartName.genus,
		runtimeName ?? '',
		versionName ?? ''
	)

	const versionSchema = schema?.versionName !== undefined && schema.schema === undefined ? false : schema?.schema

	if (versionName && runtimeName) {
		return (
			<HelmConfiguration
				versionName={versionName}
				runtimeName={runtimeName}
				schema={versionSchema}
				values={values}
				chartName={e_ChartName.genus}
				showMain={deploymentNavigation.showRuntimeDeployment}
			/>
		)
	}
}
