import React from 'react'
import { Text } from '@genusbiz/web-ui/controls'
import { createStyle } from 'src/theming'
import { useSelector } from 'react-redux'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { EditRuntimeValuesButton } from './EditRuntimeValuesButton'
import { commonStyles } from '../utils/commonStyle'
import classNames from 'clsx'

const classes = createStyle((theme) => ({
	leftPaddedContent: {
		'@media (min-width: 400px)': {
			paddingLeft: theme.spacing.size2,
		},
	},
	title: {
		marginTop: theme.spacing.size1,
	},
}))

interface IOperatingTypesHelmButtonsProps {
	targets: IK8sRuntime[]
	isValidVersion: boolean
	setChosenHelmTarget: (target: string) => void
	title: string
}

export const OperatingTypesHelmButtons = (props: IOperatingTypesHelmButtonsProps) => {
	const helmValueErrors = useSelector(versionDeploymentSelectors.selectRuntimesValueErrors)

	return (
		<>
			<Text className={classNames(classes.title, commonStyles.classes.h6Font)}>{props.title}</Text>
			<div className={classes.leftPaddedContent}>
				{props.targets.map((target) => (
					<EditRuntimeValuesButton
						targetName={target.name}
						isRuntimeWithHelmErrors={runtimeHasErrors(target.name, helmValueErrors)}
						isValidVersion={props.isValidVersion}
						setChosenHelmTarget={props.setChosenHelmTarget}
						key={target.name}
					/>
				))}
			</div>
		</>
	)
}

type IHelmValidationErrors = ReturnType<typeof versionDeploymentSelectors.selectRuntimesValueErrors>

const runtimeHasErrors = (runtimeName: string, errors: IHelmValidationErrors) =>
	errors[runtimeName] !== undefined && errors[runtimeName].length > 0
