/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from '@reduxjs/toolkit'

import { operatorFrontendRootReducer } from './features/OperatorFrontend/duck/operatorFrontendReducer'
import { modalManagerReducer } from './features/ModalManager/duck'
import { authenticationReducer } from './features/Authentication/duck'
import { kubernetesReducer } from './features/Kubernetes/duck/kubernetesReducer'
import { userSettingsRootReducer } from './features/UserSettings/duck/userSettingsReducer'
import { versionDeploymentReducer } from './features/VersionDeployment/duck/versionDeploymentReducer'
/**
 * Creates the main reducer with the dynamically injected ones
 * @returns  combineReducers
 */
export const rootReducer = () =>
	combineReducers({
		operatorFrontend: operatorFrontendRootReducer,
		modalManager: modalManagerReducer,
		authentication: authenticationReducer,
		kubernetes: kubernetesReducer,
		userSettings: userSettingsRootReducer,
		versionDeployment: versionDeploymentReducer,
	})

type OperatorRootReducer = ReturnType<typeof rootReducer>

export type IOperatorRootState = ReturnType<OperatorRootReducer>
