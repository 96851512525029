import React from 'react'
import { Button, Text, Icon } from '@genusbiz/web-ui/controls'
import { createStyle } from 'src/theming'
import { useTranslation } from 'react-i18next'
import { commonStyles } from '../utils/commonStyle'

const classes = createStyle((theme) => ({
	configItemContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	configItemText: {
		width: '180px',
		marginTop: theme.spacing.size1,
		marginBottom: theme.spacing.size1,
	},
	leftMarginContent: {
		marginLeft: theme.spacing.size2,
	},
	leftMarginContent2: {
		marginLeft: theme.spacing.size8,
	},
}))

interface IEditRuntimeValuesButtonProps {
	targetName: string
	isValidVersion: boolean
	setChosenHelmTarget: (target: string) => void
	isRuntimeWithHelmErrors: boolean
}

export const EditRuntimeValuesButton = (props: IEditRuntimeValuesButtonProps) => {
	const { t } = useTranslation()

	return (
		<div className={classes.configItemContainer}>
			<Text variant="body1" className={classes.configItemText}>
				{props.targetName}
			</Text>
			<Button
				className={commonStyles.classes.smallButton}
				variant="default"
				onClick={() => props.setChosenHelmTarget(props.targetName)}
				disabled={!props.isValidVersion}
				icon="Fluent-Settings"
				iconPosition="right"
				label={t('GENERAL:EDIT')}
			/>
			{props.isRuntimeWithHelmErrors && (
				<>
					<Icon
						iconName={'Fluent-StatusErrorFull'}
						color={'red'}
						size="size20"
						className={classes.leftMarginContent2}
					/>
					<Text variant="body1" className={classes.leftMarginContent}>
						{t('UPGRADE:INVALID_HELM')}
					</Text>
				</>
			)}
		</div>
	)
}
