import type { Palette } from '../theming/themeProperties/colors/paletteGenerator'
import { hex2Hsl, hex2Rgb } from '../theming/themeProperties/colors/colorUtils'
import { cssVar } from './themingManager'
import tinycolor from 'tinycolor2'

export type SemanticColors = ReturnType<typeof mapSemanticColorsFromPalette>

export const getBestTextColors = (backgroundColors: string[], textColors: string[]) => {
	return backgroundColors.map((backgroundColor) => tinycolor.mostReadable(backgroundColor, textColors).toHexString())
}

export const mapSemanticColorsFromPalette = (palette: Palette) => {
	const { primary, foreground, background, controlForeground, controlBackground, error, warning, success, info } =
		palette

	const baseBackground = background?.white ?? controlBackground.white
	const baseForeground = foreground?.neutralPrimary ?? controlForeground.neutralPrimary

	// AppBar text should always be 'light', even if background is light/dark
	const backgroundIsDark = hex2Hsl(baseBackground).l < 0.5
	const dynamicBrightColor = backgroundIsDark ? baseForeground : baseBackground
	const dynamicDarkColor = backgroundIsDark ? baseBackground : baseForeground
	const darkRGB = hex2Rgb(dynamicDarkColor)

	const primaryLightness = hex2Hsl(primary.themePrimary).l
	const primaryIsDark = hex2Hsl(primary.themePrimary).l < 0.8

	const foregroundLightness = hex2Hsl(baseForeground).l
	const backgroundLightness = hex2Hsl(baseBackground).l
	const deltaLightnessPrimaryForeground = Math.abs(primaryLightness - foregroundLightness)
	const deltaLightnessPrimaryBackground = Math.abs(primaryLightness - backgroundLightness)
	const primaryTextContrastColor =
		deltaLightnessPrimaryForeground > deltaLightnessPrimaryBackground ? baseForeground : baseBackground

	const errorTextColors = palette.error
		? getBestTextColors(
				[palette.error.errorPrimary, palette.error.errorLight, palette.error.errorDark],
				[foreground.neutralPrimary, background.white]
		  )
		: [foreground.neutralPrimary, background.white]

	const warningTextColors = palette.warning
		? getBestTextColors(
				[palette.warning.warningPrimary, palette.warning.warningLight, palette.warning.warningDark],
				[foreground.neutralPrimary, background.white]
		  )
		: [foreground.neutralPrimary, background.white]

	const successTextColors = palette.success
		? getBestTextColors(
				[palette.success.successPrimary, palette.success.successLight, palette.success.successDark],
				[foreground.neutralPrimary, background.white]
		  )
		: [foreground.neutralPrimary, background.white]

	const infoTextColors = palette.info
		? getBestTextColors(
				[palette.info.infoPrimary, palette.info.infoLight, palette.info.infoDark],
				[foreground.neutralPrimary, background.white]
		  )
		: [foreground.neutralPrimary, background.white]

	return {
		appBar: {
			background: cssVar(primary, 'themePrimary'),
			hoveredBackground: primaryIsDark ? cssVar(primary, 'themeDarkAlt') : cssVar(primary, 'themeLighterAlt'),
			checkedBackground: primaryIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeLighterAlt'),
			checkedHoveredBackground: primaryIsDark ? cssVar(primary, 'themeDarker') : cssVar(primary, 'themeLight'),
			pressedBackground: primaryIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeTertiary'),
			text: primaryTextContrastColor,
		},
		notificationPopup: {
			background: cssVar(primary, 'themePrimary'),
			text: primaryTextContrastColor,
		},

		body: {
			background: cssVar(background, 'white'),
			backgroundAlt: cssVar(background, 'neutralLighterAlt'),
			// selected backgrounds - might be removed
			selectedBackground: cssVar(primary, 'themeLighter'),

			selectedBorder: cssVar(primary, 'themePrimary'),
			divider: cssVar(background, 'neutralQuaternaryAlt'),
			line: cssVar(background, 'neutralLighter'),

			text: cssVar(foreground, 'neutralPrimary'),
			subtext: cssVar(foreground, 'neutralSecondary'),
			disabledText: cssVar(foreground, 'neutralTertiary'),
			errorText: cssVar(error, 'errorPrimary'),
			warningText: cssVar(warning, 'warningPrimary'),
			successText: cssVar(success, 'successPrimary'),
			infoText: cssVar(info, 'infoPrimary'),

			focusBorder: cssVar(foreground, 'neutralPrimary'),

			error: {
				primary: {
					background: cssVar(palette.error, 'errorPrimary'),
					text: errorTextColors[0],
				},

				light: {
					background: cssVar(palette.error, 'errorLight'),
					text: errorTextColors[1],
				},
				dark: {
					background: cssVar(palette.error, 'errorDark'),
					text: errorTextColors[2],
				},
			},
			warning: {
				primary: {
					background: cssVar(palette.warning, 'warningPrimary'),
					text: warningTextColors[0],
				},

				light: {
					background: cssVar(palette.warning, 'warningLight'),
					text: warningTextColors[1],
				},
				dark: {
					background: cssVar(palette.warning, 'warningDark'),
					text: warningTextColors[2],
				},
			},
			success: {
				primary: {
					background: cssVar(palette.success, 'successPrimary'),
					text: successTextColors[0],
				},

				light: {
					background: cssVar(palette.success, 'successLight'),
					text: successTextColors[1],
				},
				dark: {
					background: cssVar(palette.success, 'successDark'),
					text: successTextColors[2],
				},
			},

			info: {
				primary: {
					background: cssVar(palette.info, 'infoPrimary'),
					text: infoTextColors[0],
				},

				light: {
					background: cssVar(palette.info, 'infoLight'),
					text: infoTextColors[1],
				},
				dark: {
					background: cssVar(palette.info, 'infoDark'),
					text: infoTextColors[2],
				},
			},
		},
		links: {
			text: cssVar(primary, 'themePrimary'),
			hoveredText: cssVar(primary, 'themeDarker'),
		},
		actionLinks: {
			text: cssVar(foreground, 'neutralPrimary'),
			hoveredText: cssVar(foreground, 'neutralDark'),
		},
		badge: {
			text: dynamicBrightColor,
			background: cssVar(error, 'errorPrimary'),
		},

		button: {
			background: cssVar(controlBackground, 'white'),
			border: cssVar(controlBackground, 'neutralTertiaryAlt'),
			primary: cssVar(primary, 'themePrimary'),
			text: cssVar(controlForeground, 'neutralPrimary'),
			inlineText: cssVar(foreground, 'neutralPrimary'),
			outline: cssVar(foreground, 'neutralTertiary'),
			primaryColor: primaryTextContrastColor,
			hoveredBackground: cssVar(controlBackground, 'neutralLight'),
			hoveredInlineBackground: cssVar(background, 'neutralLight'),
			hoveredText: cssVar(controlForeground, 'neutralPrimary'),
			hoveredInlineText: cssVar(foreground, 'neutralPrimary'),
			primaryHoverBackground: primaryIsDark ? cssVar(primary, 'themeDarkAlt') : cssVar(primary, 'themeLighterAlt'),

			focusBorder: cssVar(controlForeground, 'neutralTertiary'),

			pressedBackground: cssVar(controlBackground, 'neutralQuaternaryAlt'),
			pressedInlineBackground: cssVar(background, 'neutralQuaternaryAlt'),
			pressedText: cssVar(controlForeground, 'neutralPrimary'),
			pressedInlineText: cssVar(foreground, 'neutralPrimary'),
			primaryPressedBackground: primaryIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeTertiary'),
			checkedBackground: cssVar(controlBackground, 'neutralQuaternary'),
			checkedInlineBackground: cssVar(background, 'neutralQuaternary'),
			primaryCheckedBackground: primaryIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeLighterAlt'),

			checkedText: cssVar(controlForeground, 'neutralDark'),
			checkedInlineText: cssVar(foreground, 'neutralDark'),

			checkedHoveredBackground: cssVar(controlBackground, 'neutralTertiaryAlt'),
			checkedHoveredInlineBackground: cssVar(background, 'neutralTertiaryAlt'),
			checkedHoveredText: cssVar(controlForeground, 'neutralPrimary'),
			checkedHoveredInlineText: cssVar(foreground, 'neutralPrimary'),
			primaryCheckedHoveredBackground: primaryIsDark ? cssVar(primary, 'themeDarker') : cssVar(primary, 'themeLight'),

			disabledBackground: cssVar(controlBackground, 'neutralLighter'),
			disabledBorder: cssVar(controlBackground, 'neutralLighter'),
			disabledText: cssVar(controlForeground, 'neutralTertiary'),
			disabledInlineText: cssVar(foreground, 'neutralTertiary'),
		},
		modal: {
			background: `rgba(${darkRGB.r}, ${darkRGB.g}, ${darkRGB.b}, 0.3)`,
		},
		toolbar: {
			background: cssVar(background, 'neutralLighter'),
			text: cssVar(foreground, 'black'),
			hoveredBackground: cssVar(background, 'neutralLight'),
			hoveredText: cssVar(foreground, 'neutralDark'),
			pressedBackground: cssVar(background, 'neutralQuaternaryAlt'),
			pressedText: cssVar(foreground, 'neutralDark'),
			checkedBackground: cssVar(background, 'neutralTertiaryAlt'),
			checkedText: cssVar(foreground, 'neutralDark'),
			checkedHoveredBackground: cssVar(background, 'neutralLight'),
			checkedHoveredText: cssVar(foreground, 'black'),
		},
		input: {
			background: cssVar(controlBackground, 'white'),
			buttonBackground: cssVar(controlBackground, 'white'),
			checkedBackground: cssVar(primary, 'themePrimary'),
			checkedHoveredBackground: cssVar(primary, 'themeDarkAlt'),
			disabledBackground: cssVar(controlBackground, 'neutralLighter'),

			border: cssVar(controlForeground, 'neutralTertiary'),
			hoveredBorder: cssVar(foreground, 'neutralPrimary'),
			focusBorder: cssVar(primary, 'themePrimary'),

			text: cssVar(controlForeground, 'neutralPrimary'),
			placeholderText: cssVar(controlForeground, 'neutralSecondary'),
			disabledText: cssVar(controlForeground, 'neutralTertiary'),
		},
		list: {
			background: cssVar(background, 'white'),
			hoveredBackground: cssVar(background, 'neutralLighter'),
			checkedBackground: cssVar(background, 'neutralLight'),
			checkedHoveredBackground: cssVar(background, 'neutralQuaternaryAlt'),
			selectedBackground: cssVar(background, 'neutralLight'),
			selectedHoveredBackground: cssVar(background, 'neutralTertiaryAlt'),
			pressedBackground: cssVar(background, 'neutralLight'),

			border: cssVar(background, 'neutralLight'),
			selectedBorder: cssVar(primary, 'themePrimary'),
			divider: cssVar(background, 'neutralLighter'),

			text: cssVar(foreground, 'neutralPrimary'),
		},
		menu: {
			background: cssVar(controlBackground, 'white'),
			hoveredBackground: cssVar(background, 'neutralLighter'),
			pressedBackground: cssVar(background, 'neutralLight'),

			border: cssVar(background, 'neutralTertiaryAlt'),
			divider: cssVar(background, 'neutralQuaternaryAlt'),

			text: cssVar(foreground, 'neutralPrimary'),
			hoveredText: cssVar(foreground, 'neutralDark'),
		},
		panel: {
			background: cssVar(background, 'neutralLighter'),
			hoveredBakground: cssVar(background, 'neutralLight'),
			border: cssVar(background, 'neutralQuaternaryAlt'),
			text: cssVar(foreground, 'neutralPrimary'),
		},
		dataItem: {
			background: backgroundIsDark ? cssVar(primary, 'themeDarker') : cssVar(primary, 'themeLighter'),
			hoveredBakground: backgroundIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeLight'),

			selectedBackground: backgroundIsDark ? cssVar(primary, 'themeDarkAlt') : cssVar(primary, 'themeTertiary'),
			hoveredSelectedBackground: backgroundIsDark ? cssVar(primary, 'themeDark') : cssVar(primary, 'themeDarker'),
			selectedBorder: cssVar(foreground, 'black'),
		},
	}
}
