import { all, put, takeLatest, call, select } from 'redux-saga/effects'
import { versionDeploymentSelectors } from './versionDeploymentSelectors'
import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import { operatorApi } from 'src/modules/operatorApi/operatorApi'
import { IDeployment } from 'src/interfaces/IDeployment'
import { versionDeploymentTypes } from './versionDeploymentTypes'
import { versionDeploymentActions } from './versionDeploymentActions'
import { e_ChartName, IContainerTags } from 'src/interfaces/IContainerRegistry'
import { IDeploymentJobStatus } from 'src/interfaces/IDeployJob'
import { AnySchema } from 'ajv'
import {
	IFetchConfiguration,
	IDeployVersion,
	IAbortDeployment,
	IFetchVersionOptions,
} from './IVersionDeploymentActions'
import { validateHelmValues } from 'src/components/VersionDeployment/utils/validateHelmValues'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { IBaseK8sRuntime, IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import { kubernetesTypes } from 'src/features/Kubernetes/duck/kubernetesTypes'
import { e_DeploymentJobState } from 'src/enums/e_DeploymentJobState'
import { getKeysOfValidObjects, mapMultipleValues, recordFromArray } from 'src/components/VersionDeployment/utils/utils'
import mapValues from 'lodash/mapValues'
import { operatorFrontendTypes } from 'src/features/OperatorFrontend/duck/operatorFrontendTypes'
import { getVersionDeploymentEnabled, getVersionDeploymentMethod } from '../utils/getVersionDeploymentEnvVars'
import { e_VersionDeploymentMetod } from 'src/enums/e_VersionDeploymentMethod'
import { AxiosError } from 'axios'

type _GeneratorReturnType<G extends Generator> = G extends Generator<any, infer R, any> ? R : never
type GeneratorReturnType<G extends (...args: any) => Generator> = G extends (...args: any) => Generator
	? _GeneratorReturnType<ReturnType<G>>
	: never

type PromiseReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R>
	? R
	: never

function* onStart() {
	yield tryFetchOperatorVersionOptions()
}

function* onSetRuntimes() {
	const versionDeploymentEnabled = getVersionDeploymentEnabled()

	if (versionDeploymentEnabled) {
		yield fetchDeploymentVersions()
		yield fetchDeployJobStates()
		yield disableSelectionOfOperatingTypesWithActiveDeployment()
	}
}

function* onFetchVersionOptions(action: IFetchVersionOptions) {
	switch (action.chartName) {
		case e_ChartName.genus: {
			const versionInfo = (yield tryFetchVersionOptions(action.chartName)) as GeneratorReturnType<
				typeof tryFetchVersionOptions
			>
			yield put(versionDeploymentActions.setVersionOptions(versionInfo))
			break
		}
		case e_ChartName.genusOperator: {
			const versionInfo = (yield tryFetchVersionOptions(action.chartName)) as GeneratorReturnType<
				typeof tryFetchVersionOptions
			>
			yield put(versionDeploymentActions.setOperatorVersionOptions(versionInfo))
			break
		}
	}
}

function* tryFetchOperatorVersionOptions() {
	const versionInfo = (yield tryFetchVersionOptions(e_ChartName.genusOperator)) as GeneratorReturnType<
		typeof tryFetchVersionOptions
	>
	yield put(versionDeploymentActions.setOperatorVersionOptions(versionInfo))
}

function* tryFetchVersionOptions(chartName: e_ChartName) {
	try {
		const versionInfo = (yield call(operatorApi.fetchVersionInfo, chartName)) as IContainerTags
		return versionInfo
	} catch (error) {
		put(modalManagerActions.showErrorModal(error as Error))
		return undefined
	}
}

function* onFetchConfiguration(action: IFetchConfiguration) {
	yield loadSchemaAndValues(action.chartName, [action.runtimeName], action.versionName)
}

function* onValidateDeployment(action: IFetchConfiguration) {
	yield validateDeployment(action.chartName, action.runtimeName, action.versionName)
}

function* onDeployVersion(action: IDeployVersion) {
	const deploymentMethod = getVersionDeploymentMethod()
	const isGenusOperator = deploymentMethod === e_VersionDeploymentMetod.genusOperator

	yield put(versionDeploymentActions.setIsUnfinishedDeployDispatch(isGenusOperator))
	yield deployRuntimeVersions(action.chartName, action.runtimeNames, action.versionName)
	yield fetchDeployJobStates()

	yield disableSelectionOfOperatingTypesWithActiveDeployment()
	yield put(versionDeploymentActions.setIsUnfinishedDeployDispatch(false))
}

function* onAbortDeployment(action: IAbortDeployment) {
	yield abortDeploymentJobs(action.runtimeNames)
}

function* watcherSagas() {
	yield all([
		takeLatest(operatorFrontendTypes.START, onStart),
		takeLatest(kubernetesTypes.SET_RUNTIME_CONFIGURATIONS, onSetRuntimes),
		takeLatest(versionDeploymentTypes.FETCH_VERSION_OPTIONS, onFetchVersionOptions),
		takeLatest(versionDeploymentTypes.FETCH_CONFIGURAION, onFetchConfiguration),
		takeLatest(versionDeploymentTypes.VALIDATE_DEPLOYMENT, onValidateDeployment),
		takeLatest(versionDeploymentTypes.DEPLOY_VERSION, onDeployVersion),
		takeLatest(versionDeploymentTypes.ABORT_DEPLOYMENT, onAbortDeployment),
	])
}

export const versionDeploymentSagas = {
	watcherSagas,
}

// Utils

const getUniqueDeploymentVersions = (deployments: IDeployment[]) => {
	const uniqueDeployments = new Set(deployments.map((deployment) => getDeploymentVersion(deployment)))

	return [...uniqueDeployments]
}

const getDeploymentVersion = (deployment: IDeployment) =>
	deployment.runtimeConfig.genusVersion ?? deployment.runtimeConfig.genusOperatorVersion ?? ''

function* fetchOperatingTypeDeploymentVersions(
	operatingType: e_EnvironmentOperatingType,
	groupedRuntimes: Partial<Record<e_EnvironmentOperatingType, IK8sRuntime[]>>
) {
	const runtimes = groupedRuntimes[operatingType] ?? []

	try {
		const originDeployments = (yield all(
			runtimes.map((runtime) => call(operatorApi.fetchOperatorDeployments, runtime.name))
		)) as IDeployment[][]

		const deploymentVersions = getUniqueDeploymentVersions(originDeployments.flat())

		return deploymentVersions
	} catch (error) {
		put(modalManagerActions.showErrorModal(error as Error))

		return []
	}
}

function* fetchDeploymentVersions() {
	const runtimes = (yield select(kubernetesSelectors.selectK8sRuntimes)) as ReturnType<
		typeof kubernetesSelectors.selectK8sRuntimes
	>

	const groupedRuntimes = Object.groupBy(runtimes, (runtime) => runtime.currentlyOperatingAs)

	const origin = (yield fetchOperatingTypeDeploymentVersions(
		e_EnvironmentOperatingType.origin,
		groupedRuntimes
	)) as string[]
	const active = (yield fetchOperatingTypeDeploymentVersions(
		e_EnvironmentOperatingType.active,
		groupedRuntimes
	)) as string[]
	const passive = (yield fetchOperatingTypeDeploymentVersions(
		e_EnvironmentOperatingType.passive,
		groupedRuntimes
	)) as string[]

	const deploymentVersions = { origin, active, passive }

	yield put(versionDeploymentActions.setDeploymentVersions(deploymentVersions))
}

function* fetchTargetJobState(targetName: string) {
	const status = (yield call(operatorApi.fetchDeployJobState, targetName)) as IDeploymentJobStatus
	return { targetName, status }
}

function* fetchOperatingTypeJobStates(
	operatingType: e_EnvironmentOperatingType,
	groupedRuntimes: Partial<Record<e_EnvironmentOperatingType, IBaseK8sRuntime[]>>
) {
	const runtimes = groupedRuntimes[operatingType] ?? []

	try {
		return (yield all(runtimes.map((runtime) => fetchTargetJobState(runtime.name)))) as {
			targetName: string
			status: IDeploymentJobStatus
		}[]
	} catch (error) {
		put(modalManagerActions.showErrorModal(error as Error))
		return []
	}
}

function* fetchDeployJobStates() {
	const runtimes = (yield select(kubernetesSelectors.selectK8sRuntimesIncludingOperator)) as ReturnType<
		typeof kubernetesSelectors.selectK8sRuntimesIncludingOperator
	>

	const groupedRuntimes = Object.groupBy(runtimes, (runtime) => runtime.currentlyOperatingAs)

	// JobStates is only applicable to genus operator deployment
	const deploymentMethod = getVersionDeploymentMethod()

	if (deploymentMethod !== e_VersionDeploymentMetod.genusOperator) {
		return
	}

	const origin = (yield fetchOperatingTypeJobStates(
		e_EnvironmentOperatingType.origin,
		groupedRuntimes
	)) as GeneratorReturnType<typeof fetchOperatingTypeJobStates>

	const active = (yield fetchOperatingTypeJobStates(
		e_EnvironmentOperatingType.active,
		groupedRuntimes
	)) as GeneratorReturnType<typeof fetchOperatingTypeJobStates>

	const passive = (yield fetchOperatingTypeJobStates(
		e_EnvironmentOperatingType.passive,
		groupedRuntimes
	)) as GeneratorReturnType<typeof fetchOperatingTypeJobStates>

	const operator = (yield fetchOperatingTypeJobStates(
		e_EnvironmentOperatingType.operator,
		groupedRuntimes
	)) as GeneratorReturnType<typeof fetchOperatingTypeJobStates>

	const jobStates = { origin, active, passive, operator }

	yield put(versionDeploymentActions.setJobStates(jobStates))
}

function* disableSelectionOfOperatingTypesWithActiveDeployment() {
	const jobStates = (yield select(versionDeploymentSelectors.selectJobStates)) as ReturnType<
		typeof versionDeploymentSelectors.selectJobStates
	>
	const chosenOperatingTypes = (yield select(versionDeploymentSelectors.selectChosenOperatingTypes)) as ReturnType<
		typeof versionDeploymentSelectors.selectChosenOperatingTypes
	>

	const newChosenOperatingTypes = mapMultipleValues(
		(isChosen, states) => isChosen && !isSomeOngoingDeployment(states),
		chosenOperatingTypes,
		jobStates
	)

	yield put(versionDeploymentActions.setChosenOperatingTypes(newChosenOperatingTypes))
}

const ongoingStates = [e_DeploymentJobState.inProgress, e_DeploymentJobState.pending]

const isSomeOngoingDeployment = (states: { status: IDeploymentJobStatus }[]) =>
	states.some((state) => ongoingStates.includes(state.status.deploymentJobState))

// Deploy Genus

function* fetchVersionJsonSchema(chartName: e_ChartName, versionName: string) {
	const reduxSchema = (yield select(versionDeploymentSelectors.selectVersionSchema)) as ReturnType<
		typeof versionDeploymentSelectors.selectVersionSchema
	>

	if (reduxSchema?.versionName === versionName) {
		return reduxSchema.schema
	}

	try {
		const schema = (yield call(operatorApi.fetchJsonSchemaForVersion, chartName, versionName)) as AnySchema | undefined

		yield put(versionDeploymentActions.setVersionSchema(versionName, schema))
		return schema
	} catch (e) {
		const error = e as AxiosError
		// Only show this error message if the error is not a 404. This is expected for versions that do not have a schema, i.e. all versions before 11.6
		if (error.response?.status !== 404) {
			yield put(
				modalManagerActions.showNotificationPopup({ message: `Loading Schema for ${chartName}:${versionName} Failed` })
			)
		}
		yield put(versionDeploymentActions.setVersionSchema(versionName))
	}
}

const FAILED_HELM_VALUE_LOAD = '404'

function* getRuntimeValues(
	reduxHelmValues: { [runtimeName: string]: string },
	runtimeName: string,
	forceUpdate?: boolean
) {
	if (!forceUpdate && reduxHelmValues[runtimeName] && reduxHelmValues[runtimeName] !== FAILED_HELM_VALUE_LOAD) {
		return { name: runtimeName, value: reduxHelmValues[runtimeName] }
	}

	try {
		const value = (yield call(operatorApi.fetchValuesForRuntime, runtimeName)) as string | undefined
		if (!value) {
			yield put(versionDeploymentActions.addRuntimeHelmValues(runtimeName, FAILED_HELM_VALUE_LOAD))
			return
		}

		const valuesWithoutCarriageReturn = value.replace(/\r/g, '') // \r removed here to avoid react-codemirror triggered rerender resulting from different value, as Codemirror stores text without carriage return.
		yield put(versionDeploymentActions.addRuntimeHelmValues(runtimeName, valuesWithoutCarriageReturn))

		return { name: runtimeName, value: value }
	} catch (error) {
		yield put(versionDeploymentActions.addRuntimeHelmValues(runtimeName, FAILED_HELM_VALUE_LOAD))

		const onClose = () => put(modalManagerActions.cancel())
		yield put(modalManagerActions.showErrorModal(error as Error, 'CANCEL', onClose))
	}
}

const getValueEntry = (value: GeneratorReturnType<typeof getRuntimeValues>) => value && { [value.name]: value.value }
const mergeDefinedValues = (
	prev: { [name: string]: string } | undefined,
	curr: { [name: string]: string } | undefined
) => prev && curr && { ...prev, ...curr }
const isUnfinishedRecursion = (
	value: GeneratorReturnType<typeof getRuntimeValues>,
	values: GeneratorReturnType<typeof getRuntimeValues>[]
) => value && values.length > 0

const recursiveMergeValidValues = ([value, ...values]: GeneratorReturnType<typeof getRuntimeValues>[]):
	| { [name: string]: string }
	| undefined =>
	isUnfinishedRecursion(value, values)
		? mergeDefinedValues(getValueEntry(value), recursiveMergeValidValues(values))
		: getValueEntry(value)

function* fetchRuntimeHelmValues(targetK8sRuntimes: string[], forceUpdate?: boolean) {
	const reduxHelmValues = (yield select(versionDeploymentSelectors.selectRuntimesValues)) as ReturnType<
		typeof versionDeploymentSelectors.selectRuntimesValues
	>

	const yieldValues = (yield all(
		targetK8sRuntimes.map((runtime) => getRuntimeValues(reduxHelmValues, runtime, forceUpdate))
	)) as GeneratorReturnType<typeof getRuntimeValues>[]

	const values = recursiveMergeValidValues(yieldValues)

	if (values) {
		return values
	}
}

function* validateSchemaAndValues(
	schema: AnySchema | undefined,
	values: { [runtimeName: string]: string } | undefined
) {
	if (!schema || !values) {
		return false
	}

	let valid = true

	yield all(
		Object.entries(values).map(function* ([runtimeName, value]) {
			const validation = validateHelmValues(schema, value)
			yield put(versionDeploymentActions.setRuntimeValueErrors(runtimeName, validation.errors ?? []))

			if (!validation.validity) {
				valid = false
			}
		})
	)

	if (!valid) {
		yield put(
			modalManagerActions.showNotificationPopup({
				message: `Validation of schema and helm values failed`,
				colorSetId: 'red',
			})
		)
	} else {
		yield put(modalManagerActions.showNotificationPopup({ message: `Valid schema and values` }))
	}

	return valid
}

function* loadSchemaAndValues(chartName: e_ChartName, runtimeNames: string[], versionName: string) {
	const schema = (yield fetchVersionJsonSchema(chartName, versionName)) as GeneratorReturnType<
		typeof fetchVersionJsonSchema
	>
	const values = (yield fetchRuntimeHelmValues(runtimeNames)) as GeneratorReturnType<typeof fetchRuntimeHelmValues>

	return { schema, values }
}

function* validateDeployment(chartName: e_ChartName, runtimeName: string, versionName: string) {
	const { schema, values } = (yield loadSchemaAndValues(chartName, [runtimeName], versionName)) as GeneratorReturnType<
		typeof loadSchemaAndValues
	>

	yield validateSchemaAndValues(schema, values)
}

function* deployRuntimeVersions(chartName: e_ChartName, runtimeNames: string[], versionName: string) {
	yield put(
		modalManagerActions.showNotificationPopup({
			message: `Trying to deploy version: ${chartName}:${versionName} to ${runtimeNames.join(', ')}`,
		})
	)

	const { schema, values } = (yield loadSchemaAndValues(chartName, runtimeNames, versionName)) as GeneratorReturnType<
		typeof loadSchemaAndValues
	>

	if (!values) {
		return
	}

	if (schema) {
		const validDeployment = (yield validateSchemaAndValues(schema, values)) as GeneratorReturnType<
			typeof validateSchemaAndValues
		>

		if (!validDeployment) {
			return
		}
	} else {
		yield put(
			modalManagerActions.showNotificationPopup({
				message: `Could not validate helm values. Missing schema for ${versionName}`,
			})
		)
	}

	const helmValuesForHelmDeployment = runtimeNames.map((k8sRuntimeName) => ({
		helmDeploymentName: k8sRuntimeName,
		helmValues: values[k8sRuntimeName],
	}))

	try {
		const response = (yield operatorApi.deployHelmChart(
			helmValuesForHelmDeployment,
			chartName,
			versionName
		)) as PromiseReturnType<typeof operatorApi.deployHelmChart>

		if (response.statusCode === 200) {
			const deploymentConflicts = Object.entries(response.deployResponse)
				.filter(([_, value]) => value.statusCode === 409)
				.map(([key, value]) => ({ key, value }))

			if (deploymentConflicts.length > 0) {
				const [conflict, ...restConflicts] = deploymentConflicts
				const cause = '409'
				const name = 'conflict'
				const failedRuntimeNames = restConflicts.reduce((prev, curr) => prev + ', ' + curr.key, conflict.key)
				const message = `Deployment jobs for the following runtimes already exsits: ${failedRuntimeNames}`

				yield put(modalManagerActions.showErrorModal({ cause, name, message }))
			}

			const runtimesWithStartedJobs = getKeysOfValidObjects(
				response.deployResponse,
				(response) => response.isSuccessStatusCode
			)

			yield updateJobStates(runtimesWithStartedJobs, PENDING_JOB_STATE)

			yield put(versionDeploymentActions.setChosenVersion(undefined))
			yield put(versionDeploymentActions.setChosenVersionGroup(undefined))
		} else {
			const cause = response.statusCode.toString()
			const name = 'Deployment Error'
			const message = `Version deployment failed with the following error code: ${cause}`

			yield put(modalManagerActions.showErrorModal({ cause, name, message }))
		}
	} catch (error) {
		put(modalManagerActions.showErrorModal(error as Error))
	}
}

function* updateJobStates(runtimes: string[], status: IDeploymentJobStatus, canSetUnknown?: boolean) {
	const oldJobStates = (yield select(versionDeploymentSelectors.selectJobStates)) as ReturnType<
		typeof versionDeploymentSelectors.selectJobStates
	>

	const jobStates = mapValues(oldJobStates, (states) => getUpdatedRuntimeStates(states, runtimes, status))

	yield put(versionDeploymentActions.setJobStates(jobStates, canSetUnknown))
}

function* abortDeploymentJobs(runtimeNames: string[]) {
	try {
		const responsePromises = recordFromArray(
			runtimeNames,
			(name) => name,
			(name) => operatorApi.abortDeployJob(name)
		)

		const responses = (yield all(responsePromises)) as Record<
			string,
			PromiseReturnType<typeof operatorApi.abortDeployJob>
		>

		const abortedRuntimes = getKeysOfValidObjects(responses, (response) => response.isSuccessStatusCode)

		yield updateJobStates(abortedRuntimes, UNKNOWN_JOB_STATE, true)
	} catch (error) {
		put(modalManagerActions.showErrorModal(error as Error))
	}
}

const getUpdatedRuntimeStates = (
	states: {
		targetName: string
		status: IDeploymentJobStatus
	}[],
	runtimesWithStartedJobs: string[],
	status: IDeploymentJobStatus
) => states.map((state) => (runtimesWithStartedJobs.includes(state.targetName) ? { ...state, status } : state))

const PENDING_JOB_STATE: IDeploymentJobStatus = {
	deploymentJobState: e_DeploymentJobState.pending,
	deploymentJobStateName: e_DeploymentJobState[e_DeploymentJobState.pending],
	deploymentPodLog: '',
}

const UNKNOWN_JOB_STATE: IDeploymentJobStatus = {
	deploymentJobState: e_DeploymentJobState.unknown,
	deploymentJobStateName: e_DeploymentJobState[e_DeploymentJobState.unknown],
	deploymentPodLog: '',
}
