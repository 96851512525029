import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { useResizeObserver } from '../utils/useResizeObserver'
import { useTranslation } from '../../translation'
import { useEventListener } from '../utils/useEventListener'
import { Icon } from '../Icon'

const classes = createStyle((theme) => ({
	tabControl: {
		position: 'relative',
		display: 'flex',
		fontSize: theme.typography.styles.body1.fontSize,
		fontWeight: theme.typography.styles.body1.fontWeight,
		minWidth: 0,
	},

	tabIndicator: {
		width: 0,
		height: 3,
		left: 0,
		background: theme.palette.primary.themePrimary,
		pointerEvents: 'none',
		marginTop: -3,
		willChange: 'width, left',
		position: 'relative',
	},

	tabControlWithSeparator: {
		marginTop: 8,
		marginBottom: 8,
		borderBottom: '1px solid ' + theme.colors.body.divider,
	},
	'flex-start': {
		justifyContent: 'flex-start',
	},
	center: {
		justifyContent: 'safe center',
	},
	'flex-end': {
		justifyContent: 'safe flex-end',
	},
	stretch: {
		justifyContent: 'stretch',
	},
	wrap: {
		flexWrap: 'wrap',
	},

	scrollerEl: {
		flex: 1,
		alignSelf: 'stretch',
		overflowX: 'auto',
		display: 'flex',
		gap: 4,
		// Hide scrollbar
		scrollbarWidth: 'none',
		// Hide scrollbar for Safari
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},

	scrollBtn: {
		flex: '0 0 0.5rem',
		alignSelf: 'stretch',
		paddingInline: '0.5rem',

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingBlock: 0,
		background: 'rgba(0, 0, 0, 0)',
		border: 'none',
		cursor: 'pointer',
		'&:disabled': {
			opacity: 0.5,
			cursor: 'default',
			pointerEvents: 'none',
		},
	},
}))

interface ITabControlHeader {
	children: React.ReactElement | React.ReactElement[]
	displaySeparatorBelow?: boolean
	alignment?: 'flex-start' | 'center' | 'flex-end' | 'stretch'
	wrap?: boolean
	className?: string
}

export const TabControlHeader = React.forwardRef((props: ITabControlHeader, ref: React.Ref<HTMLDivElement>) => {
	const { tcvi } = useTranslation()

	const [hasOverflow, setHasOverflow] = useState(false)
	const [isScrollStart, setIsScrollStart] = useState(true)
	const [isScrollEnd, setIsScrollEnd] = useState(false)

	const scrollerRef = useRef<HTMLDivElement>(null)

	const setScrollPositionInfo = useCallback(() => {
		const scrollerEl = scrollerRef.current
		if (!scrollerEl) {
			return
		}
		setIsScrollStart(scrollerEl.scrollLeft <= 2)
		setIsScrollEnd(scrollerEl.scrollLeft + scrollerEl.clientWidth + 2 >= scrollerEl.scrollWidth)
	}, [])

	useEffect(() => {
		scrollerRef.current?.addEventListener('scroll', setScrollPositionInfo)
		return () => {
			scrollerRef.current?.removeEventListener('scroll', setScrollPositionInfo)
		}
	}, [hasOverflow, scrollerRef.current])

	const onResize = useCallback(() => {
		const scrollerEl = scrollerRef.current
		if (!scrollerEl) {
			return
		}
		if (scrollerEl.scrollWidth > scrollerEl.clientWidth) {
			setHasOverflow(true)
			requestAnimationFrame(() => setScrollPositionInfo())
		} else {
			setHasOverflow(false)
		}
	}, [])
	useResizeObserver(onResize, scrollerRef)

	const scroll = (direction: 'left' | 'right') => () => {
		scrollerRef.current?.scrollTo({
			left: direction === 'left' ? 0 : scrollerRef.current.scrollWidth,
			behavior: 'smooth',
		})
	}

	const onKeyDown = useCallback((e: React.KeyboardEvent) => {
		const activeEl = document.activeElement
		if (!activeEl || !scrollerRef.current?.contains(activeEl)) {
			return
		}

		// This key navigation is based on the fact that the children of scrollerRef are focusable buttons.
		// The scroll buttons are outside the scrollerRef and are not navigatable by keyboard.
		if (e.key === 'ArrowLeft') {
			const prevSibling = activeEl.previousElementSibling
			const prevElement = (prevSibling || scrollerRef.current?.lastElementChild) as HTMLElement | null
			prevElement?.focus({ preventScroll: true })
			// RequestAnimationFrame is needed, otherwise preventScroll will stop the scroll midway.
			requestAnimationFrame(() => prevElement?.scrollIntoView({ inline: 'center' }))
		} else if (e.key === 'ArrowRight') {
			const nextSibling = activeEl.nextElementSibling
			const nextElement = (nextSibling || scrollerRef.current?.firstElementChild) as HTMLElement | null
			nextElement?.focus({ preventScroll: true })
			requestAnimationFrame(() => nextElement?.scrollIntoView({ inline: 'center' }))
		}
	}, [])
	useEventListener('keydown', onKeyDown, scrollerRef.current)

	return (
		<div
			ref={ref}
			className={classNames(
				classes.tabControl,
				props.displaySeparatorBelow && classes.tabControlWithSeparator,
				props.wrap && classes.wrap,
				props.className
			)}
		>
			{hasOverflow && (
				<button
					onClick={scroll('left')}
					aria-label={tcvi('GENERAL:SCROLL_TO_START')}
					title={tcvi('GENERAL:SCROLL_TO_START')}
					tabIndex={-1}
					className={classes.scrollBtn}
					disabled={isScrollStart}
				>
					<Icon iconName="Fluent-ChevronLeft" size="inherit" />
				</button>
			)}
			<div ref={scrollerRef} className={classNames(classes.scrollerEl, props.alignment && classes[props.alignment])}>
				{props.children}
			</div>
			{hasOverflow && (
				<button
					onClick={scroll('right')}
					aria-label={tcvi('GENERAL:SCROLL_TO_END')}
					title={tcvi('GENERAL:SCROLL_TO_END')}
					tabIndex={-1}
					className={classes.scrollBtn}
					disabled={isScrollEnd}
				>
					<Icon iconName="Fluent-ChevronRight" size="inherit" />
				</button>
			)}
		</div>
	)
})

TabControlHeader.displayName = 'TabControlHeader'
