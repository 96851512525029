import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'

import { Checkbox } from '../Checkbox'
import { Icon } from '../Icon'
import { DropdownOptionType } from './Dropdown.types'
import { useDataAttributes } from '../utils/useDataAttributes'

const classes = createStyle((theme) => ({
	listItem: {
		display: 'flex',
		alignItems: 'center',
		color: theme.controls.field.colors.color,
		background: theme.controls.field.colors.buttonBackground,
		textAlign: 'left',
		padding: theme.controls.field.padding,
		outline: 0,
		minHeight: '1.85em',
		userSelect: 'none',
		border: '1px solid transparent',
		'&::before': {
			content: 'none',
		},
	},
	selectableListItem: {
		cursor: 'pointer',
		'&:hover': {
			background: theme.controls.field.hoverColors.buttonBackground,
			color: theme.palette.primary.themePrimary,
		},
		'&:hover:active': {
			background: theme.controls.field.pressedColors.buttonBackground,
		},
	},
	selected: {
		background: theme.controls.field.checkedColors.buttonBackground,
		'&:hover': {
			background: theme.controls.field.hoverColors.buttonBackground,
		},
	},
	text: {
		flex: 1,
		alignItems: 'center',
		minHeight: '1.5em',

		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	header: {
		color: theme.palette.primary.themePrimary,
		fontWeight: '600',
	},

	clearCommand: {
		fontWeight: '600',
	},

	divider: {
		background: theme.colors.list.border,
		height: 1,
		margin: '0.5em',
	},
	disabled: { opacity: 0.5 },
	iconPlaceholder: {
		width: 16,
	},
	marginRight: { marginRight: '0.5rem' },
}))

interface IDropdownListItem {
	id?: string
	label?: string
	onClick?: (index: number) => void
	onMouseEnter?: (index: number) => void
	isSelected?: boolean
	isPreselected?: boolean
	type?: string
	disabled?: boolean
	multiSelect?: boolean
	index?: number
	iconClassName?: string
	iconColor?: string
	screenTip?: string
	reserveIconSpace?: boolean
	contentRender?: (label?: string) => React.ReactNode
	dataAttributes?: Record<string, string>
}

export const DropdownListItem = (props: IDropdownListItem) => {
	const dataAttributes = useDataAttributes(props.dataAttributes, `list-item-${props.index}`)

	if (props.type === DropdownOptionType.divider) {
		return <li className={classes.divider} />
	}

	if (props.type === DropdownOptionType.header) {
		return <li className={classNames(classes.listItem, classes.header)}>{props.label}</li>
	}

	const itemCSS = classNames(classes.listItem, {
		[classes.selectableListItem]: !props.disabled,
		[classes.disabled]: props.disabled,
		[classes.selected]: (!props.multiSelect && props.isSelected) || props.isPreselected,
		[classes.clearCommand]: props.type === 'null',
	})

	const selectByMouseMove = () => {
		props.index !== undefined && props.onMouseEnter?.(props.index)
	}

	return (
		<li
			className={itemCSS}
			onPointerDown={(e) => {
				e.preventDefault()
				e.stopPropagation()
			}}
			onMouseMove={selectByMouseMove}
			onClick={(e) => {
				if (!props.disabled && props.index !== undefined) {
					e.stopPropagation()

					props.onClick?.(props.index)
				}
			}}
			id={props.id}
			data-itemindex={props.index}
			role="option"
			aria-selected={props.isSelected ? 'true' : 'false'}
			aria-label={props.label}
			tabIndex={-1}
			title={props.screenTip}
			{...dataAttributes}
		>
			{props.multiSelect && (
				<Checkbox
					checked={props.isSelected}
					className={classes.marginRight}
					disabled={props.disabled}
					hasCheckMarkOnly
				/>
			)}
			{props.contentRender?.()}
			{!props.contentRender && (
				<>
					{props.iconClassName && (
						<Icon iconClassName={props.iconClassName} className={classes.marginRight} color={props.iconColor} />
					)}
					{!props.iconClassName && props.reserveIconSpace && <span className={classes.iconPlaceholder} />}
					{props.label && <span className={classes.text}>{props.label}</span>}
				</>
			)}
		</li>
	)
}

DropdownListItem.displayName = 'DropdownListItem'
