export enum versionDeploymentTypes {
	START = 'VERSION_DEPLOYMENT/START',
	SET_DEPLOYMENT_VERSIONS = 'VERSION_DEPLOYMENT/SET_DEPLOYMENT_VERSIONS',
	SET_VERSION_OPTIONS = 'VERSION_DEPLOYMENT/SET_VERSION_OPTIONS',
	SET_OPERATOR_VERSION_OPTIONS = 'VERSION_DEPLOYMENT/SET_OPERATOR_VERSION_OPTIONS',
	FETCH_VERSION_OPTIONS = 'VERSION_DEPLOYMENT/FETCH_VERSION_OPTIONS',
	SET_JOB_STATES = 'VERSION_DEPLOYMENT/SET_JOB_STATES',
	FETCH_CONFIGURAION = 'VERSION_DEPLOYMENT/FETCH_CONFIGURAION',
	SET_VERSION_SCHEMA = 'VERSION_DEPLOYMENT/SET_VERSION_SCHEMA',
	SET_RUNTIME_HELM_VALUES = 'VERSION_DEPLOYMENT/SET_RUNTIME_HELM_VALUES',
	ADD_RUNTIME_HELM_VALUES = 'VERSION_DEPLOYMENT/ADD_RUNTIME_HELM_VALUES',
	SET_RUNTIME_VALUE_ERRORS = 'VERSION_DEPLOYMENT/SET_RUNTIME_VALUE_ERRORS',
	DEPLOY_VERSION = 'VERSION_DEPLOYMENT/DEPLOY_VERSION',
	VALIDATE_DEPLOYMENT = 'VERSION_DEPLOYMENT/VALIDATE_DEPLOYMENT',
	ABORT_DEPLOYMENT = 'VERSION_DEPLOYMENT/ABORT_DEPLOYMENT',

	SET_CHOSEN_OPERATING_TYPES = 'VERSION_DEPLOYMENT/SET_CHOSEN_OPERATING_TYPES',
	SET_CHOSEN_VERSION = 'VERSION_DEPLOYMENT/SET_CHOSEN_VERSION',
	SET_CHOSEN_VERSION_GROUP = 'VERSION_DEPLOYMENT/SET_CHOSEN_VERSION_GROUP',

	SET_CHOSEN_OPERATOR_VERSION = 'VERSION_DEPLOYMENT/SET_CHOSEN_OPERATOR_VERSION',
	SET_CHOSEN_OPERATOR_VERSION_GROUP = 'VERSION_DEPLOYMENT/SET_CHOSEN_OPERATOR_VERSION_GROUP',

	SET_RUNTIME_SELECTED_FOR_CONFIGURATION = 'SET_RUNTIME_SELECTED_FOR_CONFIGURATION',

	SET_IS_UNFINISHED_DEPLOY_DISPATCH = 'VERSION_DEPLOYMENT/SET_IS_UNFINISHED_DEPLOY_DISPATCH',
}
