import { useState } from 'react'
import type { ICommonButtonTypes } from '../DialogTypes'

export const useConfirmationMessageCallbacks = (onClose?: (commonButton: ICommonButtonTypes) => void) => {
	const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)

	const handleClose = (commonButton: ICommonButtonTypes) => {
		setShowConfirmationMessage(false)
		onClose?.(commonButton)
	}

	const handleYes = () => {
		handleClose('ok')
	}

	const handleNo = () => {
		handleClose('cancel')
	}

	const handleCancel = () => {
		setShowConfirmationMessage(false)
	}

	return {
		showConfirmationMessage,
		setShowConfirmationMessage,
		handleYes,
		handleNo,
		handleCancel,
	}
}
