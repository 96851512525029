import { e_EnvironmentOperatingType } from 'src/enums/e_EnvironmentOperatingTypes'
import {
	IAvailabilityGroup,
	IEnvironmentAvailabilitySchedule,
	IMaintenanceConfiguration,
} from 'src/interfaces/IAvailabilityWindow'
import { IClusterEvent } from 'src/interfaces/IClusterEvent'
import { IEndpoint } from 'src/interfaces/IEndpoint'
import { IEnvironmentResourceOverview } from 'src/interfaces/IEnvironmentResourceOverview'
import { IBaseK8sRuntime, IK8sRuntime } from 'src/interfaces/IK8sRuntime'
import {
	IFetchEndpoints,
	ISetEndpoints,
	ISetK8sRuntimes,
	ISetEnvironmentAvailabilitySchedule,
	IFetchEnvironmentAvailabilitySchedule,
	ISetEnvironmentResourceOverview,
	IFetchClusterEvents,
	ISetClusterEvents,
	ISetAlwaysUp,
	ISetEnvironmentAvailabilityScheduleBuffer,
	IAddOrUpdateAvailabilityGroup,
	IDeleteAvailabilityGroup,
	ITurnOffEnvironment,
	ISaveEnvironmentAvailabilitySchedule,
	ISetEnvironmentAvailabilityMaintenance,
} from './IKubernetesActions'
import { kubernetesTypes } from './kubernetesTypes'

const fetchEndpoints = (): IFetchEndpoints => ({ type: kubernetesTypes.FETCH_OPERATOR_ENDPOINTS })

const fetchClusterEvents = (): IFetchClusterEvents => ({
	type: kubernetesTypes.FETCH_CLUSTER_EVENTS,
})

const fetchEnvironmentAvailabilitySchedule = (): IFetchEnvironmentAvailabilitySchedule => ({
	type: kubernetesTypes.FETCH_DEPLOYMENT_AVAILABILITY_SCHEDULE,
})
const setK8sRuntimes = (k8sRuntimes: IK8sRuntime[], operatorRuntime: IBaseK8sRuntime): ISetK8sRuntimes => ({
	type: kubernetesTypes.SET_RUNTIME_CONFIGURATIONS,
	k8sRuntimes,
	operatorRuntime,
})

const setEndpoints = (endpoints: IEndpoint[]): ISetEndpoints => ({
	type: kubernetesTypes.SET_ENDPOINTS_CONFIGURATIONS,
	endpoints,
})

const setEnvironmentResourceOverview = (
	environment: e_EnvironmentOperatingType,
	resourceOverview: IEnvironmentResourceOverview
): ISetEnvironmentResourceOverview => ({
	type: kubernetesTypes.SET_ENVIRONMENT_RESOURCE_OVERVIEW,
	environment,
	resourceOverview,
})

const setEnvironmentAvailabilitySchedule = (
	environmentAvailabilitySchedule: IEnvironmentAvailabilitySchedule
): ISetEnvironmentAvailabilitySchedule => ({
	type: kubernetesTypes.SET_DEPLOYMENT_AVAILABILITY_SCHEDULE,
	environmentAvailabilitySchedule,
})

const setEnvironmentAvailabilityScheduleBuffer = (
	startupBufferConfiguration: number,
	shutdownBufferConfiguration: number
): ISetEnvironmentAvailabilityScheduleBuffer => ({
	type: kubernetesTypes.SET_DEPLOYMENT_AVAILABILITY_SCHEDULE_BUFFER,
	startupBufferConfiguration,
	shutdownBufferConfiguration,
})

const setEnvironmentAvailabilityMaintenance = (
	maintenanceConfiguration: IMaintenanceConfiguration
): ISetEnvironmentAvailabilityMaintenance => ({
	type: kubernetesTypes.SET_DEPLOYMENT_AVAILABILITY_MAINTENANCE,
	maintenanceConfiguration,
})

const setAlwaysUp = (alwaysUp: boolean, environmentOperatingType: number): ISetAlwaysUp => ({
	type: kubernetesTypes.SET_ALWAYS_UP,
	environmentOperatingType: environmentOperatingType,
	isAlwaysUp: alwaysUp,
})

const addOrUpdateAvailabilityGroup = (
	availabilityGroup: IAvailabilityGroup,
	environmentOperatingType: e_EnvironmentOperatingType
): IAddOrUpdateAvailabilityGroup => ({
	type: kubernetesTypes.ADD_OR_UPDATE_AVAILABILITY_GROUP,
	availabilityGroup,
	environmentOperatingType,
})

const deleteAvailabilityGroup = (
	environmentOperatingType: e_EnvironmentOperatingType,
	id: string
): IDeleteAvailabilityGroup => ({
	type: kubernetesTypes.DELETE_AVAILABILITY_GROUP,
	environmentOperatingType,
	id,
})

const turnOffEnvironment = (environmentOperatingType: e_EnvironmentOperatingType): ITurnOffEnvironment => ({
	type: kubernetesTypes.TURN_OFF_ENVIRONMENT,
	environmentOperatingType,
})

const saveEnvironmentAvailabilitySchedule = (): ISaveEnvironmentAvailabilitySchedule => ({
	type: kubernetesTypes.SAVE_ENVIRONMENT_AVAILABILITY_SCHEDULE,
})

const setClusterEvents = (clusterEvents: IClusterEvent[]): ISetClusterEvents => ({
	type: kubernetesTypes.SET_CLUSTER_EVENTS,
	clusterEvents,
})

export const kubernetesActions = {
	fetchEndpoints,
	fetchEnvironmentAvailabilitySchedule,
	fetchClusterEvents,
	setK8sRuntimes,
	setEndpoints,
	setEnvironmentResourceOverview,
	setEnvironmentAvailabilitySchedule,
	setEnvironmentAvailabilityScheduleBuffer,
	setEnvironmentAvailabilityMaintenance,
	setAlwaysUp,
	addOrUpdateAvailabilityGroup,
	deleteAvailabilityGroup,
	turnOffEnvironment,
	saveEnvironmentAvailabilitySchedule,
	setClusterEvents,
}
