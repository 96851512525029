import React from 'react'
import classNames from 'clsx'
import { createStyle } from '../../theming'
import { narrowFontStyleToBuiltinTextVariant, Text } from '../Text'
import { Button } from '../Button'
import { useTranslation } from '../../translation'

const classes = createStyle((theme) => ({
	tabControlItem: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: 0,

		cursor: 'pointer',
		color: theme.colors.button.text,
		background: 'none',
		minHeight: '32px',
		userSelect: 'none',
		outline: 0,
		border: `1px solid transparent`,
		'&:not(:disabled):hover': {
			backgroundColor: theme.colors.button.hoveredBackground,
		},
		'&:not(:disabled):active': {
			backgroundColor: theme.colors.button.pressedBackground,
		},
		'&:disabled': {
			cursor: 'default',
			opacity: 0.5,
		},
		'&:focus-visible': {
			border: `1px solid ${theme.colors.body.focusBorder}`,
		},
	},
	headerContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4px 18px 3px',
		flex: 1,
	},

	content: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	closeButton: {
		marginLeft: '8px',
	},

	selected: {
		color: theme.palette.primary.themePrimary,
		'& $activeIndicator': {
			opacity: 1,
		},
		'&:hover $activeIndicator': {
			width: '100%',
		},
	},
	stretch: { flexGrow: 1, alignItems: 'center' },
	activeIndicator: {
		width: 'calc(100% - 12px)',
		alignSelf: 'center',
		height: 3,
		opacity: 0,
		background: theme.palette.primary.themePrimary,
		transition: `opacity, width ${theme.transitions.duration.shorter} ${theme.transitions.easing.easeOut}`,
	},
	ellipsisNoWrap: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}))

interface ITabControlHeaderItem {
	/**
	 * Text that will be rendered in the tab control header
	 */
	text?: string | React.ReactNode
	/**
	 * Custom content thet will be rendered in the tab control header
	 */
	headerContent?: string | React.ReactNode
	onActivate: (e: React.MouseEvent) => void
	isActive?: boolean
	disabled?: boolean
	hidden?: boolean
	stretch?: boolean
	variant?: string
	disableTypographyStyle?: boolean
	className?: string
	onClose?: () => void
	dataAttributes?: Record<string, string>
	screenTip?: string
}

export const TabControlHeaderItem = React.forwardRef(
	(props: ITabControlHeaderItem, ref: React.Ref<HTMLButtonElement>) => {
		const { tcvi } = useTranslation()

		if (props.hidden) {
			return null
		}

		return (
			<button
				className={classNames(classes.tabControlItem, props.className, {
					[classes.stretch]: props.stretch,
					[classes.selected]: props.isActive,
				})}
				onClick={(e: React.MouseEvent) => {
					props.onActivate(e)
				}}
				disabled={props.disabled}
				ref={ref}
				{...props.dataAttributes}
				title={props.screenTip}
				tabIndex={props.isActive ? 0 : -1}
			>
				<span className={classes.headerContent}>
					<span className={classes.content}>
						{props.headerContent}
						{!props.headerContent && (
							<>
								<Text
									variant={narrowFontStyleToBuiltinTextVariant(props.variant)}
									disableTypographyStyle={props.disableTypographyStyle}
									className={classes.ellipsisNoWrap}
									style={{ margin: 0 }}
								>
									{props.text}
								</Text>
							</>
						)}
					</span>
					{props.onClose && (
						<Button
							className={classes.closeButton}
							variant="inline"
							size="extraSmall"
							icon="Fluent-ChromeClose"
							onClick={props.onClose}
							screenTip={tcvi('GENERAL:CLOSE')}
						/>
					)}
				</span>

				<span className={classes.activeIndicator} />
			</button>
		)
	}
)

TabControlHeaderItem.displayName = 'TabControlItem'
