import {
	IAvailabilityGroup,
	IEnvironmentAvailabilitySchedule,
	e_RelativeWeekDay,
} from 'src/interfaces/IAvailabilityWindow'
import { getWeekDayTimeFromDuration } from './dateTimeUtils'
import { generateGuid } from './generateGuid'
import { e_Weekday } from 'src/enums/e_Weekday'

export const deserializeEnvironmentAvailabilitySchedule = (
	schedule: IEnvironmentAvailabilitySchedule
): IEnvironmentAvailabilitySchedule => {
	return {
		id: schedule.id,
		bufferConfiguration: schedule.bufferConfiguration,
		maintenanceConfiguration: schedule.maintenanceConfiguration,
		environmentAvailabilities: schedule.environmentAvailabilities.map((environmentAvailability) => {
			return {
				...environmentAvailability,
				availabilityWindows: environmentAvailability.availabilityWindows.map((window) => {
					if (window.durationInMinutes) {
						window.from.timezone = window.from.timezone || 'UTC'
						return {
							comment: window.description || window.comment,
							id: window.id,
							from: {
								weekDayName: window.from.weekDayName,
								time: window.from.time,
								timezone: window.from.timezone,
							},
							to: getWeekDayTimeFromDuration(window.from, window.durationInMinutes),
							windowType: window.windowType,
							// Ensure backwards compatibility with windows created before "groupId" and "enabled" was added.
							groupId: window.groupId || generateGuid(),
							enabled: window.enabled === undefined ? true : window.enabled,
						}
					} else {
						throw new Error('Missing property "durationInMinutes" in ' + JSON.stringify(window))
					}
				}),
				availabilityGroups: environmentAvailability.availabilityWindows.reduce((accumulator, window) => {
					if (typeof window.durationInMinutes === 'number') {
						window.from.timezone = window.from.timezone || 'UTC'
						window.to = getWeekDayTimeFromDuration(window.from, window.durationInMinutes)
						// Ensure backwards compatibility with windows created before "groupId" and "enabled" was added.
						window.groupId = window.groupId || generateGuid()
						window.enabled = window.enabled === undefined ? true : window.enabled

						if (!accumulator.some((group) => group.id === window.groupId)) {
							let toDay: e_Weekday | e_RelativeWeekDay = window.to.weekDayName
							if (window.to.relativeWeekDay) {
								toDay =
									window.to.weekDayName === window.from.weekDayName
										? e_RelativeWeekDay.sameDay
										: e_RelativeWeekDay.nextDay
							}
							accumulator.push({
								id: window.groupId,
								windowIds: { [window.from.weekDayName]: window.id },
								fromDays: [window.from.weekDayName],
								fromTime: window.from.time,
								toDay: toDay,
								toTime: window.to.time,
								enabled: window.enabled,
								timezone: window.from.timezone,
								comment: window.description || window.comment || '', // Backwards compatibility with "description"
								windowType: window.windowType,
							})
						} else {
							const group = accumulator.find((filteredGroup) => filteredGroup.id === window.groupId)
							if (group) {
								group.fromDays.push(window.from.weekDayName)
								group.windowIds[window.from.weekDayName] = window.id
								// Backwards compatibility
								group.toDay =
									window.to.weekDayName === window.from.weekDayName
										? e_RelativeWeekDay.sameDay
										: e_RelativeWeekDay.nextDay
							}
						}
					} else {
						throw new Error('Missing property "durationInMinutes" in ' + JSON.stringify(window))
					}
					return accumulator
				}, [] as IAvailabilityGroup[]),
			}
		}),
	}
}
