import { DropdownOptionType, IDropdownItem } from '@genusbiz/web-ui/controls'
import { IContainerTag, TVersion } from 'src/interfaces/IContainerRegistry'
import { convertDateTime } from 'src/utils/dateTimeUtils'

const getKey = (version: IDropdownItem<IContainerTag>) =>
	(extractVersion(version.value) ?? 'UNKOWN_VERSION') as TVersion
const extractVersion = (tag: IContainerTag) => tag.name.match(/\d+.\d+/g)?.[0]

const getOptions = (tags: IContainerTag[]) => tags.map((value) => formatOption(value))

const formatOption = (tag: IContainerTag) =>
	({
		value: tag,
		label: tag.name,
		type: DropdownOptionType.item as const,
	}) as IDropdownItem<IContainerTag>

const formatTime = (time: string) => convertDateTime(time, 'LL')

const getVersionEditTimes = (version: IContainerTag) => {
	const createdTime = formatTime(version.createdTime)
	const updatedTime = formatTime(version.lastUpdateTime)

	const isUpdatedVersion = createdTime !== updatedTime

	const timed = isUpdatedVersion ? `Created: ${createdTime}, Last updated: ${updatedTime}` : `Created: ${createdTime}`

	return timed
}

const getSelectedVersionEditTimes = (selectedVersion?: IContainerTag) =>
	selectedVersion && getVersionEditTimes(selectedVersion)

export const versionSelectorUtils = {
	getKey,
	getOptions,
	getSelectedVersionEditTimes,
}
