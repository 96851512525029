import { GridLayout } from '@genusbiz/web-ui/surfaces/Grid/GridLayout'
import { e_GridTrackType, IGridLayout } from '@genusbiz/web-ui/surfaces/Grid/gridTypes'
import { Checkbox, Text } from '@genusbiz/web-ui/controls'
import React from 'react'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { versionDeploymentSelectors } from 'src/features/VersionDeployment/duck/versionDeploymentSelectors'
import { createStyle } from 'src/theming'
import mapValues from 'lodash/mapValues'
import { kubernetesSelectors } from 'src/features/Kubernetes/duck/kubernetesSelectors'
import { isActive } from 'src/utils/publicationIsActive'
import { e_EnvironmentType } from 'src/enums/e_EnvironmentType'
import { e_DeploymentJobState } from 'src/enums/e_DeploymentJobState'
import { IDeploymentJobStatus } from 'src/interfaces/IDeployJob'
import { useDispatch } from 'react-redux'
import { versionDeploymentActions } from 'src/features/VersionDeployment/duck/versionDeploymentActions'
import { VersionWarning } from '../components/VersionWarning'
import classNames from 'clsx'
import { commonStyles } from '../utils/commonStyle'

const classes = createStyle((theme) => ({
	grid: {
		columnGap: theme.spacing.size5,
	},
}))

const ORIGIN = 'origin'
const PASSIVE = 'passive'
const ACTIVE = 'active'

const GRID_AREAS = {
	active_checkbox: 'active_checkbox',
	active_version: 'active_version',
	active_version_info: 'active_version_info',
	passive_checkbox: 'passive_checkbox',
	passive_version: 'passive_version',
	passive_version_info: 'passive_version_info',
	origin_checkbox: 'origin_checkbox',
	origin_version: 'origin_version',
	origin_version_info: 'origin_version_info',
}

const GRID_CONFIG: IGridLayout[] = [
	{
		rows: [{ type: e_GridTrackType.auto }, { type: e_GridTrackType.auto }, { type: e_GridTrackType.auto }],
		columns: [{ type: e_GridTrackType.auto }, { type: e_GridTrackType.auto }, { type: e_GridTrackType.auto }],
		areas: {
			[GRID_AREAS.active_checkbox]: undefined,
			[GRID_AREAS.active_version]: undefined,
			[GRID_AREAS.active_version_info]: undefined,
			[GRID_AREAS.passive_checkbox]: undefined,
			[GRID_AREAS.passive_version]: undefined,
			[GRID_AREAS.passive_version_info]: undefined,
			[GRID_AREAS.origin_checkbox]: undefined,
			[GRID_AREAS.origin_version]: undefined,
			[GRID_AREAS.origin_version_info]: undefined,
		},
	},
	{
		maxWidth: 600,
		rows: [
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
			{ type: e_GridTrackType.auto },
		],
		columns: [{ type: e_GridTrackType.auto }],
		areas: {
			[GRID_AREAS.active_checkbox]: undefined,
			[GRID_AREAS.active_version]: undefined,
			[GRID_AREAS.active_version_info]: undefined,
			[GRID_AREAS.passive_checkbox]: undefined,
			[GRID_AREAS.passive_version]: undefined,
			[GRID_AREAS.passive_version_info]: undefined,
			[GRID_AREAS.origin_checkbox]: undefined,
			[GRID_AREAS.origin_version]: undefined,
			[GRID_AREAS.origin_version_info]: undefined,
		},
	},
]

type TOperatingTypes = typeof ORIGIN | typeof PASSIVE | typeof ACTIVE

export const EnvironmentSelectionPanel = () => {
	const productVersions = useSelector(versionDeploymentSelectors.selectDeploymentVersions)
	const environmentNames = useOperatingTypeEnvironmentNames()

	const environmentTypes = mapValues(environmentNames, (name?: string) => name ?? 'Unknown type')
	const versionNames = mapValues(productVersions, joinVersions)

	const runtimeIsOngoing = useIsOngoingRuntime()

	const chosenOperatingTypes = useSelector(versionDeploymentSelectors.selectChosenOperatingTypes)

	const dispatch = useDispatch()

	const toggleEntry = <T extends { [K in keyof T]: boolean }, K extends keyof T>(obj: T, key: K) => ({
		...obj,
		[key]: !obj[key],
	})

	const toggleActive = (operatingType: TOperatingTypes) =>
		dispatch(versionDeploymentActions.setChosenOperatingTypes(toggleEntry(chosenOperatingTypes, operatingType)))

	const chooseRuntimesIsDisabled = useSelector(versionDeploymentSelectors.selectIsUnfinishedDeployDispatch)

	const versionOptions = useSelector(versionDeploymentSelectors.selectVersionOptions)?.tags.map((tag) => tag.name)

	return (
		<GridLayout
			gridConfig={GRID_CONFIG}
			className={classNames(commonStyles.classes.sectionContentWrapper, classes.grid)}
		>
			<Checkbox
				valueLabel={`Active (${environmentTypes.active})`}
				checked={chosenOperatingTypes.active}
				onChange={() => toggleActive(ACTIVE)}
				data-gridname={GRID_AREAS.active_checkbox}
				disabled={chooseRuntimesIsDisabled || runtimeIsOngoing.active}
			/>
			<Text data-gridname={GRID_AREAS.active_version} className={commonStyles.classes.versionNumber}>
				{versionNames.active}
			</Text>
			<VersionWarning
				data-gridname={GRID_AREAS.active_version_info}
				currentVersions={productVersions.active}
				versionOptions={versionOptions}
			/>

			<Checkbox
				valueLabel={`Passive (${environmentTypes.passive})`}
				checked={chosenOperatingTypes.passive}
				onChange={() => toggleActive(PASSIVE)}
				data-gridname={GRID_AREAS.passive_checkbox}
				disabled={chooseRuntimesIsDisabled || runtimeIsOngoing.passive}
			/>
			<Text data-gridname={GRID_AREAS.passive_version} className={commonStyles.classes.versionNumber}>
				{versionNames.passive}
			</Text>
			<VersionWarning
				data-gridname={GRID_AREAS.passive_version_info}
				currentVersions={productVersions.passive}
				versionOptions={versionOptions}
			/>

			<Checkbox
				valueLabel={`Origin`}
				checked={chosenOperatingTypes.origin}
				onChange={() => toggleActive(ORIGIN)}
				data-gridname={GRID_AREAS.origin_checkbox}
				disabled={chooseRuntimesIsDisabled || runtimeIsOngoing.origin}
			/>
			<Text data-gridname={GRID_AREAS.origin_version} className={commonStyles.classes.versionNumber}>
				{versionNames.origin}
			</Text>
			<VersionWarning
				data-gridname={GRID_AREAS.origin_version_info}
				currentVersions={productVersions.origin}
				versionOptions={versionOptions}
			/>
		</GridLayout>
	)
}

const useIsOngoingRuntime = () => {
	const jobStates = useSelector(versionDeploymentSelectors.selectJobStates)
	const runtimeIsOngoing = mapValues(jobStates, (states) => isSomeOngoingDeployment(states))

	return runtimeIsOngoing
}

const ongoingStates = [e_DeploymentJobState.inProgress, e_DeploymentJobState.pending]

const isSomeOngoingDeployment = (states: { status: IDeploymentJobStatus }[]) =>
	states.some((state) => ongoingStates.includes(state.status.deploymentJobState))

const joinVersions = (versions: string[]) => versions.join(', ')

const useOperatingTypeEnvironmentNames = () => {
	const runtimes = useSelector(kubernetesSelectors.selectK8sRuntimes)
	const activeRuntime = runtimes.find((runtime) => isActive(runtime.currentPublication))
	const passiveRuntime = runtimes.find(
		(runtime) => !isActive(runtime.currentPublication) && runtime.environmentType !== e_EnvironmentType.origin
	)

	return {
		active: activeRuntime?.environmentTypeName,
		passive: passiveRuntime?.environmentTypeName,
	}
}
