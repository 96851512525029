export enum e_ThemeColors {
	primary = 'primary',
	foreground = 'foreground',
	background = 'background',
	controlForeground = 'controlForeground',
	controlBackground = 'controlBackground',
	success = 'success',
	warning = 'warning',
	error = 'error',
	info = 'info',
}
