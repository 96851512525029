import { createStyle } from '@genusbiz/web-ui/theming'
import React, { useEffect, useState } from 'react'

import { Button } from '@genusbiz/web-ui/controls'
import { useTranslation } from 'react-i18next'
import { IPod } from 'src/interfaces/IPod'
import { operatorApi } from 'src/modules/operatorApi'
import { modalManagerActions } from 'src/features/ModalManager/duck'
import { LogContainer } from './LogContainer'
import { useDispatch, useSelector } from 'react-redux'
import { operatorFrontendSelectors } from 'src/features/OperatorFrontend/duck/operatorFrontendSelectors'
import { defaultIcons } from 'src/consts/defaultIcons'

const classes = createStyle({
	dialog: {
		height: '90%',
		width: '90%',
	},
	container: {
		flex: 1,
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		height: '100%',
		backgroundColor: 'white',
	},
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	actionButton: {
		marginLeft: '8px',
		width: '150px',
		height: '35px',
	},
	topContent: {
		padding: '4px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
})

interface IProps {
	pod: IPod
}

export const PodEvents = (props: IProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [events, setEvents] = useState<string>('')
	const [includeTimestamps, setIncludeTimestamps] = useState(true)
	const k8sRuntimeName = useSelector(operatorFrontendSelectors.selectSelectedK8sRuntimeName) || ''

	const onCommandbarSetIncludeTimestamps = () => {
		setIncludeTimestamps(!includeTimestamps)
	}
	useEffect(() => {
		fetchPodEvents()
	}, [])

	const fetchPodEvents = () => {
		void operatorApi
			.fetchOperatorClusterEvents(k8sRuntimeName)
			.then((res) => {
				const clusterEvents = res
				const eventsForSelectedPod = clusterEvents.filter((event) => event.data.involvedObject.name === props.pod.name)
				const eventStrings = eventsForSelectedPod.map((event) => {
					return `${event.objectMetadata.creationTimestamp} {"lastTimeRecorded: "${event.data.lastTimeRecorded}", "count": "${event.data.count}", "type": "${event.data.eventTypeName}", "reason": "${event.data.reason}", "message": "${event.data.message}"}`
				})
				setEvents(eventStrings.join('\n'))
			})
			.catch((err: Error) =>
				dispatch(modalManagerActions.showNotificationPopup({ message: 'Failed to load events: ' + err.message }))
			)
	}

	return (
		<>
			<div className={classes.actions}>
				<Button
					onClick={() => {
						fetchPodEvents()
					}}
					variant="inline"
					className={classes.actionButton}
					icon="Fluent-Refresh"
				>
					{t('GENERAL:REFRESH')}
				</Button>
				<Button
					onClick={() => {
						onCommandbarSetIncludeTimestamps()
					}}
					variant="inline"
					className={classes.actionButton}
					icon={defaultIcons.analysisTimer}
				>
					{includeTimestamps ? t('PODS:HIDE_TIMESTAMPS') : t('PODS:SHOW_TIMESTAMPS')}
				</Button>
			</div>
			<div className={classes.container}>
				<div className={classes.topContent}>
					<div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
						<h4 style={{ color: '#cc3300' }}>{t('PODS:NOTE_SERVER_TIME')}</h4>
					</div>
				</div>
				<LogContainer log={events} />
			</div>
		</>
	)
}
