import React, { useContext } from 'react'
import { AccordionContext } from './AccordionContext'
import clsx from 'clsx'
import { createStyle } from '../../theming'
import { Icon } from '../../controls/Icon'
import { Text } from '../../controls/Text'

const classes = createStyle({
	accordionItem: {},
	accordionItemHeader: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		height: '44px',
		paddingLeft: '4px',
		cursor: 'pointer',
	},
	accordionItemContents: {
		opacity: 0,
		transition: 'opacity 300ms ease-out',
		height: '0px',
	},
	accordionItemContentsExpanded: {
		paddingBottom: '8px',
		opacity: 1,
		height: 'auto',
	},
	icon: {
		transform: 'rotate(0deg)',
		transition: 'transform 100ms ease-out',
	},

	expanded: {
		transform: 'rotate(90deg)',
	},
})

interface IAccordionItemProps {
	id: string
	title: string | React.ReactNode

	children: React.ReactElement
}

export const AccordionItem = (props: IAccordionItemProps) => {
	const accordionContext = useContext(AccordionContext)

	const isExpanded = accordionContext.expandedItemIds.includes(props.id)

	const onHeaderClick = () => {
		accordionContext.onHeaderClick && accordionContext.onHeaderClick(props.id)
	}

	const onHeaderKeyDown = (keyEvent: React.KeyboardEvent) => {
		if (keyEvent.key === 'Enter' || keyEvent.key === ' ') {
			keyEvent.preventDefault()
			keyEvent.stopPropagation()
			onHeaderClick()
		}
	}

	return (
		<div className={classes.accordionItem}>
			<div className={classes.accordionItemHeader} tabIndex={0} onClick={onHeaderClick} onKeyDown={onHeaderKeyDown}>
				<Icon className={clsx(classes.icon, isExpanded && classes.expanded)} iconName={'Fluent-ChevronRight'} />
				{typeof props.title === 'string' && <Text variant={accordionContext.level || 'h5'}>{props.title}</Text>}
				{typeof props.title !== 'string' && props.title}
			</div>
			<div className={clsx(classes.accordionItemContents, isExpanded && classes.accordionItemContentsExpanded)}>
				{isExpanded && props.children}
			</div>
		</div>
	)
}
